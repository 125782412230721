import {styled, Button as MUIButton, ButtonProps, alpha} from '@mui/material'

type HPButtonProps = ButtonProps & {backgroundMode?: 'light' | 'medium'}
/**
 * Styled wrapper around MUI Button component which could be used on light and dark background
 * accepts all MUI Button props plus backgroundMode prop
 */
export const HPButton = styled(MUIButton, {
  shouldForwardProp: (prop) => prop !== 'backgroundMode'
})<HPButtonProps>(({theme: {palette}, backgroundMode = 'light'}) =>
  backgroundMode === 'medium'
    ? {
        // styles for dark background
        '&.MuiButtonBase-root': {
          '& .MuiSvgIcon-root': {
            color: 'inherit'
          },
          '&.MuiButton-containedPrimary': {
            backgroundColor: palette.primary.light,
            color: palette.common.white,
            borderColor: alpha(palette.common.white, 0.4),
            '&:hover ': {
              backgroundColor: palette.primary.main,
              borderColor: palette.primary.main
            },
            '&.Mui-focused': {
              backgroundColor: palette.primary.dark,
              borderColor: palette.primary.dark
            },
            '&.Mui-active': {
              backgroundColor: palette.primary.light,
              borderColor: palette.primary.light
            },
            '&.Mui-disabled': {
              color: alpha(palette.common.white, 0.5),
              backgroundColor: alpha(palette.primary.light, 0.3),
              borderColor: alpha(palette.primary.light, 0.3)
            }
          },
          '&.MuiButton-outlined': {
            backgroundColor: 'unset',
            color: palette.common.white,
            borderColor: alpha(palette.common.white, 0.4),
            '&:hover ': {
              backgroundColor: alpha(palette.common.black, 0.03),
              borderColor: alpha(palette.common.white, 0.15)
            },
            '&.Mui-focused': {
              color: palette.common.white,
              backgroundColor: alpha(palette.common.white, 0.05),
              borderColor: palette.secondary.dark
            },
            '&.Mui-active': {
              backgroundColor: alpha(palette.common.black, 0.05)
            },
            '&.Mui-disabled': {
              color: alpha(palette.common.white, 0.5),
              backgroundColor: alpha(palette.common.white, 0.05),
              borderColor: alpha(palette.common.white, 0.15)
            }
          },
          '&.MuiButton-text': {
            backgroundColor: 'unset',
            color: palette.common.white,
            '&:hover ': {
              backgroundColor: alpha(palette.common.black, 0.03)
            },
            '&.Mui-focused': {
              backgroundColor: alpha(palette.common.white, 0.05)
            },
            '&.Mui-active': {
              backgroundColor: alpha(palette.common.black, 0.05)
            },
            '&.Mui-disabled': {
              color: alpha(palette.common.white, 0.3)
            }
          }
        }
      }
    : // styles for light background, already configured in HProduceTheme
      {}
)
