import {Moment} from 'moment-timezone'

export const convertNumberRangeToDateHourRange = (
  range: [number, number],
  baseRangeStart: Moment
): [Moment, Moment] => {
  const start = baseRangeStart.clone().add(range[0], 'hours')
  const end = baseRangeStart.clone().add(range[1], 'hours')
  return [start, end]
}

export const convertDateHourRangeToNumberRange = (
  range: [Moment, Moment],
  baseRangeStart: Moment
): [number, number] => {
  const start = range[0].diff(baseRangeStart, 'hours')
  const end = range[1].diff(baseRangeStart, 'hours')
  return [start, end]
}

export const shiftRange = (range: [Moment, Moment], days: number, direction: 'left' | 'right') => {
  const [start, end] = range
  const newStart =
    direction === 'left' ? start.clone().add(days, 'days') : start.clone().subtract(days, 'days')
  const newEnd =
    direction === 'left' ? end.clone().add(days, 'days') : end.clone().subtract(days, 'days')
  return [newStart, newEnd]
}

export const calculateRangeWithinBoundary = (
  inputRange: [number, number],
  boundary: [number, number]
): [number, number] => {
  const [startRange, endRange] = inputRange
  const delta = endRange - startRange
  const [min, max] = boundary

  if (delta > max - min) {
    return boundary
  }
  if (startRange < min) {
    return [min, min + delta]
  }
  if (endRange > max) {
    return [max - delta, max]
  }
  return inputRange
}
