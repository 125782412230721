import {useWindowResizeEvent} from '@hconnect/uikit'
import React, {useState, useCallback, useLayoutEffect, useRef} from 'react'

/**
 * hook to use in responsive charts to modify chart width when widnow is resized
 * ref should should be passed to the chart wrapper div
 * width should be used in the scaling fns
 */
export const useResizableChartWidth = (): [React.RefObject<HTMLDivElement>, number] => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  const onWindowResize = useCallback(() => setWidth(0), [])
  useWindowResizeEvent(onWindowResize)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth ?? 0)
  })

  return [ref, width]
}
