import type {NotifyFn} from '@hconnect/uikit/src/common'
import type {AxiosError} from 'axios'

import {MutationError} from './interfaces/api'

type RaiseError = (error: Error | null) => void

export const notifyIfErrorMessage = (
  data: unknown,
  raiseError: RaiseError,
  notify: NotifyFn
): void => {
  if (typeof data === 'string') notify('error', data)
  else if (data) raiseError(new Error(JSON.stringify(data)))
  // TODO: Add generic error message when no data.
}

export const handleConfigurationError = <T>(
  error: AxiosError<MutationError<T>>,
  raiseError: RaiseError,
  notify: NotifyFn
): void => {
  const errorData = error.response?.data
  const errorMessage = typeof errorData === 'string' ? errorData : errorData?.detail
  if (!errorMessage) {
    raiseError(error)
    return
  }
  // handle overall message
  notifyIfErrorMessage(errorMessage, raiseError, notify)
}
