import GetAppIcon from '@mui/icons-material/GetApp'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {queries} from '../../../../../shared/api/queries'
import {useTrackPlannerEvent} from '../../../../../shared/helpers/trackPlannerEvents'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'

export const _ExcelDownload: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  const trackPlannerEvent = useTrackPlannerEvent()

  const {t} = useTranslation()
  const downloadExcel = () => {
    trackPlannerEvent({name: 'onExportProductionPlan'})
    void queries.downloadElectricityDataExcelFile(plantCode)
  }

  return (
    <Box sx={{display: 'flex', alignItems: 'center'}} onClick={downloadExcel}>
      <Button variant="text" sx={{color: 'primary.light'}}>
        <GetAppIcon sx={{mr: 0.5}} /> {t('planning.exportTable')}
      </Button>
    </Box>
  )
}

export const ExcelDownload = React.memo(_ExcelDownload)
