import isEmpty from 'lodash/isEmpty'

import {ShippingType} from '../enums'
import {
  MaterialDemandByDay,
  MaterialDemandById,
  MaterialDemandEntry,
  SeparateDemandPerType,
  CombinedDemandData,
  DemandDataPerMaterial,
  AggregatedDemandData
} from '../interfaces/api'

/**
 * Helper function to filter demand daily entries by materialIds
 * @param materialDemandByDay
 * @param materialIds
 * @returns list of demand entries for selected materials
 */
export const getSelectedMaterialDemandEntries = (
  materialDemandById: MaterialDemandById,
  materialIds?: number[]
) => {
  if (materialIds === undefined) {
    return Object.values(materialDemandById)
  }
  return Object.entries(materialDemandById)
    .filter(([id]) => {
      return materialIds.includes(Number(id))
    })
    .map(([, demand]) => demand)
}

export const getCombinedValueFromValuesPerShippingType = (
  demandPerType: Partial<SeparateDemandPerType>
) => {
  return isEmpty(demandPerType)
    ? null
    : Object.values(demandPerType).reduce((acc, value) => acc + value, 0)
}

/**
 * helper function to get combined demand data for materials daily
 * @param materialDemandByDay
 * @param materialIds
 * @returns combined demand data for materials daily
 */

export const combineDemandDataForMaterials = (materialDemand: MaterialDemandEntry[]) => {
  const addNotNullValue = (acc: number | null, value: number | null) => {
    if (value === null) {
      return acc
    }
    return (acc ?? 0) + value
  }
  return materialDemand.reduce<Record<keyof CombinedDemandData, number | null>>(
    (acc, entry) => {
      const combinedPredictions = getCombinedValueFromValuesPerShippingType(entry.predictions)
      acc.predictions = addNotNullValue(acc.predictions, combinedPredictions)
      const combinedMerged = getCombinedValueFromValuesPerShippingType(entry.merged)
      acc.merged = addNotNullValue(acc.merged, combinedMerged)
      const combinedActuals = getCombinedValueFromValuesPerShippingType(entry.actuals)
      acc.actuals = addNotNullValue(acc.actuals, combinedActuals)
      const combinedPreOrders = getCombinedValueFromValuesPerShippingType(entry.preOrders)
      acc.preOrders = addNotNullValue(acc.preOrders, combinedPreOrders)
      return acc
    },
    {predictions: null, merged: null, actuals: null, preOrders: null}
  )
}

export const getCombinedDemandDataDaily = (
  materialDemandByDay: MaterialDemandByDay,
  materialIds?: number[]
) => {
  const demandData = Object.entries(materialDemandByDay).reduce<CombinedDemandData>(
    (acc, [day, materialDemandById]) => {
      const selectedMaterialDemandEntries = getSelectedMaterialDemandEntries(
        materialDemandById,
        materialIds
      )
      const data = combineDemandDataForMaterials(selectedMaterialDemandEntries)
      // we need to filter null values to distinguish between no data (null) and 0
      if (data.predictions !== null) {
        acc.predictions[day] = data.predictions
      }
      if (data.merged !== null) {
        acc.merged[day] = data.merged
      }
      if (data.actuals !== null) {
        acc.actuals[day] = data.actuals
      }
      if (data.preOrders !== null) {
        acc.preOrders[day] = data.preOrders
      }
      return acc
    },
    {predictions: {}, merged: {}, actuals: {}, preOrders: {}}
  )
  return demandData
}

export const getDemandDataPerMaterial = (
  materialDemandByDay: MaterialDemandByDay
): DemandDataPerMaterial => {
  const demandEntriesByMaterialId = Object.values(materialDemandByDay).reduce<
    Record<string, MaterialDemandEntry[]>
  >((acc, materialDemandById) => {
    Object.entries(materialDemandById).forEach(([materialId, demand]) => {
      if (!acc[materialId]) {
        acc[materialId] = []
      }
      acc[materialId].push(demand)
    })
    return acc
  }, {})

  const aggregatedDemandByMaterialId = Object.fromEntries(
    Object.entries(demandEntriesByMaterialId).map(([materialId, entries]) => {
      return [materialId, getAggregatedDemandData(entries)]
    })
  )

  return aggregatedDemandByMaterialId
}

export const incrementDemandValues = (
  acc: Partial<SeparateDemandPerType>,
  values: Partial<SeparateDemandPerType>
) => {
  Object.entries(values).forEach(([shippingType, value]) => {
    acc[shippingType] = (acc[shippingType] ?? 0) + value
  })
}

export const getAggregatedDemandData = (
  materialDemand: (MaterialDemandEntry | AggregatedDemandData)[]
) => {
  return materialDemand.reduce<AggregatedDemandData>(
    (acc, entry) => {
      incrementDemandValues(acc.actuals, entry.actuals)
      incrementDemandValues(acc.preOrders, entry.preOrders)
      incrementDemandValues(acc.merged, entry.merged)
      incrementDemandValues(acc.predictions, entry.predictions)
      return acc
    },
    {
      actuals: {},
      preOrders: {},
      merged: {[ShippingType.Truck]: 0},
      predictions: {[ShippingType.Truck]: 0}
    }
  )
}
