import {dataTestId} from '@hconnect/uikit'
import {Done} from '@mui/icons-material'
import {Button, DialogContent, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useScheduleCostAvoidanceDialog} from '../../../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {useScheduleCostAvoidanceQuery} from '../../../../shared/hooks/api'

import {NegativeCostAvoidanceInfo} from './NegativeCostAvoidanceInfo'
import {PositiveCostAvoidanceInfo} from './PositiveCostAvoidanceInfo'

interface CostAvoidanceDialogContentProps {
  scheduleId: number
}

export const CostAvoidanceDialogContent: React.FC<CostAvoidanceDialogContentProps> = ({
  scheduleId
}) => {
  const {t} = useTranslation()
  const {setIsDialogOpen} = useScheduleCostAvoidanceDialog()
  const {data: scheduleCostAvoidance, remove: clearCostAvoidance} =
    useScheduleCostAvoidanceQuery(scheduleId)

  const isCostAvoidanceNegative = Boolean(
    scheduleCostAvoidance && scheduleCostAvoidance.costAvoidance <= 0
  )

  const handleClose = () => {
    clearCostAvoidance()
    setIsDialogOpen(false)
  }

  if (!scheduleCostAvoidance) {
    return null
  }

  return (
    <DialogContent sx={{p: 3}} {...dataTestId('cost_avoidance_dialog_content')}>
      {isCostAvoidanceNegative ? (
        <NegativeCostAvoidanceInfo scheduleCostAvoidance={scheduleCostAvoidance} />
      ) : (
        <PositiveCostAvoidanceInfo scheduleCostAvoidance={scheduleCostAvoidance} />
      )}
      <Stack direction="row" justifyContent="center" sx={{pt: 4}}>
        <Button
          variant="contained"
          startIcon={<Done sx={{color: 'common.white'}} />}
          onClick={handleClose}
          {...dataTestId('close_cost_avoidance_dialog_button')}
        >
          {t('common.close')}
        </Button>
      </Stack>
    </DialogContent>
  )
}
