import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useEffect} from 'react'
import {useErrorHandler} from 'react-error-boundary'

import {notifyIfErrorMessage} from '../../../common'
import {CommentsCategory} from '../../../enums'
import {ErrorResponse, Comment} from '../../../interfaces/api'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useScheduleCommentsByScheduleItemId = (
  scheduleItemsIds: string[],
  isDisabled = false
) => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const plantCode = useUrlParam('plantCode')

  const query = usePlannerQuery(
    'comments',
    [
      {
        plantCode,
        commentsCategory: CommentsCategory.AssetOperationTimes,
        commentsCategoryInstanceIds: scheduleItemsIds
      }
    ],
    {
      select: (comments) =>
        comments.reduce(
          (acc, current) => {
            // NOTE: only one comment is possible per schedule item
            acc[current.commentsCategoryInstanceId] = current
            return acc
          },
          {} as Record<string, Comment>
        ),
      keepPreviousData: true,
      enabled: scheduleItemsIds.length > 0 && !isDisabled
    }
  )
  useEffect(() => {
    if (query.isError) {
      notifyIfErrorMessage(
        (query.error as AxiosError<ErrorResponse>)?.response?.data.detail,
        raiseError,
        notify
      )
    }
  }, [query.error, query.isError, notify, raiseError])
  return query
}
