import React, {createContext, useContext, useMemo, useState} from 'react'

import {PlannerForecastType} from '../../../shared/enums'

interface SelectedForecastTypeState {
  selectedForecastType: PlannerForecastType
  setSelectedForecastType: (forecastType: PlannerForecastType) => void
}

const SelectedForecastTypeContext = createContext<SelectedForecastTypeState | undefined>(undefined)

export const useSelectedForecastType = () => {
  const context = useContext(SelectedForecastTypeContext)
  if (!context) {
    throw new Error('useSelectedForecastType must be used within a SelectedForecastTypeProvider')
  }
  return context
}

export const SelectedForecastTypeProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [selectedForecastType, setSelectedForecastType] = useState<PlannerForecastType>(
    PlannerForecastType.MLForecast
  )

  const memoizedState = useMemo(
    () => ({
      selectedForecastType,
      setSelectedForecastType
    }),
    [selectedForecastType, setSelectedForecastType]
  )

  return (
    <SelectedForecastTypeContext.Provider value={memoizedState}>
      {children}
    </SelectedForecastTypeContext.Provider>
  )
}
