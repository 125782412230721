import {ScaleOptions, ChartOptions} from 'chart.js/auto'

export const coreChartConfig: ChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'nearest'
  },
  layout: {
    padding: {
      // to render top grid line correctly
      top: 1,
      left: 0,
      right: 0,
      // to render bottom grid line correctly
      bottom: 1
    }
  }
}

export const coreTimeScaleOptions: ScaleOptions<'time'> = {
  type: 'time',
  position: 'bottom',
  offset: false,
  ticks: {
    display: true,
    padding: 10,
    maxRotation: 0,
    minRotation: 0,
    source: 'labels'
  },
  grid: {
    drawTicks: true,
    lineWidth: 1,
    offset: true
  },
  border: {
    display: true
  }
}

export const coreLinearScaleOptions: ScaleOptions<'linear'> = {
  type: 'linear',
  position: 'left',
  offset: false,
  ticks: {
    display: true,
    padding: 10
  },
  grid: {
    drawTicks: true,
    lineWidth: 1,
    offset: true
  },
  border: {
    display: true
  }
}
