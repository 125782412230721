import {Typography, styled} from '@mui/material'
import React, {CSSProperties, HTMLAttributes} from 'react'

import {dataTestId} from '@hconnect/uikit'
import {HCEMPalette} from '../pallette'

const StyledNotificationDiv = styled('div')(({theme}) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: HCEMPalette.notificationRed,
  color: theme.palette.common.white,
  minWidth: theme.spacing(3),
  minHeight: theme.spacing(3),
  borderRadius: '100%',
  boxSizing: 'content-box'
}))

interface PlannerNotificationProps extends HTMLAttributes<HTMLDivElement> {
  value: number
  testId: string
  styles?: {notification?: CSSProperties}
}

/**
 * this component could be used as a direct child of MUI Tooltip component or on it's own
 * Tooltip component also injects some props to the child component, so that's why we are
 * destructuring rest props directly to the root component
 */
export const PlannerNotification = React.forwardRef<HTMLDivElement, PlannerNotificationProps>(
  ({value, testId = 'planner_notification', styles, ...rest}, ref) => {
    return (
      <StyledNotificationDiv
        {...rest}
        ref={ref}
        style={styles?.notification}
        {...dataTestId(testId)}
      >
        <Typography variant="body2" component="span">
          {value}
        </Typography>
      </StyledNotificationDiv>
    )
  }
)

PlannerNotification.displayName = 'PlannerNotification'
