import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Skeleton, Fade, Stack} from '@mui/material'
import React, {memo} from 'react'

import {usePlanningComments} from '../../../../shared/hooks/api/comments'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

import {CommentSidebarHeader} from './CommentSidebarHeader'
import {CommentSidebarList} from './CommentSidebarList'

interface CommentsSidebarProps {
  width: number
  isOpen: boolean
  closeSidebar: () => void
  isReadOnly: boolean
}

export const _CommentsSidebar: React.FC<CommentsSidebarProps> = ({
  width,
  isOpen,
  closeSidebar,
  isReadOnly
}) => {
  const {startOfPlan, endOfPlan, startOfChart, endOfChart} = usePlanningChartStartEnd()

  const {comments} = usePlanningComments({
    planTimeframe: [startOfPlan, endOfPlan],
    scheduleItemRange: [startOfChart, endOfChart]
  })

  return (
    <Fade in={isOpen}>
      <CardBox
        sx={{
          width,
          position: 'absolute',
          right: 0,
          // negative top to compensate for the card content padding
          top: -24,
          bottom: 0,
          zIndex: 3,
          overflowY: 'auto',
          pt: 0,
          px: 0,
          display: 'flex',
          flexDirection: 'column'
        }}
        {...dataTestId('comments_sidebar')}
      >
        <CommentSidebarHeader comments={comments} onCloseSidebar={closeSidebar} />
        <Stack sx={{p: 3, flex: 1}} spacing={3}>
          {comments ? (
            <CommentSidebarList comments={comments} isReadOnly={isReadOnly} />
          ) : (
            <Skeleton
              variant="rectangular"
              height={'100%'}
              {...dataTestId('comments_sidebar_skeleton')}
            />
          )}
        </Stack>
      </CardBox>
    </Fade>
  )
}

export const CommentsSidebar = memo(_CommentsSidebar)
