import {dataTestId} from '@hconnect/uikit'
import {LockOutlined} from '@mui/icons-material'
import {SvgIcon, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const FixedForOptimizerRow = () => {
  const {t} = useTranslation()

  return (
    <Stack direction="row" alignItems="center" {...dataTestId('fixed_for_optimizer_row')}>
      <SvgIcon component={LockOutlined} sx={{mr: 1}} />
      <Typography variant="body1">{t('planning.fixedForOptimizer')}</Typography>
    </Stack>
  )
}
