import moment, {Moment} from 'moment-timezone'

import {MomentRange} from './../selectors/time'

const startEndParamFormat = 'YYYY-MM-DDTHH'

export const convertToURLDatetime = (datetime: Moment, timezoneId: string) =>
  datetime.clone().tz(timezoneId).format(startEndParamFormat)

/**
 * getting parsed start and end datetime from URL params
 * requires start and end params to pass validation
 * start and end params are in local time
 * @returns only valid moment range or undefined
 */
export const parseStartEndDatetimeFromURLParams = ({
  start: startParam,
  end: endParam,
  timezoneId
}: {
  start: string | null
  end: string | null
  timezoneId: string
}): MomentRange | undefined => {
  // since start and end are in local time, we need to convert them to the timezone
  const parsedStart = startParam !== null ? moment.tz(startParam, timezoneId).utc() : undefined
  const parsedEnd = endParam !== null ? moment.tz(endParam, timezoneId).utc() : undefined
  const areStartAndEndExist = parsedStart && parsedEnd
  if (
    !areStartAndEndExist ||
    !parsedStart.isValid() ||
    !parsedEnd.isValid() ||
    parsedEnd.isSameOrBefore(parsedStart)
  ) {
    return undefined
  }
  return [parsedStart, parsedEnd]
}
