import type {PageProps} from '@hconnect/uikit/src/lib2'
import {Page, TourBanner, mergeSx} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {PlannerHelmet} from '../components/helmets/PlannerHelmet'

import {useFullscreen} from './providers/FullscreenProvider'

type PlannerPageLayoutProps = Partial<
  Pick<
    PageProps,
    | 'title'
    | 'headerActionContent'
    | 'nameHeaderSx'
    | 'sxActionContent'
    | 'aboveTitle'
    | 'sx'
    | 'data-test-id'
  >
> & {
  pageTitle?: string
  children: React.ReactNode
  isTourBannerEnabled: boolean
}

/**
 * Wrapper around the uikit page component that takes care of some default props for planner specifically.
 * @param classNameHeader
 * @param children
 * @param props
 * @constructor
 */
export const PlannerPageLayout = ({
  nameHeaderSx,
  children,
  pageTitle,
  isTourBannerEnabled,
  ...props
}: PlannerPageLayoutProps) => {
  const {isFullScreen} = useFullscreen()
  return (
    <>
      <PlannerHelmet title={pageTitle} />
      {isTourBannerEnabled && <TourBanner sx={{mt: 3, mx: {xs: 2, lg: 6}}} />}
      <Page
        sx={{
          px: {
            xs: 2,
            lg: 6
          }
        }}
        boxed={!isFullScreen}
        nameHeaderSx={mergeSx({mb: 1.5, px: 3}, nameHeaderSx)}
        {...props}
      >
        {children}
      </Page>
    </>
  )
}
PlannerPageLayout.displayName = 'PlannerPageLayout'
