import {
  styled,
  ToggleButtonGroup as MUIToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButton,
  alpha
} from '@mui/material'
import React from 'react'

import {dataTestId} from '../../common'

type BackgroundOption = {backgroundMode?: 'light' | 'dark'}

type StyledToggleButtonGroup = Pick<
  ToggleButtonGroupProps,
  'sx' | 'exclusive' | 'color' | 'disabled'
> &
  BackgroundOption
/**

 */
const StyledToggleButtonGroup = styled(MUIToggleButtonGroup, {
  shouldForwardProp: (prop) => prop !== 'backgroundMode'
})<StyledToggleButtonGroup>(({theme: {palette}, backgroundMode = 'light'}) => ({
  // base style override
  '& .MuiButtonBase-root': {
    paddingTop: 9,
    paddingBottom: 9
  },
  '& svg': {
    marginRight: 8
  },
  // styles for dark background
  ...(backgroundMode === 'dark'
    ? {
        borderColor: alpha(palette.common.white, 0.4),
        '&:hover': {
          borderColor: alpha(palette.common.white, 0.2)
        },
        '& .MuiToggleButtonGroup-grouped:not(.Mui-selected)': {
          backgroundColor: 'unset',
          color: alpha(palette.common.white, 0.6),
          '&:hover': {
            backgroundColor: alpha(palette.common.white, 0.03)
          },
          '&.Mui-disabled svg': {
            color: alpha(palette.common.white, 0.6)
          }
        },
        // primary styles
        '& .MuiToggleButtonGroup-grouped.MuiToggleButton-primary': {
          borderColor: alpha(palette.common.white, 0),
          backgroundColor: 'unset',
          '&:hover': {
            backgroundColor: alpha(palette.primary.light, 0.03)
          },
          color: palette.common.white,
          '&.Mui-selected': {
            backgroundColor: palette.primary.light,
            borderColor: alpha(palette.common.white, 0.15),
            '&:hover': {
              backgroundColor: alpha(palette.primary.light, 0.95)
            }
          }
        },
        // secondary styles
        '& .MuiToggleButtonGroup-grouped.MuiToggleButton-secondary.Mui-selected': {
          backgroundColor: alpha(palette.common.white, 0.05),
          borderColor: alpha(palette.common.white, 0.15),
          color: palette.common.white,
          '&:hover': {
            backgroundColor: alpha(palette.common.white, 0.03)
          }
        }
      }
    : {})
}))

interface ToggleOption<T> {
  value: T
  title: string
  dataTestId?: string
  icon?: JSX.Element
}

interface HPToggleButtonsProps<T> extends StyledToggleButtonGroup {
  value: T
  onChange: (value: T) => void
  options: ToggleOption<T>[]
  ['data-test-id']?: string
}

export const HPToggleButton = <T extends string>({
  value,
  onChange,
  options,
  ...otherProps
}: HPToggleButtonsProps<T>) => {
  return (
    <StyledToggleButtonGroup
      value={value}
      onChange={(e, newValue: T | null) => {
        if (newValue !== null) {
          onChange(newValue)
        }
      }}
      color="primary"
      exclusive
      {...otherProps}
    >
      {options.map((option, index) => (
        <ToggleButton
          key={index}
          value={option.value}
          sx={{
            '& svg': {
              mr: 1
            }
          }}
          {...dataTestId(option.dataTestId ?? `option_${index}`)}
        >
          {option?.icon}
          {option.title}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}
