import {MaterialStorage} from '@hconnect/common/types'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {isStorageWithMaterialGuard} from '../../../selectors/storages'
import {usePlannerQuery} from '../usePlannerQuery'

const selector = (storages: MaterialStorage[]) => {
  const storagesTypesWithMaterial = storages
    .filter(isStorageWithMaterialGuard)
    .map((storage) => storage.materialType)

  return [...new Set(storagesTypesWithMaterial)]
}

export const useCurrentStoredMaterialTypesQuery = () => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('materialStorage', [plantCode], {
    select: selector
  })
}
