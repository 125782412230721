import {dataTestId, formatTimeZoneDate} from '@hconnect/uikit'
import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'

interface OperationTimeStartEndRowProps {
  scheduleItem: ScheduleItem
}

export const OperationTimeStartEndRow = ({scheduleItem}: OperationTimeStartEndRowProps) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  return (
    <Stack spacing={2} direction="row">
      <Stack {...dataTestId('operation_start_time')} sx={{flex: '50%'}}>
        <Typography variant="caption">{t('common.start')}</Typography>
        <Typography variant="body1">
          {formatTimeZoneDate(scheduleItem.start, timezoneId, 'ddd DD, HH:mm', language)}
        </Typography>
      </Stack>
      <Stack {...dataTestId('operation_end_time')} sx={{flex: '50%'}}>
        <Typography variant="caption">{t('common.end')}</Typography>
        <Typography variant="body1">
          {formatTimeZoneDate(scheduleItem.end, timezoneId, 'ddd DD, HH:mm', language)}
        </Typography>
      </Stack>
    </Stack>
  )
}
