import {Moment} from 'moment-timezone'
import {useTranslation} from 'react-i18next'

import {useCurrentTime} from '../../hooks/useCurrentTime'
import {DatetimeValueChartLabels, ChartLabel, DatetimeValueChartLabelsProps} from '../d3chart'

import {useStockDevelopmentChartConfig} from './StockDevelopmentChartConfigProvider'

export type StockDevelopmentChartDatetimeLabelsProps = Omit<
  DatetimeValueChartLabelsProps,
  'overrideXLabel' | 'yTitle' | 'xScale' | 'yScale' | 'verticalSteps' | 'formatDatetime' | 'ySteps'
> & {
  formatDatetime: (datetime: Moment) => string
}

export const StockDevelopmentChartDatetimeLabels: React.FC<
  StockDevelopmentChartDatetimeLabelsProps
> = ({children, formatDatetime, ...props}) => {
  const {t} = useTranslation()
  const {timezoneId, verticalSteps, xScale, yScale} = useStockDevelopmentChartConfig()

  const currentTime = useCurrentTime({timezoneId})

  return (
    <DatetimeValueChartLabels
      {...props}
      ySteps={verticalSteps}
      xScale={xScale}
      yScale={yScale}
      timezoneId={undefined}
      yTitle={t('stock.materialOrders.stockTons')}
      overrideXLabel={(datetime) =>
        datetime.clone().startOf('day').isSame(currentTime.clone().startOf('day'), 'day') ? (
          <ChartLabel
            label={t('common.now')}
            sx={{
              left: xScale(currentTime.clone()),
              transform: 'translateX(-50%)'
            }}
          />
        ) : (
          <ChartLabel
            label={formatDatetime(datetime)}
            sx={{
              left: xScale(datetime.clone()),
              transform: 'translateX(-50%)'
            }}
          />
        )
      }
    >
      {children}
    </DatetimeValueChartLabels>
  )
}
