import {SiloIndicator} from '@hconnect/common/components/materials'
import {HistoryStorageWithMaterial} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {formatFloat} from '@hconnect/uikit/src/common'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Typography, Theme, Tooltip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReachVariant} from '../../../../shared/enums'
import {useMaterialsById} from '../../../../shared/hooks/api'
import type {MaterialInfo} from '../../../../shared/selectors/stock'
import {StockCard} from '../../shared/StockCard'

import {LastSiloMeasurements} from './LastSiloMeasurements'
import {formatReach, formatReachDate, reachToVariant} from './siloCardUtils'

const sortStoragesByNameAsc = (storages: HistoryStorageWithMaterial[]) => {
  const sortedStorages = [...storages]
  sortedStorages.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}))
  return sortedStorages
}

const getColoredInfoWidgetColors = (theme: Theme, variant: ReachVariant) => {
  const colors: Record<ReachVariant, string> = {
    ok: theme.palette.success.main,
    info: theme.palette.warning.main,
    warning: theme.palette.error.dark,
    other: theme.palette.primary.main
  }
  return colors[variant]
}

interface SiloCard {
  materialInfo: MaterialInfo
  timezoneId: string
  colSpan?: number
}

export const SiloCard: React.FC<SiloCard> = ({materialInfo, timezoneId, colSpan = 1}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {data: materialsById} = useMaterialsById()

  const storages = sortStoragesByNameAsc(materialInfo.storages)

  return (
    <StockCard
      page="storage"
      title={materialsById ? materialsById[materialInfo.materialId].name : t('common.loading')}
      additionalHeader={<LastSiloMeasurements silos={storages} timezoneId={timezoneId} />}
      material={materialsById ? materialsById[materialInfo.materialId] : undefined}
      {...dataTestId(`stock_card_${materialInfo.materialId}`)}
      colSpan={colSpan}
    >
      <Box sx={{display: 'flex', mb: 3}}>
        {/* Total */}
        <Box sx={{mr: 2}}>
          <Typography variant="caption">{t('common.total')}</Typography>
          <Typography variant="subtitle1">
            {t('stock.inventory', {
              currentInventory: formatFloat(materialInfo.level, 0, language),
              maxInventory: formatFloat(materialInfo.capacity, 0, language)
            })}
          </Typography>
        </Box>
        {/* Reach */}
        <Box>
          <Typography variant="caption">
            {t('stock.rangeLabel')}
            <Tooltip title={<Typography variant="body1">{t('stock.toolTipMessage')}</Typography>}>
              <InfoOutlined sx={{pl: 0.5, fontSize: '1.5em', height: 'auto'}} />
            </Tooltip>
          </Typography>
          <Tooltip
            title={
              materialInfo.reach !== undefined &&
              materialInfo.maxReach !== undefined &&
              materialInfo.reachDate
                ? t('stock.outOfStockDate', {
                    prefix: t(
                      materialInfo.reach >= materialInfo.maxReach
                        ? 'stock.atLeastUntil'
                        : 'stock.until'
                    ),
                    date: `${formatReachDate(materialInfo.reachDate, timezoneId, language)}`
                  })
                : t('stock.unknown')
            }
          >
            <Typography
              variant="subtitle1"
              sx={(theme) => ({
                color: getColoredInfoWidgetColors(theme, reachToVariant(materialInfo.reach))
              })}
              {...dataTestId(`formatted-reach-${materialInfo.materialId}`)}
            >
              {formatReach(materialInfo, t)}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      {/* Silo indicators */}
      <Box sx={{display: 'grid', gap: 1, gridTemplateColumns: 'repeat(auto-fit, 64px)'}}>
        {storages.map(
          ({id, materialId, currentStorageLevel, capacity, deadStock, name, minimumStockLevel}) => (
            <SiloIndicator
              key={id}
              id={materialId}
              level={currentStorageLevel.level}
              capacity={capacity}
              deadStockLevel={deadStock}
              siloName={name}
              shouldShowName={true}
              minTargetLevel={minimumStockLevel}
            />
          )
        )}
      </Box>
    </StockCard>
  )
}
