import {
  dayFormatter,
  dayNameFormatter,
  formatLocalizedDateMonth,
  formatLocalizedDateWithShortYear,
  shortDayNameFormatter,
  shortMonthNameFormatter,
  timeFormatter
} from '@hconnect/uikit/src/common/utils/dateformatting'
import moment, {Moment, MomentInput} from 'moment-timezone'

import type {Iso8601} from '../interfaces/api'
import type {DatetimeValue} from '../interfaces/common'

export type DateTimeFragment = 'startDate' | 'endDate' | 'startTime' | 'endTime'
export type ParsableTime = string | Date | Moment | number
export interface Timespan<T extends ParsableTime = ParsableTime> {
  start: T
  end: T
}

export type Range<T> = [start: T, end: T]
export type NumberRange = Range<number>
export type ParsableTimeRange<T extends ParsableTime = ParsableTime> = Range<T>
export type DateRange = ParsableTimeRange<Date>
export type MomentRange = ParsableTimeRange<Moment>

export const iso8601Min = (d1: string, d2: string) => (d1 <= d2 ? d1 : d2)

type SplitIntoIntervals = (
  start: MomentInput,
  end: MomentInput,
  intervalLength: number
) => Timespan<string>[]

/**
 * Takes a timespan and splits it up into multiple intervals with given length.
 *
 * @returns ISO8601 formatted start/end strings
 * @param start
 * @param end
 * @param intervalLength Length of the interval in minutes.
 */
export const splitToIntervals: SplitIntoIntervals = (start, end, intervalLength) => {
  const slots = [] as Timespan<string>[]

  const current = moment.utc(start)
  const endString = moment.utc(end).toISOString()

  while (current.isBefore(end)) {
    const s = moment.utc(current).toISOString()
    current.add(intervalLength, 'minutes')
    const e = iso8601Min(current.toISOString(), endString)
    slots.push({start: s, end: e})
  }

  return slots
}

// Takes a date YYYY-MM-DD and a timestamp THH:MM:ss.SSSZ and returns a full date
export const convertToDateTime = (date: string, time: string): Iso8601 =>
  moment.utc(`${date}${time}`).toISOString()

// Takes a date and returns YYYY-MM-DD
export const convertToDate = (dateTime: Moment): string => dateTime.format('YYYY-MM-DD')

// Takes a date and returns THH:mm:ss.SSSZ
export const convertToTime = (dateTime: Moment): string => dateTime.format('THH:mm:ss.SSSZ')

// Checks if a date is inside a given start+end
export const isDateInsideFrame =
  (start: Moment, end: Moment) =>
  (date: MomentInput): boolean =>
    moment.utc(date).isSameOrAfter(start) && moment.utc(date).isSameOrBefore(end)

// Returns either the date, if it's inside the given start+end. If not it returns the start/end closest to the date
export const getDateInsideFrame =
  (start: Moment, end: Moment) =>
  (date: MomentInput): Moment => {
    if (moment.utc(date).isBefore(start)) return moment.utc(start)
    if (moment.utc(date).isAfter(end)) return moment.utc(end)
    return moment.utc(date)
  }

// Returns a list of DateTimes within defaultStart+defaultEnd and filters out DateTimes, that are passed an additional start/end
export const getSelectableDateTime = (
  defaultStart: Moment,
  defaultEnd: Moment,
  timezoneId: string,
  restrict: 'start' | 'end'
) => {
  const selectableDateTime: string[] = []

  const current = defaultStart.clone()

  while (current.isBefore(defaultEnd)) {
    selectableDateTime.push(convertToDate(current.tz(timezoneId)))
    current.add(1, 'day')
  }

  return (restrictedDatetime?: string): string[] => {
    const startOfRestrictedDatetime: string | undefined = convertToDate(
      moment.utc(restrictedDatetime).tz(timezoneId).startOf('day')
    )

    return selectableDateTime.filter(
      (item) =>
        !restrictedDatetime ||
        (restrict === 'start' && item >= startOfRestrictedDatetime) ||
        (restrict === 'end' && item <= startOfRestrictedDatetime)
    )
  }
}

export const getPlantUtcOffset = (plantLocation?: string, date?: Moment): number => {
  if (!plantLocation) {
    return 0
  }
  if (!date) {
    return moment().tz(plantLocation).utcOffset()
  }
  return date.tz(plantLocation).utcOffset()
}

/**
 * @deprecated we should handle this diffently, e.g. by using the moment.tz library
 */
export const adjustEnLocale = (language: string): string => (language === 'en' ? 'en-GB' : language)

export const isWeekend = (date: Iso8601 | Moment, timezoneId: string): boolean =>
  moment.utc(date).tz(timezoneId).day() === 0 || moment.utc(date).tz(timezoneId).day() === 6

/**
 * @deprecated use function from uikit/common/utils formatLocalizedDateMonth
 */
export const getDayAndMonthWithTimezone = (
  date: Moment,
  timezoneId: string,
  language: string
): string => {
  // in order to prevent DST bugs we need to format date with added UTC offset
  // UTC offset in minutes
  const utcOffset = date.tz(timezoneId).utcOffset()
  const dateWithUTCOffset = date.clone().add(utcOffset, 'minutes')
  const day: string = dateWithUTCOffset.locale(language).format('DD')
  const month: string = dateWithUTCOffset.locale(language).format('MM')

  switch (language) {
    case 'de':
    case 'de-DE':
    case 'de-AT':
    case 'de-CH':
    case 'ru':
      return `${day}.${month}.`
    case 'en-US':
      return `${month}/${day}`
    default:
      return `${day}/${month}`
  }
}

interface GetListOfDaysParams {
  start: Moment
  end?: Moment
  additionalDays?: number
}

export const getListOfDays = ({start, end, additionalDays}: GetListOfDaysParams): Moment[] => {
  const retList: Moment[] = []
  retList.push(start)

  if (end !== undefined) {
    const current: Moment = start.clone().add(1, 'day')

    while (current < end) {
      retList.push(current.clone())
      current.add(1, 'days')
    }
  }

  if (additionalDays && additionalDays > 0) {
    const lastInList: Moment = retList[retList.length - 1]

    for (let i = 1; i <= additionalDays; i++) {
      retList.push(lastInList.clone().add(i, 'days'))
    }
  }
  return retList
}

export const groupBy = (
  values: DatetimeValue[],
  groupBy: (dateTime: string) => string
): DatetimeValue[] => {
  const grouped: Record<string, number> = {}
  for (const {datetime, value} of values) {
    const key = groupBy(datetime)
    grouped[key] = (grouped[key] ?? 0) + value
  }
  return Object.entries(grouped).map(([datetime, value]) => ({datetime, value}))
}

/**
 * Function returns summer winter timeshift offset for particular range
 * @param range of localized dates
 * @returns 0 if no timeshift, -1 for time shift to summer time, 1 for timeshift to winter time
 */

export const getTimeShiftHourOffset = (range: Moment[]): -1 | 0 | 1 => {
  const isRangeStartsInSummerTime = range[0].isDST()

  const isTimeShiftOccursWithinRange = (range: Moment[]) => {
    if (isRangeStartsInSummerTime) {
      return !!range.find((date) => !date.isDST())
    }
    return !!range.find((date) => date.isDST())
  }
  const checkIfNextDayWillBeInOtherTime = (lastDay: Moment, nextDay: Moment) => {
    return lastDay.isDST() !== nextDay.isDST()
  }
  const getHourOffset = () => {
    return isRangeStartsInSummerTime ? 1 : -1
  }

  if (isTimeShiftOccursWithinRange(range)) {
    return getHourOffset()
  }
  if (
    checkIfNextDayWillBeInOtherTime(
      range[range.length - 1].clone(),
      range[range.length - 1].clone().add(1, 'day')
    )
  ) {
    return getHourOffset()
  }

  return 0
}

/**
 *
 * function to return localized date time, example: "ddd DD/MM, HH:mm"
 * (short day name, day of month, hours and minutes)
 */
export const getLocalizedDayMonthTimeFormat = (
  datetime: Moment,
  timezoneId: string,
  locale: string
): string => {
  const timezoneOffset = datetime.tz(timezoneId).utcOffset()

  const shortDayName = shortDayNameFormatter(datetime, locale, timezoneOffset)
  const dateMonth = formatLocalizedDateMonth(datetime, locale)
  const time = timeFormatter(datetime, locale, timezoneOffset)
  const formattedTime = `${shortDayName} ${dateMonth}, ${time}`
  return formattedTime
}

/**
 * function to return localized short month name and day of month, example: "MMM DD"
 * utc offset would be calculated based on provided datetime
 */
export const getLocalizedShortMonthDayFormat = (datetime: Moment, locale: string): string => {
  const utcOffset = datetime.utcOffset()
  const shortMonth = shortMonthNameFormatter(datetime.toDate(), locale, utcOffset)
  const day = dayFormatter(datetime.toDate(), locale, utcOffset)
  return `${shortMonth} ${day}`
}

type ValueAndCount = {
  value: number
  count: number
}
/**
 * function to calculate daily average value timezone aware
 * @param data should be in regular interval(e.g. 15 minutes)
 * @param timezoneId
 * @returns DatetimeValue<Moment>[]
 */

export const calculateDailyAverage = (
  data: DatetimeValue<Moment>[],
  timezoneId: string
): DatetimeValue<Moment>[] => {
  const dailyValueAndCount = data.reduce<Record<string, ValueAndCount>>((acc, entry) => {
    const date = entry.datetime.clone().tz(timezoneId).startOf('day').toISOString()

    if (!acc[date]) {
      acc[date] = {value: 0, count: 0}
    }
    acc[date].value += entry.value
    acc[date].count += 1
    return acc
  }, {})

  return Object.entries(dailyValueAndCount).map(([datetime, {value, count}]) => {
    return {
      datetime: moment.utc(datetime).tz(timezoneId),
      value: value / count
    }
  })
}

export const isStartOfDay = (datetime: Moment) => datetime.isSame(datetime.clone().startOf('day'))

/**
 * function to format long day name and date considering locale
 * @param datetime
 * @param locale
 */

export const longDayNameAndDateFormatter = (
  datetime: Moment,
  locale: string,
  options?: {hideYear: boolean}
): string => {
  const day = dayNameFormatter(datetime, locale, datetime.utcOffset())
  const shouldHideYear = options?.hideYear ?? false
  const formatDateFn = shouldHideYear ? formatLocalizedDateMonth : formatLocalizedDateWithShortYear
  const date = formatDateFn(datetime, locale)
  return `${day}, ${date}`
}

/**
 * function to format range of dates with long day name and date considering locale
 * if range is in the same year, the year for the first date is omitted
 */

export const longDayNameAndDateRangeFormatter = (
  [start, end]: MomentRange,
  locale: string,
  timezoneId: string
): string => {
  const isSameYear = start.tz(timezoneId).isSame(end.tz(timezoneId), 'year')
  const startFormatted = longDayNameAndDateFormatter(start.tz(timezoneId), locale, {
    hideYear: isSameYear
  })
  const endFormatted = longDayNameAndDateFormatter(end.tz(timezoneId), locale)
  return `${startFormatted} - ${endFormatted}`
}
