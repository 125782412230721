import {PermissionType} from '@hconnect/apiclient/src/types'
import {useMemo} from 'react'

import {CommentsCategory} from '../../enums'
import {usePermissionsQuery} from '../../hooks/api'
import {checkPermissionAccessByCountryOrPlantId} from '../../permissions'
import {usePlantConfig} from '../usePlantConfigData'
import {useUrlParam} from '../useUrlParam'

enum CommentPermissionTypes {
  ViewComments = 'VIEW_COMMENTS',
  AddComments = 'ADD_COMMENTS',
  EditComments = 'EDIT_COMMENTS',
  DeleteComments = 'DELETE_COMMENTS'
}
/**
 * @deprecated
 */

export const useCommentsPermissions = (category: CommentsCategory) => {
  const {data: permissions} = usePermissionsQuery()
  const latest = usePlantConfig()
  const plantCode = useUrlParam('plantCode')
  const countryId = latest.country

  return useMemo(() => {
    const commentsPermissions = (permissions || []).filter(({permissionType, parsedDataScope}) => {
      const commentPermissionDataScope = parsedDataScope
      return (
        (
          [
            CommentPermissionTypes.ViewComments,
            CommentPermissionTypes.AddComments,
            CommentPermissionTypes.EditComments,
            CommentPermissionTypes.DeleteComments
          ] as PermissionType[]
        ).includes(permissionType) &&
        commentPermissionDataScope.commentCategory === category &&
        checkPermissionAccessByCountryOrPlantId(countryId, plantCode, commentPermissionDataScope)
      )
    })

    const commentsPermissionTypes = commentsPermissions.map(
      (permission) => permission.permissionType
    )

    const canViewComments = commentsPermissionTypes.includes(CommentPermissionTypes.ViewComments)
    const canAddComments = commentsPermissionTypes.includes(CommentPermissionTypes.AddComments)
    const canEditComments = commentsPermissionTypes.includes(CommentPermissionTypes.EditComments)
    const canDeleteComments = commentsPermissionTypes.includes(
      CommentPermissionTypes.DeleteComments
    )
    return {canViewComments, canAddComments, canEditComments, canDeleteComments}
  }, [permissions, category, plantCode, countryId])
}
