import {Schedule} from '../../../interfaces/api'
import {MomentRange} from '../../../selectors/time'
import {usePeriodicAutofillCheck} from '../../usePeriodicAutofillCheck'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface ScheduleQueryParams {
  isDisabled?: boolean
  range: MomentRange
}

const countScheduleItemsSelector = (data: Schedule) => Object.values(data?.schedules ?? {}).length

export const useCountScheduleItemsQuery = ({
  isDisabled = false,
  range: [startOfPlan, endOfPlan]
}: ScheduleQueryParams) => {
  const plantCode = useUrlParam('plantCode')

  // schedule is reloaded if there is a change in the autofill status
  usePeriodicAutofillCheck()

  return usePlannerQuery(
    'schedule',
    [{plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}],
    {enabled: !isDisabled, select: countScheduleItemsSelector}
  )
}
