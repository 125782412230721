import {dataTestId} from '@hconnect/uikit'
import {Box, useTheme} from '@mui/material'
import React from 'react'

const LINE_WIDTH_PX = 2

export interface CurrentTimeIndicatorProps {
  xOffset: number
}

export const CurrentTimeIndicator: React.FC<CurrentTimeIndicatorProps> = ({xOffset}) => {
  const {palette} = useTheme()

  // no need to display it outside of the viewport
  if (xOffset < 0) return null
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: Math.round(xOffset - LINE_WIDTH_PX / 2),
        height: 1,
        width: LINE_WIDTH_PX,
        backgroundColor: palette.text.primary,
        pointerEvents: 'none',
        zIndex: 1
      }}
      {...dataTestId('current_time_indicator')}
    />
  )
}
