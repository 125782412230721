import React, {HTMLAttributes} from 'react'

export type FormatLabelFn = (value: number) => React.ReactNode

export interface LabelProps extends HTMLAttributes<HTMLDivElement> {
  value: number
  formatLabel?: FormatLabelFn
}
export const Label: React.FC<LabelProps> = ({value, formatLabel, ...props}) => {
  const labelValue = formatLabel?.(value)
  return (
    <div style={{textAlign: 'center', pointerEvents: 'none', flex: 1}} {...props}>
      {labelValue}
    </div>
  )
}

export interface ActiveTrackLabelProps {
  min: number
  max: number
}

export const DefaultActiveTrackLabel: React.FC<ActiveTrackLabelProps> = ({min, max}) => (
  <div style={{textAlign: 'center', pointerEvents: 'none'}}>{`${min} - ${max}`}</div>
)
