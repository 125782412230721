import {Palette, Typography, Paper} from '@mui/material'
import React from 'react'

import {dataTestId as spreadDataTestId} from '../../../common/utils/testingUtils'

type AccentColor = keyof Pick<Palette, 'info' | 'error' | 'warning' | 'success'>

export interface AlertProps {
  /**
   * Default is 'info'
   */
  accentColor?: AccentColor
  content: string | React.ReactNode
  'data-test-id'?: string
}

export const Alert: React.FC<AlertProps> = ({
  accentColor = 'info',
  content,
  'data-test-id': dataTestId
}) => {
  const isStringContent = typeof content === 'string'
  return (
    <Paper
      sx={{
        position: 'relative',
        overflow: 'hidden',
        px: 2,
        py: 1.5,
        '&::before': {
          content: "''",
          left: 0,
          top: 0,
          position: 'absolute',
          width: '4px',
          height: '100%',
          display: 'block',
          backgroundColor: accentColor + '.main'
        }
      }}
      {...spreadDataTestId(dataTestId ?? 'info_panel')}
    >
      {isStringContent ? <Typography variant="body1">{content}</Typography> : content}
    </Paper>
  )
}
