import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  EnvAuthRequestProvider,
  getDefaultDFBackends
} from '@hconnect/apiclient'

export const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
const selectedBackend = backendSelector.getSelectedBackend()

export const loginStorage = new BrowserLoginStorage(
  `HC-${process.env.REACT_APP_CLIENT_NAME}-${selectedBackend.NAME}`
)

export const authRequestProvider = new EnvAuthRequestProvider(backendSelector)
export const loginFlow = new BrowserLoginFlow(loginStorage, authRequestProvider, backendSelector)
