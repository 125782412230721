import type {BaseUrlOverride} from './types'

const backendServices = [
  'rootcausefailureanalysis',
  'comments',
  'scheduler',
  'permissions',
  'identity',
  'configurations',
  'predict',
  'predictDev',
  'materials',
  'assets'
] as const

export type BackendService = (typeof backendServices)[number]

// Service-specific override Definitions. Keys here can be specified in`.env.devLocal` files to have the FE intercept calls to the route and send them to a locally running BE
export const backEndServiceOverrides: Record<BackendService, BaseUrlOverride> = {
  configurations: {
    urlMatcher: /\/configurations/,
    baseUrl: 'http://localhost:5666'
  },
  identity: {
    urlMatcher: /\/identity/,
    baseUrl: 'http://localhost:5000'
  },
  permissions: {
    urlMatcher: /\/(users|roles)/,
    baseUrl: 'http://localhost:5522'
  },
  comments: {
    urlMatcher: /\/comments/,
    baseUrl: 'http://localhost:5760'
  },
  rootcausefailureanalysis: {
    urlMatcher: /\/rootcausefailureanalysis/,
    baseUrl: 'http://localhost:6500'
  },
  scheduler: {
    urlMatcher: /\/schedules/,
    baseUrl: 'http://localhost:5740'
  },
  predict: {
    urlMatcher: /\/predict/,
    baseUrl: 'http://localhost:5666'
  },
  predictDev: {
    urlMatcher: /\/predict/,
    baseUrl: 'https://api-dev.hconnect.heidelbergcement.com/'
  },
  materials: {
    urlMatcher: /\/(materials|measurements)/,
    baseUrl: 'http://localhost:5770'
  },
  assets: {
    urlMatcher: /\/assets/,
    baseUrl: 'http://localhost:5780'
  }
}
