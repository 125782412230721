import {MaterialType} from '@hconnect/common/types'
import {Grid} from '@mui/material'
import {FC} from 'react'

import {RouteName} from '../../routing'
import {SimpleStockDevelopmentCard} from '../../shared/components/stockDevelopmentChart/SimpleStockDevelopmentCard'
import {pageDataTestId} from '../../shared/formatutils'
import {useStoredMaterialIdsQuery} from '../../shared/hooks/api'
import {useSearchParams} from '../../shared/hooks/useSearchParams'

export const PageMaterials: FC = () => {
  const [obtainedParams] = useSearchParams('materialType')
  const obtainedMaterialType = obtainedParams.materialType
  if (obtainedMaterialType === null) {
    throw new Error('BUG: materialType search param should be set before rendering this component')
  }
  const selectedMaterialType = obtainedMaterialType as MaterialType

  const {data: selectedMaterialIds} = useStoredMaterialIdsQuery({
    materialType: selectedMaterialType
  })
  if (!selectedMaterialIds) {
    throw new Error('Bug: storages with material should be loaded to render this component')
  }

  return (
    <Grid container spacing={2} {...pageDataTestId(RouteName.STOCK_MATERIAL)}>
      {selectedMaterialIds.map((id) => (
        <Grid key={id} item xs={12} lg={6}>
          <SimpleStockDevelopmentCard materialId={id} materialIds={selectedMaterialIds} />
        </Grid>
      ))}
    </Grid>
  )
}
