import {Moment} from 'moment-timezone'
import {useMemo} from 'react'

import {AssetType} from '../../../shared/enums'
import {useElectricityPricesDataQuery} from '../../../shared/hooks/api/dataScience/useElectricityPricesDataQuery'
import {useScheduleQuery} from '../../../shared/hooks/api/schedules/useScheduleQuery'
import {useAssetHistoryDataByScheduleItemId} from '../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {getKPIs} from '../../../shared/scheduleKPIs'

interface ScheduleKPIs {
  totalCosts: number
  totalCementTons: number
  totalMWh: number
  costsPerTon: number
  costsPerMWh: number
}

type UseOptimizedAndManualScheduleKPIs = (
  startOfPlan: Moment,
  endOfPlan: Moment
) => Record<'manual' | 'optimized', ScheduleKPIs> | undefined

export const useOptimizedAndManualScheduleKPIs: UseOptimizedAndManualScheduleKPIs = (
  startOfPlan,
  endOfPlan
) => {
  const {data: prices} = useElectricityPricesDataQuery([startOfPlan, endOfPlan])
  const {data: manualSchedule} = useScheduleQuery({
    range: [startOfPlan, endOfPlan],
    isOptimized: false
  })
  const {data: optimizedSchedule} = useScheduleQuery({
    range: [startOfPlan, endOfPlan],
    isOptimized: true
  })

  const historyAssetsByManualScheduleItemId = useAssetHistoryDataByScheduleItemId({
    timeFrame: [startOfPlan, endOfPlan],
    schedule: manualSchedule
  })
  const historyAssetsByOptimizedScheduleItemId = useAssetHistoryDataByScheduleItemId({
    timeFrame: [startOfPlan, endOfPlan],
    schedule: optimizedSchedule
  })

  return useMemo(() => {
    if (
      !prices ||
      !manualSchedule ||
      !optimizedSchedule ||
      !historyAssetsByManualScheduleItemId ||
      !historyAssetsByOptimizedScheduleItemId
    ) {
      return undefined
    }
    const manualSchedulesForCements = Object.fromEntries(
      Object.entries(manualSchedule.schedules).filter(
        ([scheduleItemId]) =>
          historyAssetsByManualScheduleItemId[scheduleItemId].asset.type === AssetType.CementMill
      )
    )
    const optimizedSchedulesForCements = Object.fromEntries(
      Object.entries(optimizedSchedule.schedules).filter(
        ([scheduleItemId]) =>
          historyAssetsByOptimizedScheduleItemId[scheduleItemId].asset.type === AssetType.CementMill
      )
    )

    const {
      assetsKPIs: {total: manualTotalKPIs}
    } = getKPIs({
      prices: prices.mergedPrices,
      schedules: manualSchedulesForCements,
      startDatetime: startOfPlan,
      endDatetime: endOfPlan,
      assetDataByScheduleItemId: historyAssetsByManualScheduleItemId
    })

    const {
      assetsKPIs: {total: optimizedTotalKPIs}
    } = getKPIs({
      prices: prices.mergedPrices,
      schedules: optimizedSchedulesForCements,
      startDatetime: startOfPlan,
      endDatetime: endOfPlan,
      assetDataByScheduleItemId: historyAssetsByOptimizedScheduleItemId
    })

    return {
      manual: {
        totalCosts: manualTotalKPIs.costs,
        totalCementTons: manualTotalKPIs.producedTons,
        totalMWh: manualTotalKPIs.consumedMWh,
        costsPerTon:
          manualTotalKPIs.costs === 0 ? 0 : manualTotalKPIs.costs / manualTotalKPIs.producedTons,
        costsPerMWh:
          manualTotalKPIs.consumedMWh === 0
            ? 0
            : manualTotalKPIs.costs / manualTotalKPIs.consumedMWh
      },
      optimized: {
        totalCosts: optimizedTotalKPIs.costs,
        totalCementTons: optimizedTotalKPIs.producedTons,
        totalMWh: optimizedTotalKPIs.consumedMWh,
        costsPerTon:
          optimizedTotalKPIs.costs === 0
            ? 0
            : optimizedTotalKPIs.costs / optimizedTotalKPIs.producedTons,
        costsPerMWh:
          optimizedTotalKPIs.consumedMWh === 0
            ? 0
            : optimizedTotalKPIs.costs / optimizedTotalKPIs.consumedMWh
      }
    }
  }, [
    startOfPlan,
    endOfPlan,
    prices,
    manualSchedule,
    optimizedSchedule,
    historyAssetsByManualScheduleItemId,
    historyAssetsByOptimizedScheduleItemId
  ])
}
