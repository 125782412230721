import {Moment} from 'moment-timezone'

import {PriceLevel} from './enums'
import {DatetimeValue, PricePerHour} from './interfaces/common'

export interface AmpelItem {
  startDatetime: string
  endDatetime: string
  priceLevel: PriceLevel
}

// TODO HCP-74416
export type AmpelPriceLevels =
  | {
      minPrice: number
      veryLowPriceMax: number
      lowPriceMax: number
      midPriceMax: number
      highPriceMax: number
      maxPrice: number
    }
  | undefined

export const computePriceLevelsByQuantile = (
  prices: DatetimeValue<Moment | string>[]
): AmpelPriceLevels => {
  // TODO HCP-74416
  if (prices.length === 0) {
    return undefined
  }
  const sortedPrices = prices.map((o) => o.value).sort((a, b) => a - b)
  const N = sortedPrices.length

  const minPrice = sortedPrices[0]
  const veryLowPriceMax = sortedPrices[Math.floor((N - 1) / 5)]
  const lowPriceMax = sortedPrices[Math.floor(((N - 1) * 2) / 5)]
  const midPriceMax = sortedPrices[Math.floor(((N - 1) * 3) / 5)]
  const highPriceMax = sortedPrices[Math.floor(((N - 1) * 4) / 5)]
  const maxPrice = sortedPrices[N - 1]

  return {minPrice, veryLowPriceMax, lowPriceMax, midPriceMax, highPriceMax, maxPrice}
}
/**
 * function to get price level from price and calculated price levels
 * @param price
 * @param priceLevels
 * @returns
 */
export const getPriceLevel = (
  price: number,
  priceLevels: AmpelPriceLevels | undefined
): PriceLevel => {
  if (!priceLevels) {
    return PriceLevel.unknown
  }
  if (price <= priceLevels.veryLowPriceMax) {
    return PriceLevel.veryLow
  }
  if (price <= priceLevels.lowPriceMax) {
    return PriceLevel.low
  }
  if (price <= priceLevels.midPriceMax) {
    return PriceLevel.mid
  }
  if (price <= priceLevels.highPriceMax) {
    return PriceLevel.high
  }
  return PriceLevel.veryHigh
}

export const getPricePerHour = (
  datetimeValues: DatetimeValue<Moment>[],
  priceLevels: AmpelPriceLevels | undefined
): PricePerHour => {
  if (!priceLevels) {
    return {}
  }
  const tmp: {[key: string]: {sum: number; count: number}} = {}

  for (const entry of datetimeValues) {
    const date = entry.datetime.clone().startOf('hour').toISOString()

    if (!(date in tmp)) {
      tmp[date] = {sum: 0, count: 0}
    }
    tmp[date].sum += entry.value
    tmp[date].count += 1
  }

  const result: PricePerHour = {}

  for (const k of Object.keys(tmp)) {
    result[k] = {
      price: tmp[k].sum / tmp[k].count,
      level: PriceLevel.unknown
    }

    result[k].level = getPriceLevel(result[k].price, priceLevels)
  }
  return result
}
