import MockAdapter from 'axios-mock-adapter'
import moment from 'moment'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx} from './utils'

export const enableAssetsEndpoints = (mock: MockAdapter) => {
  // GET history assets
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/assets/history$`)).reply((config) => {
    const {from, to} = config.params as {from: string; to: string}
    const {
      burglengenfeld: {historyAssets, backendErrors}
    } = mockStore.scenario()

    const selectedAssetsSnapshotEntries = Object.entries(historyAssets)
      .map(
        ([assetId, assetSnapshots]) =>
          [
            assetId,
            assetSnapshots.filter((asset) =>
              moment.utc(asset.updatedOn ?? asset.createdOn).isBefore(to)
            )
          ] as [string, typeof assetSnapshots]
      )
      .map(([assetId, assetSnapshots]) => {
        const filterByFromDate = assetSnapshots.filter((asset) =>
          moment.utc(asset.updatedOn ?? asset.createdOn).isAfter(from)
        )
        return [
          assetId,
          filterByFromDate.length ? filterByFromDate : [assetSnapshots[assetSnapshots.length - 1]]
        ] as [string, typeof filterByFromDate]
      })
    const selectedAssetHistory = Object.fromEntries(selectedAssetsSnapshotEntries)

    if (backendErrors.assetsError) {
      return sleepResponse(backendErrors.assetsError)
    }

    return sleepResponse([200, selectedAssetHistory])
  })

  return mock
}
