import {useCallback} from 'react'

import {StockDevelopmentResponse} from '../../../interfaces/api'
import {
  getStockDevelopmentDataPerMaterial,
  getStockDevelopmentForRange
} from '../../../selectors/stockDevelopment'
import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {STOCK_DEVELOPMENT_END_HOURS_PADDING} from '../constants'
import {usePlannerQuery} from '../usePlannerQuery'

interface StockDevelopmentParams {
  materialIds: number[] | undefined
  timeframe: MomentRange
  selectedRange?: MomentRange
  useOptimizedSchedule?: boolean
}

export const useStockDevelopmentQuery = ({
  materialIds = [],
  timeframe,
  selectedRange,
  useOptimizedSchedule = false
}: StockDevelopmentParams) => {
  const plantCode = useUrlParam('plantCode')

  const selector = useCallback(
    (stockDevelopment: StockDevelopmentResponse | undefined) => {
      if (!stockDevelopment) {
        return undefined
      }
      const selectedStockDevelopment = selectedRange
        ? getStockDevelopmentForRange(stockDevelopment, selectedRange)
        : stockDevelopment

      return getStockDevelopmentDataPerMaterial(selectedStockDevelopment)
    },
    [selectedRange]
  )
  return usePlannerQuery(
    'stockDevelopment',
    [
      {
        plantCode,
        materialIds,
        from: timeframe[0].toISOString(),
        // We need an additional hour of stock levels data to display material orders correctly
        to: timeframe[1]
          .clone()
          .add(STOCK_DEVELOPMENT_END_HOURS_PADDING, 'hour')
          .startOf('hour')
          .toISOString(),
        useOptimizedSchedule
      }
    ],
    {enabled: Boolean(materialIds.length), select: selector}
  )
}
