import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useEffect} from 'react'
import {useErrorHandler} from 'react-error-boundary'

import {notifyIfErrorMessage} from '../../../common'
import {useSelectedTimeframe} from '../../../components/providers/SelectedTimeframeProvider'
import {CommentsCategory} from '../../../enums'
import {ErrorResponse, Comment} from '../../../interfaces/api'
import {checkPermissionCommentCategory} from '../../../permissions'
import {usePermission} from '../../permissions/usePermission'
import {useUrlParam} from '../../useUrlParam'
import {useMaterialOrdersQuery} from '../materialOrders'
import {usePlannerQuery} from '../usePlannerQuery'

/**
 * hook to return comments for orders in a selected timeframe for a specific materialId
 * should be used when materialId url param is available
 * @returns
 */

export const useMaterialOrdersCommentsByOrderId = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const plantCode = useUrlParam('plantCode')
  const materialIdParam = useUrlParam('materialId')
  const materialId = Number(materialIdParam)

  const {selectedTimeframe} = useSelectedTimeframe()
  const {data: materialOrders} = useMaterialOrdersQuery({
    materialId,
    timeframe: selectedTimeframe
  })

  const materialOrdersIds = materialOrders?.map(({id}) => id) ?? []

  const canViewComments = usePermission(
    'VIEW_COMMENTS',
    checkPermissionCommentCategory(CommentsCategory.MaterialOrders)
  )

  const shouldEnableQuery = materialOrdersIds.length > 0 && canViewComments

  const query = usePlannerQuery(
    'comments',
    [
      {
        plantCode,
        commentsCategory: CommentsCategory.MaterialOrders,
        commentsCategoryInstanceIds: materialOrdersIds.map((id) => String(id))
      }
    ],
    {
      select: (comments) =>
        comments.reduce<Record<string, Comment>>((dict, comment) => {
          // NOTE: only one comment is possible per material order
          dict[comment.commentsCategoryInstanceId] = comment
          return dict
        }, {}),
      enabled: shouldEnableQuery
    }
  )
  useEffect(() => {
    if (query.isError) {
      notifyIfErrorMessage(
        (query.error as AxiosError<ErrorResponse>)?.response?.data.detail,
        raiseError,
        notify
      )
    }
  }, [query.error, query.isError, notify, raiseError])
  return query
}
