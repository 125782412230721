import React, {useContext, useMemo} from 'react'

interface PlanningChartScheduleDataState {
  isOptimized: boolean
}

const PlanningChartScheduleDataContext = React.createContext<
  PlanningChartScheduleDataState | undefined
>(undefined)

export const usePlanningChartScheduleData = () => {
  const context = useContext(PlanningChartScheduleDataContext)
  if (!context) {
    throw new Error(
      'Cannot use PlanningChartScheduleDataContext outside of a PlanningChartScheduleDataProvider'
    )
  }
  return context
}

interface PlanningChartScheduleDataProviderProps {
  isOptimized?: boolean
  children: React.ReactNode
}
export const PlanningChartScheduleDataProvider = ({
  isOptimized = false,
  children
}: PlanningChartScheduleDataProviderProps) => {
  const memoizedState: PlanningChartScheduleDataState = useMemo(
    () => ({
      isOptimized
    }),
    [isOptimized]
  )

  return (
    <PlanningChartScheduleDataContext.Provider value={memoizedState}>
      {children}
    </PlanningChartScheduleDataContext.Provider>
  )
}
