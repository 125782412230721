import {Typography, alpha} from '@mui/material'
import React from 'react'

import {LabelProps} from '../RangeSlider/Label'

interface GetDayRangeSliderLabelParams {
  checkIsWeekend: (value: number) => boolean
}

export const getDayRangeSliderLabel = ({
  checkIsWeekend
}: GetDayRangeSliderLabelParams): React.FC<LabelProps> =>
  React.memo(({formatLabel, value, ...props}) => {
    const labelValue = formatLabel?.(value)
    const isWeekend = checkIsWeekend(value)
    return (
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          pointerEvents: 'none',
          flex: 1,
          color: ({palette}) =>
            isWeekend ? alpha(palette.common.white, 0.6) : palette.common.white
        }}
        {...props}
      >
        {labelValue}
      </Typography>
    )
  })
