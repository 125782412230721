import {isStorageWithMaterialGuard} from '../../../selectors/storages'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useCurrentStoragesWithMaterialQuery = () => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('materialStorage', [plantCode], {
    select: (storages) => {
      return storages.filter(isStorageWithMaterialGuard)
    }
  })
}
