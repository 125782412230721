import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import React from 'react'
import {Helmet} from 'react-helmet-async'

import {useTryGetUrlParam} from '../../hooks/useTryGetUrlParam'

interface PlannerHelmetProps {
  title?: string
}

export const PlannerHelmet: React.FC<PlannerHelmetProps> = ({title}) => {
  const plantCode = useTryGetUrlParam('plantCode')
  const plantName = plantCode ? getPlantName(plantCode) : ''

  const titleParts = ['Planner', plantName, title].filter(Boolean)

  return (
    <Helmet>
      <title>{titleParts.join(' - ')}</title>
    </Helmet>
  )
}
