import {MaterialStoragesHistory} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {
  calculateCapacityForStorages,
  calculateDeadStockLevelForStorages,
  calculateMinTargetLevelForStorages
} from '../../../selectors/storageLevels'
import {
  getStoragesFromHistory,
  isHistoryStorageWithMaterialGuard
} from '../../../selectors/storages'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

const selector = (storageHistory: MaterialStoragesHistory, materialId: number, date: Moment) => {
  const storagesWithMaterial = getStoragesFromHistory(storageHistory, date)
    .filter(isHistoryStorageWithMaterialGuard)
    .filter((storage) => storage.materialId === materialId)

  const minTargetLevels = calculateMinTargetLevelForStorages(storagesWithMaterial)
  const deadStockLevel = calculateDeadStockLevelForStorages(storagesWithMaterial)
  const storageCapacity = calculateCapacityForStorages(storagesWithMaterial)
  return {...minTargetLevels, deadStockLevel, storageCapacity}
}

interface MaterialStorageDataQueryParams {
  timeFrame: MomentRange
  materialId: number
  date?: Moment
}

export const useMaterialStorageDataFromHistoryQuery = ({
  materialId,
  timeFrame,
  date
}: MaterialStorageDataQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const [from, to] = timeFrame
  return usePlannerQuery(
    'materialStorageHistory',
    [plantCode, from.toISOString(), to.toISOString()],
    {
      select: (data) => selector(data, materialId, date ?? to)
    }
  )
}
