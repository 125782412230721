import {dataTestId, formatTons} from '@hconnect/uikit/src/common'
import {
  Box,
  BoxProps,
  Skeleton,
  Stack,
  SvgIconProps,
  Tooltip,
  Typography,
  darken
} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CementIcon} from '../../../../assets/CementIcon'
import {
  SILO_HEIGHT,
  SiloSkeleton
} from '../../../../shared/components/skeletons/SiloIndicatorSkeleton'
import {
  useHistoryAssetsByIdQuery,
  useHistoryAssetsQuery,
  useMaterialsRecipesById,
  useMaterialStorageDataFromHistoryQuery
} from '../../../../shared/hooks/api'
import {useHistoryMaterialsById} from '../../../../shared/hooks/api/materials/useHistoryMaterialsById'
import {getMillProductionTimeForMaterial} from '../../../../shared/selectors'
import {MomentRange} from '../../../../shared/selectors/time'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

interface StoredMaterialProps extends BoxProps {
  materialId: number
  stock: number | undefined
}

const iconStyle: SvgIconProps = {
  style: {
    fontSize: '12px',
    marginRight: '8px'
  }
}

export const StoredMaterial: React.FC<StoredMaterialProps> = ({
  materialId,
  stock: stockLevel,
  ...props
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const planTimeFrame: MomentRange = [startOfPlan, endOfPlan]

  const {data: recipesById} = useMaterialsRecipesById()
  const {data: assetsById} = useHistoryAssetsByIdQuery({
    timeFrame: planTimeFrame,
    useErrorBoundary: false
  })
  const {data: assets} = useHistoryAssetsQuery({timeFrame: planTimeFrame, useErrorBoundary: false})
  const {data: materialsById} = useHistoryMaterialsById({timeFrame: planTimeFrame})
  const {data: storageData} = useMaterialStorageDataFromHistoryQuery({
    materialId,
    timeFrame: planTimeFrame
  })

  const storageCapacity = storageData?.storageCapacity ?? 0
  const minTargetLevel = storageData?.minTargetLevel ?? 0
  const deadStockLevel = storageData?.deadStockLevel ?? 0

  const millETA = useMemo(() => {
    if (!recipesById || !assetsById || !assets || stockLevel === undefined) {
      return undefined
    }
    return getMillProductionTimeForMaterial({
      assetsById,
      assets,
      tonsOfMaterial: Math.max(0, storageCapacity - stockLevel),
      recipesById,
      materialId
    })
      .map(
        (production) =>
          `${production.asset_name}: ${Math.ceil(production.time).toLocaleString(language)}h`
      )
      .join(' ')
  }, [assetsById, assets, stockLevel, storageCapacity, recipesById, materialId, language])

  const shouldShowSkeletonLoader = !materialsById || stockLevel === undefined

  if (shouldShowSkeletonLoader) {
    return <SiloSkeleton />
  }

  const toPercent = (value: number) => `${value * 100}%`
  const trimAndCalculatePercentage = (value: number) => {
    // we trim value so it does not fall outside of range [0, storageCapacity]
    const visibleValue = Math.min(Math.max(0, value), storageCapacity)
    // storage should not have 0 capacity, but if that's the case display 50% ( return 0.5 )
    return toPercent(storageCapacity <= 0 ? 0.5 : visibleValue / storageCapacity)
  }

  const material = materialsById[materialId]
  const siloName = material.name

  const isAboveTargetLevel = storageData?.minTargetLevel && stockLevel >= minTargetLevel

  return (
    <Tooltip
      title={
        <>
          <Typography variant="body1"> {t('stockCard.silo', {name: siloName})} </Typography>
          {Boolean(minTargetLevel) && (
            <Typography variant="body1">
              {t('stockCard.minStockLevel', {amount: minTargetLevel})}
            </Typography>
          )}
          <Typography variant="body1">
            {t('stockCard.deadStockLevel', {amount: deadStockLevel})}
          </Typography>
        </>
      }
    >
      <Box
        {...props}
        sx={{minHeight: (theme) => theme.spacing(SILO_HEIGHT)}}
        {...dataTestId(`stored_material_${materialId}`)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <CementIcon {...iconStyle} />
            </Box>
            <Typography
              variant="subtitle1"
              {...dataTestId('silo_name')}
              sx={{whiteSpace: 'nowrap'}}
            >
              {siloName}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              {...dataTestId(`${siloName}_fill_level`)}
              sx={{whiteSpace: 'nowrap'}}
            >
              {formatTons(stockLevel, language)}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ml: 1, mr: 1, color: (theme) => theme.palette.secondary.main}}
            >
              /
            </Typography>
            <Typography
              sx={{color: (theme) => theme.palette.secondary.main, whiteSpace: 'nowrap'}}
              variant="subtitle1"
            >
              {formatTons(storageCapacity)}
            </Typography>
          </Stack>
        </Stack>
        {/* Bar graph made of gifs */}
        <Box
          sx={{
            height: (theme) => `${theme.spacing(2)}`,
            mt: 1,
            width: '100%',
            backgroundColor: (theme) => theme.palette.grey[100],
            display: 'block',
            position: 'relative'
          }}
        >
          {/* Fill Level*/}
          <Box
            sx={{
              width: trimAndCalculatePercentage(stockLevel),
              height: '100%',
              backgroundColor: isAboveTargetLevel ? 'primary.light' : 'error.main',
              transition: 'width 500ms'
            }}
          />
          {/* Minimum target level*/}
          {Boolean(minTargetLevel) && (
            <Box
              sx={(theme) => ({
                position: 'absolute',
                height: '100%',
                top: 0,
                borderRight: `1px solid ${theme.palette.grey[300]}`,
                left: trimAndCalculatePercentage(minTargetLevel)
              })}
              {...dataTestId(`${materialId}_min_target_level`)}
            />
          )}
          {/* Dead stock*/}
          <Box
            sx={({palette}) => ({
              position: 'absolute',
              width: trimAndCalculatePercentage(Math.min(deadStockLevel, stockLevel)),
              left: 0,
              bottom: 0,
              borderRight: `1px solid ${darken(
                isAboveTargetLevel ? palette.primary.light : palette.error.main,
                0.2
              )}`,
              backgroundColor: darken(
                isAboveTargetLevel ? palette.primary.light : palette.error.main,
                0.2
              ),
              height: '100%'
            })}
            {...dataTestId(`${materialId}_dead_stock_level`)}
          />
        </Box>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            {millETA !== undefined ? (
              <Typography variant="caption">{millETA}</Typography>
            ) : (
              <Skeleton variant="text" sx={{width: ({spacing}) => spacing(12)}} />
            )}
          </Box>
          {/* TODO: to be implemented in HCP-64017  */}
          {/* <Box>
          <Typography variant="caption">Empty on XXXX</Typography>
        </Box> */}
        </Stack>
      </Box>
    </Tooltip>
  )
}
