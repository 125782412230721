import {Card, mergeSx} from '@hconnect/uikit/src/lib2'
import {ReactComponent as Illustration} from '@hconnect/uikit/src/lib2/pages/illustration500.svg'
import {Stack, Button, Typography, SxProps, Theme, Box} from '@mui/material'
import React from 'react'

type PrimaryAction = {
  actionText: string
  onAction: () => void
}
type SecondaryAction = {
  secondaryActionText: string
  onSecondaryAction: () => void
}
type FallBackActions = PrimaryAction & Partial<SecondaryAction>
type ErrorFallbackProps = {
  title: string
  message: string
  detail?: string
  sx?: SxProps<Theme>
  ['data-test-id']?: string
} & FallBackActions

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  title,
  message,
  detail,
  onAction,
  actionText,
  onSecondaryAction,
  secondaryActionText,
  sx,
  ['data-test-id']: dataTestId
}) => {
  return (
    <Card
      data-test-id={dataTestId ?? 'error_fallback'}
      sx={mergeSx({width: 1}, sx)}
      cardTitleSx={{textAlign: 'center'}}
      headerContent={title}
      footerContent={
        <Stack direction="row" spacing={2} sx={{justifyContent: 'center'}}>
          {onSecondaryAction && (
            <Button variant="outlined" onClick={onSecondaryAction}>
              {secondaryActionText}
            </Button>
          )}
          <Button variant="contained" onClick={onAction}>
            {actionText}
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2} sx={{alignItems: 'center'}}>
        <Box sx={{pb: 1}}>
          <Illustration />
        </Box>
        <Typography variant="body1">{message}</Typography>
        {detail && <Typography variant="caption">{detail}</Typography>}
      </Stack>
    </Card>
  )
}
