import {dataTestId, datetimeFormatter, formatTons} from '@hconnect/uikit'
import {CardTitle, hpTheme, HPButton} from '@hconnect/uikit/src/lib2'
import {Close, Edit, DeleteOutline} from '@mui/icons-material'
import {Typography} from '@mui/material'
import {Stack} from '@mui/system'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IncomingMaterialStatusToIconMap} from '../../../../app/page-stock/materialOrders/materialOrdersIcons'
import {IncomingMaterialStatus} from '../../../enums'
import {useCancelMaterialOrder, useDeleteMaterialOrder} from '../../../hooks/api/materialOrders'
import {usePermission} from '../../../hooks/permissions/usePermission'
import {usePlantConfig} from '../../../hooks/usePlantConfigData'
import {useUrlParam} from '../../../hooks/useUrlParam'
import {IncomingMaterial} from '../../../selectors/materialOrders'
import {useSelectedMaterialOrder} from '../../providers/SelectedMaterialOrderProvider'

const {palette} = hpTheme

const incomingMaterialStatusToColorMap: Record<IncomingMaterialStatus, string> = {
  [IncomingMaterialStatus.Cancelled]: palette.error.light,
  [IncomingMaterialStatus.Delivered]: palette.success.light,
  [IncomingMaterialStatus.Overdue]: palette.warning.light,
  [IncomingMaterialStatus.Unknown]: palette.info.dark,
  [IncomingMaterialStatus.Planned]: palette.info.dark
}

// TODO unify with schedule item tooltip HCP-62762
const TooltipDataRow: React.FC<{
  title: string
  content: React.ReactNode
  'data-test-id'?: string
}> = ({title, content, ['data-test-id']: testId}) => {
  return (
    <Stack>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="subtitle1" {...dataTestId(testId ?? 'toolitip_data_row')}>
        {content}
      </Typography>
    </Stack>
  )
}

interface IncomingMaterialTooltipContentProps {
  incomingMaterial: IncomingMaterial
  closeTooltip: () => void
}
export const IncomingMaterialTooltipContent: React.FC<IncomingMaterialTooltipContentProps> = ({
  incomingMaterial,
  closeTooltip
}) => {
  const plantCode = useUrlParam('plantCode')
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const {value, status} = incomingMaterial
  const Icon = IncomingMaterialStatusToIconMap[status]
  const {setSelectedMaterialOrder} = useSelectedMaterialOrder()

  const canChangeMaterialOrder = usePermission('CHANGE_MATERIAL_ORDERS')

  const {mutate: cancelMaterialOrder, isLoading: isCanceling} = useCancelMaterialOrder()
  const {mutate: deleteMaterialOrder, isLoading: isDeleting} = useDeleteMaterialOrder()

  const handleEditMaterialOrder = (materialOrderId: number) => {
    closeTooltip()
    setSelectedMaterialOrder({id: materialOrderId})
  }
  const handleCancelMaterialOrder = (materialOrderId: number) => {
    closeTooltip()
    cancelMaterialOrder({plantCode, materialOrderId})
  }
  const handleDeleteMaterialOrder = (materialOrderId: number) => {
    closeTooltip()
    deleteMaterialOrder({plantCode, materialOrderId})
  }

  const {
    scheduleAgreement: {vendorName, documentNumber, vendorId}
  } =
    status !== IncomingMaterialStatus.Unknown
      ? incomingMaterial.order
      : incomingMaterial.unplannedDelivery

  const positionNumber =
    status !== IncomingMaterialStatus.Unknown
      ? incomingMaterial.order.scheduleAgreement.positionNumber
      : undefined

  const date = moment
    .utc(
      status !== IncomingMaterialStatus.Unknown
        ? incomingMaterial.order.scheduledDate
        : incomingMaterial.unplannedDelivery.deliveredOn
    )
    .tz(timezoneId)

  const shouldDisableActions = isCanceling || isDeleting || !canChangeMaterialOrder

  const shouldShowActualDeliveredValue =
    status === IncomingMaterialStatus.Delivered || status === IncomingMaterialStatus.Overdue

  return (
    <Stack
      spacing={2}
      sx={{width: ({spacing}) => spacing(48)}}
      {...dataTestId('incoming_material_tooltip_content')}
    >
      <Stack>
        <CardTitle sx={{mb: 0}} {...dataTestId('incoming_material_datetime')}>
          {datetimeFormatter(date, language, date.utcOffset())}
        </CardTitle>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Icon component="svg" sx={{color: incomingMaterialStatusToColorMap[status]}} />
          <Typography
            variant="subtitle1"
            color={incomingMaterialStatusToColorMap[status]}
            {...dataTestId('incoming_material_status')}
          >
            {t(`stock.materialOrders.${status}`)}
          </Typography>
        </Stack>
      </Stack>
      <TooltipDataRow
        {...dataTestId('incoming_material_amount')}
        title={t('common.amount')}
        content={
          <>
            {shouldShowActualDeliveredValue && `${formatTons(value, language)}/`}
            {formatTons(
              status !== IncomingMaterialStatus.Unknown
                ? incomingMaterial.order.amount
                : incomingMaterial.unplannedDelivery.amountDelivered,
              language
            )}
          </>
        }
      />
      <TooltipDataRow
        {...dataTestId('incoming_material_vendor')}
        title={t('stock.materialOrders.vendor')}
        content={vendorId ? vendorName : t('stock.materialOrders.unknownVendor')}
      />
      {documentNumber && (
        <TooltipDataRow
          {...dataTestId('incoming_material_document_number')}
          title={t('stock.materialOrders.documentNumber')}
          content={documentNumber}
        />
      )}
      {positionNumber && (
        <TooltipDataRow
          {...dataTestId('incoming_material_position_number')}
          title={t('stock.materialOrders.positionNumber')}
          content={positionNumber}
        />
      )}
      <Stack direction="row" spacing={1.5}>
        {incomingMaterial.status === IncomingMaterialStatus.Planned && (
          <>
            <HPButton
              variant="contained"
              color="error"
              startIcon={<Close />}
              disabled={shouldDisableActions}
              onClick={() => handleCancelMaterialOrder(incomingMaterial.order.id)}
              {...dataTestId('cancel_material_order_button')}
            >
              {t('common.cancel')}
            </HPButton>
            <HPButton
              startIcon={<Edit />}
              disabled={shouldDisableActions}
              onClick={() => handleEditMaterialOrder(incomingMaterial.order.id)}
              {...dataTestId('edit_material_order_button')}
            >
              {t('common.edit')}
            </HPButton>
          </>
        )}
        {incomingMaterial.status === IncomingMaterialStatus.Cancelled && (
          <HPButton
            variant="contained"
            color="error"
            startIcon={<DeleteOutline />}
            disabled={shouldDisableActions}
            onClick={() => handleDeleteMaterialOrder(incomingMaterial.order.id)}
            {...dataTestId('delete_material_order_button')}
          >
            {t('common.delete')}
          </HPButton>
        )}
      </Stack>
    </Stack>
  )
}
