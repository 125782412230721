import {AutofillStatus} from '../../../enums'
import {useUrlParam} from '../../../hooks/useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

const AUTOFILL_REFETCH_INTERVAL = 5 * 60 * 1000
const AUTOFILL_REFETCH_INTERVAL_IF_RUNNING = 30 * 1000

export const useGetAutofillStatus = () => {
  // this logic is so refresh period is AUTOFILL_REFETCH_INTERVAL_IF_RUNNING ( 30s )
  // when autofill is running, otherwise it is AUTOFILL_REFETCH_INTERVAL ( 5min )

  const plantCode = useUrlParam('plantCode')

  const autofillStatusOverview = usePlannerQuery('autofillStatus', [plantCode], {
    refetchInterval: AUTOFILL_REFETCH_INTERVAL,
    refetchOnMount: false
  })

  const autofillStatusIfRunning = usePlannerQuery('autofillStatus', [plantCode], {
    refetchInterval: AUTOFILL_REFETCH_INTERVAL_IF_RUNNING,
    refetchOnMount: false,
    enabled: autofillStatusOverview.data?.status === AutofillStatus.InProgress
  })

  if (autofillStatusOverview.data?.status !== AutofillStatus.InProgress) {
    return autofillStatusOverview
  }

  return autofillStatusIfRunning
}
