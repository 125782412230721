import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Typography, Skeleton} from '@mui/material'
import React from 'react'

export const SiloLevelsSkeleton = () => {
  return (
    <CardBox
      sx={{display: 'flex', flexDirection: 'column'}}
      {...dataTestId('silo_levels_skeleton')}
    >
      <Box mb={3}>
        <Typography variant="h3">
          <Skeleton variant="text" width={250} />
        </Typography>
      </Box>
      <Box width="100%" height={280}>
        <Skeleton variant="rectangular" height="100%" />
      </Box>
    </CardBox>
  )
}
