import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface CostAvoidanceDailyQueryParams {
  fromDay: string
  toDay: string
}

export const useCostAvoidanceDailyQuery = ({fromDay, toDay}: CostAvoidanceDailyQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('costAvoidanceDaily', [{plantCode, fromDay, toDay}])
}
