import MockAdapter from 'axios-mock-adapter'

import {SubmitSchedule} from '../../shared/interfaces/api'
import {mockStore} from '../mockStore'

import {sleepResponse} from './utils'

export const enableElectricityEndpoints = (mock: MockAdapter) => {
  // GET Electricity (C#)
  mock.onGet('schedules/current/load-curves').reply(() => {
    const {
      burglengenfeld: {backendErrors, electricity}
    } = mockStore.scenario()
    if (backendErrors.loadCurvesError) {
      return sleepResponse(backendErrors.loadCurvesError)
    }
    return sleepResponse([200, electricity])
  })

  // POST Electricity Plan Submission (C#)
  mock.onPost('/electricity').reply((config) => {
    const {scheduleId} = config.params as SubmitSchedule
    return sleepResponse([200, {scheduleId}])
  })

  // POST send backup plan via email
  mock.onPost('/electricity/production-planning-backup').reply(() => {
    return sleepResponse([200, {}])
  })

  return mock
}
