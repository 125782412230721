import {formatTimeZoneDate} from '@hconnect/uikit/src/common'
import {Box, Typography, Tooltip, TooltipProps} from '@mui/material'
import Markdown from 'markdown-to-jsx'
import {FC, CSSProperties} from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerNotification} from '../../../../shared/components/PlannerNotification'
import {dataTestId} from '@hconnect/uikit'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {Comment} from '../../../../shared/interfaces/api'

interface CommentNotificationProps {
  comment: Comment
  styles?: {notification?: CSSProperties; tooltip?: CSSProperties}
  tooltipPlacement?: TooltipProps['placement']
}

export const CommentNotificationWithTooltip: FC<CommentNotificationProps> = ({
  comment,
  styles,
  tooltipPlacement = 'top-end'
}) => {
  const latest = usePlantConfig()
  const {
    i18n: {language}
  } = useTranslation()
  const timezoneId = latest.timezone_id
  return (
    <Box sx={{pointerEvents: 'auto'}}>
      <Tooltip
        placement={tooltipPlacement}
        // for mobile devices
        enterTouchDelay={0}
        sx={{
          '& .MuiTooltip-tooltip': {
            maxWidth: (theme) => theme.spacing(80)
          }
        }}
        style={styles?.tooltip}
        title={
          <Box {...dataTestId('comment_notification_tooltip_content')}>
            <Markdown>{comment.value}</Markdown>
            <Box pt={1.5}>
              <Typography variant="body1">
                {`${comment.updatedBy || comment.createdBy}, ${formatTimeZoneDate(
                  comment.updatedOn || comment.createdOn,
                  timezoneId,
                  'DD MMM LT',
                  language
                )}`}
              </Typography>
            </Box>
          </Box>
        }
      >
        <PlannerNotification
          value={1}
          testId="comment_notification"
          styles={{notification: styles?.notification}}
        />
      </Tooltip>
    </Box>
  )
}
