import {removeHcemQuery} from '../../../api/queryHelpers'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useScheduleCostAvoidanceQuery = (scheduleId: number) => {
  const plantCode = useUrlParam('plantCode')
  // this query is always disabled, it returns only available data in cache
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const query = usePlannerQuery('scheduleCostAvoidance', [{plantCode, scheduleId}], {
    enabled: false
  })
  const remove = () => removeHcemQuery('scheduleCostAvoidance', {plantCode, scheduleId})
  return {...query, remove}
}
