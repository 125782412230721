import {dataTestId} from '@hconnect/uikit'
import type {SxProps, Theme, TypographyProps} from '@mui/material'
import {Typography} from '@mui/material'
import React, {FC} from 'react'

import {KPIUnit, useKPIValueFormatter} from '../helpers/KPIs'

type Props = {
  amount?: number
  unit?: KPIUnit
  digits?: number
  testId?: string
  sx?: SxProps<Theme>
} & Pick<TypographyProps, 'variant'>

export const KPI: FC<Props> = ({amount, unit, digits, testId, variant, sx}) => {
  const getFormattedContent = useKPIValueFormatter()

  return (
    <Typography variant={variant || 'h3'} sx={sx} {...dataTestId(testId ?? 'kpi')}>
      {amount !== undefined
        ? unit !== undefined
          ? getFormattedContent(amount, unit, {digits})
          : amount
        : '-'}
    </Typography>
  )
}
