export type WindowEventListener<K extends keyof WindowEventMap> = (
  this: Window,
  event: WindowEventMap[K]
) => void

type AddOptions = boolean | AddEventListenerOptions | undefined
type RemoveOptions = boolean | EventListenerOptions | undefined

export const useWindowListener = <K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void
): [add: (options?: AddOptions) => void, remove: (options?: RemoveOptions) => void] => {
  const removeListener = (options?: RemoveOptions) =>
    window.removeEventListener(type, listener, options)
  const addListener = (options?: AddOptions) => {
    removeListener()
    window.addEventListener(type, listener, options)
  }
  return [addListener, removeListener]
}
