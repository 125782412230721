import type {Env} from '@hconnect/common/hproduce'
import {hproduce} from '@hconnect/common/hproduce/config/hproduce'
import {planner} from '@hconnect/common/hproduce/config/planner'
import React, {useLayoutEffect} from 'react'

const env = process.env.REACT_APP_STAGE as Env

const HProduceEntryPointRedirectUrl = `${hproduce.url[env]}/${planner.name}`

export const EntryPointRedirect: React.FC = () => {
  useLayoutEffect(() => {
    window.location.replace(HProduceEntryPointRedirectUrl)
  }, [])
  return null
}
