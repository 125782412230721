import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material'
import {Box, Typography, alpha} from '@mui/material'
import {Theme, useTheme} from '@mui/system'
import React from 'react'

const getStylesByDifference = (difference: number, theme: Theme) => {
  const differenceStyles: Record<
    'positiveDifference' | 'noDifference' | 'negativeDifference',
    React.CSSProperties
  > = {
    positiveDifference: {
      backgroundColor: alpha(theme.palette.success.main, 0.2),
      border: `2px solid ${alpha(theme.palette.success.main, 0.3)}`,
      color: theme.palette.success.light
    },
    noDifference: {
      backgroundColor: alpha(theme.palette.common.white, 0.05),
      border: `2px solid ${alpha(theme.palette.common.white, 0.1)}`,
      color: theme.palette.warning.light
    },
    negativeDifference: {
      backgroundColor: alpha(theme.palette.error.light, 0.2),
      border: `2px solid ${alpha(theme.palette.error.light, 0.3)}`,
      color: theme.palette.error.light
    }
  }
  if (difference > 0) return differenceStyles.positiveDifference
  if (difference < 0) return differenceStyles.negativeDifference
  return differenceStyles.noDifference
}

const getKPIArrow = (difference: number) => {
  if (difference > 0) return <ArrowDropUp />
  if (difference < 0) return <ArrowDropDown />
}

interface Props {
  label: string
  difference?: number
  children?: React.ReactNode
}

export const HighlightedKPI = ({label, difference = 0, children}: Props) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        ...getStylesByDifference(difference, theme),
        p: 1.5,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 1,
        textAlign: 'center'
      }}
    >
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        {getKPIArrow(difference)}
        {children}
      </Box>
      <Typography variant="body2" sx={{color: 'text.secondary'}}>
        {label}
      </Typography>
    </Box>
  )
}
