import moment, {Moment} from 'moment-timezone'
import {useMemo} from 'react'

interface UseStartOfTodayParams {
  createdAt: string
  timezoneId: string
}

export const useStartOfToday = ({createdAt, timezoneId}: UseStartOfTodayParams): Moment => {
  return useMemo(() => {
    return moment.utc(createdAt).tz(timezoneId).startOf('day')
  }, [createdAt, timezoneId])
}
