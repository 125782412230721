import React from 'react'

import {MainRoutes, RouteName} from './routing/router'

export const subPageDynamicImportMap: Record<keyof MainRoutes, () => Promise<{default: React.FC}>> =
  {
    [RouteName.STOCK]: () =>
      import('./app/page-stock/PageStock').then((module) => ({default: module.PageStock})),
    [RouteName.ELECTRICITY_FORECAST]: () =>
      import('./app/page-electricity/PageElectricityForecast').then((module) => ({
        default: module.PageElectricityForecast
      })),
    [RouteName.SALES_FORECAST]: () =>
      import('./app/page-demand/PageSalesForecast').then((module) => ({
        default: module.PageSalesForecast
      })),
    [RouteName.PRODUCTION_PLANNING]: () =>
      import('./app/page-planning/PageProductionPlanning').then((module) => ({
        default: module.PageProductionPlanning
      })),
    [RouteName.OPERATOR_VIEW]: () =>
      import('./app/page-operator-view/PageControlOperation').then((module) => ({
        default: module.PageControlOperation
      }))
  }

export const PageNotFound = React.lazy(() =>
  import('./app/page-not-found/PageNotFound').then((module) => ({
    default: module.PageNotFound
  }))
)
