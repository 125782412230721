import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

type StockLevelOverwriteMutationParams = {
  materialId: number
  enabled: boolean
  timeframe?: [start: Moment, end: Moment]
}

export const useStockLevelOverwritesQuery = ({
  materialId,
  enabled,
  timeframe
}: StockLevelOverwriteMutationParams) => {
  const plantCode = useUrlParam('plantCode')
  const [start, end]: (Moment | undefined)[] = timeframe ?? []
  return usePlannerQuery(
    'stockOverwrites',
    [{plantCode, from: start?.toISOString(), to: end?.toISOString(), materialId}],
    {
      enabled: enabled && !!timeframe
    }
  )
}
