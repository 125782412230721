/**
 * Function converts index to ASCII characters from A to Z
 * @param index values from 0 to 25
 * @returns string
 */
export const convertIndexToLetter = (index: number) => {
  if (index < 0 || index > 25) {
    throw new Error('Index value should be within the range from 0 to 25')
  }
  return String.fromCharCode(index + 65)
}
