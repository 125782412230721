import {dataTestId} from '@hconnect/uikit'
import {AutoAwesome, AccountCircleOutlined} from '@mui/icons-material'
import {Stack, Typography, SvgIconProps} from '@mui/material'
import moment from 'moment-timezone'
import {useTranslation, Trans} from 'react-i18next'

import {AssetOperationTimeSource} from '../../../../../../shared/enums'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import type {ScheduleItem} from '../../../../../../shared/interfaces/api/schedule'
import {getLocalizedDayMonthTimeFormat} from '../../../../../../shared/selectors/time'

export const ScheduleItemUpdatedByInfo = ({
  scheduleItem: {source, updatedBy, updatedOn}
}: {
  scheduleItem: ScheduleItem
}) => {
  const {
    i18n: {language}
  } = useTranslation()

  const {timezone_id: timezoneId} = usePlantConfig()
  const iconProps: SvgIconProps = {fontSize: 'inherit', sx: {verticalAlign: 'middle'}}

  const formattedDateTime = getLocalizedDayMonthTimeFormat(
    moment.utc(updatedOn),
    timezoneId,
    language
  )

  return (
    <Stack spacing={1} {...dataTestId('schedule_item_updated_by_on_info')}>
      {source === AssetOperationTimeSource.Optimizer ? (
        <>
          <Typography variant="caption">
            <Trans
              i18nKey="planning.createdByOptimizer"
              components={{1: <AutoAwesome {...iconProps} />}}
            />
          </Typography>
          <Typography variant="caption">
            <Trans
              i18nKey="planning.acceptedByOn"
              components={{
                1: <AccountCircleOutlined {...iconProps} />
              }}
              values={{name: updatedBy, date: formattedDateTime}}
            />
          </Typography>
        </>
      ) : (
        <Typography variant="caption">
          <Trans
            i18nKey="planning.lastChangedByOn"
            components={{
              1: <AccountCircleOutlined {...iconProps} />
            }}
            values={{name: updatedBy, date: formattedDateTime}}
          />
        </Typography>
      )}
    </Stack>
  )
}
