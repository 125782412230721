import {AxiosResponse} from 'axios'

import {ClientConfig} from '../../types'

export const createOnResponseInterceptor =
  (clientConfig: Partial<ClientConfig>) => async (response: AxiosResponse) => {
    const {onResponse} = clientConfig
    if (onResponse) {
      await onResponse(response)
    }

    return response
  }
