import moment from 'moment-timezone'
import {useState} from 'react'
import {useInterval} from 'react-use'

export const getCurrentTime = (timezoneId: string) => {
  return moment.utc().tz(timezoneId).startOf('minute')
}

interface CurrentTimeParams {
  timezoneId: string
  intervalMs?: number
  isDisabled?: boolean
}

export const useCurrentTime = ({
  timezoneId,
  intervalMs = 1000 * 60,
  isDisabled = false
}: CurrentTimeParams) => {
  const [currentTime, setCurrentTime] = useState(() => getCurrentTime(timezoneId))

  useInterval(
    () => {
      setCurrentTime(getCurrentTime(timezoneId))
    },
    isDisabled ? null : intervalMs
  )
  return currentTime
}
