import {dataTestId} from '@hconnect/uikit'
import {Typography, Skeleton, Stack} from '@mui/material'
import {FC} from 'react'

import {ReactComponent as SiloIcon} from '../../../../../../assets/icons/SilosIcon.svg'
import {PlannerIcon} from '../../../../../../shared/components/PlannerIcon'
import {useHistoryStoragesByIdQuery} from '../../../../../../shared/hooks/api'
import {SelectedSilos} from '../../../../../../shared/interfaces/api'
import {usePlanningChartStartEnd} from '../../../PlanningChartStartEndProvider'

import {SelectedSilo} from './SelectedSilo'

interface SelectedSilosRow {
  selectedSilos: SelectedSilos
  scheduleItemEnd: string
}

export const SelectedSilosRow: FC<SelectedSilosRow> = ({
  selectedSilos
  // scheduleItEnd
}) => {
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  // const scheduleItemEndUTC = moment.utc(scheduleItemEnd)
  // HOTFIX: displaying storage name for endOfPlan instead of scheduleItemEnd
  //TODO HCP-81604 filter selectedSilos by scheduleItemEnd, then select snapshot by date
  const {data: storagesById} = useHistoryStoragesByIdQuery({
    timeFrame: [startOfPlan, endOfPlan]
  })
  return (
    <Stack direction="row" spacing={0} alignItems="center" {...dataTestId('selected_silos_row')}>
      <PlannerIcon icon={<SiloIcon />} sx={{pl: 0}} />
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
        {storagesById ? (
          <SelectedSilo selectedSilos={selectedSilos} storagesById={storagesById} />
        ) : (
          <Typography variant="body1">
            <Skeleton sx={{width: ({spacing}) => spacing(5)}} />
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
