import {dataTestId} from '@hconnect/uikit'
import {Collapse, Stack, Box} from '@mui/material'
import React, {useState} from 'react'

import {AssetCapacity} from '../../../shared/interfaces/api/kpi'

import {AssetCapacityHeader} from './AssetCapacityHeader'
import {AssetCapacityTable} from './AssetCapacityTable'

interface AssetCapacitySectionProps {
  assetId: number
  assetCapacity: AssetCapacity
}

export const AssetCapacitySection: React.FC<AssetCapacitySectionProps> = ({
  assetCapacity,
  assetId
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <Stack
      sx={{
        '&:not(:last-child)': {
          borderBottom: '1px solid',
          borderColor: ({palette}) => palette.grey[200]
        }
      }}
      {...dataTestId('asset_capacity_section')}
    >
      <Box sx={{my: 2}}>
        <AssetCapacityHeader
          key={assetId}
          assetCapacity={assetCapacity}
          assetId={Number(assetId)}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </Box>
      <Collapse in={!isCollapsed}>
        <AssetCapacityTable assetCapacity={assetCapacity} />
      </Collapse>
    </Stack>
  )
}
