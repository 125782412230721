import {MaterialType, NewMaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {HPTextField} from '@hconnect/uikit/src/lib2'
import {MenuItem} from '@mui/material'
import {useTranslation} from 'react-i18next'

type SelectedMaterialType = MaterialType | NewMaterialType | 'all_types'

type SelectMaterialTypeDropdownProps = {
  selectedMaterialType: SelectedMaterialType
  storedMaterialTypes: (MaterialType | NewMaterialType)[]
  changeSelectedMaterialType: (materialType: SelectedMaterialType) => void
}

export const SelectMaterialTypeDropdown = ({
  selectedMaterialType,
  changeSelectedMaterialType,
  storedMaterialTypes
}: SelectMaterialTypeDropdownProps) => {
  const {t} = useTranslation()

  if (storedMaterialTypes.length === 0) {
    return null
  }

  return (
    <HPTextField
      sx={{
        width: ({spacing}) => spacing(21)
      }}
      variant="outlined"
      fullWidth
      select
      label={t('stock.materialType')}
      value={selectedMaterialType}
      onChange={(e) => {
        const newMaterialType = e.target.value as MaterialType
        changeSelectedMaterialType(newMaterialType)
      }}
      {...dataTestId('material_type_select')}
    >
      <MenuItem
        key={'all_types'}
        value={'all_types'}
        {...dataTestId('material_type_select_item_all_types')}
      >
        {t('stock.materials.allTypes')}
      </MenuItem>
      {storedMaterialTypes.map((materialType, index) => (
        <MenuItem
          key={index}
          value={materialType}
          {...dataTestId(`material_type_select_item_${materialType}`)}
        >
          {t(`stock.materials.materialTypes.${materialType}`)}
        </MenuItem>
      ))}
    </HPTextField>
  )
}
