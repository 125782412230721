import {UrlParams} from '../../routing'

import {useTryGetUrlParam} from './useTryGetUrlParam'

/**
 * Typed wrapper around react router's `useParams` hook that gets a set of known parameters from the current URL
 * Wrapper of useTryGetUrlParam which can return undefined. This hook instead throws error if parameter not found.
 */
export const useUrlParam = (param: keyof UrlParams) => {
  const parameter = useTryGetUrlParam(param)
  if (parameter === undefined) {
    throw new Error(`Could not obtain url parameter with name ${param}`)
  }
  return parameter
}
