import {PermissionType} from '@hconnect/apiclient'
import {useMemo} from 'react'

import {usePermissionsQuery} from '../../hooks/api'
import {PlannerDataScope} from '../../interfaces/api/permissions'
import {checkPermissionAccessByCountryOrPlantId} from '../../permissions'
import {usePlantConfig} from '../usePlantConfigData'
import {useUrlParam} from '../useUrlParam'

/*
 * generic hook to check permissions by type and datascope
 */

export const usePermission = (
  type: PermissionType,
  dataScopeTestFn?: (dataScope: Partial<PlannerDataScope>) => boolean
) => {
  const {data: permissions} = usePermissionsQuery([type])
  const plantCode = useUrlParam('plantCode')
  const {country: countryId} = usePlantConfig()

  return useMemo(() => {
    if (!permissions) {
      return false
    }
    const foundPermissions = permissions
      .filter(({parsedDataScope}) =>
        checkPermissionAccessByCountryOrPlantId(countryId, plantCode, parsedDataScope)
      )
      .filter(({parsedDataScope}) => (dataScopeTestFn ? dataScopeTestFn(parsedDataScope) : true))

    const foundPermissionTypes = foundPermissions.map(({permissionType}) => permissionType)

    return foundPermissionTypes.includes(type)
  }, [permissions, type, plantCode, countryId, dataScopeTestFn])
}
