import type {Moment} from 'moment-timezone'

import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface MaterialOrderQueryParams {
  materialId: number
  materialOrderId: number | undefined
  timeframe: [Moment, Moment] | undefined
}

export const useMaterialOrderQuery = ({
  materialId,
  materialOrderId,
  timeframe
}: MaterialOrderQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const [start, end]: (Moment | undefined)[] = timeframe ?? []

  const isEnabled = Boolean(timeframe)

  return usePlannerQuery(
    'materialOrders',
    [{plantCode, from: start?.toISOString(), to: end?.toISOString(), materialId}],
    {
      enabled: isEnabled,
      select: (materialOrders) => {
        return materialOrders.find((order) => order.id === materialOrderId)
      }
    }
  )
}
