declare global {
  interface Window {
    msCrypto: Crypto
  }
}

export const generateRandomString = (length: number): string => {
  // eslint-disable-next-line
  const buffer = new Uint8Array(length)
  const randomString: string[] = []
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz'

  const crypto = window.crypto || window.msCrypto
  if (!crypto) {
    for (let i = 0; i < length; i += 1) {
      randomString[i] = charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return randomString.join('')
  }

  const random = crypto.getRandomValues(buffer)

  for (let i = 0; i < random.length; i++) {
    randomString.push(charset[random[i] % charset.length])
  }

  return randomString.join('')
}

// Used modified (uint16->uint8) version of http://stackoverflow.com/a/11058858
const str2binArray = (str: string): Uint8Array => {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)

  for (let i = 0; i < str.length; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return bufView
}

const base64URLEncode = (arrayBuffer: Uint8Array) => {
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export const deriveChallenge = async (codeVerifier: string): Promise<string> => {
  if (codeVerifier.length < 43 || codeVerifier.length > 128) {
    throw new Error(
      `Length of codeVerifier is invalid, codeVerifier should be between 43 and 128 characters long, current length: ${codeVerifier.length}`
    )
  }
  if (!window?.crypto?.subtle) {
    throw new Error('window.crypto.subtle is not available')
  }

  const hashedVerifierBuffer = await crypto.subtle.digest('SHA-256', str2binArray(codeVerifier))

  return base64URLEncode(new Uint8Array(hashedVerifierBuffer))
}
