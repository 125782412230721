import {debounce} from 'lodash'
import {useEffect, useMemo} from 'react'

import {AUTOSAVE_INTERVAL_MS} from '../constants'

interface AutoSaveValueParams<T extends string | number> {
  currentValue: T
  shouldSave: boolean
  saveFn: () => Promise<void> | void
  interval?: number
}

/**
 * hook which saves the value when it changes and shouldSave is true
 * it debounces the save function with interval
 */
export const useAutoSaveValue = <T extends string | number>({
  currentValue,
  shouldSave,
  interval = AUTOSAVE_INTERVAL_MS,
  saveFn
}: AutoSaveValueParams<T>) => {
  const debouncedSaveFn = useMemo(() => debounce(saveFn, interval), [saveFn, interval])

  useEffect(() => {
    if (shouldSave) {
      void debouncedSaveFn()
    }
    return () => {
      debouncedSaveFn.cancel()
    }
  }, [currentValue, debouncedSaveFn, shouldSave])
}
