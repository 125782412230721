import {MaterialType} from '@hconnect/common/types'
import {SxProps, Theme} from '@mui/material'
import React from 'react'

import {CementIcon} from '../../assets/CementIcon'
import {ClinkerIcon} from '../../assets/ClinkerIcon'

const getMaterialIcon = (materialType: MaterialType) => {
  switch (materialType) {
    case MaterialType.Clinker:
      return ClinkerIcon
    case MaterialType.Cement:
      return CementIcon
    default:
      return null
  }
}

export const MaterialTypeIcon: React.FC<{materialType: MaterialType; sx?: SxProps<Theme>}> = ({
  materialType,
  sx
}) => {
  const Icon = getMaterialIcon(materialType)
  return Icon ? <Icon sx={{fontSize: ({spacing}) => spacing(1.5), ...sx}} /> : null
}
