import {MailOutline} from '@mui/icons-material'
import {Button} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {dataTestId} from '@hconnect/uikit'
import {useSendBackupPlan} from '../../../../../shared/hooks/api/schedules/useSendBackupPlan'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'

export const SendBackupButton = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {mutate: sendBackupPlan, isLoading} = useSendBackupPlan()
  return (
    <Button
      startIcon={<MailOutline />}
      variant="text"
      sx={{color: 'primary.light'}}
      disabled={isLoading}
      onClick={() => sendBackupPlan({plantCode})}
      {...dataTestId('send_backup_plan_button')}
    >
      {t('planning.sendBackupPlan')}
    </Button>
  )
}
