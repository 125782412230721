import {generatePath} from 'react-router-dom'

type RelPath<Path> = Path extends `${string}/${infer LastPart}` ? RelPath<LastPart> : Path

export const getRelPath = <Path extends `${string}/${string}`>(
  absolutePath: Path
): RelPath<Path> => {
  const relUrls: string[] = absolutePath.split('/')
  return relUrls.at(-1) as RelPath<Path>
}

type WrapParamInObj<P> = P extends `${infer Param}` ? {[K in Param]: string} : never
type IsParam<Part> = Part extends `:${infer Param}` ? Param : never
type ExtractParams<Path> = Path extends `${infer PartA}/${infer PartB}`
  ? WrapParamInObj<IsParam<PartA>> | ExtractParams<PartB>
  : WrapParamInObj<IsParam<Path>>

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never

export function getUrl<
  Path extends `${string}/${string}`,
  Extracted extends ExtractParams<Path>,
  Params extends UnionToIntersection<Extracted>
>(routePath: Path, params: Params): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return generatePath(routePath, params)
}
