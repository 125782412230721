import {Box, darken} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {FC} from 'react'

import {usePlanningChartStartEnd} from '../../../../app/page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {dataTestId} from '@hconnect/uikit'
import {toPixel} from '../../../helpers/utils'
import {useElectricityPricesDataQuery} from '../../../hooks/api/dataScience/useElectricityPricesDataQuery'

interface ActualPricesIndicatorProps {
  hoursList: Moment[]
  testId?: string
  cellWidth: number
}
/**
 * Component meant to be use inside grid container, to highlight forecast prices
 * if actual prices are available
 * @param hoursList
 * @returns
 */
export const ActualPricesIndicator: FC<ActualPricesIndicatorProps> = React.memo(
  ({hoursList, cellWidth, testId = 'actual_prices_indicator'}) => {
    const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
    const {data: electricityPrice} = useElectricityPricesDataQuery([startOfPlan, endOfPlan])

    const actualPrices = electricityPrice?.actual ?? []
    const lastActualDatetime: Moment | undefined = actualPrices[actualPrices.length - 1]?.datetime

    const endOfActualPricesCellIndex = lastActualDatetime
      ? hoursList
          .map((hour) => hour.toISOString())
          .indexOf(lastActualDatetime.clone().add(15, 'minutes').toISOString())
      : undefined

    const isActualPriceIndicatorVisible =
      actualPrices.length > 0 &&
      endOfActualPricesCellIndex !== undefined &&
      endOfActualPricesCellIndex > 0
    if (!isActualPriceIndicatorVisible) {
      return null
    }
    return (
      <Box
        {...dataTestId(testId)}
        sx={(theme) => ({
          backgroundColor: darken(theme.palette.primary.dark, 0.4),
          height: theme.spacing(1),
          width: toPixel(cellWidth * hoursList.length)
        })}
      >
        <Box
          sx={{
            height: 1,
            width: toPixel(endOfActualPricesCellIndex * cellWidth),
            backgroundColor: (theme) => theme.palette.grey[500]
          }}
        />
      </Box>
    )
  }
)
