import {Box, alpha} from '@mui/material'
import useSize from '@react-hook/size'
import React, {
  MouseEvent as SyntheticMouseEvent,
  TouchEvent as SyntheticTouchEvent,
  useMemo,
  useRef,
  useState
} from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'

import {useDomRect} from './hooks/useDomRect'
import {useWindowListener} from './hooks/useWindowListener'
import type {ActiveTrackLabelProps, FormatLabelFn} from './Label'
import {DefaultActiveTrackLabel, Label, LabelProps} from './Label'
import {
  getPercentagesFromValues,
  getPositionFromEvent,
  getPositionsFromSteps,
  getStepValueFromValue,
  getValueFromKey,
  getValueFromPosition
} from './position-utils'
import type {SliderKey} from './Slider'
import {Slider} from './Slider'
import {Track} from './Track'
import type {NumberRange} from './utils'
import {absoluteLength, areRangesEqual, areRangeSizesEqual, capitalize} from './utils'

const keys: SliderKey[] = ['min', 'max']

const KeyToIndex: Record<SliderKey, number> = {
  min: 0,
  max: 1
}

export interface RangeSliderProps {
  ariaLabelledBy?: string
  ariaControls?: string
  isDraggable?: boolean
  values: NumberRange
  setValues: React.Dispatch<React.SetStateAction<NumberRange>>
  minMax: NumberRange
  name?: string
  label?: {
    formatFn?: FormatLabelFn
    skip?: (index: number, props: {sliderWidth: number; totalSteps: number}) => boolean
    step: number
    offsetStart?: number
    LabelComponent?: React.FC<LabelProps>
    centerLabel?: boolean
  }
  onChangeStart?: (value: NumberRange) => void
  onChangeComplete?: (value: NumberRange) => void
  onTrackHover?: (isHovered: boolean) => void
  step?: number
  stepLimits?: {
    min?: number
    max?: number
  }
  roundClicks?: {
    roundTo: number
    offsetStart?: number
  }
  ActiveTrackLabel?: React.FC<ActiveTrackLabelProps>
  onChange: (value: NumberRange) => void
}

export const RangeSlider: React.FC<RangeSliderProps> = React.memo(
  ({
    ariaControls,
    ariaLabelledBy,
    minMax,
    name,
    roundClicks,
    onChangeComplete,
    onTrackHover,
    onChangeStart,
    label: labelProps,
    step = 1,
    ActiveTrackLabel = DefaultActiveTrackLabel,
    isDraggable,
    values,
    setValues,
    stepLimits,
    onChange
  }) => {
    const withCurrentValues = (cb?: (values: NumberRange) => void) =>
      setValues((values) => {
        cb?.(values)
        return values
      })

    const isWithinRange = (newValues: NumberRange) => {
      const [rangeMin, rangeMax] = minMax
      const [min, max] = newValues
      return min >= rangeMin && max <= rangeMax && min < max
    }

    const isGreaterThanOneStep = (newValues: NumberRange, oldValues: NumberRange): boolean => {
      const [currentMin, currentMax] = oldValues
      const [min, max] = newValues
      const minDiff = absoluteLength(min, currentMin)
      const maxDiff = absoluteLength(max, currentMax)

      return [minDiff, maxDiff].some((diff) => diff >= step)
    }
    const pullBackStepsToLimits = (newValues: NumberRange, oldValues: NumberRange): NumberRange => {
      if (areRangeSizesEqual(newValues, oldValues)) return newValues // there is no step change that could cause a limit break

      const [newMin, newMax] = newValues
      const [oldMin] = oldValues

      const isMinChanged = newMin !== oldMin
      const newDiff = absoluteLength(newMin, newMax)
      if (stepLimits?.min) {
        if (newDiff < stepLimits.min) {
          return isMinChanged
            ? [newMax - stepLimits.min, newMax]
            : [newMin, newMin + stepLimits.min]
        }
      }
      if (stepLimits?.max) {
        if (newDiff > stepLimits.max) {
          return isMinChanged
            ? [newMax - stepLimits.max, newMax]
            : [newMin, newMin + stepLimits.max]
        }
      }
      return newValues
    }

    const updateValues = (newValues: NumberRange) =>
      setValues((oldValues) => {
        const shouldUpdate =
          isWithinRange(newValues) &&
          isGreaterThanOneStep(newValues, oldValues) &&
          !areRangesEqual(newValues, oldValues)

        if (!shouldUpdate) return oldValues
        else {
          const pulledBackValues = pullBackStepsToLimits(newValues, oldValues)
          onChange(pulledBackValues)
          return pulledBackValues
        }
      })

    const roundedTrackClicks = roundClicks?.roundTo
    const roundOffsetStart = roundClicks?.offsetStart ?? 0
    const label = useMemo(
      () => ({
        formatFn: (value: number) => Math.round(value),
        LabelComponent: Label,
        step: 1,
        offsetStart: 0,
        centerLabel: true,
        ...labelProps
      }),
      [labelProps]
    )

    const percentages = useMemo(() => getPercentagesFromValues(values, minMax), [minMax, values])

    const trackRef = useRef<HTMLDivElement | null>(null)
    const withTrackRect = useDomRect(trackRef)
    const sliderRef = useRef<HTMLDivElement | null>(null)
    const [sliderWidth] = useSize(sliderRef)

    const startTrackDragPosition = useRef<number | null>(null)
    const [isSliderDragging, setIsSliderDragging] = useState<boolean>(false)

    const getClosestKeyToPosition = (position: number, clientRect: DOMRect): SliderKey => {
      const [minPosition, maxPosition] = getPositionsFromSteps(values, minMax, clientRect)

      const minDiff = absoluteLength(position, minPosition)
      const maxDiff = absoluteLength(position, maxPosition)
      return minDiff < maxDiff ? 'min' : 'max'
    }

    const updateValuesFromPositions = (trackPositions: NumberRange) =>
      withTrackRect((clientRect) => {
        const [minPosition, maxPosition] = trackPositions
        const minValue = getValueFromPosition(minPosition, minMax, clientRect)
        const maxValue = getValueFromPosition(maxPosition, minMax, clientRect)
        updateValues([getStepValueFromValue(minValue, step), getStepValueFromValue(maxValue, step)])
      })

    const updateValuesFromPosition = (key: SliderKey, trackPosition: number) =>
      withTrackRect((clientRect) => {
        const positions = getPositionsFromSteps(values, minMax, clientRect)
        positions[KeyToIndex[key]] = trackPosition
        updateValuesFromPositions(positions)
      })

    const handleSliderDrag = (event: MouseEvent | TouchEvent, key: SliderKey) => {
      if (!isSliderDragging) setIsSliderDragging(true)
      withTrackRect((clientRect) => {
        const position = getPositionFromEvent(event, clientRect)
        updateValuesFromPosition(key, position)
      })
    }

    const pullBackValuesIfBeyondTrack = ([newMin, newMax]: NumberRange): NumberRange => {
      const pullFromRightEdge = newMax > minMax[1] ? newMax - minMax[1] : 0
      const pushFromLeftEdge = newMin < minMax[0] ? minMax[0] - newMin : 0
      return [newMin, newMax].map(
        (val) => val + pushFromLeftEdge - pullFromRightEdge
      ) as NumberRange
    }
    const roundValues = ([min, max]: NumberRange): NumberRange => {
      if (!roundedTrackClicks) return [min, max]
      const offsetMin = Math.max(0, min - roundOffsetStart)
      const span = max - min
      const roundedFactor = Math.round(offsetMin / roundedTrackClicks)
      const newMin = roundedFactor * roundedTrackClicks + roundOffsetStart
      return [newMin, newMin + span]
    }

    const handleTrackDrag = (event: MouseEvent) => {
      if (!isDraggable || isSliderDragging) return

      withTrackRect((trackRect) => {
        if (!startTrackDragPosition.current) return

        const [min, max] = values

        const eventPosition = getPositionFromEvent(event, trackRect)

        const eventValue = getValueFromPosition(eventPosition, minMax, trackRect)
        const stepValue = getStepValueFromValue(eventValue, step)

        const prevPosition = startTrackDragPosition.current
        const prevEventValue = getValueFromPosition(prevPosition, minMax, trackRect)
        const prevStepValue = getStepValueFromValue(prevEventValue, step)

        const offset = prevStepValue - stepValue

        const pulledBack = pullBackValuesIfBeyondTrack([min - offset, max - offset])
        updateValues(pulledBack)
      })
    }

    const handleTrackMouseDown = (
      event: SyntheticMouseEvent | SyntheticTouchEvent,
      position: number
    ): boolean => {
      let shouldDrag = true
      withTrackRect((clientRect) => {
        const positionValue = getValueFromPosition(position, minMax, clientRect)
        startTrackDragPosition.current = getPositionFromEvent(event, clientRect)

        const [min, max] = values

        const isGreaterThanMax = positionValue > max
        const isLessThanMin = positionValue < min
        const isBeyondTrack = isGreaterThanMax || isLessThanMin

        if (!isBeyondTrack) return

        const onIsNotDraggable = () => {
          const sliderKey = getClosestKeyToPosition(position, clientRect)
          updateValuesFromPosition(sliderKey, position)
        }
        const onIsDraggable = () => {
          const range = max - min
          const midStep = range > 1 ? range / 2 : 0
          const stepValue = isGreaterThanMax
            ? Math.ceil(positionValue + midStep)
            : Math.floor(positionValue - midStep)
          const offset = isGreaterThanMax ? stepValue - max : stepValue - min
          const rounded = roundValues([min + offset, max + offset])
          const pulledBack = pullBackValuesIfBeyondTrack(rounded)

          updateValues(pulledBack)
          shouldDrag = false
        }
        isDraggable ? onIsDraggable() : onIsNotDraggable()
      })
      return shouldDrag
    }

    const handleInteractionEnd = () => {
      setIsSliderDragging(false)
      withCurrentValues(onChangeComplete)
    }

    const [addMouseUp] = useWindowListener('mouseup', () => handleInteractionEnd())
    const [addTouchEnd] = useWindowListener('touchend', () => handleInteractionEnd())

    const handleInteractionStart = () => {
      withCurrentValues(onChangeStart)
      addMouseUp({once: true})
      addTouchEnd({once: true})
    }

    const renderSliders = (): React.ReactNode =>
      keys.map((key) => {
        const [minVal, maxVal] = values
        const isMin = key === 'min'
        const value = isMin ? minVal : maxVal

        const [minPercent, maxPercent] = percentages
        const percentage = isMin ? minPercent : maxPercent

        const currentMinMax: NumberRange = isMin ? [minMax[0], maxVal] : [minVal, minMax[1]]

        return (
          <Slider
            ariaLabelledBy={ariaLabelledBy}
            ariaControls={ariaControls}
            formatLabel={label.formatFn}
            key={key}
            minMax={currentMinMax}
            value={value}
            onSliderStart={handleInteractionStart}
            onSliderDrag={handleSliderDrag}
            percentage={percentage}
            type={key}
          />
        )
      })

    const renderHiddenInputs = (): React.ReactNode => {
      if (!name) return null

      return keys.map((key) => {
        const value = getValueFromKey(key, values)
        const newName = `${name}${capitalize(key)}`

        return <input key={key} type="hidden" name={newName} value={value} />
      })
    }

    const labels = useMemo((): React.ReactNode => {
      const labels: React.ReactNode[] = []
      const totalSteps = Math.ceil((minMax[1] - label.offsetStart) / label.step)

      let i: number, curStep: number
      for (curStep = label.offsetStart, i = 0; curStep < minMax[1]; curStep += label.step, i++) {
        labels.push(
          label.skip && label.skip(i, {sliderWidth, totalSteps}) ? (
            <div key={curStep} {...dataTestId(`slider_step_label_${curStep}_skipped`)} />
          ) : (
            <label.LabelComponent
              key={curStep}
              value={curStep}
              formatLabel={label.formatFn}
              {...dataTestId(`slider_step_label_${curStep}`)}
            />
          )
        )
      }
      const startFr = `${label.offsetStart}fr`
      const repeatFr = `repeat(${labels.length - 1}, ${label.step}fr)`
      const endFr = `${minMax[1] - (label.offsetStart + (labels.length - 1) * label.step)}fr`

      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'grid',
            gridTemplateColumns: `${startFr} ${repeatFr} ${endFr}`,
            alignItems: 'center',
            userSelect: 'none',
            pointerEvents: 'none',
            zIndex: -1
          }}
        >
          <div />
          {labels}
        </div>
      )
    }, [label, minMax, sliderWidth])

    return (
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          position: 'relative',
          gridAutoFlow: 'row',
          border: (theme) => `1px solid ${alpha(theme.palette.common.white, 0.4)}`,
          borderRadius: (theme) => theme.spacing(1)
        }}
        {...dataTestId('range-slider')}
        onMouseEnter={() => onTrackHover?.(true)}
        onMouseLeave={() => onTrackHover?.(false)}
        onMouseDown={handleInteractionStart}
        onTouchStart={handleInteractionStart}
        ref={sliderRef}
      >
        <Track
          isDraggable={!!isDraggable}
          ref={trackRef}
          percentages={percentages}
          trackLabel={<ActiveTrackLabel min={values[0]} max={values[1]} />}
          onTrackDrag={handleTrackDrag}
          onTrackMouseDown={handleTrackMouseDown}
        >
          {renderSliders()}
        </Track>
        {labels}

        {renderHiddenInputs()}
      </Box>
    )
  }
)
