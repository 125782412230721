import {RefObject} from 'react'

// function to move chart elements horizontally using css tranlate3d
export const moveElementsHorizontally = (amount: number, refs: RefObject<HTMLDivElement>[]) => {
  // translate3d is used to force gpu acceleration, to transform more efficiently
  const transformValue = `translate3d(${amount > 0 ? -amount : 0}px, 0px, 0px)`
  refs.forEach((ref) => {
    if (ref.current) {
      ref.current.style.transform = transformValue
    }
  })
}
