import {Moment} from 'moment-timezone'
import {useCallback, useMemo} from 'react'

import {convertToURLDatetime, parseStartEndDatetimeFromURLParams} from '../helpers/urlParams.utils'
import {adjustRangeByMinMaxLimitAndBoundary} from '../helpers/utils'
import {MomentRange} from '../selectors/time'

import {usePlantConfig} from './usePlantConfigData'
import {useSearchParams} from './useSearchParams'

interface ChartStartEndFromURLParams {
  startOfPlan: Moment
  endOfPlan: Moment
  minMaxRangeHours: [number, number]
}

export const useChartStartEndFromURL = ({
  startOfPlan,
  endOfPlan,
  minMaxRangeHours: [minRangeHours, maxRangeHours]
}: ChartStartEndFromURLParams) => {
  const {timezone_id: timezoneId} = usePlantConfig()
  const [obtainedParams, setSearchParams] = useSearchParams('start', 'end')
  const rangeFromURL = parseStartEndDatetimeFromURLParams({...obtainedParams, timezoneId})
  if (!rangeFromURL) {
    throw new Error('BUG: URL params should be handled on page level')
  }
  const [parsedStart, parsedEnd] = rangeFromURL

  const [adjustedStart, adjustedEnd] = useMemo(() => {
    return adjustRangeByMinMaxLimitAndBoundary(
      [parsedStart, parsedEnd],
      [startOfPlan, endOfPlan],
      [minRangeHours, maxRangeHours]
    )
  }, [parsedStart, parsedEnd, startOfPlan, endOfPlan, maxRangeHours, minRangeHours])

  const updateStartEnd = useCallback(
    ([start, end]: MomentRange) => {
      setSearchParams({
        start: convertToURLDatetime(start, timezoneId),
        end: convertToURLDatetime(end, timezoneId)
      })
    },
    [setSearchParams, timezoneId]
  )

  return [{start: adjustedStart, end: adjustedEnd}, updateStartEnd] as [
    {start: Moment; end: Moment},
    (params: MomentRange) => void
  ]
}
