import {dataTestId, formatTons} from '@hconnect/uikit'
import {Stack, Box, Typography, useTheme, Tooltip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DeliveryStatus} from '../../../../shared/enums'
import {DeliveryDetail} from '../../../../shared/selectors/materialDeliveryDetails'
import {DeliveryStatusBarIcon} from '../materialOrdersIcons'

import {MaterialDeliveryTooltipContent} from './MaterialDeliveryTooltipContent'

interface MaterialDeliveriesStatusBarProps {
  deliveries: DeliveryDetail[]
  amountOrdered: number | undefined
}

export const MaterialDeliveriesStatusBar: React.FC<MaterialDeliveriesStatusBarProps> = ({
  deliveries,
  amountOrdered
}) => {
  const {spacing, palette} = useTheme()
  const deliveryStatusToStyleMap: Record<DeliveryStatus, {backgroundColor: string}> = {
    [DeliveryStatus.Delivered]: {backgroundColor: palette.success.light},
    [DeliveryStatus.Overdue]: {backgroundColor: palette.warning.light},
    [DeliveryStatus.Unknown]: {backgroundColor: palette.secondary.light}
  }
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const amountDelivered = deliveries
    .filter((delivery) => delivery.status !== DeliveryStatus.Overdue)
    .reduce((sum, delivery) => sum + delivery.amount, 0)

  const totalAmount = Math.max(amountDelivered, amountOrdered ?? 0)

  const getPercentageOfOrdered = (amount: number) =>
    totalAmount === 0 ? 0 : (amount * 100) / totalAmount

  const getDeliveryBarWidth = (delivered: number) =>
    `calc(${getPercentageOfOrdered(delivered)}% - ${
      deliveries.length ? spacing(0.25 - 1 / deliveries.length) : '0px'
    })`

  return (
    <Stack spacing={0.5}>
      <Box sx={{display: 'flex', justifyItems: 'space-between'}}>
        <Typography variant="body1" sx={{flex: 1}}>
          {t('stock.materialOrders.deliveryStatus')}
        </Typography>
        <Box sx={{display: 'inline-flex'}}>
          {amountOrdered !== undefined ? (
            <>
              <Typography variant="subtitle1">{amountDelivered}</Typography>
              <Typography variant="body1">
                &nbsp;{`/ ${formatTons(amountOrdered, language)}`}
              </Typography>
            </>
          ) : (
            <Typography variant="subtitle1">{formatTons(amountDelivered, language)}</Typography>
          )}
        </Box>
      </Box>
      {/* TODO extract status bar component */}
      <Stack
        direction="row"
        spacing={0.25}
        sx={({spacing, palette}) => ({
          width: '1',
          display: 'flex',
          height: spacing(3),
          backgroundColor: palette.grey[200],
          borderRadius: spacing(0.25)
        })}
      >
        {deliveries.map((delivery, index) => (
          <Tooltip key={index} title={<MaterialDeliveryTooltipContent delivery={delivery} />}>
            <Box
              sx={{
                display: 'flex',
                width: getDeliveryBarWidth(delivery.amount),
                backgroundColor: deliveryStatusToStyleMap[delivery.status],
                justifyContent: 'center',
                alignItems: 'center'
              }}
              {...dataTestId(`delivery_bar_${delivery.status}`)}
            >
              <DeliveryStatusBarIcon status={delivery.status} />
            </Box>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  )
}
