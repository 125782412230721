import {HistoryMaterialStorage} from '@hconnect/common/types'
import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerSwitch} from '../../../../../../shared/components/formComponents/PlannerSwitch'
import {useScheduleQuery} from '../../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useUpdateScheduleItem} from '../../../../../../shared/hooks/api/schedules/useUpdateScheduleItem'
import {useAssetHistoryDataByScheduleItemId} from '../../../../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'

import {SelectStorages} from './SelectStorages'
import {SelectStoragesOrdered} from './SelectStoragesOrdered'

interface SelectStorageSectionProps {
  scheduleItem: ScheduleItem
  startOfPlan: Moment
  endOfPlan: Moment
  availableStorages: HistoryMaterialStorage[]
  storagesById: Record<string, HistoryMaterialStorage>
}

export const SelectStorageSection: FC<SelectStorageSectionProps> = ({
  scheduleItem,
  startOfPlan,
  endOfPlan,
  availableStorages,
  storagesById
}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})

  const isSiloNominationDisabled = moment.utc(scheduleItem.end).isBefore(nowRounded)

  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], isOptimized: false})
  const assetDataByScheduleItemId = useAssetHistoryDataByScheduleItemId({
    timeFrame: [startOfPlan, endOfPlan],
    schedule
  })

  const {updateScheduleItem} = useUpdateScheduleItem({
    startOfPlan,
    endOfPlan,
    assetDataByScheduleItemId
  })

  const {
    selectedSilos: {isOrderSpecified, ids: selectedSilosIds}
  } = scheduleItem

  const isSelectedStorageIdValid = (storageId: number) =>
    availableStorages.map(({id}) => id).includes(storageId)

  const validSelectedStorageIds = selectedSilosIds.filter(isSelectedStorageIdValid)

  const handleToggleSpecifyOrder = () => {
    updateScheduleItem({
      ...scheduleItem,
      selectedSilos: {...scheduleItem.selectedSilos, isOrderSpecified: !isOrderSpecified}
    })
  }

  const checkIfStorageSelected = (storageId: number) => validSelectedStorageIds.includes(storageId)

  const availableStoragesSorted = [...availableStorages]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((storage) => ({...storage, isSelected: checkIfStorageSelected(storage.id)}))

  const availableStorageIdsOrdered = [
    ...new Set([...validSelectedStorageIds, ...availableStoragesSorted.map(({id}) => id)])
  ]
  const availableStoragesOrdered = availableStorageIdsOrdered.map((id) => ({
    ...storagesById[id],
    isSelected: checkIfStorageSelected(id)
  }))

  return (
    <Stack {...dataTestId('select_storage_section')}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CardSectionTitle>{t('planning.produceTo')}</CardSectionTitle>
        <PlannerSwitch
          label={t('planning.specifyOrder')}
          value={isOrderSpecified}
          onChange={handleToggleSpecifyOrder}
          disabled={isSiloNominationDisabled}
          {...dataTestId('specify_order_switch')}
        />
      </Stack>
      {isOrderSpecified ? (
        <SelectStoragesOrdered
          availableStorages={availableStoragesOrdered}
          scheduleItem={scheduleItem}
          isReadOnly={isSiloNominationDisabled}
          startOfPlan={startOfPlan}
          endOfPlan={endOfPlan}
        />
      ) : (
        <SelectStorages
          availableStorages={availableStoragesSorted}
          scheduleItem={scheduleItem}
          startOfPlan={startOfPlan}
          endOfPlan={endOfPlan}
          isReadOnly={isSiloNominationDisabled}
        />
      )}
    </Stack>
  )
}
