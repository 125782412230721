import {
  closestCenter,
  DndContext,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core'
import {rectSortingStrategy, SortableContext, arrayMove} from '@dnd-kit/sortable'
import {HistoryMaterialStorage} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {Stack, Box, alpha, Typography} from '@mui/material'
import {Moment} from 'moment-timezone'
import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useScheduleQuery} from '../../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useUpdateScheduleItem} from '../../../../../../shared/hooks/api/schedules/useUpdateScheduleItem'
import {useAssetHistoryDataByScheduleItemId} from '../../../../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'

import {SortableStorageButton} from './SortableStorageButton'

interface SelectStoragesOrderedProps {
  availableStorages: (Pick<HistoryMaterialStorage, 'id' | 'name'> & {isSelected: boolean})[]
  startOfPlan: Moment
  endOfPlan: Moment
  scheduleItem: ScheduleItem
  isReadOnly: boolean
}

export const SelectStoragesOrdered: FC<SelectStoragesOrderedProps> = ({
  availableStorages,
  scheduleItem,
  startOfPlan,
  endOfPlan,
  isReadOnly
}) => {
  const {t} = useTranslation()
  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], isOptimized: false})
  const assetDataByScheduleItemId = useAssetHistoryDataByScheduleItemId({
    timeFrame: [startOfPlan, endOfPlan],
    schedule
  })
  const {updateScheduleItem, isUpdateScheduleItemLoading} = useUpdateScheduleItem({
    startOfPlan,
    endOfPlan,
    assetDataByScheduleItemId
  })
  const [draggableStorages, setDraggableStorages] = useState(availableStorages)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {distance: 10}
    }),
    useSensor(MouseSensor, {
      activationConstraint: {distance: 10}
    })
  )

  const handleSelectStorage = (storageId: number) => {
    const newStorages = draggableStorages.map((item) =>
      item.id === storageId ? {...item, isSelected: !item.isSelected} : item
    )
    const updatedIds = newStorages.filter(({isSelected}) => isSelected).map(({id}) => id)
    setDraggableStorages(newStorages)
    updateScheduleItem({...scheduleItem, selectedSilos: {isOrderSpecified: true, ids: updatedIds}})
  }

  const handleUpdateStorageOrder = (storageIds: number[]) => {
    updateScheduleItem({
      ...scheduleItem,
      selectedSilos: {isOrderSpecified: true, ids: storageIds}
    })
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event
    if (active.id !== over?.id) {
      setDraggableStorages((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over?.id)
        const updatedStorages = arrayMove(items, oldIndex, newIndex)
        const updatedSelectedStorages = updatedStorages.filter((storage) => storage.isSelected)
        const updatedSelectedStoragesIds = updatedSelectedStorages.map(({id}) => id)

        handleUpdateStorageOrder(updatedSelectedStoragesIds)
        return updatedStorages
      })
    }
  }
  const isSelectingAndOrderingDisabled = isReadOnly || isUpdateScheduleItemLoading

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={draggableStorages}
        strategy={rectSortingStrategy}
        disabled={isSelectingAndOrderingDisabled}
      >
        <Typography variant="body2" sx={{mb: 2}}>
          {t('planning.dragSilosInfo')}
        </Typography>
        <Stack
          gap={1.5}
          direction="row"
          flexWrap="wrap"
          {...dataTestId('ordered_storage_selector')}
        >
          {draggableStorages.map((storage, index) => (
            <Stack
              spacing={1.5}
              direction="row"
              key={storage.id}
              alignItems="center"
              {...dataTestId('ordered_storage_button_wrapper')}
            >
              {
                <NumberLetterSequenceIndicator
                  numberIndex={index}
                  {...dataTestId('order_indicator')}
                />
              }
              {/* Background in place of dragged element */}
              <Box
                sx={{
                  backgroundColor: ({palette}) => alpha(palette.primary.main, 0.08),
                  borderRadius: ({spacing}) => spacing(1)
                }}
              >
                <SortableStorageButton
                  storage={storage}
                  isSelected={storage.isSelected}
                  isDisabled={isSelectingAndOrderingDisabled}
                  handleSelectStorage={handleSelectStorage}
                />
              </Box>
            </Stack>
          ))}
        </Stack>
      </SortableContext>
    </DndContext>
  )
}
