import {Project} from '@hconnect/common/project'
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

import {
  createBaseUrlInterceptor,
  createErrorInterceptor,
  createNoCacheInterceptor,
  createOnRequestInterceptor,
  createOnResponseInterceptor,
  createTokenHandlerRequestInterceptor,
  createTokenHandlerRequestInterceptorWithToken,
  requestIdHeaderInterceptor,
  timezoneHeaderInterceptor,
  validateJsonResponseInterceptor
} from './interceptors'
import {createUrlOverrideInterceptor} from './interceptors/request/urlOverride'
import {BackendService} from './localBackendOverrides'
import {ClientConfig, PublicClientConfig, TokenClientConfig} from './types'

export function createHttpClient(
  clientConfig: ClientConfig,
  axiosConfig?: AxiosRequestConfig
): AxiosInstance {
  const client = axios.create(axiosConfig)

  client.interceptors.request.use(createOnRequestInterceptor(clientConfig))
  client.interceptors.request.use(timezoneHeaderInterceptor)
  client.interceptors.request.use(requestIdHeaderInterceptor)
  if (process.env.REACT_APP_LOCAL_BACKENDS) {
    const localBackends = process.env.REACT_APP_LOCAL_BACKENDS.split(',') as BackendService[]
    client.interceptors.request.use(createUrlOverrideInterceptor(localBackends))
  }
  client.interceptors.request.use(createBaseUrlInterceptor(clientConfig))
  client.interceptors.request.use(createNoCacheInterceptor())
  client.interceptors.request.use(createTokenHandlerRequestInterceptor(clientConfig))

  client.interceptors.response.use(createOnResponseInterceptor(clientConfig))
  client.interceptors.response.use(
    validateJsonResponseInterceptor(clientConfig),
    createErrorInterceptor(clientConfig)
  )

  return client
}

export function createHttpClientWithToken(
  clientConfig: TokenClientConfig,
  axiosConfig?: AxiosRequestConfig
): AxiosInstance {
  const client = axios.create(axiosConfig)

  client.interceptors.request.use(createOnRequestInterceptor(clientConfig))
  client.interceptors.request.use(timezoneHeaderInterceptor)
  client.interceptors.request.use(requestIdHeaderInterceptor)
  client.interceptors.request.use(createBaseUrlInterceptor(clientConfig))
  client.interceptors.request.use(createNoCacheInterceptor())
  client.interceptors.request.use(createTokenHandlerRequestInterceptorWithToken(clientConfig))

  client.interceptors.response.use(createOnResponseInterceptor(clientConfig))
  client.interceptors.response.use(
    validateJsonResponseInterceptor(clientConfig),
    createErrorInterceptor(clientConfig)
  )

  return client
}

export function createPublicHttpClient(
  clientConfig: PublicClientConfig,
  axiosConfig?: AxiosRequestConfig
): AxiosInstance {
  const client = axios.create(axiosConfig)

  client.interceptors.request.use(createOnRequestInterceptor(clientConfig))
  client.interceptors.request.use(timezoneHeaderInterceptor)
  client.interceptors.request.use(requestIdHeaderInterceptor)
  client.interceptors.request.use(createBaseUrlInterceptor(clientConfig))
  client.interceptors.request.use(createNoCacheInterceptor())

  client.interceptors.response.use(createOnResponseInterceptor(clientConfig))
  client.interceptors.response.use(
    validateJsonResponseInterceptor(clientConfig),
    createErrorInterceptor(clientConfig)
  )

  return client
}

export type ClientInstance = AxiosInstance
export type ClientRequestConfig = AxiosRequestConfig
export type ClientResponse = AxiosResponse
export type ClientError = AxiosError
