import {formatTimeZoneDate, formatTons} from '@hconnect/uikit'
import {useTranslation} from 'react-i18next'

import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'

interface DeliveriesCardHeaderParams {
  amount: number
  scheduledDate: string
  documentNumber?: string
  positionNumber?: string
}

export const useDeliveriesCardHeaderOrderInfo = ({
  amount,
  scheduledDate,
  documentNumber,
  positionNumber
}: DeliveriesCardHeaderParams) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const formatOrderDate = (date: string) => formatTimeZoneDate(date, timezoneId, 'L', language)
  return [
    formatTons(amount, language),
    formatOrderDate(scheduledDate),
    ...(documentNumber
      ? [t('stock.materialOrders.agreementNumber', {number: documentNumber})]
      : []),
    ...(positionNumber ? [t('stock.materialOrders.position', {number: positionNumber})] : [])
  ].join(' | ')
}
