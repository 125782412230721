import {URL} from '@cliqz/url-parser'
import {AxiosResponse} from 'axios'
import globalThis from 'globalthis'
import jwtDecode from 'jwt-decode'

import {AuthenticatorResponse, PersistedToken} from './types'

const browserGlobal = globalThis()

export const getAuthorizationHeader = (accessToken = '') => ({
  Authorization: `Bearer ${accessToken}`
})

export const getURLEncodedContentTypeHeader = () => ({
  'Content-Type': 'application/x-www-form-urlencoded'
})

export const convertAuthToApiToken = (
  responseData: Required<Pick<AuthenticatorResponse, 'id_token' | 'refresh_token' | 'access_token'>>
): PersistedToken => {
  const idToken = responseData.id_token
  const refreshToken = responseData.refresh_token
  const accessToken = responseData.access_token
  return {idToken, refreshToken, accessToken}
}

export function decodeAccessToken<T>(accessToken: string): T {
  return jwtDecode(accessToken)
}

/**
 * Incomplete representation of some JWT token core fields for our tools usage.
 */
export interface BasicTokenContent {
  exp: number
  iss: string
}

export const hasAccessTokenExpired = (accessToken: string): boolean => {
  if (!accessToken) {
    return true
  }

  const decoded: BasicTokenContent = decodeAccessToken<BasicTokenContent>(accessToken)
  const expiresAt = 'exp' in decoded ? decoded.exp * 1000 : 0

  const now = browserGlobal.API_CLIENT_FORCE_EXPIRE ? Infinity : Date.now()

  if (browserGlobal.API_CLIENT_FORCE_EXPIRE) {
    console.log('API-Client: Force Expired Previous Token')
    browserGlobal.API_CLIENT_FORCE_EXPIRE = false
  }

  if (browserGlobal.API_CLIENT_DEBUG_TOKEN) {
    const remainingMinutes = Math.round((expiresAt - now) / 1000 / 60)
    console.log(`API-Client: Remaining Token Minutes: ${remainingMinutes}`)
  }

  return now > expiresAt
}

export const extractBaseUrlFromAccessToken = (accessToken: string): string => {
  if (
    process.env.NODE_ENV !== 'production' &&
    (typeof accessToken !== 'string' || accessToken === '')
  ) {
    throw new Error('Invalid accessToken passed to `extractBaseUrlFromAccessToken`!')
  }

  const decoded: BasicTokenContent = decodeAccessToken<BasicTokenContent>(accessToken)
  return decoded.iss || ''
}

interface ParsedUrl {
  protocol?: string
  hostname?: string
  port?: string
  pathname?: string
  search?: string
  hash?: string
}

const urlFields = ['protocol', 'hostname', 'port', 'pathname', 'search', 'hash']

export function prefixString(value: string | undefined, prefix: string): string | undefined {
  if (!value) {
    return value
  }

  if (value.startsWith(prefix)) {
    return value
  }

  return `${prefix}${value}`
}

export function buildUrl(fields: ParsedUrl) {
  const port = prefixString(fields.port, ':')
  const search = prefixString(fields.search, '?')
  const hash = prefixString(fields.hash, '#')

  return `${fields.protocol ? `${fields.protocol}//` : ''}${fields.hostname}${port}${
    fields.pathname !== '/' || fields.hostname ? fields.pathname : ''
  }${search}${hash}`
}

export function parseUrl(url: string): ParsedUrl {
  const result: ParsedUrl = {}

  const parsed = new URL(url)
  urlFields.forEach((field) => {
    result[field] = parsed[field]
  })

  // OnSite uses custom url schemes for app-linking:

  // export const AuthorizationUrlSchemeDEV = 'hconnectonsiteauthorizationdev'
  // export const AuthorizationUrlSchemeQA = 'hconnectonsiteauthorizationqa'
  // export const AuthorizationUrlScheme = 'hconnectonsiteauthorization'
  // Platform.OS === 'android' ? `${authorizationUrlScheme}://authorize/` : `${authorizationUrlScheme}://`

  // Disabled original implementation
  // Unfortunately Chrome as of v85 (NodeJS not) has issues of parsing custom protocols correctly.

  // if (typeof URL === 'function') {
  //   const parsed = new URL(url)
  //   urlFields.forEach((field) => {
  //     result[field] = parsed[field]
  //   })
  // }

  // // Easier and lower weight than a full blown URL polyfill for IE11
  // if (typeof document !== 'undefined' && document.createElement) {
  //   const link = document.createElement('a')
  //   link.href = url
  //   urlFields.forEach((field) => {
  //     result[field] = link[field]
  //   })
  // }

  return result
}

export function retrieveResponseData<T>(
  apiFunction: (...apiFunctionArguments: any[]) => Promise<AxiosResponse<T>>,
  ...apiFunctionArguments: any[]
) {
  return async (): Promise<T> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const response = await apiFunction(...apiFunctionArguments)
    return response.data
  }
}
