import {dataTestId} from '@hconnect/uikit'
import {Check, Clear} from '@mui/icons-material'
import {Stack, Button} from '@mui/material'
import {useTranslation} from 'react-i18next'

interface AcceptRejectOptimizedPlanButtonsProps {
  handleRejectOptimizedPlan: () => void
  handleSelectOptimizedPlan: () => void
}
export const AcceptRejectOptimizedPlanButtons = ({
  handleRejectOptimizedPlan,
  handleSelectOptimizedPlan
}: AcceptRejectOptimizedPlanButtonsProps) => {
  const {t} = useTranslation()
  return (
    <Stack spacing={2} direction="row" justifyContent="flex-end">
      <Button
        size="large"
        variant="outlined"
        color="error"
        startIcon={<Clear fontSize="small" />}
        onClick={handleRejectOptimizedPlan}
        {...dataTestId('reject_optimized_plan_button')}
      >
        {t('planning.rejectOptimizedPlan')}
      </Button>
      <Button
        size="large"
        variant="contained"
        color="primary"
        startIcon={<Check fontSize="small" />}
        onClick={handleSelectOptimizedPlan}
        {...dataTestId('accept_optimized_plan_button')}
      >
        {t('optimizer.chooseOptimizedPlan')}
      </Button>
    </Stack>
  )
}
