import React, {useContext, useState, useMemo} from 'react'

interface ScheduleCostAvoidanceDialogState {
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ScheduleCostAvoidanceDialogContext = React.createContext<
  ScheduleCostAvoidanceDialogState | undefined
>(undefined)

export const useScheduleCostAvoidanceDialog = () => {
  const context = useContext(ScheduleCostAvoidanceDialogContext)
  if (!context)
    throw new Error(
      'Cannot use ScheduleCostAvoidanceDialogContext outside of a ScheduleCostAvoidanceDialogProvider'
    )
  return context
}

export const ScheduleCostAvoidanceDialogProvider = ({children}: {children: React.ReactNode}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const memoizedState = useMemo(() => ({isDialogOpen, setIsDialogOpen}), [isDialogOpen])

  return (
    <ScheduleCostAvoidanceDialogContext.Provider value={memoizedState}>
      {children}
    </ScheduleCostAvoidanceDialogContext.Provider>
  )
}
