import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'

import {useScheduleCostAvoidanceDialog} from '../../../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {PlannerFeature} from '../../../../shared/enums'
import {useTrackPlannerEvent} from '../../../../shared/helpers/trackPlannerEvents'
import {usePeakLoadWindowsQuery} from '../../../../shared/hooks/api'
import {useScheduleQuery} from '../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useSubmitSchedule} from '../../../../shared/hooks/api/schedules/useSubmitSchedule'
import {usePlannerFeature} from '../../../../shared/hooks/usePlannerFeature'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {Electricity, Schedule} from '../../../../shared/interfaces/api'
import {getPeakLoadWindows} from '../../../../shared/selectors/electricity'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'

import {ElectricityPurchaseConfirmation} from './ElectricityPurchaseConfirmation'
import {SubmissionButton} from './SubmissionButton'

interface SubmitPlanSectionProps {
  electricity: Electricity | undefined
  isSubmitAvailable: boolean
  startOfPlan: Moment
  endOfPlan: Moment
}

export const SubmitPlanSection: React.FC<SubmitPlanSectionProps> = React.memo(
  ({isSubmitAvailable, electricity, startOfPlan, endOfPlan}) => {
    const plantCode = useUrlParam('plantCode')
    const trackPlannerEvent = useTrackPlannerEvent()
    const {timezone_id: timezoneId} = usePlantConfig()

    const powerConsumptionQuarterly = electricity?.planned

    const {mutate: submitSchedule} = useSubmitSchedule()
    const {isOptimized} = usePlanningChartScheduleData()
    const {data: schedule} = useScheduleQuery({
      range: [startOfPlan, endOfPlan],
      isOptimized,
      useErrorBoundary: false
    })

    const submissionInfo = schedule?.submissionInfo

    const isCostAvoidanceEnabled = usePlannerFeature(PlannerFeature.CostAvoidance)
    const {setIsDialogOpen} = useScheduleCostAvoidanceDialog()

    const [showConfirmation, setShowConfirmation] = React.useState(false)
    const {data: peakLoadWindows} = usePeakLoadWindowsQuery(
      [
        moment.utc(submissionInfo?.start).tz(timezoneId),
        moment.utc(submissionInfo?.end).tz(timezoneId)
      ],
      {
        enabled: !!submissionInfo
      }
    )

    const peakLoadWindowsData = useMemo(() => {
      if (powerConsumptionQuarterly && submissionInfo && peakLoadWindows) {
        const submissionStart = moment.utc(submissionInfo.start).tz(timezoneId)
        const submissionEnd = moment.utc(submissionInfo.end).tz(timezoneId)
        return getPeakLoadWindows({
          peakLoadWindows: peakLoadWindows.filter(
            (plw) =>
              submissionEnd.isSameOrAfter(plw.start) && submissionStart.isSameOrBefore(plw.end)
          ),
          powerConsumption: powerConsumptionQuarterly
        })
      }
      return undefined
    }, [peakLoadWindows, powerConsumptionQuarterly, submissionInfo, timezoneId])

    const isSubmitDisabled =
      (peakLoadWindowsData && peakLoadWindowsData.some((plw) => plw.violated)) ||
      !schedule ||
      !submissionInfo

    const handleSubmitPlan = (schedule: Schedule) => {
      trackPlannerEvent({name: 'onSubmitPlanClick'})
      submitSchedule(
        {
          plantCode,
          scheduleId: schedule.scheduleId,
          updatedById: schedule.lastSaved.savedById,
          updatedOn: schedule.lastSaved.savedAt
        },
        {
          onSuccess: isCostAvoidanceEnabled ? () => setIsDialogOpen(true) : undefined
        }
      )
    }
    return (
      <>
        {showConfirmation && submissionInfo && electricity && (
          <ElectricityPurchaseConfirmation
            show={showConfirmation}
            submissionInfo={submissionInfo}
            electricity={electricity}
            onClose={(submit: boolean) => {
              if (submit && schedule) {
                handleSubmitPlan(schedule)
              }
              setShowConfirmation(false)
            }}
          />
        )}
        {isSubmitAvailable && (
          <SubmissionButton
            id="submit-plan-button"
            buttonLabel="planning.submitSchedule"
            buttonTestId="button-schedule-save-and-submit"
            disabled={isSubmitDisabled}
            onClick={() => setShowConfirmation(true)}
          />
        )}
      </>
    )
  }
)
