import {formatLocalizedDateMonth, dataTestId} from '@hconnect/uikit'
import {Typography, Grid, Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {SiloLevelsSkeleton} from '../../shared/components/skeletons/SiloLevelsSkeleton'
import {
  useHistoryAssetsQuery,
  useMaterialsRecipesById,
  useCurrentStoragesWithMaterialQuery
} from '../../shared/hooks/api'
import {useHistoryMaterialsById} from '../../shared/hooks/api/materials/useHistoryMaterialsById'
import {getOptimizedMaterialIdsWithStorages} from '../../shared/selectors/storageLevels'

import {OptimizerSummaryComparison} from './OptimizerSummaryComparison'
import {SiloLevelsCard} from './SiloLevelsCard'

const getSiloLevelsSkeletons = (amount: number) => {
  return Array.from({length: amount}, (_, index) => (
    <Grid item sm={12} lg={6} key={index}>
      <SiloLevelsSkeleton />
    </Grid>
  ))
}

interface LayoutOptimizerSummaryProps {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const LayoutOptimizerSummary: React.FC<LayoutOptimizerSummaryProps> = ({
  startOfPlan,
  endOfPlan
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {data: storagesWithMaterial} = useCurrentStoragesWithMaterialQuery()
  const {data: assets} = useHistoryAssetsQuery({timeFrame: [startOfPlan, endOfPlan]})
  const {data: materialsById} = useHistoryMaterialsById({timeFrame: [startOfPlan, endOfPlan]})
  const {data: recipesById} = useMaterialsRecipesById()
  const optimizedMaterialIds = useMemo(
    () =>
      assets && recipesById && storagesWithMaterial
        ? getOptimizedMaterialIdsWithStorages({assets, recipesById, storagesWithMaterial})
        : undefined,
    [recipesById, assets, storagesWithMaterial]
  )

  const shouldShowLoader = materialsById && optimizedMaterialIds

  return (
    <div>
      <div {...dataTestId('optimizer_screen_description')}>
        <Typography
          sx={{
            marginBottom: '16px',
            color: 'primary.contrastText',
            opacity: 0.6
          }}
        >
          {t('optimizer.optimizerPageDescription', {
            from: formatLocalizedDateMonth(startOfPlan, language),
            to: formatLocalizedDateMonth(endOfPlan, language)
          })}
        </Typography>
      </div>
      <Box sx={{mb: 2}}>
        <OptimizerSummaryComparison startOfPlan={startOfPlan} endOfPlan={endOfPlan} />
      </Box>
      <Grid container spacing={2}>
        {shouldShowLoader
          ? optimizedMaterialIds.map((materialId, index) => (
              <Grid item xs={12} lg={6} key={`${materialId}_${index}_card`}>
                <SiloLevelsCard material={materialsById[materialId]} />
              </Grid>
            ))
          : getSiloLevelsSkeletons(6)}
      </Grid>
    </div>
  )
}
