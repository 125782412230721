import {hpTheme} from '@hconnect/uikit/src/lib2'
import {TooltipOptions} from 'chart.js/auto'

export const coreTooltipTypographyConfig: Partial<TooltipOptions> = {
  titleFont: {
    family: hpTheme.typography.fontFamily,
    size: parseInt(hpTheme.typography.subtitle1.fontSize as string),
    weight: hpTheme.typography.subtitle1.fontWeight as number,
    lineHeight: hpTheme.typography.subtitle1.lineHeight
  },
  bodyFont: {
    family: hpTheme.typography.fontFamily,
    size: parseInt(hpTheme.typography.h3.fontSize as string),
    weight: hpTheme.typography.h3.fontWeight as number,
    lineHeight: hpTheme.typography.h3.lineHeight
  },
  footerFont: {
    family: hpTheme.typography.fontFamily,
    size: parseInt(hpTheme.typography.caption.fontSize as string),
    weight: hpTheme.typography.caption.fontWeight as number,
    lineHeight: hpTheme.typography.caption.lineHeight
  }
}

export const coreWhiteTooltipConfig: Partial<TooltipOptions> = {
  ...coreTooltipTypographyConfig,
  titleColor: hpTheme.palette.text.primary,
  bodyColor: hpTheme.palette.text.primary,
  footerColor: hpTheme.palette.text.secondary,
  backgroundColor: hpTheme.palette.common.white,
  displayColors: false
}
export const coreBlackTooltipConfig: Partial<TooltipOptions> = {
  ...coreTooltipTypographyConfig,
  displayColors: false
}
