import {dataTestId as spreadDataTestId} from '@hconnect/uikit'
import {InfoOutlined} from '@mui/icons-material'
import {Typography} from '@mui/material'
import React from 'react'

interface InfoCaptionProps {
  children: React.ReactNode
  iconPosition?: 'start' | 'end'
  ['data-test-id']?: string
}

export const InfoCaption: React.FC<InfoCaptionProps> = ({
  children,
  iconPosition = 'start',
  ['data-test-id']: dataTestId
}) => {
  const InfoIcon = (
    <InfoOutlined
      sx={{
        fontSize: 'inherit',
        mr: iconPosition === 'start' ? 0.5 : 0,
        ml: iconPosition === 'end' ? 0.5 : 0
      }}
    />
  )

  return (
    <Typography
      variant="caption"
      sx={{display: 'flex', alignItems: 'center', whiteSpace: 'pre-wrap'}}
      {...spreadDataTestId(dataTestId ?? ' info_caption')}
    >
      {iconPosition === 'start' && InfoIcon}
      {children}
      {iconPosition === 'end' && InfoIcon}
    </Typography>
  )
}
