export const absoluteLength = (a: number, b: number): number => Math.abs(a - b)

export type NumberRange = [min: number, max: number]

export const clamp = (value: number, [min, max]: NumberRange) => Math.min(Math.max(value, min), max)

export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1)

export const isRangeValid = ([min, max]: NumberRange) => min >= max

export const isValueInRange = (value: number, [min, max]: NumberRange) => value > min && value < max

export const isRangeInRange = (innerRange: NumberRange, outerRange: NumberRange) =>
  innerRange.every((value) => isValueInRange(value, outerRange))
export const areRangesEqual = (a: NumberRange, b: NumberRange): boolean =>
  a.every((value, index) => value === b[index])
export const areRangeSizesEqual = (a: NumberRange, b: NumberRange): boolean =>
  absoluteLength(...a) === absoluteLength(...b)
