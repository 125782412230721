import {AppInsightsAdapter} from '@hconnect/common/logging'
import {customThemeConstants, hpTheme, Loader} from '@hconnect/uikit/src/lib2'
import {ThemeProvider} from '@mui/material'
import {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'

import {checkAndEnableMockIfRequired} from './__mock__/registerMocks'
import {initLocalization} from './localization'
import {PlannerErrorBoundary} from './shared/components/errorBoundary/PlannerErrorBoundary'
import {Provider} from './shared/components/providers/index.provider'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore hack to disable react overlay for Cypress
if (typeof window.Cypress !== 'undefined') {
  require('./disableErrorOverlay.css')
  console.info('Cypress detected, disabling React Error Overlay')
}
initLocalization()
checkAndEnableMockIfRequired()

AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as HTMLElement)

const {palette} = customThemeConstants()

root.render(
  <ThemeProvider theme={hpTheme}>
    <Router>
      <PlannerErrorBoundary
        fallbackSx={{width: 'auto', maxWidth: ({spacing}) => spacing(60)}}
        fallbackWrapperSx={{
          height: '100vh',
          background: palette.backgroundGradient,
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onRetry={() => location.reload()}
      >
        <Suspense fallback={<Loader />}>
          <Provider />
        </Suspense>
      </PlannerErrorBoundary>
    </Router>
  </ThemeProvider>
)
