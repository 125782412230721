import {FormControlLabel, Switch} from '@mui/material'
import type {FormControlLabelProps} from '@mui/material'
import React from 'react'

interface PlannerCheckboxProps
  extends Pick<FormControlLabelProps, 'inputRef' | 'disabled' | 'onBlur' | 'onKeyDown'> {
  label?: string
  ['data-test-id']?: string
  value: boolean
  onChange?: (checked: boolean) => void
}

export const PlannerSwitch = ({label, value, onChange, ...otherProps}: PlannerCheckboxProps) => {
  return (
    <FormControlLabel
      control={<Switch />}
      label={label}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      {...otherProps}
    />
  )
}
