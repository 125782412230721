import {hpTheme} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {PriceLevel} from './enums'
import {HCEMPalette} from './pallette'

const ampelBackgroundColorStyles: Record<
  PriceLevel,
  Required<Pick<React.CSSProperties, 'backgroundColor'>>
> = {
  veryLow: {backgroundColor: HCEMPalette.evergreen},
  low: {backgroundColor: HCEMPalette.swamp},
  mid: {backgroundColor: HCEMPalette.dirtyHoney},
  high: {backgroundColor: HCEMPalette.pumpkin},
  veryHigh: {backgroundColor: HCEMPalette.halloween},
  unknown: {backgroundColor: hpTheme.palette.grey[300]}
}
const ampelFillStyles: Record<PriceLevel, React.CSSProperties> = {
  veryLow: {fill: HCEMPalette.evergreen},
  low: {fill: HCEMPalette.swamp},
  mid: {fill: HCEMPalette.dirtyHoney},
  high: {fill: HCEMPalette.pumpkin},
  veryHigh: {fill: HCEMPalette.halloween},
  unknown: {fill: hpTheme.palette.grey[500]}
}
const ampelColorStyles: Record<PriceLevel, Required<Pick<React.CSSProperties, 'color'>>> = {
  veryLow: {color: HCEMPalette.evergreen},
  low: {color: HCEMPalette.swamp},
  mid: {color: HCEMPalette.dirtyHoney},
  high: {color: HCEMPalette.pumpkin},
  veryHigh: {color: HCEMPalette.halloween},
  unknown: {color: hpTheme.palette.grey[500]}
}

export const getAmpelBackgroundColorStyle = (priceLevel = PriceLevel.unknown) =>
  ampelBackgroundColorStyles[priceLevel]

export const getAmpelFillStyle = (priceLevel = PriceLevel.unknown): React.CSSProperties =>
  ampelFillStyles[priceLevel]

export const getAmpelColorStyle = (priceLevel = PriceLevel.unknown) => ampelColorStyles[priceLevel]
