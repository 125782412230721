import * as d3 from 'd3'
import {useLayoutEffect, useEffect, useState, RefObject} from 'react'

/**
 * hook for tracking a drag and drop  distance horizontaly in pixels
 * @param ref any html element
 * @returns deltaX in px
 */
export const useHorizontalDrag = <T extends HTMLElement>(ref: RefObject<T>) => {
  const [deltaX, setDeltaX] = useState<number>(0)

  useLayoutEffect(() => {
    if (!ref.current) return
    const refContainerD3 = d3.select<Element, unknown>(ref.current)
    let startX = 0
    refContainerD3.call(
      d3
        .drag()
        .on('start', (event) => {
          event.sourceEvent.stopPropagation()
          startX = event.x
        })
        .on('end', (event) => {
          event.sourceEvent.stopPropagation()
          const deltaX = (event.x - startX) * -1
          setDeltaX(deltaX)
        })
    )
  }, [ref])

  useEffect(() => {
    // reseting deltaX after event
    if (deltaX) setDeltaX(0)
  }, [deltaX])

  // deltaX in px
  return {deltaX}
}
