/* eslint-disable complexity */
// TODO: remove eslint-disable by HCP-79008
import {Material, MaterialSource} from '@hconnect/common/types'
import {Card, CardProps} from '@hconnect/uikit/src/lib2'
import {Tooltip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'

import {PLANT_ROUTES, RoutingState, getUrl} from '../../../routing'
import {PlannerFeature} from '../../../shared/enums'
import {useTrackPlannerEvent} from '../../../shared/helpers/trackPlannerEvents'
import {useIsMaterialUnmeasured} from '../../../shared/hooks/api/materials/useIsMaterialUnmeasured'
import {usePermission} from '../../../shared/hooks/permissions/usePermission'
import {usePlannerFeature} from '../../../shared/hooks/usePlannerFeature'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'

import {StockCardHeader} from './StockCardHeader'

interface StockCard extends CardProps {
  title: React.ReactNode
  additionalHeader?: React.ReactNode
  material?: Material
  page: 'storage' | 'material'
  colSpan?: number
  children: React.ReactNode
}

export const StockCard: React.FC<StockCard> = ({
  title,
  additionalHeader,
  colSpan,
  page,
  material,
  children,
  ...rest
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()
  const trackPlannerEvent = useTrackPlannerEvent()

  const canViewMaterialOrders = usePermission('VIEW_MATERIAL_ORDERS')
  const isRawMaterialsPlanningFeatureAvailable = usePlannerFeature(
    PlannerFeature.RawMaterialsPlanning
  )

  const {data: isMaterialUnmeasured} = useIsMaterialUnmeasured(material?.id)

  const canGoToBoughtToVendorPage =
    !!material &&
    material.source === MaterialSource.BoughtFromVendor &&
    canViewMaterialOrders &&
    isRawMaterialsPlanningFeatureAvailable

  const canGoToOverrideMaterials =
    !!material && isMaterialUnmeasured && isRawMaterialsPlanningFeatureAvailable

  const canGoToMaterialDetail =
    canGoToOverrideMaterials || (canGoToBoughtToVendorPage && material.products.length > 0)

  const boughtFromVendorIsDisabled =
    !canGoToOverrideMaterials && canGoToBoughtToVendorPage && !material?.products.length

  const handleCardClick =
    canGoToMaterialDetail && !boughtFromVendorIsDisabled
      ? () => {
          trackPlannerEvent({name: 'userEntersMaterialPlan'})
          navigate(
            getUrl(PLANT_ROUTES.STOCK.MATERIALS.ORDERS.path, {
              plantCode,
              materialId: String(material.id)
            }),
            {
              state: {
                materialsVisitedFrom: page
              } as RoutingState
            }
          )
        }
      : undefined

  const card = (
    <Card
      {...rest}
      onClick={handleCardClick}
      sx={{
        cursor: canGoToMaterialDetail
          ? boughtFromVendorIsDisabled
            ? 'help'
            : 'pointer'
          : 'default',
        gridColumn: `span ${colSpan}`
      }}
      headerContent={
        <StockCardHeader
          title={title}
          material={material}
          canGoToBoughtToVendorPage={canGoToBoughtToVendorPage}
          additionalHeader={additionalHeader}
        />
      }
    >
      {children}
    </Card>
  )

  return canGoToMaterialDetail && boughtFromVendorIsDisabled ? (
    <Tooltip
      title={t('stock.boughtFromVendorDisabledTooltip')}
      followCursor={true}
      placement="bottom"
    >
      <span>{card}</span>
    </Tooltip>
  ) : (
    card
  )
}
