import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {numberRegEx, sleepResponse} from './utils'

export const enableMaterialDemandEndpoints = (mock: MockAdapter) => {
  // GET material demand
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-demand/daily$`)).reply((config) => {
    const {from, to} = config.params as {from: string; to: string}
    const {
      burglengenfeld: {materialDemandByDay}
    } = mockStore.scenario()
    // from to are in local time
    const fromDay = moment(from)
    const toDay = moment(to)
    const filteredMaterialDemandByDay = Object.fromEntries(
      Object.entries(materialDemandByDay).filter(([day]) => {
        return moment(day).isBetween(fromDay, toDay, 'day', '[]')
      })
    )
    return sleepResponse([200, filteredMaterialDemandByDay])
  })
  return mock
}
