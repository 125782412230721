import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {getAssetsFromHistoryByDate, getOperationModesById} from '../../../selectors/assets'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

interface HistoryOperationModesByIdQueryParams {
  timeFrame: MomentRange
  // date which indicates point in time for which assets should be selected from history
  date?: Moment
}
/**
 * hook to retrieve assets by id dictionary from assets history
 */
export const useHistoryOperationModesByIdQuery = ({
  timeFrame: [startOfPlan, endOfPlan],
  date
}: HistoryOperationModesByIdQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery(
    'assetsHistory',
    [plantCode, startOfPlan.toISOString(), endOfPlan.toISOString()],
    {
      select: (assetsHistory) => {
        // if date not provided, uses endOfPlan value
        const assets = getAssetsFromHistoryByDate(assetsHistory, date ?? endOfPlan)
        return getOperationModesById(assets)
      }
    }
  )
}
