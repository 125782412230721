import {Grid} from '@mui/material'
import {Moment} from 'moment-timezone'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {getUrl, PLANT_ROUTES} from '../../routing'
import {useTrackPlannerEvent} from '../../shared/helpers/trackPlannerEvents'
import {useRejectOptimizedSchedule} from '../../shared/hooks/api/schedules/useRejectOptimizedSchedule'
import {useSelectOptimizedSchedule} from '../../shared/hooks/api/schedules/useSelectOptimizedSchedule'
import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {getScheduleKPIsDiff} from '../../shared/scheduleKPIs'

import {AcceptRejectOptimizedPlanButtons} from './AcceptRejectOptimizedPlanButtons'
import {KPIBox} from './KPIBox'
import {KPIBoxSkeleton} from './KPIBoxSkeleton'
import {useOptimizedAndManualScheduleKPIs} from './shared/useOptimizedAndManualScheduleKPIs'

interface OptimizerSummaryComparisonProps {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const OptimizerSummaryComparison = ({
  startOfPlan,
  endOfPlan
}: OptimizerSummaryComparisonProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const trackPlannerEvent = useTrackPlannerEvent()

  const {mutate: rejectSchedule} = useRejectOptimizedSchedule({startOfPlan, endOfPlan})
  const {mutate: selectSchedule} = useSelectOptimizedSchedule({startOfPlan, endOfPlan})

  const handleRejectOptimizedPlan = () => {
    rejectSchedule(
      {plantCode},
      {onSuccess: () => navigate(getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.path, {plantCode}))}
    )
  }

  const handleSelectOptimizedPlan = () => {
    selectSchedule(
      {plantCode},
      {onSuccess: () => navigate(getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.path, {plantCode}))}
    )
  }
  const plantCode = useUrlParam('plantCode')
  const scheduleKPIs = useOptimizedAndManualScheduleKPIs(startOfPlan, endOfPlan)

  if (!scheduleKPIs) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <KPIBoxSkeleton />
        </Grid>
        <Grid item xs={12} lg={6}>
          <KPIBoxSkeleton />
        </Grid>
      </Grid>
    )
  }

  const {
    totalCosts: totalCostsOptimized,
    totalCementTons: totalCementTonsOptimized,
    costsPerTon: costsPerTonOptimized,
    costsPerMWh: costsPerMWhOptimized
  } = scheduleKPIs.optimized

  const {
    totalCosts: totalCostsManual,
    totalCementTons: totalCementTonsManual,
    costsPerTon: costsPerTonManual,
    costsPerMWh: costsPerMWhManual
  } = scheduleKPIs.manual

  const diffInput = {
    tonsManual: totalCementTonsManual,
    tonsOptimized: totalCementTonsOptimized,
    costsManual: totalCostsManual,
    costsOptimized: totalCostsOptimized,
    costsPerTonManual,
    costsPerTonOptimized,
    costsPerMWhManual,
    costsPerMWhOptimized
  }

  const optimizedDiff = getScheduleKPIsDiff({
    type: 'optimized',
    values: diffInput
  })
  const manualDiff = getScheduleKPIsDiff({
    type: 'manual',
    values: diffInput
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <KPIBox
          title={t('optimizer.lastSavedMillPlan')}
          producedTons={totalCementTonsManual}
          producedTonsDifference={manualDiff.tonsDiff}
          totalPrice={totalCostsManual}
          totalPriceDifference={manualDiff.costsDiff}
          pricePerTon={costsPerTonManual}
          pricePerTonDifference={manualDiff.costsPerTonDiff}
          pricePerMWh={costsPerMWhManual}
          pricePerMWhDifference={manualDiff.costsPerMWhDiff}
          onViewDetails={() => {
            trackPlannerEvent({name: 'onViewDetailsLastSavedPlan'})
            navigate(
              getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.DETAILS.LAST_PLAN.path, {
                plantCode
              })
            )
          }}
          testId="manual_plan"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <KPIBox
          title={t('optimizer.optimizedMillPlan')}
          producedTons={totalCementTonsOptimized}
          producedTonsDifference={optimizedDiff.tonsDiff}
          totalPrice={totalCostsOptimized}
          totalPriceDifference={optimizedDiff.costsDiff}
          pricePerTon={costsPerTonOptimized}
          pricePerTonDifference={optimizedDiff.costsPerTonDiff}
          pricePerMWh={costsPerMWhOptimized}
          pricePerMWhDifference={optimizedDiff.costsPerMWhDiff}
          onViewDetails={() => {
            trackPlannerEvent({name: 'onViewDetailsOptimizedPlan'})
            navigate(
              getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.DETAILS.OPTIMIZED_PLAN.path, {
                plantCode
              })
            )
          }}
          testId="optimized_plan"
        >
          <AcceptRejectOptimizedPlanButtons
            handleRejectOptimizedPlan={handleRejectOptimizedPlan}
            handleSelectOptimizedPlan={handleSelectOptimizedPlan}
          />
        </KPIBox>
      </Grid>
    </Grid>
  )
}
