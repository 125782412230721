import React from 'react'

/**
 * Calculates the dom rect once and then sets it after a use layout effect
 * @param ref
 */
export const useDomRect =
  <E extends Element>(ref: React.MutableRefObject<E | null>) =>
  (cb: (rect: DOMRect) => void) =>
    ref?.current && cb(ref.current.getBoundingClientRect())
