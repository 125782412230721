import type {Moment} from 'moment-timezone'

import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface MaterialOrdersQueryParams {
  materialId: number
  timeframe: [Moment, Moment] | undefined
}

export const useMaterialOrdersQuery = ({materialId, timeframe}: MaterialOrdersQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const [start, end]: (Moment | undefined)[] = timeframe ?? []

  const isEnabled = Boolean(timeframe)

  return usePlannerQuery(
    'materialOrders',
    [{plantCode, from: start?.toISOString(), to: end?.toISOString(), materialId}],
    {enabled: isEnabled}
  )
}
