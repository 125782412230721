import {dataTestId} from '@hconnect/uikit'
import {HPToggleButton} from '@hconnect/uikit/src/lib2'
import {Moment} from 'moment-timezone'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'

import {PLANT_ROUTES, getUrl} from '../../../routing'
import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {PlanningScope} from '../../../shared/enums'
import {convertToURLDatetime} from '../../../shared/helpers/urlParams.utils'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {MomentRange} from '../../../shared/selectors/time'

const mapDailyTimeframeToMonthly = (start: Moment, timezoneId: string): MomentRange => {
  return [
    start.clone().tz(timezoneId).startOf('month'),
    start.clone().tz(timezoneId).startOf('month').add(1, 'month')
  ]
}

const getStartEndParamsByScopeType = (
  scope: PlanningScope,
  timeframe: MomentRange,
  timezoneId: string
) => {
  if (scope === PlanningScope.Daily) {
    return {
      start: convertToURLDatetime(timeframe[0], timezoneId),
      end: convertToURLDatetime(timeframe[1], timezoneId)
    }
  }
  const monthlyTimeframe = mapDailyTimeframeToMonthly(timeframe[0], timezoneId)
  return {
    start: convertToURLDatetime(monthlyTimeframe[0], timezoneId),
    end: convertToURLDatetime(monthlyTimeframe[1], timezoneId)
  }
}

export const PlanningScopeSelect = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId} = usePlantConfig()
  const relativePath = useUrlParam('*')
  const planningScope = relativePath.split('/')[1] as PlanningScope
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }
  return (
    <HPToggleButton
      {...dataTestId('daily_monthly_toggle_button')}
      value={planningScope}
      backgroundMode="dark"
      color="primary"
      options={[
        {value: PlanningScope.Daily, title: t('planning.daily'), dataTestId: 'daily_button'},
        {
          value: PlanningScope.Monthly,
          title: t('planning.monthly'),
          dataTestId: 'monthly_button'
        }
      ]}
      onChange={(type) => {
        const search = new URLSearchParams(
          getStartEndParamsByScopeType(type, selectedTimeframe, timezoneId)
        ).toString()
        navigate({
          pathname: getUrl(
            type === PlanningScope.Daily
              ? PLANT_ROUTES.PRODUCTION_PLANNING.DAILY.path
              : PLANT_ROUTES.PRODUCTION_PLANNING.MONTHLY.path,
            {plantCode}
          ),
          search
        })
      }}
    />
  )
}
