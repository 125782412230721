import {dataTestId} from '@hconnect/uikit'
import {formatTons} from '@hconnect/uikit/src/common'
import {useTheme} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ChartGrid, ChartPath, DatetimeValueChartLabels} from '../../shared/components/d3chart'
import {useResizableChartWidth} from '../../shared/components/d3chart/utils'
import {useDateScale, useLinearScale} from '../../shared/helpers/scale'
import {calculateNiceAxisSteps} from '../../shared/helpers/utils'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'
import {StockDevelopmentEntry} from '../../shared/interfaces/api'
import {DatetimeValue} from '../../shared/interfaces/common'
import {HCEMPalette} from '../../shared/pallette'

interface SiloLevelsChartProps {
  listOfDays: Moment[]
  manualStock: StockDevelopmentEntry & {combined: DatetimeValue[]}
  optimizedStock: StockDevelopmentEntry & {combined: DatetimeValue[]}
  storageCapacity: number
  targetLevel?: DatetimeValue[]
}

export const SiloLevelsChart: React.FC<SiloLevelsChartProps> = ({
  listOfDays,
  manualStock,
  optimizedStock,
  storageCapacity,
  targetLevel
}) => {
  const {palette} = useTheme()
  const {
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()

  const {steps: verticalSteps} = calculateNiceAxisSteps({
    maxValue: storageCapacity,
    shouldUseExactMaxValue: true
  })
  const [ref, width] = useResizableChartWidth()
  const height = 200

  const yScale = useLinearScale({domain: [0, storageCapacity], range: [height, 0]})

  const xScale = useDateScale({
    domain: [listOfDays[0], listOfDays[listOfDays.length - 1]],
    range: [0, width],
    isUTC: true
  })

  return (
    <DatetimeValueChartLabels
      xSteps={listOfDays}
      ySteps={verticalSteps}
      xScale={xScale}
      yScale={yScale}
      timezoneId={timezoneId}
      yStepsFormatter={(step, index) => {
        const valueInTons = formatTons(Number(step), language)
        if (index === verticalSteps.length - 1) {
          return `${valueInTons}
          (max)`
        }
        return valueInTons
      }}
    >
      <div ref={ref} {...dataTestId('silo-levels-chart')}>
        <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
          <ChartGrid
            xSteps={listOfDays}
            ySteps={verticalSteps}
            xScale={xScale}
            yScale={yScale}
            overrideHorizontalLineStyles={(_, index) =>
              index === verticalSteps.length - 1 ? {stroke: palette.error.dark} : undefined
            }
          />
          <ChartPath
            // differentiate between actual and forecast if needed
            datetimeValues={manualStock.combined}
            xScale={xScale}
            yScale={yScale}
            overridePathAttributes={{stroke: HCEMPalette.brightPink}}
            fill
            testId="silo-level-manual-path"
          />
          <ChartPath
            // differentiate between actual and forecast if needed
            datetimeValues={optimizedStock.combined}
            xScale={xScale}
            yScale={yScale}
            fill
            testId="silo-level-optimizer-path"
          />
          {targetLevel && (
            <ChartPath
              datetimeValues={targetLevel}
              xScale={xScale}
              yScale={yScale}
              overridePathAttributes={{
                stroke: HCEMPalette.orangePeach,
                strokeDasharray: '8,5',
                strokeWidth: '1px'
              }}
              testId="min_target_level_line"
            />
          )}
        </svg>
      </div>
    </DatetimeValueChartLabels>
  )
}
