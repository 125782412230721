import {MaterialType, Material} from '@hconnect/common/types'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {AssetType} from '../../shared/enums'
import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx} from './utils'

export const enableMaterialsEndpoints = (mock: MockAdapter) => {
  // GET materials (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials$`)).reply(() => {
    const {
      burglengenfeld: {materialsWithRecipes}
    } = mockStore.scenario()
    const materials = materialsWithRecipes.map<Material>(
      ({id, name, createdBy, createdOn, status, type, source, products}) => {
        return {id, name, createdBy, createdOn, status, type, source, products}
      }
    )

    return sleepResponse([200, materials])
  })

  // GET materials history (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/history$`)).reply((config) => {
    const {from, to} = config.params as {from: string; to: string}
    const {
      burglengenfeld: {materialsHistory}
    } = mockStore.scenario()

    const selectedMaterialsSnapshotEntries = Object.entries(materialsHistory)
      .map(
        ([materialId, materialSnapshots]) =>
          [
            materialId,
            materialSnapshots.filter((material) =>
              moment.utc(material.updatedOn ?? material.createdOn).isBefore(to)
            )
          ] as [string, typeof materialSnapshots]
      )
      .map(([materialId, materialSnapshots]) => {
        const filterByFromDate = materialSnapshots.filter((material) =>
          moment.utc(material.updatedOn ?? material.createdOn).isAfter(from)
        )
        // assuming that BE provide at least one snapshot
        const snapshots = filterByFromDate.length
          ? filterByFromDate
          : [materialSnapshots[materialSnapshots.length - 1]]

        return [materialId, snapshots] as [string, typeof filterByFromDate]
      })

    const selectedMaterialHistory = Object.fromEntries(selectedMaterialsSnapshotEntries)

    return sleepResponse([200, selectedMaterialHistory])
  })

  // GET recipes per assetType
  mock.onGet(new RegExp(`/plants/${numberRegEx}/materials/recipes`)).reply((config) => {
    const {
      burglengenfeld: {materialsWithRecipes}
    } = mockStore.scenario()
    const assetType = config?.params?.assetType as AssetType | undefined
    const allRecipes = materialsWithRecipes.map(({recipes}) => recipes).flat()

    if (assetType === undefined) {
      return sleepResponse([200, allRecipes])
    }

    const assetTypeToMainMaterialTypeMap: Record<AssetType, MaterialType[]> = {
      CementMill: [MaterialType.Cement],
      RawMill: [MaterialType.RawMeal],
      RotaryKiln: [MaterialType.Clinker],
      CoalMill: [MaterialType.ConventionalFuel, MaterialType.AlternativeFuel],
      Crusher: [MaterialType.RawMaterial, MaterialType.RawMeal],
      Other: [
        MaterialType.Cement,
        MaterialType.Clinker,
        MaterialType.ConventionalFuel,
        MaterialType.AlternativeFuel,
        MaterialType.RawMaterial,
        MaterialType.RawMeal
      ],
      BaseLoad: []
    }

    const possibleMaterialTypes = assetTypeToMainMaterialTypeMap[assetType]
    const recipes = materialsWithRecipes
      .map((materialWithRecipe) => {
        if (possibleMaterialTypes.includes(materialWithRecipe.type)) {
          return materialWithRecipe.recipes
        }
        return []
      })
      .flat()

    return sleepResponse([200, recipes])
  })

  // GET material vendors
  mock
    .onGet(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/schedule-agreements$`))
    .reply(() => {
      const {
        burglengenfeld: {scheduleAgreements}
      } = mockStore.scenario()

      return sleepResponse([200, scheduleAgreements])
    })

  return mock
}
