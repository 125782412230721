import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import {Skeleton, Stack} from '@mui/material'
import React from 'react'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {useAssetsCapacity} from '../../../shared/hooks/api'

import {AssetCapacityCardHeader} from './AssetCapacityCardHeader'
import {AssetCapacitySection} from './AssetCapacitySection'

export const AssetCapacityCard: React.FC = () => {
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }

  const {data: assetsCapacity} = useAssetsCapacity(selectedTimeframe)

  return (
    <Card headerContent={<AssetCapacityCardHeader />} {...dataTestId('asset_capacity_card')}>
      <Stack>
        {assetsCapacity ? (
          Object.entries(assetsCapacity).map(([stringAssetId, assetCapacity]) => {
            const assetId = Number(stringAssetId)
            return (
              <AssetCapacitySection
                key={assetId}
                assetCapacity={assetCapacity}
                assetId={Number(assetId)}
              />
            )
          })
        ) : (
          <Skeleton variant="rectangular" height="50vh" />
        )}
      </Stack>
    </Card>
  )
}
