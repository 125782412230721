import {dataTestId} from '@hconnect/uikit'
import {Alert, Card} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/system'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IncomingMaterialStatus} from '../../../../shared/enums'
import {mapIncomingMaterialToDeliveryDetails} from '../../../../shared/selectors/materialDeliveryDetails'
import {IncomingMaterial} from '../../../../shared/selectors/materialOrders'

import {MaterialDeliveriesStatusBar} from './MaterialDeliveriesStatusBar'
import {MaterialDeliveriesTable} from './MaterialDeliveriesTable'
import {MaterialOrderedDeliveriesCardHeader} from './MaterialOrderedDeliveriesCardHeader'
import {MaterialUnknownDeliveriesCardHeader} from './MaterialUnknownDeliveriesCardHeader'

interface MaterialDeliveriesCardProps {
  incomingMaterial: IncomingMaterial
}

export const MaterialDeliveriesCard: React.FC<MaterialDeliveriesCardProps> = ({
  incomingMaterial
}) => {
  const {t} = useTranslation()

  const amountOrdered =
    incomingMaterial.status !== IncomingMaterialStatus.Unknown
      ? incomingMaterial.order.amount
      : undefined

  const deliveryDetails = mapIncomingMaterialToDeliveryDetails(incomingMaterial)

  return (
    <Card
      headerContent={
        incomingMaterial.status === IncomingMaterialStatus.Unknown ? (
          <MaterialUnknownDeliveriesCardHeader incomingMaterial={incomingMaterial} />
        ) : (
          <MaterialOrderedDeliveriesCardHeader incomingMaterial={incomingMaterial} />
        )
      }
      {...dataTestId(`material_delivery_details_card_${incomingMaterial.status}`)}
    >
      <Stack spacing={2}>
        {incomingMaterial.status === IncomingMaterialStatus.Unknown && (
          <Alert content={t('stock.materialOrders.unknownDeliveryInfo')} accentColor="warning" />
        )}
        {incomingMaterial.status === IncomingMaterialStatus.Cancelled && (
          <Alert
            content={t('stock.materialOrders.canceledOrderInfo')}
            accentColor="error"
            {...dataTestId('canceled_order_warning')}
          />
        )}
        <MaterialDeliveriesStatusBar amountOrdered={amountOrdered} deliveries={deliveryDetails} />
        {deliveryDetails.length !== 0 && <MaterialDeliveriesTable deliveries={deliveryDetails} />}
      </Stack>
    </Card>
  )
}
