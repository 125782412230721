import {Box} from '@mui/system'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Legend, LegendItem} from '../../../shared/components/Legend'

export const StockDevelopmentChartLegend = () => {
  const {t} = useTranslation()
  return (
    <Legend testId="stock_development_chart_legend">
      <LegendItem label={t('stock.materialOrders.minimumStock')}>
        <Box
          component="svg"
          sx={{
            width: ({spacing}) => spacing(5),
            height: '3px'
          }}
        >
          <Box
            component="line"
            x1={0}
            x2={30}
            y1={1}
            y2={1}
            sx={{
              strokeWidth: 1,
              strokeDasharray: '4,4',
              shapeRendering: 'crispEdges',
              opacity: 0.5,
              stroke: ({palette}) => palette.error.light
            }}
          />
        </Box>
      </LegendItem>
      <LegendItem label={t('stock.materialOrders.capacityDeadStock')}>
        <Box
          component="svg"
          sx={{
            width: ({spacing}) => spacing(5),
            height: '3px'
          }}
        >
          <Box
            component="line"
            x1={0}
            x2={30}
            y1={1}
            y2={1}
            sx={{
              shapeRendering: 'crispEdges',
              strokeWidth: 1,
              opacity: 0.5,
              stroke: ({palette}) => palette.error.light
            }}
          />
        </Box>
      </LegendItem>
    </Legend>
  )
}
