import moment from 'moment-timezone'

import {usePlantConfig} from './usePlantConfigData'

/**
 * hook to abstract away current plant date time information
 * @returns
 * timezoneId ex. Europe/Berlin
 * datetimeUtc actual UTC time
 * datetimeWithTz with timezone information passed
 * utcOffset UTC offset in minutes
 * plant local time formatted as UTC, used when BE accepts local time as UTC
 */
export const usePlantDatetime = () => {
  // created_at it's a time reference point, updates when latest EP is called
  const {timezone_id: timezoneId, created_at: plantTime} = usePlantConfig()
  const datetimeUtc = moment.utc(plantTime)
  const datetimeWithTz = datetimeUtc.clone().tz(timezoneId)
  const utcOffset = datetimeWithTz.utcOffset()
  const localDatetimeUtc = datetimeWithTz.clone().add(utcOffset, 'minutes')
  return {timezoneId, datetimeUtc, datetimeWithTz, utcOffset, localDatetimeUtc}
}
