import {useTheme} from '@mui/material'

import {StockDevelopmentChartSkeleton} from '../../../../../shared/components/stockDevelopmentChart/StockDevelopmentChartSkeleton'
import {
  useMaterialStorageDataQuery,
  useStoredMaterialIdsFromHistoryQuery
} from '../../../../../shared/hooks/api'
import {useStockDevelopmentQuery} from '../../../../../shared/hooks/api/kpi/useStockDevelopmentQuery'
import {usePlantConfig} from '../../../../../shared/hooks/usePlantConfigData'
import {getListOfDays} from '../../../../../shared/selectors/time'
import {usePlanningChartStartEnd} from '../../PlanningChartStartEndProvider'

import {PlanningStockDevelopmentChart} from './PlanningStockDevelopmentChart'

interface PlanningStockDevelopmentChartContainerProps {
  materialId: number
  cellWidth: number
  totalSteps: number
  immediateHourOffset: number
}

export const PlanningStockDevelopmentChartContainer = ({
  materialId,
  cellWidth,
  totalSteps,
  immediateHourOffset
}: PlanningStockDevelopmentChartContainerProps) => {
  const {spacing} = useTheme()
  const latest = usePlantConfig()
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()

  const {data: allMaterialIds} = useStoredMaterialIdsFromHistoryQuery({
    timeFrame: [startOfPlan, endOfPlan]
  })

  const {data: stockDevelopment, isLoading: isStockDevelopmentLoading} = useStockDevelopmentQuery({
    materialIds: allMaterialIds,
    timeframe: [startOfPlan, endOfPlan]
  })
  const {data: storageData} = useMaterialStorageDataQuery({materialId})

  const listOfDays = getListOfDays({start: startOfPlan, end: endOfPlan, additionalDays: 1})

  const shouldShowChartSkeleton = !listOfDays || isStockDevelopmentLoading || !stockDevelopment

  const chartHeight = spacing(50)

  return shouldShowChartSkeleton ? (
    <StockDevelopmentChartSkeleton height={chartHeight} />
  ) : (
    <PlanningStockDevelopmentChart
      timezoneId={latest.timezone_id}
      listOfDays={listOfDays}
      stockData={stockDevelopment[materialId]}
      cellWidth={cellWidth}
      totalSteps={totalSteps}
      immediateHourOffset={immediateHourOffset}
      minTargetLevels={
        storageData && {
          week: storageData.minTargetLevel,
          weekend: storageData.minTargetLevelWeekend
        }
      }
      deadStockLevel={storageData?.deadStockLevel}
      storageCapacity={storageData?.storageCapacity}
      height={parseInt(chartHeight)}
    />
  )
}
