import React from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router-dom'

import {PlannerPageSkeleton} from '../../shared/components/skeletons/PlannerPageSkeleton'
import {usePlantConfigQuery, usePermissionsQuery} from '../../shared/hooks/api'
import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {Log} from '../../shared/Log'
import {getPageAvailability} from '../../shared/permissions'
import {getUrl} from '../helpers'

const log = Log.context('DefaultPageRedirect')

export const DefaultPageRedirect: React.FC = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {data: plantConfig} = usePlantConfigQuery()
  const {data: permissions} = usePermissionsQuery()

  if (!plantConfig || !permissions) {
    return <PlannerPageSkeleton />
  }

  const pageAvailability = getPageAvailability(
    plantConfig.available_features,
    permissions,
    plantConfig.country,
    plantCode
  )

  const availableMainRoutes = pageAvailability.filter(
    (availabilityDetail) => availabilityDetail.isAvailable && availabilityDetail.hasPermission
  )

  if (availableMainRoutes.length === 0) {
    const errorMessage = t('error.notAbleToForwardToThePage')
    log.extras({plantCode}).critical(errorMessage)
    throw new Error(errorMessage)
  }

  const [defaultRoute] = availableMainRoutes

  return <Navigate to={getUrl(defaultRoute.path, {plantCode})} replace />
}
