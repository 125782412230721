import {debounce} from 'lodash'
import {useEffect} from 'react'

const RESIZE_EVENT_DELAY = 100

export const useWindowResizeEvent = (
  onWindowResize: () => void,
  delay: number = RESIZE_EVENT_DELAY
) => {
  useEffect(() => {
    const updateSize = debounce(() => {
      onWindowResize()
    }, delay)

    window.addEventListener('resize', updateSize)
    return () => {
      updateSize.cancel()
      window.removeEventListener('resize', updateSize)
    }
  }, [onWindowResize, delay])
}
