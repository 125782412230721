import MockAdapter from 'axios-mock-adapter'
import {v4 as uuidv4} from 'uuid'

import {CommentsCategory, CommentStatus} from '../../shared/enums'
import {CreateEditComment, Comment} from '../../shared/interfaces/api'
import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario, uuidRegEx} from './utils'

const commentsCategoryRegExp = `(${CommentsCategory.AssetOperationTimes}|${CommentsCategory.Assets}|${CommentsCategory.MaterialOrders})`

const getComments = (comments: Comment[], category: CommentsCategory, instanceIds: string[]) =>
  comments.filter(
    (comment) =>
      comment.commentsCategory === category &&
      instanceIds.includes(comment.commentsCategoryInstanceId)
  )

export const enableCommentsEndpoints = (mock: MockAdapter) => {
  // GET comments (C#)
  mock.onGet(new RegExp(`/comments/${numberRegEx}/${commentsCategoryRegExp}?`)).reply((config) => {
    const commentsCategory = (config.url as string).split('/')[3] as CommentsCategory

    const commentCategoryInstanceIds = (
      config.params as {commentCategoryInstanceIds: string}
    ).commentCategoryInstanceIds.split(',')

    const {
      burglengenfeld: {comments}
    } = mockStore.scenario()

    return sleepResponse([200, getComments(comments, commentsCategory, commentCategoryInstanceIds)])
  })

  // POST comments (C#)
  mock
    .onPost(
      new RegExp(`/comments/${numberRegEx}/${commentsCategoryRegExp}/(${uuidRegEx}|${numberRegEx})`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const commentsCategory = (config.url as string).split('/')[3] as CommentsCategory
      const scheduleItemId = (config.url as string).split('/')[4]
      const {value} = JSON.parse(config.data as string) as CreateEditComment

      const updatedComments = [...scenario.burglengenfeld.comments]
      const newComment: Comment = {
        commentId: uuidv4(),
        commentsCategory,
        commentsCategoryInstanceId: scheduleItemId,
        status: CommentStatus.NEW,
        createdOn: '2019-08-16T20:00:00.000Z',
        createdBy: 'Max Mustermann',
        value
      }
      updatedComments.push(newComment)
      scenario.burglengenfeld.comments = updatedComments
      saveScenario(scenario)
      const responseBody: Comment = {...newComment}
      return sleepResponse([200, responseBody])
    })

  // PUT schedule comments (C#)
  mock
    .onPut(
      new RegExp(
        `/comments/${numberRegEx}/${commentsCategoryRegExp}/(${uuidRegEx}|${numberRegEx})/${uuidRegEx}`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const commentId = (config.url as string).split('/')[5]
      const {value: newValue} = JSON.parse(config.data as string) as CreateEditComment

      const updatedComments = [...scenario.burglengenfeld.comments]
      const updatedComment = updatedComments.find(
        ({commentId: id}) => id === commentId
      ) as unknown as Comment
      // updating only value of a comment for mocking purpose
      updatedComment.value = newValue
      updatedComment.status = CommentStatus.EDITED
      scenario.burglengenfeld.comments = updatedComments
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // DELETE schedule comments (C#)
  mock
    .onDelete(
      new RegExp(
        `/comments/${numberRegEx}/${commentsCategoryRegExp}/(${uuidRegEx}|${numberRegEx})/${uuidRegEx}`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const commentId = (config.url as string).split('/')[5]

      const updatedComments = scenario.burglengenfeld.comments.filter(
        ({commentId: id}) => commentId !== id
      )

      scenario.burglengenfeld.comments = updatedComments
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  return mock
}
