import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useEffect} from 'react'
import {useErrorHandler} from 'react-error-boundary'

import {notifyIfErrorMessage} from '../../../common'
import {CommentsCategory} from '../../../enums'
import {ErrorResponse} from '../../../interfaces/api'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useAssetCommentsQuery = (assetsIds?: number[], isDisabled = false) => {
  const plantCode = useUrlParam('plantCode')
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  const isEnabled = Boolean(assetsIds?.length) && !isDisabled

  const query = usePlannerQuery(
    'comments',
    [
      {
        plantCode,
        commentsCategory: CommentsCategory.Assets,
        commentsCategoryInstanceIds: assetsIds?.map(String)
      }
    ],
    {enabled: isEnabled}
  )

  useEffect(() => {
    if (query.isError) {
      notifyIfErrorMessage(
        (query.error as AxiosError<ErrorResponse>)?.response?.data.detail,
        raiseError,
        notify
      )
    }
  }, [query.error, query.isError, notify, raiseError])

  return query
}
