import {NumericTextField} from '@hconnect/common/components/forms/NumericTextField'
import {dataTestId as spreadDataTestId} from '@hconnect/uikit'
import type {TextFieldProps} from '@mui/material'

const validateMinMaxValue = (value: number, min: number | undefined, max: number | undefined) => {
  const isGreaterThanMin = min === undefined || value >= min
  const isLessThenMax = max === undefined || value <= max
  return isGreaterThanMin && isLessThenMax
}

function mapInputValueToValue(inputValue: string) {
  return inputValue === '' ? null : parseFloat(inputValue)
}

function mapValueToInputValue(value: number | null) {
  return value === null ? '' : value
}

interface PlannerNumberInputProps
  extends Pick<TextFieldProps, 'inputRef' | 'label' | 'disabled' | 'onBlur' | 'onKeyDown' | 'sx'> {
  step?: number
  min?: number
  max?: number
  value: number | null
  onChange: (value: PlannerNumberInputProps['value']) => void
  error?: string
  dataTestId?: string
}

export const PlannerNumberInput = ({
  sx,
  inputRef,
  step = 1,
  min,
  max,
  label,
  value,
  onChange,
  error,
  disabled,
  dataTestId = 'number_input',
  ...eventProps
}: PlannerNumberInputProps) => {
  return (
    <NumericTextField
      sx={sx}
      fullWidth
      label={label}
      inputRef={inputRef}
      inputProps={{step: step}}
      onChange={({target: {value}}) => {
        const parsedValue = mapInputValueToValue(value)
        if (parsedValue === null) {
          return onChange(parsedValue)
        }
        if (validateMinMaxValue(parsedValue, min, max)) {
          return onChange(parsedValue)
        }
      }}
      value={mapValueToInputValue(value)}
      error={Boolean(error)}
      disabled={disabled}
      helperText={error}
      {...eventProps}
      {...spreadDataTestId(dataTestId)}
    />
  )
}
