import {useParams} from 'react-router-dom'

import {UrlParams} from '../../../../routing'
import {usePlannerQuery} from '../usePlannerQuery'

export const usePlantConfigQuery = (plantCode?: string) => {
  const {plantCode: plantCodeParam} = useParams<UrlParams>()
  return usePlannerQuery('plantConfig', [plantCode ?? plantCodeParam], {
    enabled: Boolean(plantCode || plantCodeParam)
  })
}
