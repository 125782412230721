import {TFunction} from 'i18next'
import {RegisterOptions} from 'react-hook-form'

type Validator<T extends unknown[]> = (t: TFunction, ...param: T) => RegisterOptions

/**
 * React-hooks-forms validator for use in a `register` call.  Preconfigured for `min` value validation
 */
export const minValidator = (t: TFunction, value: number) => ({
  min: {
    value,
    message: t('error.minValue', {value})
  }
})
/**
 * React-hooks-forms validator for use in a `register` call.  Preconfigured for `max` value validation
 */
export const maxValidator = (t: TFunction, value: number) => ({
  max: {
    value,
    message: t('error.maxValue', {value})
  }
})

export const rangeValidator: Validator<[range: [min: number, max: number]]> = (t, [min, max]) => ({
  ...minValidator(t, min),
  ...maxValidator(t, max)
})

export const requiredValidator: Validator<[]> = (t) =>
  ({
    required: t('error.required')
  }) as RegisterOptions
