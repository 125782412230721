import type {AxiosRequestConfig} from 'axios'

import {backEndServiceOverrides} from '../../localBackendOverrides'
import type {BackendService} from '../../localBackendOverrides'

/**
 * Creates and returns Url override interceptor.
 * Intended to be used on localhost to connect frontend into locally running backend of specific services.
 *
 * USAGE:
 * 1. package.json: add new script `start:local` which should use `unienv --context devLocal`
 * 2. Add new `.env.devLocal` file into root of app that should use overrides
 * 3. Add `REACT_APP_LOCAL_BACKENDS=service1,service2` environment variable into `.env.devLocal` file
 * 4. If needed add `service1` definition into `localBackendOverrides` file
 * 5. Run app using `yarn run start:local`
 * @param localBackends list of projects which overrides should be used.
 * @returns url override interceptor.
 */
export function createUrlOverrideInterceptor(localBackends: BackendService[]) {
  const overrides = localBackends.map((backend) => backEndServiceOverrides[backend])
  return (config: AxiosRequestConfig) => {
    const override = overrides.find((override) => config.url?.match(override.urlMatcher))

    return {...config, baseURL: override?.baseUrl || config.baseURL}
  }
}
