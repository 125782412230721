import {dataTestId, useTranslation} from '@hconnect/uikit'
import {HPTextField} from '@hconnect/uikit/src/lib2'
import {MenuItem} from '@mui/material'

import {useMaterialsById} from '../../../../../shared/hooks/api'

type SelectMaterialIdDropdownProps = {
  selectedMaterialId: number | undefined
  materialIds: number[]
  changeSelectedMaterialId: (materialType: number | undefined) => void
}

export const SelectMaterialIdDropdown = ({
  selectedMaterialId,
  changeSelectedMaterialId,
  materialIds
}: SelectMaterialIdDropdownProps) => {
  const {t} = useTranslation()
  const {data: materialsById} = useMaterialsById()

  if (materialsById === undefined || materialIds.length === 0) {
    return null
  }

  return (
    <HPTextField
      sx={{
        width: ({spacing}) => spacing(21)
      }}
      variant="outlined"
      fullWidth
      select
      label={t('planning.material')}
      value={selectedMaterialId}
      onChange={(e) => {
        const newMaterialId = parseInt(e.target.value) as number | undefined
        changeSelectedMaterialId(newMaterialId)
      }}
      {...dataTestId('material_id_select')}
    >
      {materialIds.map((materialId, index) => (
        <MenuItem
          key={index}
          value={materialId}
          {...dataTestId(`material_id_select_item_${materialId}`)}
        >
          {materialsById[materialId]?.name ?? ''}
        </MenuItem>
      ))}
    </HPTextField>
  )
}
