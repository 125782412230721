import {useState, useCallback, useLayoutEffect} from 'react'
import {unstable_batchedUpdates} from 'react-dom'

interface GanttChartCellsParams {
  hoursDisplayed: number
  cellsPerHour: number
  chartWidth: number
}

export const useGanttChartCells = ({
  hoursDisplayed,
  cellsPerHour,
  chartWidth
}: GanttChartCellsParams) => {
  const [cellsDisplayed, setCellsDisplayed] = useState<number>(hoursDisplayed * cellsPerHour)
  const [cellWidth, setCellWidth] = useState<number>(chartWidth / cellsDisplayed)

  const updateGanttCells = useCallback(
    (newRange: [number, number]) => {
      const [min, max] = newRange
      const hoursInRange = max - min

      const isRangeDifferent = hoursInRange !== cellsDisplayed

      const conditionalDispatches: [boolean, () => void][] = [
        [isRangeDifferent, () => setCellsDisplayed(hoursInRange)],
        [isRangeDifferent, () => setCellWidth(chartWidth / hoursInRange)]
      ]

      const dispatches: (() => void)[] = conditionalDispatches
        .filter(([condition]) => condition)
        .map(([, dispatch]) => dispatch)

      unstable_batchedUpdates(() => {
        dispatches.forEach((dispatch) => dispatch())
      })
    },
    [chartWidth, cellsDisplayed]
  )

  // effect to update cell width when chart width changes
  useLayoutEffect(() => {
    setCellWidth(chartWidth / cellsDisplayed)
  }, [setCellWidth, chartWidth, cellsDisplayed])

  return {updateGanttCells, cellWidth, cellsDisplayed}
}
