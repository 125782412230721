import {useLayoutEffect, useState, RefObject, useCallback} from 'react'

/**
 * hook for tracking wheel scroll (deltaY) distance inside the ref
 * @param ref any html element
 * @param scrollHorizontally scrolling horizontally holding shift key
 * @returns delta in px
 */
export const useWheelScroll = <T extends HTMLElement>(
  ref: RefObject<T>,
  scrollHorizontally = false
) => {
  const [delta, setDelta] = useState<number>(0)

  const handleWheelScroll = useCallback(
    (e: WheelEvent) => {
      const isHorizontalScroll = e.shiftKey && scrollHorizontally
      const isVerticalScroll = !e.shiftKey && !scrollHorizontally
      if (isHorizontalScroll || isVerticalScroll) {
        // when scrolling inside ref prevent from scrolling the page
        e.preventDefault()
        setDelta(e.deltaY)
      }
    },
    [scrollHorizontally]
  )

  useLayoutEffect(() => {
    const currentRef = ref.current
    if (!currentRef) return
    currentRef.addEventListener('wheel', handleWheelScroll)
    return () => {
      currentRef.removeEventListener('wheel', handleWheelScroll)
    }
  }, [ref, handleWheelScroll])

  // delta in px
  return {delta, setDelta}
}
