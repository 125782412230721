import {dataTestId} from '@hconnect/uikit'
import {
  convertDateHourRangeToNumberRange,
  convertNumberRangeToDateHourRange,
  shiftRange
} from '@hconnect/uikit/src/lib2/components/rangeSliders/DayRangeSlider/dayRangeSlider.utils'
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material'
import {styled, IconButton, alpha, Stack} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {FC, useCallback} from 'react'

import {useSelectedTimeframe} from '../../shared/components/providers/SelectedTimeframeProvider'
import {NumberRange} from '../../shared/selectors/time'

import {usePlanningChartStartEnd} from './dailyPlanning/PlanningChartStartEndProvider'
import {PlannerDayRangeSlider} from './PlannerDayRangeSlider'

const StyledIconButton = styled(IconButton)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(6),
  width: theme.spacing(6),
  border: `1px solid ${alpha(theme.palette.common.white, 0.4)}`,
  borderRadius: theme.spacing(1),
  color: theme.palette.common.white,
  '&.Mui-disabled .MuiSvgIcon-root': {
    color: theme.palette.grey[500]
  }
}))

const BASE_RANGE_INCREMENT_DAYS = 7
interface ProductionPlanningSliderProps {
  updateGanttCells: (range: NumberRange) => void
}

export const ProductionPlanningSlider: FC<ProductionPlanningSliderProps> = React.memo(
  ({updateGanttCells}) => {
    const {selectedTimeframe, setSelectedTimeframe} = useSelectedTimeframe()
    if (!selectedTimeframe) {
      throw new Error('selectedTimeframe is not defined')
    }

    const {stepsPerHour, hoursRange, setHoursRange, boundary} = usePlanningChartStartEnd()

    const selectedRange = convertNumberRangeToDateHourRange(hoursRange, selectedTimeframe[0])

    const setSelectedRange = useCallback(
      (range: [Moment, Moment]) => {
        setHoursRange(convertDateHourRangeToNumberRange(range, selectedTimeframe[0]))
      },
      [setHoursRange, selectedTimeframe]
    )

    const shiftBaseRangeToPast = useCallback(() => {
      const [selectedRangeStart, selectedRangeEnd] = selectedRange
      const [newBaseRangeStart, newBaseRangeEnd] = shiftRange(
        selectedTimeframe,
        BASE_RANGE_INCREMENT_DAYS,
        'right'
      )
      setSelectedTimeframe([newBaseRangeStart, newBaseRangeEnd])
      // if the selected range is outside of the new base range, adjust the selected range
      if (selectedRangeEnd.isAfter(newBaseRangeEnd, 'hour')) {
        const rangeInHours = selectedRange[1].diff(selectedRange[0], 'hours')
        const adjustedStart = newBaseRangeEnd.clone().subtract(rangeInHours, 'hours')
        const adjustedEnd = newBaseRangeEnd
        setHoursRange(
          convertDateHourRangeToNumberRange([adjustedStart, adjustedEnd], newBaseRangeStart)
        )
      } else {
        setHoursRange(
          convertDateHourRangeToNumberRange(
            [selectedRangeStart, selectedRangeEnd],
            newBaseRangeStart
          )
        )
      }
    }, [selectedRange, selectedTimeframe, setSelectedTimeframe, setHoursRange])

    const shiftBaseRangeToFuture = useCallback(() => {
      const [selectedRangeStart, selectedRangeEnd] = selectedRange
      const [newBaseRangeStart, newBaseRangeEnd] = shiftRange(
        selectedTimeframe,
        BASE_RANGE_INCREMENT_DAYS,
        'left'
      )
      setSelectedTimeframe([newBaseRangeStart, newBaseRangeEnd])
      // if the selected range is outside of the new base range, adjust the selected range
      if (selectedRangeStart.isBefore(newBaseRangeStart, 'hour')) {
        const rangeInHours = selectedRange[1].diff(selectedRange[0], 'hours')
        const adjustedStart = newBaseRangeStart
        const adjustedEnd = newBaseRangeStart.clone().add(rangeInHours, 'hours')
        setHoursRange(
          convertDateHourRangeToNumberRange([adjustedStart, adjustedEnd], newBaseRangeStart)
        )
      } else {
        setHoursRange(
          convertDateHourRangeToNumberRange(
            [selectedRangeStart, selectedRangeEnd],
            newBaseRangeStart
          )
        )
      }
    }, [selectedRange, selectedTimeframe, setSelectedTimeframe, setHoursRange])

    const handleRangeChangeComplete = useCallback(
      (dateRange: [Moment, Moment]) =>
        updateGanttCells(convertDateHourRangeToNumberRange(dateRange, selectedTimeframe[0])),
      [selectedTimeframe, updateGanttCells]
    )

    const isShiftPastButtonDisabled = selectedTimeframe[0]
      .clone()
      .subtract(BASE_RANGE_INCREMENT_DAYS, 'days')
      .isBefore(boundary[0], 'day')

    const isShiftFutureButtonDisabled = selectedTimeframe[1]
      .clone()
      .add(BASE_RANGE_INCREMENT_DAYS, 'days')
      .isAfter(boundary[1], 'day')

    return (
      <Stack spacing={1.5} sx={{paddingX: 3}} direction="row" alignItems="center">
        <StyledIconButton
          disabled={isShiftPastButtonDisabled}
          onClick={shiftBaseRangeToPast}
          {...dataTestId('base_range_shift_past_button')}
        >
          <ArrowBackIos />
        </StyledIconButton>
        <PlannerDayRangeSlider
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          onRangeChangeComplete={handleRangeChangeComplete}
          baseRange={selectedTimeframe}
          stepsPerHour={stepsPerHour}
        />
        <StyledIconButton
          disabled={isShiftFutureButtonDisabled}
          onClick={shiftBaseRangeToFuture}
          {...dataTestId('base_range_shift_future_button')}
        >
          <ArrowForwardIos />
        </StyledIconButton>
      </Stack>
    )
  }
)

ProductionPlanningSlider.displayName = 'ProductionPlanningSlider'
