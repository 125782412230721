import {dataTestId} from '@hconnect/uikit'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Box, Typography, styled} from '@mui/material'
import {BoxProps} from '@mui/system'
import React from 'react'

const StyledLabel = styled(Typography)(({theme}) => ({
  color: theme.palette.text.primary,
  lineHeight: '14px',
  display: 'block'
}))

type ChartLabelProps = Pick<BoxProps, 'sx'> & {label: React.ReactNode; 'data-test-id'?: string}

export const ChartLabel: React.FC<ChartLabelProps> = ({sx, label, 'data-test-id': testId}) => {
  const baseSx: typeof sx = {
    position: 'absolute',
    textAlign: 'center'
  }
  return (
    <Box sx={mergeSx(baseSx, sx)} {...dataTestId(testId ?? 'chart_label')}>
      <StyledLabel variant="caption">{label}</StyledLabel>
    </Box>
  )
}
