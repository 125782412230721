import moment from 'moment-timezone'
import {useMemo} from 'react'

import {mockStore} from '../../__mock__/mockStore'
import {MomentRange} from '../selectors/time'

import {usePlantConfig} from './usePlantConfigData'

const LONG_TERM_PLAN_START_DATE = '2024-01-01'
const LONG_TERM_PLAN_MAX_FULL_YEARS = 2

const getLongTermPlanBoundary = (startOfToday: moment.Moment): MomentRange => {
  const isMockStoreEnabled = mockStore.isEnabled()
  // changing this value for testing purposes
  const start = isMockStoreEnabled
    ? moment.utc(mockStore.scenario().burglengenfeld.start).startOf('year')
    : moment.utc(LONG_TERM_PLAN_START_DATE)
  const end = startOfToday.clone().add(LONG_TERM_PLAN_MAX_FULL_YEARS, 'year').startOf('year')
  return [start, end]
}

export const useLongTermPlanBoundary = (): MomentRange => {
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const startOfToday = moment.utc(createdAt).tz(timezoneId).startOf('day')

  return useMemo(() => getLongTermPlanBoundary(startOfToday), [startOfToday])
}
