import moment, {Moment} from 'moment-timezone'

import type {ScheduleItem, ScheduleItemDict} from '../interfaces/api'

// split schedule item into pieces if necessary such that a schedule item is within 1 day (24h, 23h/25h for winter/summer time switches)
export const getScheduleInTimeFrame = (
  scheduleItemDict: ScheduleItemDict,
  startDatetime: Moment,
  endDatetime: Moment
): ScheduleItemDict => {
  const newSchedule: ScheduleItemDict = {}

  for (const item of Object.values<ScheduleItem>(scheduleItemDict)) {
    const start = moment.utc(item.start)
    const end = moment.utc(item.end)

    if (start < endDatetime && end > startDatetime) {
      newSchedule[item.id] = {
        ...item,
        start: moment.max(start, startDatetime).toISOString(),
        end: moment.min(end, endDatetime).toISOString()
      }
    }
  }

  return newSchedule
}
