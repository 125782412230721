import {useUrlParam} from '../../../hooks/useUrlParam'
import {ScheduleAgreementsByVendor} from '../../../interfaces/api'
import {usePlannerQuery} from '../usePlannerQuery'

export const useScheduleAgreementsByVendorIdQuery = () => {
  const plantCode = useUrlParam('plantCode')
  const obtainedMaterialId = useUrlParam('materialId')
  const materialId = Number(obtainedMaterialId)

  return usePlannerQuery('scheduleAgreementsByVendor', [{plantCode, materialId}], {
    select: (scheduleAgreements) =>
      scheduleAgreements.reduce<Record<string, ScheduleAgreementsByVendor>>(
        (acc, scheduleAgreementByVendor) => {
          acc[scheduleAgreementByVendor.vendorId] = scheduleAgreementByVendor
          return acc
        },
        {}
      )
  })
}
