import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {DeleteOutline} from '@mui/icons-material'
import {Button} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedScheduleItemId} from '../../../../../../shared/components/providers/SelectedScheduleItemProvider'
import {useDeleteComment} from '../../../../../../shared/hooks/api/comments/useDeleteComment'
import {useDeleteScheduleItem} from '../../../../../../shared/hooks/api/schedules/useDeleteScheduleItem'
import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../../../../shared/hooks/useUrlParam'
import {Comment, ScheduleItem} from '../../../../../../shared/interfaces/api'

interface DeleteScheduleItemButtonProps {
  startOfPlan: Moment
  endOfPlan: Moment
  scheduleItem: ScheduleItem
  isReadOnly: boolean
  comment?: Comment
}

export const DeleteScheduleItemButton: React.FC<DeleteScheduleItemButtonProps> = ({
  startOfPlan,
  endOfPlan,
  scheduleItem,
  isReadOnly,
  comment
}) => {
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId} = usePlantConfig()
  const {t} = useTranslation()
  const {setScheduleItemId} = useSelectedScheduleItemId()

  const deleteScheduleItem = useDeleteScheduleItem({startOfPlan, endOfPlan})
  const {mutate: deleteComment} = useDeleteComment()

  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})

  const handleDeleteComment = (comment: Comment) => {
    deleteComment({
      plantCode,
      commentsCategory: comment.commentsCategory,
      commentsCategoryInstanceId: scheduleItem.id,
      commentId: comment.commentId
    })
  }

  const handleDeleteScheduleItem = () => {
    setScheduleItemId(undefined)
    deleteScheduleItem(scheduleItem)
    if (comment) {
      handleDeleteComment(comment)
    }
  }

  const isDeleteButtonDisabled = isReadOnly || moment.utc(scheduleItem.start).isBefore(nowRounded)

  return (
    <Button
      {...dataTestId('schedule_edit_delete')}
      startIcon={<DeleteOutline color="error" />}
      color="secondary"
      onClick={handleDeleteScheduleItem}
      disabled={isDeleteButtonDisabled}
    >
      {t('planning.deletePlan')}
    </Button>
  )
}
