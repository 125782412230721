import {dataTestId} from '@hconnect/uikit'
import {SwapHoriz} from '@mui/icons-material'
import {alpha, Button, styled} from '@mui/material'
import React, {useCallback, useLayoutEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {createSearchParams, useNavigate} from 'react-router-dom'

import {getUrl, PLANT_ROUTES, RouteName} from '../../routing'
import {PlannerPageLayout} from '../../shared/components/PlannerPageLayout'
import {SelectedScheduleItemProvider} from '../../shared/components/providers/SelectedScheduleItemProvider'
import {PlannerPageSkeleton} from '../../shared/components/skeletons/PlannerPageSkeleton'
import {LONG_TERM_PLANNING_DAYS} from '../../shared/constants'
import {PlannerLSSettingsKeys} from '../../shared/enums'
import {pageDataTestId} from '../../shared/formatutils'
import {useTrackPlannerEvent} from '../../shared/helpers/trackPlannerEvents'
import {
  convertToURLDatetime,
  parseStartEndDatetimeFromURLParams
} from '../../shared/helpers/urlParams.utils'
import {adjustRangeByMinMaxLimitAndBoundary} from '../../shared/helpers/utils'
import {usePlannerUISettings} from '../../shared/hooks/usePlannerUISettings'
import {usePlanRange} from '../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'
import {useSearchParams} from '../../shared/hooks/useSearchParams'
import {useUrlParam} from '../../shared/hooks/useUrlParam'

import {LayoutOptimizerPlanDetails} from './LayoutOptimizerPlanDetails'
import {OptimizerBackButton} from './OptimizerBackToSummary'

const MINIMUM_RANGE_HOURS = 12

const StyledSecondaryButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: alpha(theme.palette.common.white, 0.05),
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.common.white, 0.1)}`,
    borderColor: theme.palette.grey[100]
  }
}))

export const PageOptimizedPlanDetails: React.FC = () => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const navigate = useNavigate()
  const plantCode = useUrlParam('plantCode')
  const trackPlannerEvent = useTrackPlannerEvent()
  const [startOfPlan, endOfPlan] = usePlanRange({
    days: LONG_TERM_PLANNING_DAYS,
    timezoneId,
    createdAt
  })

  const maxSliderRange = useMemo(
    () => endOfPlan.diff(startOfPlan, 'hours'),
    [startOfPlan, endOfPlan]
  )

  const [obtainedParams, setSearchParams] = useSearchParams('start', 'end')

  const parsedSelectedRange = useMemo(() => {
    const parsedRange = parseStartEndDatetimeFromURLParams({...obtainedParams, timezoneId})
    // adjust range for long term boundary
    return parsedRange
      ? adjustRangeByMinMaxLimitAndBoundary(
          parsedRange,
          [startOfPlan, endOfPlan],
          [MINIMUM_RANGE_HOURS, maxSliderRange]
        )
      : undefined
  }, [obtainedParams, startOfPlan, endOfPlan, maxSliderRange, timezoneId])

  // slider default range in optimizer details view
  const {settings: sliderSettings, updateSettings: updateSliderSetting} = usePlannerUISettings(
    PlannerLSSettingsKeys.OptimizerSliderSettings,
    {defaultRange: 24}
  )
  const updateSliderDefaultRange = useCallback(
    (defaultRange: number) => {
      updateSliderSetting({defaultRange})
    },
    [updateSliderSetting]
  )
  const sliderDefaultRange = sliderSettings.defaultRange

  // if selectedRange is not set, set it to default selected range
  useLayoutEffect(() => {
    if (!parsedSelectedRange) {
      setSearchParams({
        start: convertToURLDatetime(startOfPlan, timezoneId),
        end: convertToURLDatetime(startOfPlan.clone().add(sliderDefaultRange, 'hours'), timezoneId)
      })
    }
  }, [parsedSelectedRange, setSearchParams, startOfPlan, sliderDefaultRange, timezoneId])

  if (!parsedSelectedRange) {
    return <PlannerPageSkeleton />
  }

  return (
    <SelectedScheduleItemProvider>
      <PlannerPageLayout
        {...pageDataTestId(RouteName.OPTIMIZED_PLAN_DETAILS)}
        isTourBannerEnabled={true}
        title={t('navItems.optimizerDetails')}
        pageTitle={t('navItems.optimizerDetails')}
        aboveTitle={<OptimizerBackButton to="summary" {...dataTestId('back_to_summary_button')} />}
        headerActionContent={
          <StyledSecondaryButton
            size="large"
            startIcon={<SwapHoriz fontSize="small" />}
            onClick={() => {
              trackPlannerEvent({name: 'onSwitchToViewLastSavedPlan'})
              navigate({
                pathname: getUrl(
                  PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.DETAILS.LAST_PLAN.path,
                  {
                    plantCode
                  }
                ),
                search: parsedSelectedRange
                  ? createSearchParams({
                      start: convertToURLDatetime(parsedSelectedRange[0], timezoneId),
                      end: convertToURLDatetime(parsedSelectedRange[1], timezoneId)
                    }).toString()
                  : undefined
              })
            }}
            {...dataTestId('switch_to_last_saved_plan_details_button')}
          >
            {t('optimizer.switchViewToLastSavedPlan')}
          </StyledSecondaryButton>
        }
      >
        <LayoutOptimizerPlanDetails
          startOfPlan={startOfPlan}
          endOfPlan={endOfPlan}
          isOptimized
          sliderDefaultRange={sliderDefaultRange}
          sliderMinMaxRange={[MINIMUM_RANGE_HOURS, maxSliderRange]}
          updateSliderDefaultRange={updateSliderDefaultRange}
        />
      </PlannerPageLayout>
    </SelectedScheduleItemProvider>
  )
}
