import MockAdapter from 'axios-mock-adapter'
import {flow} from 'lodash'

import {ApiClient} from '../shared/api/apiClient'

import {enableAssetsEndpoints} from './mockServices/assets.mock'
import {enableCommentsEndpoints} from './mockServices/comments.mock'
import {enableDSEndpoints} from './mockServices/ds.mocks'
import {enableElectricityEndpoints} from './mockServices/electricity.mock'
import {enableKpiEndpoints} from './mockServices/kpi.mock'
import {enableMaterialDemandEndpoints} from './mockServices/materialDemand.mock'
import {enableMaterialOrdersEndpoints} from './mockServices/materialOrders.mock'
import {enableMaterialsEndpoints} from './mockServices/materials.mock'
import {enableMaterialStorageEndpoints} from './mockServices/materialStorage.mock'
import {enableSchedulerEndpoints} from './mockServices/scheduler.mock'
import {enableLogging} from './mockServices/utils'
import {mockStore} from './mockStore'

export const checkAndEnableMockIfRequired = () => {
  if (!mockStore.isEnabled()) {
    return
  }

  const mock = new MockAdapter(ApiClient.axiosInstance, {onNoMatch: 'throwException'})

  mockStore.enableDfApiMockForAxios(mock)
  enableBurglengenfeldMock(mock)
  enableInvalidPlantsMock(mock)
  enableLogging(ApiClient.axiosInstance)
}

const enableBurglengenfeldMock = (mock: MockAdapter) => {
  flow(
    enableDSEndpoints,
    enableMaterialsEndpoints,
    enableSchedulerEndpoints,
    enableElectricityEndpoints,
    enableCommentsEndpoints,
    enableAssetsEndpoints,
    enableMaterialStorageEndpoints,
    enableMaterialOrdersEndpoints,
    enableKpiEndpoints,
    enableMaterialDemandEndpoints
  )(mock)
}

/**
 * We add some plant mock responses for plants that not exist so that we can navigate there and
 * test the error response codes accordingly
 */
const enableInvalidPlantsMock = (mock: MockAdapter) => {
  // 401 unauthorized
  mock.onGet('/hcem/ui/v2/latest/viernulleins').reply(() => [
    401,
    {
      status: 'ERROR_OPERATION_NOT_AUTHORIZED',
      detail: 'You are not authorized to access the given plant resource',
      errors: {}
    }
  ])
  mock.onGet('/hcem/ui/v2/config/viernulleins').reply(() => [
    401,
    {
      status: 'ERROR_OPERATION_NOT_AUTHORIZED',
      detail: 'You are not authorized to access the given plant resource',
      errors: {}
    }
  ])

  mock.onGet('/hcem/ui/v2/latest/viernulldrei').reply(() => [
    403,
    {
      status: 'ERROR_UNKNOWN_PLANT',
      detail: 'There is no plant known by this id.',
      errors: {}
    }
  ])
  mock.onGet('/hcem/ui/v2/config/viernulldrei').reply(() => [
    403,
    {
      status: 'ERROR_UNKNOWN_PLANT',
      detail: 'There is no plant known by this id.',
      errors: {}
    }
  ])
  mock.onGet('/hcem/ui/v2/schedule/viernulldrei').reply(() => [
    403,
    {
      status: 'ERROR_UNKNOWN_PLANT',
      detail: 'There is no plant known by this id.',
      errors: {}
    }
  ])
}
