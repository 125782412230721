import moment from 'moment-timezone'

import {DatetimeValue} from '../interfaces/common'

import {groupBy} from './time'

/**
 * @deprecated remove when finally switched to new demand service
 * Helper function to format demand forecast values into a single day's forecast values to simplify calculations.
 */
export const groupDemandByDay = (demands: DatetimeValue[] | undefined, timezoneId: string) =>
  groupBy(demands ?? [], (datetime) =>
    moment.utc(datetime).tz(timezoneId).add(1, 'day').startOf('day').toISOString()
  )
