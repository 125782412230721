import {formatFloat} from '@hconnect/uikit'
import {Chip, alpha, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

const DEFAULT_THRESHOLD_PERCENTAGE = 3

/**
 * A chip that displays a percentage value in a color-coded manner.
 * handles infinity as a special case of value
 */

export const ColorCodedPercentChip: React.FC<{
  value: number
  threshold?: number
}> = ({value, threshold = DEFAULT_THRESHOLD_PERCENTAGE}) => {
  const {
    i18n: {language}
  } = useTranslation()
  const {palette} = useTheme()

  const variant: 'neutral' | 'negative' | 'positive' = (() => {
    if (value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY) {
      return 'neutral'
    }
    if (value > threshold) {
      return 'positive'
    }
    return value < -threshold ? 'negative' : 'neutral'
  })()

  const variantToColor = {
    neutral: palette.text.secondary,
    negative: palette.error.main,
    positive: palette.success.main
  }

  const formattedValue =
    value === Number.POSITIVE_INFINITY
      ? '+∞'
      : value === Number.NEGATIVE_INFINITY
        ? '-∞'
        : `${formatFloat(value, 0, language)}%`

  return (
    <Chip
      sx={{
        color: variantToColor[variant],
        background: alpha(variantToColor[variant], 0.1)
      }}
      label={formattedValue}
    />
  )
}
