import {
  HistoryMaterialStorage,
  HistoryStorageWithMaterial,
  Recipe,
  RecipeComponentType,
  MaterialStoragesHistory,
  MaterialStorage,
  StorageWithMaterial
} from '@hconnect/common/types'
import {Moment} from 'moment'

import type {OperationModeResponse} from '../interfaces/api'

import {getRelevantHistoryEntry} from './history'
import {getRecipeComponentsWithMaterial} from './materials'

export const isStorageWithMaterialGuard = (
  storage: MaterialStorage
): storage is StorageWithMaterial => storage.materialId !== null
export const isHistoryStorageWithMaterialGuard = (
  storage: HistoryMaterialStorage
): storage is HistoryStorageWithMaterial => storage.materialId !== null

export const totalCapacityForStorages = (storages: HistoryMaterialStorage[]) =>
  storages.reduce((totalCapacity, storage) => totalCapacity + storage.capacity, 0)
export const totalDeadStockForStorages = (storages: HistoryMaterialStorage[]) =>
  storages.reduce((total, storage) => total + storage.deadStock, 0)

export const filterMaterialsStoredInStorages = (
  storages: HistoryStorageWithMaterial[],
  materialIds: number[]
) => {
  const materialIdsInStorages = new Set(storages.map((storage) => storage.materialId))
  return materialIds.filter((id) => materialIdsInStorages.has(id))
}

// get result of the form [{measuredAt: '2020-02-20T10:10:10Z', storages: [{id: ..., }, {id: ..., }]}, ...] sorted by measuredAt
export const getStoragesGroupedByUpdate = (
  storages: HistoryStorageWithMaterial[]
): {measuredAt: string; storages: HistoryStorageWithMaterial[]}[] => {
  const tmp: {[id: string]: HistoryStorageWithMaterial[]} = {}

  for (const storage of storages) {
    if (!(storage.currentStorageLevel.measuredAt in tmp)) {
      tmp[storage.currentStorageLevel.measuredAt] = []
    }
    tmp[storage.currentStorageLevel.measuredAt].push(storage)
  }
  return Object.keys(tmp)
    .sort()
    .map((key) => ({
      measuredAt: key,
      storages: tmp[key].sort()
    }))
}

/**
 * function that returns storage ids connected to particular recipeId
 */
const getStorageIdsByRecipeId = (storages: HistoryStorageWithMaterial[], recipe: Recipe) => {
  return storages.filter(
    (storage) =>
      getRecipeComponentsWithMaterial(storage.materialId, recipe, [RecipeComponentType.Output])
        .length > 0
  )
}

/**
 * function which returns storage ids for particular operation mode
 */
export const getStoragesWithMaterialForOperationMode = (
  recipesById: Record<string, Recipe>,
  storages: HistoryStorageWithMaterial[],
  operationMode: OperationModeResponse
): HistoryStorageWithMaterial[] => {
  if (!operationMode.recipeId) {
    return []
  }

  return getStorageIdsByRecipeId(storages, recipesById[operationMode.recipeId])
}

export const getStoragesFromHistory = (history: MaterialStoragesHistory, date: Moment) => {
  return Object.values(history).map((storageHistory) =>
    getRelevantHistoryEntry(storageHistory, date)
  )
}
