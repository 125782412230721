import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {fetchHcemQuery} from '../../../api/queries'
import {invalidateHcemQuery} from '../../../api/queryHelpers'
import {notifyIfErrorMessage} from '../../../common'
import {SubmitSchedule} from '../../../interfaces/api'
import {ErrorResponse} from '../../../interfaces/api/common'
import {useUrlParam} from '../../useUrlParam'

export const useSubmitSchedule = () => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const raiseError = useErrorHandler()
  return useMutation<
    SubmitSchedule,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.submitElectricityPlan>[0]
  >({
    mutationFn: mutations.submitElectricityPlan,
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: ({scheduleId}) => {
      notify('success', t('success.planSubmit'))
      return fetchHcemQuery('scheduleCostAvoidance', {plantCode, scheduleId})
    },
    onSettled: (data, error, {plantCode}) => {
      invalidateHcemQuery('electricity', {plantCode})
      invalidateHcemQuery('schedule')
    }
  })
}
