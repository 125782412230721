import React from 'react'

import {AssetType} from '../shared/enums'

import {ReactComponent as RotaryKilnIcon} from './icons/KilnRotary.svg'
import {ReactComponent as CementMillIcon} from './icons/MillCement.svg'
import {ReactComponent as RawMillIcon} from './icons/MillRaw.svg'
import {ReactComponent as OtherAssetsIcon} from './icons/OtherAssetsIcon.svg'
export const assetIconMap: Record<AssetType, React.ReactNode> = {
  [AssetType.CementMill]: <CementMillIcon />,
  [AssetType.RawMill]: <RawMillIcon />,
  [AssetType.RotaryKiln]: <RotaryKilnIcon />,
  [AssetType.Other]: <OtherAssetsIcon />,
  [AssetType.Crusher]: <OtherAssetsIcon />,
  // TODO find icons for coal mill and crusher
  [AssetType.CoalMill]: <></>,
  [AssetType.BaseLoad]: <></>
}
