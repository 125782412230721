import {Box, Button, Divider, SvgIcon} from '@mui/material'
import React from 'react'

import {HIcon} from '../../common'
import {CardBox} from '../components'
import {Content, ShellFooter} from '../shell'

interface LandingPageProps {
  onClickButton: () => void
  customLogoStyle?: React.CSSProperties
  loginButtonLabel?: string
  footer?: React.ReactNode
  bgColor?: string
  children?: React.ReactNode
}

export const LandingPage: React.FC<LandingPageProps> = ({
  children,
  onClickButton,
  loginButtonLabel = 'Mit HConnect anmelden',
  footer = <ShellFooter label={'powered by'} />,
  bgColor
}) => {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      style={{...(bgColor && {background: bgColor})}}
    >
      <Content justifyContent="center" alignItems="center">
        <CardBox
          width={640}
          height={296}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center" mb={5}>
            {children}
          </Box>
          <Button
            startIcon={<SvgIcon component={HIcon} />}
            color="primary"
            variant="contained"
            onClick={onClickButton}
            data-test-id="landing-page-button-login-hconnect"
          >
            {loginButtonLabel}
          </Button>
        </CardBox>
      </Content>
      <Divider variant="middle" light />
      {footer}
    </Box>
  )
}
