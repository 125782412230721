import type {AxiosRequestConfig, AxiosRequestHeaders} from 'axios'

export const timezoneHeaderInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const posixOffset = new Date().getTimezoneOffset()

  const headers: AxiosRequestHeaders = {
    ...config.headers,
    'X-Timezone-Offset': posixOffset.toString()
  }

  return {...config, headers}
}
