import {Moment} from 'moment-timezone'
import React from 'react'

import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {HCEMPalette} from '../../pallette'

type StyleFormatFn<ValueT> = (value: ValueT, index: number) => ChartGridProps['lineStyles']

interface ChartGridProps {
  xSteps: Moment[]
  ySteps: number[]
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  lineStyles?: Pick<React.CSSProperties, 'stroke' | 'strokeWidth'>
  overrideVerticalLineStyles?: StyleFormatFn<Moment>
  overrideHorizontalLineStyles?: StyleFormatFn<number>
}

export const ChartGrid: React.FC<ChartGridProps> = ({
  ySteps,
  xSteps,
  xScale,
  yScale,
  lineStyles,
  overrideVerticalLineStyles,
  overrideHorizontalLineStyles
}) => {
  return (
    <>
      {xSteps.map((datetime, index) => {
        return (
          <line
            key={`${datetime.toISOString()}_${index}`}
            x1={xScale(datetime)}
            y1={0}
            x2={xScale(datetime)}
            y2="100%"
            style={{
              stroke: HCEMPalette.thinLine,
              ...lineStyles,
              ...(overrideVerticalLineStyles && overrideVerticalLineStyles(datetime, index))
            }}
          />
        )
      })}
      {ySteps.map((step, index) => {
        return (
          <line
            key={`${step}_${index}`}
            x1={0}
            y1={yScale(step)}
            x2="100%"
            y2={yScale(step)}
            style={{
              stroke: HCEMPalette.thinLine,
              ...lineStyles,
              ...(overrideHorizontalLineStyles && overrideHorizontalLineStyles(step, index))
            }}
          />
        )
      })}
    </>
  )
}
