import {MaterialType, NewMaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import {BoxProps, Collapse, IconButton, Skeleton, Stack} from '@mui/material'
import {Moment} from 'moment-timezone'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {CollapsibleCard} from '../../../../shared/components/CollapsibleCard'
import {Timeframe} from '../../../../shared/components/providers/SelectedTimeframeProvider'
import {PlannerLSSettingsKeys} from '../../../../shared/enums'
import {toPixel} from '../../../../shared/helpers/utils'
import {
  useStoredMaterialIdsFromHistoryQuery,
  useStoredMaterialTypesFromHistoryQuery
} from '../../../../shared/hooks/api'
import {usePersistingSettingState} from '../../../../shared/hooks/usePersistingSettingState'
import {useSearchParams} from '../../../../shared/hooks/useSearchParams'
import {StockDevelopmentChartLegend} from '../../../page-stock/materialOrders/StockDevelopmentChartLegend'

import {PlanningStockDevelopmentChartContainer} from './detail/PlanningStockDevelopmentChartContainer'
import {SelectMaterialIdDropdown} from './detail/SelectMaterialIdDropdown'
import {SelectMaterialTypeDropdown} from './overview/SelectMaterialTypeDropdown'
import {StockLevelsOverviewSubtitle} from './overview/StockLevelsOverviewSubtitle'
import {SilosGridSection} from './SilosGridSection'

interface StockLevelsCardProps extends BoxProps {
  cellWidth: number
  totalSteps: number
  immediateHourOffset: number
  startOfPlan: Moment
  endOfPlan: Moment
}

export const StockLevelsCard = ({
  cellWidth,
  totalSteps,
  startOfPlan,
  endOfPlan,
  immediateHourOffset,
  ...props
}: StockLevelsCardProps) => {
  const {t} = useTranslation()

  const [{isSiloSectionOpen}, setIsSiloSectionOpen] = usePersistingSettingState(
    PlannerLSSettingsKeys.PlanningSiloSectionSettings,
    {isSiloSectionOpen: true}
  )

  const selectedTimeframe: Timeframe = useMemo(
    () => [startOfPlan, endOfPlan],
    [startOfPlan, endOfPlan]
  )

  const toggleIsMinimized = useCallback(
    () => setIsSiloSectionOpen({isSiloSectionOpen: !isSiloSectionOpen}),
    [isSiloSectionOpen, setIsSiloSectionOpen]
  )

  const {data: storedMaterialTypes, isLoading: storedMaterialTypesIsLoading} =
    useStoredMaterialTypesFromHistoryQuery({timeFrame: selectedTimeframe})
  const [selectedMaterialType, changeSelectedMaterialType] = useState<
    MaterialType | NewMaterialType | 'all_types'
  >('all_types')

  const {data: allStoredMaterialIds, isLoading: allStoredMaterialIdsIsLoading} =
    useStoredMaterialIdsFromHistoryQuery({
      timeFrame: selectedTimeframe
    })

  const isLoading = allStoredMaterialIdsIsLoading || storedMaterialTypesIsLoading

  const [obtainedSearchParams, setSearchParams] = useSearchParams('materialId')
  const selectedMaterialId = obtainedSearchParams.materialId
    ? Number(obtainedSearchParams.materialId).valueOf() || null
    : null
  // filter only loaded material ids
  const filteredMaterialId =
    selectedMaterialId && allStoredMaterialIds?.includes(selectedMaterialId)
      ? selectedMaterialId
      : null

  useEffect(() => {
    if (allStoredMaterialIdsIsLoading) {
      return
    }
    const newSearchParam = filteredMaterialId !== null ? filteredMaterialId.toString() : null
    if (newSearchParam !== obtainedSearchParams.materialId) {
      setSearchParams({materialId: newSearchParam})
    }
  }, [setSearchParams, obtainedSearchParams, filteredMaterialId, allStoredMaterialIdsIsLoading])

  useEffect(() => {
    if (allStoredMaterialIdsIsLoading || storedMaterialTypesIsLoading) {
      return
    }
    if (
      selectedMaterialType !== 'all_types' &&
      !storedMaterialTypes?.includes(selectedMaterialType)
    ) {
      changeSelectedMaterialType('all_types')
    }
  }, [
    allStoredMaterialIdsIsLoading,
    selectedMaterialType,
    storedMaterialTypes,
    storedMaterialTypesIsLoading
  ])

  const changeSelectedMaterialId = useCallback(
    (materialId: number | undefined) => {
      setSearchParams({materialId: materialId ? materialId.toString() : null})
    },
    [setSearchParams]
  )

  const switchToOverview = useCallback(() => {
    changeSelectedMaterialId(undefined)
  }, [changeSelectedMaterialId])

  return (
    <CollapsibleCard
      {...props}
      isCollapsed={!isSiloSectionOpen}
      onToggleCollapsed={toggleIsMinimized}
      title={
        selectedMaterialId !== undefined ? t('stock.stockDevelopment') : t('planning.stockLevels')
      }
      subtitle={
        selectedMaterialId === undefined && <StockLevelsOverviewSubtitle lastUpdated={endOfPlan} />
      }
      headerContent={
        <Collapse in={isSiloSectionOpen}>
          {filteredMaterialId !== null ? (
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
              <StockDevelopmentChartLegend />
              <SelectMaterialIdDropdown
                selectedMaterialId={filteredMaterialId}
                changeSelectedMaterialId={changeSelectedMaterialId}
                materialIds={allStoredMaterialIds || []}
              />
              <div>
                <IconButton
                  onClick={switchToOverview}
                  color="primary"
                  {...dataTestId('close_material_detail_button')}
                >
                  <Close />
                </IconButton>
              </div>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="end">
              <SelectMaterialTypeDropdown
                selectedMaterialType={selectedMaterialType}
                changeSelectedMaterialType={changeSelectedMaterialType}
                storedMaterialTypes={storedMaterialTypes || []}
              />
            </Stack>
          )}
        </Collapse>
      }
      {...dataTestId('stock_levels_card')}
    >
      <Collapse in={isSiloSectionOpen}>
        {isLoading ? (
          <Skeleton height={toPixel(430)} variant="rectangular" />
        ) : filteredMaterialId !== null ? (
          <PlanningStockDevelopmentChartContainer
            cellWidth={cellWidth}
            totalSteps={totalSteps}
            immediateHourOffset={immediateHourOffset}
            materialId={filteredMaterialId}
          />
        ) : (
          <SilosGridSection
            onSelectMaterial={changeSelectedMaterialId}
            materialType={selectedMaterialType !== 'all_types' ? selectedMaterialType : undefined}
          />
        )}
      </Collapse>
    </CollapsibleCard>
  )
}
