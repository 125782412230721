import {useCallback, useState} from 'react'

import {PlannerLocalStorageSettingsSchema} from '../components/providers/LocalStorageSettingsProvider'
import {PlannerLSSettingsKeys} from '../enums'

import {usePlannerUISettings} from './usePlannerUISettings'

export const usePersistingSettingState = <K extends PlannerLSSettingsKeys>(
  key: K,
  initial: PlannerLocalStorageSettingsSchema[K]
) => {
  const {settings: persistingState, updateSettings: updatePersistingState} = usePlannerUISettings(
    key,
    initial
  )
  const [state, setState] = useState<PlannerLocalStorageSettingsSchema[K]>(persistingState)

  const updateStateWithPersistingState = useCallback(
    (newState: PlannerLocalStorageSettingsSchema[K]) =>
      setState(() => {
        updatePersistingState(newState)
        return newState
      }),
    [updatePersistingState]
  )

  return [state, updateStateWithPersistingState] as const
}
