import {dataTestId} from '@hconnect/uikit'
import {Paper, Stack, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'

interface CostAvoidanceKPIProps {
  label: string
  amount: string
  currency: string
  titleSx?: SxProps<Theme>
}
export const CostAvoidanceKPI: React.FC<CostAvoidanceKPIProps> = ({
  label,
  amount,
  currency,
  titleSx
}: CostAvoidanceKPIProps) => {
  return (
    <Paper sx={{boxShadow: ({shadows}) => shadows[10], p: 1}} {...dataTestId('cost_avoidance_kpi')}>
      <Stack alignItems="center">
        <Stack direction="row" alignItems="center">
          <Typography variant="button" sx={titleSx}>
            {amount}
            <Typography variant="caption" sx={{ml: 0.5, color: 'inherit'}}>
              {currency}
            </Typography>
          </Typography>
        </Stack>
        <Typography variant="subtitle2">{label}</Typography>
      </Stack>
    </Paper>
  )
}
