import {Stack, Typography, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Legend, LegendItem} from '../../../shared/components/Legend'

export const AssetCapacityCardHeader: React.FC = () => {
  const {t} = useTranslation()
  const {palette} = useTheme()

  return (
    <Stack direction="row" spacing={2} sx={{justifyContent: 'space-between'}}>
      <Typography variant="h3">{t('planning.assetCapacity')}</Typography>
      <Legend>
        <LegendItem
          label={t('planning.production')}
          indicator={{variant: 'circle', style: {backgroundColor: palette.background.default}}}
        />
        <LegendItem
          label={t('planning.maintenance')}
          indicator={{variant: 'circle', style: {backgroundColor: palette.text.secondary}}}
        />
      </Legend>
    </Stack>
  )
}
