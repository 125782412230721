import {dataTestId} from '@hconnect/uikit'
import {Box, Stack} from '@mui/material'
import React from 'react'

import {prefetchPlannerQuery} from '../../shared/api/queries'
import {useSelectedTimeframe} from '../../shared/components/providers/SelectedTimeframeProvider'
import {useUrlParam} from '../../shared/hooks/useUrlParam'

import {AssetCapacityCard} from './monthlyPlanning/AssetCapacityCard'
import {MonthlyProductionPlanningActions} from './monthlyPlanning/MonthlyProductionPlanningActions'
import {ProductionSummaryCard} from './monthlyPlanning/ProductionSummaryCard'
import {SelectedForecastTypeProvider} from './monthlyPlanning/SelectedForecastTypeProvider'
import {PageProductionPlanningHeader} from './PageProductionPlanningHeader'

export const LayoutMonthlyPlanning: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('selectedTimeframe is not defined')
  }

  const [startOfPlan, endOfPlan] = selectedTimeframe

  // prefetching required data
  prefetchPlannerQuery('assetsHistory', [
    plantCode,
    startOfPlan.toISOString(),
    endOfPlan.toISOString()
  ])
  prefetchPlannerQuery('materialsRecipes', [{plantCode}])

  return (
    <Stack spacing={2} {...dataTestId('monthly_planning_layout')}>
      <PageProductionPlanningHeader actions={<MonthlyProductionPlanningActions />} />
      <Stack spacing={2} direction="row">
        <Box sx={{flex: 1}}>
          <SelectedForecastTypeProvider>
            <ProductionSummaryCard />
          </SelectedForecastTypeProvider>
        </Box>
        <Box sx={{flex: 1}}>
          <AssetCapacityCard />
        </Box>
      </Stack>
    </Stack>
  )
}
