import {Moment} from 'moment-timezone'
import React, {useContext, useState, useMemo} from 'react'

export type Timeframe = [start: Moment, end: Moment]

interface TimeframeState {
  selectedTimeframe: Timeframe | undefined
  setSelectedTimeframe: (timeframe: Timeframe | undefined) => void
}

const SelectedTimeframeContext = React.createContext<TimeframeState | undefined>(undefined)

export const useSelectedTimeframe = () => {
  const context = useContext(SelectedTimeframeContext)
  if (!context)
    throw new Error(
      'Cannot use SelectedTimeframeContext outside of a SelectedTimeframeProvider provider'
    )
  return context
}

interface SelectedTimeframeProviderProps {
  defaultTimeframe?: Timeframe
  children: React.ReactNode
}

export const SelectedTimeframeProvider: React.FC<SelectedTimeframeProviderProps> = ({
  defaultTimeframe,
  children
}) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe | undefined>(
    defaultTimeframe
  )

  const memoizedState = useMemo(
    () => ({
      selectedTimeframe,
      setSelectedTimeframe
    }),
    [selectedTimeframe]
  )

  return (
    <SelectedTimeframeContext.Provider value={memoizedState}>
      {children}
    </SelectedTimeframeContext.Provider>
  )
}
