import {camelCase} from 'lodash'
import queryString from 'query-string'

import {BackendConfig, BackendConfigList} from '../types'

import {mapHostToBackend} from './browserBackendUtils'

const STORAGE_NAME = `HC-${process.env.REACT_APP_CLIENT_NAME}-Preferred-Backend`

export class BrowserBackendSelector {
  /**
   * @param availableBackends List of backends which should be possible to choose from.
   */
  constructor(availableBackends: BackendConfigList) {
    Object.keys(availableBackends).forEach((name) => {
      availableBackends[name].NAME = name
    })

    this.backends = availableBackends
    this.bundlePreferredBackend = Object.values(availableBackends)[0]

    const parsedQueryString = queryString.parse(window.location.search)
    if (typeof parsedQueryString.backend === 'string') {
      const parsedBackend = parsedQueryString.backend
      const queryPreference = /\d/.exec(parsedBackend)
        ? parsedBackend.toLowerCase()
        : camelCase(parsedBackend)
      if (this.backends[queryPreference]) {
        this.setUserPreferredBackend(queryPreference, false)
      } else {
        console.warn(`Unsupported backend passed by queryString: ${parsedQueryString.backend}!`)
      }
    }
  }

  private readonly backends: BackendConfigList = {}
  private readonly bundlePreferredBackend: BackendConfig

  /**
   * Returns the preferred backend URL based on the current URL the app
   * is running on.
   */
  getLocationBasedPreference(): string {
    if (process.env.REACT_APP_STAGE === 'prod') {
      return 'prod'
    }

    return mapHostToBackend(window.location.host)
  }

  /**
   * Allow the user of the application to select a preferred backend by name.
   *
   * @param backendName Name of the backend to use.
   * @param reload Whether the application should be reloaded after storing the new value.
   */
  setUserPreferredBackend(backendName: string | null, reload = true) {
    if (backendName) {
      localStorage.setItem(STORAGE_NAME, backendName)
    } else {
      localStorage.removeItem(STORAGE_NAME)
    }

    if (reload) {
      window.location.reload()
    }
  }

  /**
   * Returns the user preferred backend by name.
   */
  getUserPreferredBackend(): string | null {
    return localStorage.getItem(STORAGE_NAME) || null
  }

  /**
   * Evaluates the different preference settings to returns the best matching
   * backend configuration.
   */
  getSelectedBackend(): BackendConfig {
    const userPreference = this.getUserPreferredBackend()
    const domainPreference = this.getLocationBasedPreference()
    const backendList = this.backends

    if (userPreference && backendList[userPreference]) {
      return backendList[userPreference]
    }

    if (domainPreference && backendList[domainPreference]) {
      return backendList[domainPreference]
    }

    if (!this.bundlePreferredBackend) {
      throw new Error('No backend available!')
    }

    return this.bundlePreferredBackend
  }
}
