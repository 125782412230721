import {useGuidedTours} from '@hconnect/uikit/src/lib2'
import {CommonHeaderGuidedTourProps} from '@hconnect/uikit/src/lib2/shell/header/CommonHeader'
import React, {useMemo} from 'react'

import {DefaultShell} from './DefaultShell'
import {PlannerHeader} from './PlannerHeader'

export const GuidedToursShell = ({children}: {children: React.ReactNode}) => {
  const {tours, tourStatuses} = useGuidedTours()

  const guidedTours = useMemo<CommonHeaderGuidedTourProps>(
    () => ({tours, statuses: tourStatuses}),
    [tours, tourStatuses]
  )

  return (
    <DefaultShell HeaderContent={<PlannerHeader guidedTours={guidedTours} />}>
      {children}
    </DefaultShell>
  )
}
