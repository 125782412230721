import {Recipe} from '@hconnect/common/types'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface MaterialsRecipesByIdQueryParams {
  useErrorBoundary?: boolean
}

export const useMaterialsRecipesById = (params?: MaterialsRecipesByIdQueryParams) => {
  const {useErrorBoundary = true} = params ?? {}
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('materialsRecipes', [{plantCode}], {
    select: (data) =>
      data.reduce(
        (recipesById, recipe) => ({...recipesById, [String(recipe.id)]: recipe}),
        {} as Record<string, Recipe>
      ),
    useErrorBoundary
  })
}
