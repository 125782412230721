import {Moment} from 'moment-timezone'

import {Electricity} from './../interfaces/api/electricity'
import {MomentRange, NumberRange} from './../selectors/time'

export const getSubmitTimeFrame = (
  upForPurchasing?: Electricity['upForPurchasing']
): [submitStart: Moment | undefined, submitEnd: Moment | undefined] => {
  if (!upForPurchasing?.length) return [undefined, undefined]
  const start = upForPurchasing[0].dateTimeUTC
  const end = upForPurchasing[upForPurchasing.length - 1].dateTimeUTC
  return [start, end]
}

export const toPixel = (value: number): string => `${value}px`

export const calculateRangeWithinBoundary = (
  inputRange: [number, number],
  boundary: [number, number]
): [number, number] => {
  const [startRange, endRange] = inputRange
  const delta = endRange - startRange
  const [min, max] = boundary

  if (delta > max - min) {
    return boundary
  }
  if (startRange < min) {
    return [min, min + delta]
  }
  if (endRange > max) {
    return [max - delta, max]
  }
  return inputRange
}

/**
 * Function to calculate datetime range within boundary
 * diff is calculated in hours, minutes are not taken into account
 */
export const calculateDatetimeRangeWithinBoundary = (
  inputRange: MomentRange,
  boundary: MomentRange
): MomentRange => {
  const [startRange, endRange] = inputRange
  const delta = endRange.diff(startRange, 'hours')
  const [min, max] = boundary

  if (delta > max.diff(min, 'hours')) {
    return boundary
  }
  if (startRange.isBefore(min)) {
    return [min, min.clone().add(delta, 'hours')]
  }
  if (endRange.isAfter(max)) {
    return [max.clone().subtract(delta, 'hours'), max]
  }
  return inputRange
}

/**
 * Function to calculate nice axis steps, for example 0, 10, 20, 30, or 0, 5, 10, 15
 * if it's not possible to calculate nice steps with numberOfSteps passed as param,
 * function will return close but different number of steps
 */
interface CalculateNiceAxisStepsParams {
  maxValue: number
  minValue?: number
  shouldUseExactMaxValue?: boolean
  numberOfSteps?: number
}
export const calculateNiceAxisSteps = ({
  maxValue,
  minValue = 0,
  numberOfSteps = 10,
  shouldUseExactMaxValue = false
}: CalculateNiceAxisStepsParams): {steps: number[]; stepSize: number} => {
  const span = maxValue - minValue
  // handling case here when maxValue is equal minValue
  if (span === 0) {
    return {steps: [maxValue], stepSize: 0}
  }
  // calculating matching power of ten step depending on span and number of steps
  // like 10, 100, 1000, 0.1, 0.01
  const powerOfTenStep: number = Math.pow(
    10,
    Math.floor(Math.log(span / numberOfSteps) / Math.LN10)
  )
  // calculating exact step value
  const nonRoundedStep = span / numberOfSteps
  // caculationg ration between beautiful power of ten step and exact non rounded step
  const ratio = powerOfTenStep / nonRoundedStep

  // Correcting step depending on ratio
  let correctedStep: number = powerOfTenStep
  if (ratio <= 0.15) correctedStep *= 10
  else if (ratio <= 0.35) correctedStep *= 5
  else if (ratio <= 0.75) correctedStep *= 2

  const minValueRounded = Math.ceil(minValue / correctedStep) * correctedStep
  const maxValueRounded = Math.ceil(maxValue / correctedStep) * correctedStep

  const steps: number[] = []

  for (let stepValue = minValueRounded; stepValue < maxValueRounded; stepValue += correctedStep) {
    steps.push(stepValue)
  }
  if (shouldUseExactMaxValue) {
    const shouldRemoveStepBeforMaxValue: boolean =
      maxValue - steps[steps.length - 1] <= correctedStep
    if (shouldRemoveStepBeforMaxValue) {
      steps.pop()
    }
    steps.push(maxValue)
  } else {
    steps.push(steps[steps.length - 1] + correctedStep)
  }

  return {steps, stepSize: correctedStep}
}

export const getMWhStepsForElectricityChart = (maxValue: number) => {
  let step = 1
  if (maxValue >= 100) step = 10
  else if (maxValue >= 40) step = 5
  else if (maxValue >= 11) step = 2

  const steps = Array.from({length: Math.ceil(maxValue / step)}, (_, index) => index * step)

  return steps
}

/**
 * function to fit elements to existing grid based on column count and colSpan for each element
 * @param columnCount a positive integer
 */
export const fitElementsToGridBasedOnColSpan = <TData>(
  columnCount: number,
  elements: {colSpan: number; data: TData}[]
) => {
  const filteredItems = elements.filter((item) => item.colSpan <= columnCount)
  const rows: {colSpan: number; data: TData}[][] = [[]]

  for (const item of filteredItems) {
    for (const row of rows) {
      const currentColSpan = row.reduce((acc, item) => acc + item.colSpan, 0)
      if (item.colSpan <= columnCount - currentColSpan) {
        row.push(item)
        break
      } else {
        rows.push([])
        continue
      }
    }
  }
  return rows.flat()
}

/** Function to transform array to a record by key */
export const toRecord = <T>(data: T[], property: keyof T): Record<string, T> => {
  return data.reduce(
    (acc, item) => {
      acc[String(item[property])] = item
      return acc
    },
    {} as Record<string, T>
  )
}

const adjustRangeByMinLimit = (range: MomentRange, minHours: number): MomentRange => {
  const [start, end] = range
  const rangeLenghtInHours = end.diff(start, 'hours')
  return rangeLenghtInHours < minHours ? [start, start.clone().add(minHours, 'hours')] : range
}
/**
 * function to adjust range by min max limit and boundary
 * used to sanitize url params for chart start and end
 */
export const adjustRangeByMinMaxLimitAndBoundary = (
  range: MomentRange,
  boundary: MomentRange,
  minMaxRangeHours: NumberRange
) => {
  const [minRangeHours, maxRangeHours] = minMaxRangeHours
  const rangeAdjustedByMinLimit = adjustRangeByMinLimit(range, minRangeHours)
  const rangeAdjustedByMaxLimit = calculateDatetimeRangeWithinBoundary(rangeAdjustedByMinLimit, [
    rangeAdjustedByMinLimit[0],
    rangeAdjustedByMinLimit[0].clone().add(maxRangeHours, 'hours')
  ])
  return calculateDatetimeRangeWithinBoundary(rangeAdjustedByMaxLimit, boundary)
}

/**
 * Function to calculate the percentage difference between two numbers.
 * It returns a float or positive/negative infinity.
 * @param a The first number.
 * @param b The second number.
 * @returns The percentage difference between a and b.
 */
export const calculateDeltaPercentage = (a: number, b: number): number => {
  if (a === 0 && b > 0) {
    return Number.POSITIVE_INFINITY
  }
  if (a === 0 && b < 0) {
    return Number.NEGATIVE_INFINITY
  }
  if (a > 0 && b === 0) {
    return -100
  }
  if (a === b) {
    return 0
  }
  return ((b - a) / a) * 100
}
