import {Skeleton, Paper} from '@mui/material'
import React from 'react'

interface PlannerSkeletonProps {
  height: string
}

export const PlannerSkeleton: React.FC<PlannerSkeletonProps> = ({height}) => {
  return (
    <Skeleton
      variant="rectangular"
      component={Paper}
      sx={{
        height: height,
        backgroundColor: ({palette}) => palette.background.paper
      }}
    />
  )
}
