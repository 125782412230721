import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useAssetsCapacity = ([from, to]: MomentRange) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery(
    'assetCapacity',
    [plantCode, {from: from.toISOString(), to: to.toISOString()}],
    {select: (data) => data.assets}
  )
}
