import {mergeSx} from '@hconnect/uikit/src/lib2'
import {BoxProps, Box} from '@mui/material'
import React from 'react'

import {HCEMPalette} from '../pallette'

interface PlannerIconProps extends Pick<BoxProps, 'sx'> {
  icon: React.ReactNode
}

export const PlannerIcon: React.FC<PlannerIconProps> = ({icon, sx: otherSx}) => {
  return (
    <Box
      sx={mergeSx(
        (theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          fontSize: theme.spacing(2),
          width: theme.spacing(4),
          height: theme.spacing(4),
          fill: HCEMPalette.deepOcean
        }),
        otherSx
      )}
    >
      {icon}
    </Box>
  )
}
