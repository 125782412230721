import {HistoryStorageWithMaterial} from '@hconnect/common/types'
import {dateFormatter, timeFormatter} from '@hconnect/uikit/src/common'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getStoragesGroupedByUpdate} from '../../../../shared/selectors/storages'
import {adjustEnLocale, getPlantUtcOffset} from '../../../../shared/selectors/time'

export const LastSiloMeasurements: React.FC<{
  silos: HistoryStorageWithMaterial[]
  timezoneId: string
}> = ({silos, timezoneId}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const silosGroupedByUpdate = getStoragesGroupedByUpdate(silos)
  return (
    <>
      {silosGroupedByUpdate.map((group) => (
        <Typography variant="caption" key={group.measuredAt}>
          {t('stock.lastSiloMeasurements', {
            silos: group.storages.map((silo) => silo.name).join(', '),
            date: dateFormatter(
              group.measuredAt,
              adjustEnLocale(language),
              getPlantUtcOffset(timezoneId)
            ),
            time: timeFormatter(group.measuredAt, language, getPlantUtcOffset(timezoneId))
          })}
        </Typography>
      ))}
    </>
  )
}
