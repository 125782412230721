import {MaterialStorage, MaterialType} from '@hconnect/common/types'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {isStorageWithMaterialGuard} from '../../../selectors/storages'
import {usePlannerQuery} from '../usePlannerQuery'

interface SelectorParams {
  storages: MaterialStorage[]
  materialType?: MaterialType
}

const selector = ({storages, materialType}: SelectorParams) => {
  const storagesWithMaterial = storages
    .filter(isStorageWithMaterialGuard)
    .filter((storage) => (materialType ? storage.materialType === materialType : true))

  return [...new Set(storagesWithMaterial?.map(({materialId}) => materialId) ?? [])]
}

interface StoredMaterialIdsQueryParams {
  materialType?: MaterialType
}

export const useStoredMaterialIdsQuery = ({materialType}: StoredMaterialIdsQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('materialStorage', [plantCode], {
    select: (data) => selector({storages: data, materialType})
  })
}
