import {GuidedToursProvider} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {usePlannerTours} from '../../../guidedTours/plannerTours'
import {PlannerFeature} from '../../enums'
import {usePermission} from '../../hooks/permissions/usePermission'
import {usePlannerFeature} from '../../hooks/usePlannerFeature'

export const PlannerToursProvider = ({children}: {children: React.ReactNode}) => {
  const tours = usePlannerTours()
  const canSeeCostAvoidance = usePlannerFeature(PlannerFeature.CostAvoidance)
  const canChangeMachinePlan = usePermission('CHANGE_MACHINE_PLAN')

  const isPlannerCostAvoidanceTourAllowed = canSeeCostAvoidance && canChangeMachinePlan

  const enabledTours = {plannerCostAvoidanceTour: isPlannerCostAvoidanceTourAllowed}

  return (
    <GuidedToursProvider tours={tours} enabledTours={enabledTours}>
      {children}
    </GuidedToursProvider>
  )
}
