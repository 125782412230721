import moment, {Moment} from 'moment-timezone'

/*
 * function to get the latest relevant history entry for the given from to range
 * we are checking only to date, because it's possible that the first history entry is outside the range
 * in that case, we should take newest entry available before the range
 */
export const getRelevantHistoryEntry = <T extends {updatedOn?: string; createdOn: string}>(
  // should be sorted in ascending order by updatedOn or createdOn
  historyEntries: T[],
  date: Moment
): T => {
  const relevantHistoryAsc = historyEntries.filter((entry) => {
    // if the asset has no updatedOn date, we use the createdOn date
    const timeStamp = moment.utc(entry.updatedOn ?? entry.createdOn)
    return timeStamp.isBefore(date)
  })
  const relevantEntry = relevantHistoryAsc.at(-1)
  // there always should be at least one entry in the history
  if (!relevantEntry) {
    throw new Error(
      `BUG:No relevant history found for the given date ${date.toISOString()} for entitiy updatedOn ${
        historyEntries[0].updatedOn
      } cratedOn ${historyEntries[0].createdOn}`
    )
  }
  return relevantEntry
}
