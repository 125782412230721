import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Skeleton, Typography} from '@mui/material'
import React from 'react'

export const KPIBoxSkeleton = () => {
  return (
    <CardBox sx={{height: '100%'}}>
      <Box mb={3}>
        <Typography variant="h3">
          <Skeleton variant="text" width={250} />
        </Typography>
      </Box>
      <Box sx={{mb: 3}}>
        <Skeleton variant="rectangular" height="100%" />
      </Box>
    </CardBox>
  )
}
