import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {HistoryStorageWithMaterial} from '@hconnect/common/types'

import {SelectStorageButton} from './SelectStorageButton'
interface SortableStorageButtonProps {
  storage: Pick<HistoryStorageWithMaterial, 'id' | 'name'>
  isSelected: boolean
  isDisabled: boolean
  handleSelectStorage: (id: number) => void
}

export const SortableStorageButton = ({
  storage,
  isSelected,
  isDisabled,
  handleSelectStorage
}: SortableStorageButtonProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: storage.id})

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <SelectStorageButton
        storage={storage}
        isSelected={isSelected}
        isDisabled={isDisabled}
        handleSelectStorage={handleSelectStorage}
      />
    </div>
  )
}
