import {
  formatCurrency,
  formatTons,
  formatMW,
  formatMWh,
  useTranslation
} from '@hconnect/uikit/src/common'
import {useCallback} from 'react'

import {usePlantConfig} from '../hooks/usePlantConfigData'

export type KPIUnit = 'currency' | 'tons' | 'mwh' | 'mw'

export function useKPIValueFormatter() {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const plantConfig = usePlantConfig()

  return useCallback(
    (amount: number, unit: KPIUnit, {digits}: {digits?: number} = {}) => {
      if (unit === 'tons') return formatTons(amount, language, t('common.unit.t'))
      if (unit === 'mwh') return formatMWh(amount, language, t('common.unit.MWh'))
      if (unit === 'mw') return formatMW(amount, language, t('common.unit.MW'))
      return t('common.amountInCurrency', {
        amount: formatCurrency(amount, digits ?? 0, language),
        currency: plantConfig.currency
      })
    },
    [t, language, plantConfig.currency]
  )
}
