import {Box} from '@mui/material'
import {Moment} from 'moment'
import React from 'react'

import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'

interface DatetimeValueChartHoverBarsProps {
  chartWidth: number
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  steps: Moment[]
}

export const DatetimeValueChartHoverBars: React.FC<DatetimeValueChartHoverBarsProps> = ({
  chartWidth,
  xScale,
  yScale,
  steps
}) => {
  const height = yScale(0)

  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: chartWidth,
        height: height
      }}
    >
      <svg height={height} width={chartWidth}>
        {steps.map((datetime, index) =>
          index === steps.length - 1 ? null : (
            <Box
              component="rect"
              key={index}
              x={xScale(datetime.toDate())}
              sx={{
                opacity: 0,
                fill: ({palette}) => palette.primary.light,
                width: xScale(steps[index + 1].toDate()) - xScale(datetime.toDate()),
                height: height,
                '&:hover': {
                  opacity: 0.1
                }
              }}
            />
          )
        )}
      </svg>
    </Box>
  )
}
