import {dataTestId} from '@hconnect/uikit'
import {CardTitle, Alert} from '@hconnect/uikit/src/lib2'
import {AutoAwesomeOutlined, Close, Done} from '@mui/icons-material'
import {
  Button,
  DialogContent,
  Dialog,
  Stack,
  Box,
  Typography,
  CircularProgress
} from '@mui/material'
import moment from 'moment-timezone'
import {useCallback, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'

import {useConfirmDialog} from '../../../../shared/components/providers/ConfirmDialogProvider'
import {useSelectedTimeframe} from '../../../../shared/components/providers/SelectedTimeframeProvider'
import {useDispatchAutofill} from '../../../../shared/hooks/api/optimizer/useDispatchAutofill'
import {useCountScheduleItemsQuery} from '../../../../shared/hooks/api/schedules/useCountScheduleItemsQuery'
import {useCurrentTime} from '../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'

import {AutoFillDialogContent, AutoFillDialogParams} from './AutoFillDialogContent'

export type AutoFillDialogProps = {
  dialogOpen: boolean
  onCloseDialog: () => void
}

export const AutoFillDialog = ({dialogOpen, onCloseDialog}: AutoFillDialogProps) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const {mutate: dispatchAutofill} = useDispatchAutofill()
  const plantCode = useUrlParam('plantCode')
  const {openDialog} = useConfirmDialog()
  const {selectedTimeframe} = useSelectedTimeframe()

  const now = useCurrentTime({timezoneId})

  const defaultValues = useMemo<AutoFillDialogParams>(
    () => ({
      startTime: moment
        .max([now.clone().add(1, 'week'), ...(selectedTimeframe ? [selectedTimeframe[0]] : [])])
        .clone()
        .startOf('day'),
      endTime: now.clone().add(1, 'month').endOf('day')
    }),
    [now, selectedTimeframe]
  )

  const {
    watch,
    trigger,
    control,
    formState: {isValid, isSubmitting},
    handleSubmit
  } = useForm<AutoFillDialogParams>({
    defaultValues
  })

  const startTime = watch('startTime')
  const endTIme = watch('endTime')
  const {data: scheduleItemsCount, isLoading: scheduleIsLoading} = useCountScheduleItemsQuery({
    range: [startTime, endTIme]
  })

  const executeAutofillAction = useMemo(
    () =>
      handleSubmit(({startTime, endTime}) => {
        return dispatchAutofill(
          {
            plantCode,
            config: {
              startTime: startTime.clone().startOf('day').toISOString(),
              endTime: endTime.clone().endOf('day').toISOString()
            }
          },
          {
            onSuccess: onCloseDialog
          }
        )
      }),
    [dispatchAutofill, plantCode, onCloseDialog, handleSubmit]
  )

  const onSubmit = useCallback(() => {
    if (scheduleItemsCount === undefined) {
      throw new Error('Cannot dispatch submit when scheduleItemsCount is not loaded')
    }

    if (scheduleItemsCount <= 0) {
      return executeAutofillAction()
    }

    openDialog({
      title: t('planning.autofill.replaceExistingItems'),
      description: t('planning.autofill.replaceExistingItemsDescription', {
        itemsCount: scheduleItemsCount
      }),
      showCancelButton: true,
      mainAction: {
        color: 'primary',
        text: t('common.proceed'),
        icon: <Done />,
        onAction: executeAutofillAction
      },
      additionalContent: (
        <Alert
          accentColor="error"
          content={<Trans i18nKey="planning.autofill.replaceExistingItemsWarning" />}
        />
      )
    })
  }, [scheduleItemsCount, openDialog, t, executeAutofillAction])

  return (
    <Dialog open={dialogOpen} fullWidth>
      <DialogContent {...dataTestId('autofill_dialog')}>
        <CardTitle variant="h4">{t('planning.autofill.autofillMonth')}</CardTitle>
        <Stack spacing={4}>
          <Typography variant="body1">
            <Trans i18nKey="planning.autofill.autofillDialogDescription" />
          </Typography>
          <Typography variant="body1">
            <Trans i18nKey="planning.autofill.chooseStartAndEndDate" />
          </Typography>
          <AutoFillDialogContent control={control} watch={watch} trigger={trigger} />
          <Stack direction="row" justifyContent="end" sx={{pt: 4}}>
            <Button
              {...dataTestId('autofill_dialog_cancel')}
              color="primary"
              variant="text"
              startIcon={<Close />}
              onClick={onCloseDialog}
            >
              {t('common.cancel')}
            </Button>
            <Box ml={2}>
              <Button
                {...dataTestId('autofill_dialog_autofill')}
                color="primary"
                variant="contained"
                startIcon={
                  scheduleIsLoading ? (
                    <CircularProgress size={14} color="info" />
                  ) : (
                    <AutoAwesomeOutlined />
                  )
                }
                onClick={() => void onSubmit()}
                disabled={!isValid || isSubmitting || scheduleIsLoading}
              >
                {t('planning.autofill.autofill')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
