import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {DateTimePicker} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import type {ScheduleItem} from '../../../../../../shared/interfaces/api'

interface ScheduleItemStartEndSelectProps {
  startOfPlan: Moment
  endOfPlan: Moment
  updateScheduleItem: (item: ScheduleItem) => void
  scheduleItem: ScheduleItem
}

export const ScheduleItemStartEndSelect: React.FC<ScheduleItemStartEndSelectProps> = ({
  scheduleItem,
  updateScheduleItem,
  startOfPlan,
  endOfPlan
}) => {
  const {t} = useTranslation()

  const {timezone_id: timezoneId} = usePlantConfig()
  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})

  const currentStart: Moment = moment.utc(scheduleItem.start).tz(timezoneId)
  const currentEnd: Moment = moment.utc(scheduleItem.end).tz(timezoneId)

  const handleDateChanges = useCallback(
    (value: Moment | null, type: 'start' | 'end'): void => {
      if (value === null) return

      const newStart: Moment = type === 'start' ? moment.utc(value) : currentStart
      let newEnd: Moment = type === 'end' ? moment.utc(value) : currentEnd

      if (newStart.isSameOrAfter(newEnd)) {
        newEnd = moment.utc(newStart).clone().add(15, 'minutes')
      }
      const updatedScheduleItem: ScheduleItem = {
        ...scheduleItem,
        start: newStart.toISOString(),
        end: newEnd.toISOString()
      }
      updateScheduleItem(updatedScheduleItem)
    },
    [currentEnd, currentStart, scheduleItem, updateScheduleItem]
  )

  const isStartEditDisabled = currentStart.isBefore(moment.max([nowRounded, startOfPlan]))
  const isEndEditDisabled = currentEnd.isSameOrBefore(nowRounded) || currentEnd.isAfter(endOfPlan)

  const startMin = moment.max([currentStart.clone().startOf('day'), nowRounded])

  const endMin = moment.max([
    nowRounded.clone().add(15, 'minutes'),
    currentEnd.clone().startOf('day'),
    currentStart.clone().add(15, 'minutes')
  ])

  const onChangeStart = useCallback(
    (value: Moment | null) => handleDateChanges(value, 'start'),
    [handleDateChanges]
  )

  const onChangeEnd = useCallback(
    (value: Moment | null) => handleDateChanges(value, 'end'),
    [handleDateChanges]
  )

  return (
    <Stack spacing={1.5}>
      <DateTimePicker
        value={currentStart}
        minDate={startMin}
        maxDate={endOfPlan}
        dateLabel={t('datePicker.startDate')}
        timeLabel={t('datePicker.startTime')}
        disabled={isStartEditDisabled}
        onChange={onChangeStart}
        timezoneId={timezoneId}
        {...dataTestId('schedule_edit_start_date_time')}
      />
      <DateTimePicker
        value={currentEnd}
        minDate={endMin}
        maxDate={endOfPlan}
        dateLabel={t('datePicker.endDate')}
        timeLabel={t('datePicker.endTime')}
        disabled={isEndEditDisabled}
        onChange={onChangeEnd}
        timezoneId={timezoneId}
        {...dataTestId('schedule_edit_end_date_time')}
      />
    </Stack>
  )
}
