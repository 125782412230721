import {dataTestId} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {LocalShippingOutlined, LocationCityOutlined} from '@mui/icons-material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {Box} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {Material, MaterialSource} from '../../../../../common/types'

type StockCardHeaderProps = {
  title: React.ReactNode
  material: Material | undefined
  canGoToBoughtToVendorPage: boolean
  additionalHeader: React.ReactNode
}

export const StockCardHeader = ({
  title,
  material,
  canGoToBoughtToVendorPage,
  additionalHeader
}: StockCardHeaderProps) => {
  const {t} = useTranslation()

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <CardTitle sx={{mb: 0.5}} {...dataTestId('stock_card_title')}>
            {title}
          </CardTitle>
          {material?.source === MaterialSource.BoughtFromVendor && (
            <Box sx={{display: 'flex', direction: 'row'}}>
              <Box sx={{mr: 1}}>
                <LocalShippingOutlined />
              </Box>
              <Box>{t('stock.boughtFromVendor')}</Box>
            </Box>
          )}
          {material?.source === MaterialSource.ProducedInPlant && (
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Box sx={{mr: 1}}>
                <LocationCityOutlined />
              </Box>
              <Box>{t('stock.producedInPlant')}</Box>
            </Box>
          )}
        </Box>
        {canGoToBoughtToVendorPage && (
          <ArrowForwardIosIcon
            fontSize="small"
            color="secondary"
            {...dataTestId('show_more_icon')}
          />
        )}
      </Box>
      {additionalHeader}
    </Box>
  )
}
