import {useResizableChartWidth} from '../d3chart/utils'

import {StockDevelopmentChartConfigProvider} from './StockDevelopmentChartConfigProvider'
import {
  STOCK_DEVELOPMENT_CHART_DEFAULT_HEIGHT,
  StockDevelopmentChartContent,
  StockDevelopmentChartContentProps
} from './StockDevelopmentChartContent'
import {useStockDevelopmentChartConfigData} from './utils/useStockDevelopmentChartConfigData'

type StaticStockDevelopmentChartProps = Omit<
  StockDevelopmentChartContentProps,
  'chartRef' | 'width'
> & {
  timezoneId: string
  height?: number
}

export const StaticStockDevelopmentChart = (props: StaticStockDevelopmentChartProps) => {
  const [chartRef, width] = useResizableChartWidth()

  const data = useStockDevelopmentChartConfigData({
    stockData: props.stockData,
    listOfDays: props.listOfDays,
    timezoneId: props.timezoneId,
    minTargetLevels: props.minTargetLevels,
    storageCapacity: props.storageCapacity,
    height: props.height || STOCK_DEVELOPMENT_CHART_DEFAULT_HEIGHT,
    width: width
  })

  return (
    <StockDevelopmentChartConfigProvider {...data}>
      <StockDevelopmentChartContent {...props} chartRef={chartRef} width={width} />
    </StockDevelopmentChartConfigProvider>
  )
}
