import {Stack, Box, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'

import {dataTestId as spreadDataTestId} from '../../../common/utils/testingUtils'

export interface HPCommentBodyProps {
  title: string
  content: string | React.ReactNode
  commentBodySx?: SxProps<Theme>
}

export const HPCommentBody: React.FC<HPCommentBodyProps> = ({title, content, commentBodySx}) => {
  return (
    <Stack spacing={1} sx={commentBodySx}>
      <Typography variant="h3" {...spreadDataTestId('comment_body_title')}>
        {title}
      </Typography>
      <Box {...spreadDataTestId('comment_body_content')}>
        {typeof content === 'string' ? <Typography variant="body1">{content}</Typography> : content}
      </Box>
    </Stack>
  )
}
