import moment, {Moment} from 'moment-timezone'

import {mapMaterialOrdersToIncomingMaterial} from '../../../selectors/materialOrders'
import {usePlantConfig} from '../../usePlantConfigData'
import {useUrlParam} from '../../useUrlParam'
import {useUnplannedDeliveriesQuery} from '../materialOrders'
import {usePlannerQuery} from '../usePlannerQuery'

interface IncomingMaterialsQueryParams {
  materialId: number
  timeframe: [Moment, Moment] | undefined
}

export const useIncomingMaterialsQuery = ({
  materialId,
  timeframe
}: IncomingMaterialsQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const {created_at: createdAt, timezone_id: timezoneId} = usePlantConfig()

  const {data: unplannedDeliveries} = useUnplannedDeliveriesQuery({
    materialId,
    timeframe
  })

  const [start, end]: (Moment | undefined)[] = timeframe ?? []
  const startOfToday = moment.utc(createdAt).tz(timezoneId).startOf('day')

  const isEnabled = Boolean(timeframe) && Boolean(unplannedDeliveries)

  return usePlannerQuery(
    'materialOrders',
    [{plantCode, from: start?.toISOString(), to: end?.toISOString(), materialId}],
    {
      enabled: isEnabled,
      select: (materialOrders) => {
        const ordersForMaterial = materialOrders.filter((order) => order.materialId === materialId)

        return unplannedDeliveries
          ? mapMaterialOrdersToIncomingMaterial(
              startOfToday,
              ordersForMaterial,
              unplannedDeliveries
            )
          : undefined
      }
    }
  )
}
