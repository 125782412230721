import {Box, Typography} from '@mui/material'

import {dataTestId} from '@hconnect/uikit'
import {LinearScaleFn} from '../../helpers/scale'
import {toPixel} from '../../helpers/utils'

import {ChartLabel} from './ChartLabel'

type DatetimeValueChartYLabelsProps = {
  ySteps: number[]
  yScale: LinearScaleFn
  yTitle?: string
  width?: number
  yStepsFormatter?: (step: string | number, index: number) => string
}

export const DatetimeValueChartYLabels = ({
  ySteps,
  yScale,
  yTitle,
  width,
  yStepsFormatter
}: DatetimeValueChartYLabelsProps) => {
  return (
    <Box
      sx={{position: 'relative', width: width ? toPixel(width) : undefined}}
      {...dataTestId('y_labels')}
    >
      {ySteps.map((step, index) => (
        <ChartLabel
          key={`${step}_${index}_y_label`}
          label={yStepsFormatter ? yStepsFormatter(step, index) : String(step)}
          sx={{top: yScale(step), textAlign: 'end', transform: 'translateY(-50%)', width: 1}}
        />
      ))}
      {yTitle && (
        <Box sx={{position: 'absolute', height: '100%', display: 'flex', alignItems: 'center'}}>
          <Box>
            <Typography
              variant="caption"
              sx={{transform: 'rotate(180deg)', writingMode: 'vertical-rl'}}
              {...dataTestId('y_title')}
            >
              {yTitle}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
