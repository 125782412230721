import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const usePeakLoadWindowsQuery = (
  timeFrame: MomentRange,
  {enabled, useErrorBoundary}: {enabled?: boolean; useErrorBoundary?: boolean} = {}
) => {
  const plantCode = useUrlParam('plantCode')
  const [from, to] = timeFrame
  return usePlannerQuery(
    'peakLoadWindows',
    [{plantCode, from: from.toISOString(), to: to.toISOString()}],
    {useErrorBoundary: useErrorBoundary ?? true, enabled: enabled ?? true}
  )
}
