import {groupBy} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {ScheduleItem, LatestPeakLoadWindow, OperationModeResponse} from '../interfaces/api'
import type {
  Electricity,
  ElectricityResponse,
  PowerConsumption,
  EnergyConsumptionResponse
} from '../interfaces/api/electricity'
import {PeakLoadWindow} from '../selectors'

import {splitToIntervals} from './time'

export function sortByStart<T extends {start: string | Date | Moment}>(arr: T[]) {
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  return arr.sort((a, b) => a.start.toString().localeCompare(b.start.toString()))
}

/**
 * @param powerConsumption should include baseLoad
 */

function checkPeakLoadWindowViolated(plw: PeakLoadWindow, powerConsumption: PowerConsumption[]) {
  const intervalViolatesPlw = powerConsumption.some((entry) => {
    const isIntervalInPeakLoadWindow = entry.dateTimeUTC.isBetween(
      plw.start,
      plw.end,
      undefined,
      '[]'
    )
    const isPowerViolated = entry.power > plw.maxPower
    return isIntervalInPeakLoadWindow && isPowerViolated
  })
  return intervalViolatesPlw
}

interface GetPeakLoadWindowsInput {
  peakLoadWindows: LatestPeakLoadWindow[]
  powerConsumption: PowerConsumption[]
}
export const getPeakLoadWindows = ({
  peakLoadWindows,
  powerConsumption
}: GetPeakLoadWindowsInput): PeakLoadWindow[] => {
  const plwDtos = peakLoadWindows
  const plws = plwDtos.map((plwDto) => ({
    maxPower: plwDto.max_power,
    start: moment.utc(plwDto.start),
    end: moment.utc(plwDto.end),
    violated: false
  }))

  return plws.map((plw) => ({
    ...plw,
    violated: checkPeakLoadWindowViolated(plw, powerConsumption)
  }))
}

export interface PowerDiffItem {
  hourStart: Moment
  mwDiff: number | null
}

type GetHourlyPowerDiff = (params: {
  hours: Moment[]
  plannedQuarterlyPower: PowerConsumption[]
  purchasedQuarterlyPower: PowerConsumption[]
}) => PowerDiffItem[]

const roundNumber = (
  num: number,
  decimals: number,
  mathClass: (x: number) => number = Math.round
): number => {
  if (decimals <= 0) return mathClass(num)
  const decimalCalc: number = 10 ** decimals
  return mathClass(num * decimalCalc) / decimalCalc
}

/**
 * function to calculate hourly deltas between planned and purchased
 * baseload should be inlcluded in both planned and purchased
 */

export const getHourlyPowerDiff: GetHourlyPowerDiff = ({
  hours,
  plannedQuarterlyPower,
  purchasedQuarterlyPower
}) => {
  const plannedMWhByHour = groupBy(plannedQuarterlyPower, (item) =>
    item.dateTimeUTC.clone().startOf('hour').toISOString()
  )
  const purchasedMWhByHour = groupBy(purchasedQuarterlyPower, (item) =>
    item.dateTimeUTC.clone().startOf('hour').toISOString()
  )
  return hours.map((hourStart): PowerDiffItem => {
    const key = hourStart.toISOString()
    const purchased = purchasedMWhByHour[key]
    const planned = plannedMWhByHour[key]

    if (!purchased || !planned) return {hourStart, mwDiff: null}
    const totalProjectedMws =
      planned.reduce((total, item) => total + item.power, 0) / planned.length
    const totalPurchasedMws =
      purchased.reduce((total, item) => total + item.power, 0) / purchased.length

    const mwDiff = roundNumber(totalPurchasedMws - totalProjectedMws, 1)
    return {hourStart, mwDiff}
  })
}

/**
 * Function used to convert BE response data to creating moment.utc objects which
 * are used on FE, and keeping original isoString, so there is no need to convert it back and forth
 * @param energyConsumptionResponse
 * @returns
 */
export const mapEnergyConsumptionResToEnergyConsumption = (
  energyConsumptionResponse: EnergyConsumptionResponse[]
): PowerConsumption[] =>
  energyConsumptionResponse.map(({datetime, power}) => ({
    dateTimeIso: datetime,
    power,
    dateTimeUTC: moment.utc(datetime)
  }))

/** function to split submitted electricity by deadline */
export const splitElectricityByDeadline = (
  submitted: Electricity['submitted'],
  nextPurchasingDeadline: string,
  timezoneId: string
) => {
  const startOfSubmission = moment
    .utc(nextPurchasingDeadline)
    .clone()
    .tz(timezoneId)
    .add(1, 'day')
    .startOf('day')
  return submitted.reduce<Pick<Electricity, 'purchased' | 'upForPurchasing'>>(
    (acc, item) => {
      if (item.dateTimeUTC.isBefore(startOfSubmission)) {
        acc.purchased.push(item)
      } else {
        acc.upForPurchasing.push(item)
      }
      return acc
    },
    {purchased: [], upForPurchasing: []}
  )
}

function ensureListSpacedIn15MinInterval(list: PowerConsumption[]) {
  const returnList: PowerConsumption[] = []
  /// Ensure that the list is in 15 min intervals starting from the time of first element
  for (let i = 0; i < list.length; i++) {
    if (i === list.length - 1) {
      returnList.push(list[i])
      break
    }
    for (
      let curTime = list[i].dateTimeUTC;
      list[i + 1].dateTimeUTC.isAfter(curTime);
      curTime = curTime.clone().add(15, 'minutes')
    ) {
      const dateTimeIso = curTime.toISOString()
      returnList.push(
        dateTimeIso === list[i].dateTimeIso
          ? list[i]
          : {
              dateTimeUTC: curTime,
              dateTimeIso: dateTimeIso,
              power: list[i].power
            }
      )
    }
  }
  return returnList
}

export const mapLoadCurvesResToElectricityData = (
  data: ElectricityResponse,
  timezoneId: string
): Electricity => {
  const planned = mapEnergyConsumptionResToEnergyConsumption(data.planned)
  const submitted = mapEnergyConsumptionResToEnergyConsumption(data.submitted)
  const nextPurchasingDeadline = data.nextPurchasingDeadline
  const lastSubmitted = data.lastSubmitted
  const {purchased, upForPurchasing} = splitElectricityByDeadline(
    submitted,
    nextPurchasingDeadline,
    timezoneId
  )
  return {
    planned: ensureListSpacedIn15MinInterval(planned),
    submitted: ensureListSpacedIn15MinInterval(submitted),
    purchased: ensureListSpacedIn15MinInterval(purchased),
    upForPurchasing: ensureListSpacedIn15MinInterval(upForPurchasing),
    nextPurchasingDeadline,
    lastSubmitted
  }
}

export const getScheduleItemPowerConsumptionQuaterlyDict = (
  scheduleItem: Pick<ScheduleItem, 'start' | 'end'>,
  operationMode: OperationModeResponse
) => {
  const {start, end} = scheduleItem
  const powerConsumption = operationMode.powerConsumption
  const powerConsumptionIntervals = splitToIntervals(start, end, 15).map(({start, end}) => ({
    start,
    end,
    power: powerConsumption ?? 0
  }))

  return Object.fromEntries(powerConsumptionIntervals.map((entry) => [entry.start, entry.power]))
}
