import Ajv, {ValidateFunction} from 'ajv'
import {AxiosResponse} from 'axios'

import {schemas} from '../../schemas'
import {ClientConfig} from '../../types'

export const createJsonValidator = (schemaId: string): ValidateFunction => {
  const schema = schemas[schemaId]
  if (!schema) {
    throw new Error(`No schema with ID ${schemaId}`)
  }
  const ajv = new Ajv({allErrors: true, strict: 'log'})
  return ajv.compile(schema)
}

export const validateJsonResponseInterceptor =
  (clientConfig: Partial<ClientConfig>): any =>
  (response: any): any => {
    const {config, data} = response
    const {onValidateJsonError} = clientConfig
    let schemaId = ''
    let url = ''

    if (config) {
      const {schemaId: SCHEMA_ID, url: URL} = config
      schemaId = SCHEMA_ID
      url = URL
    }

    if (!schemaId) {
      return response
    }

    if (!data) {
      console.debug('No data in response.')
      return response
    }

    const validateJSON = createJsonValidator(schemaId)

    console.debug(`Validating response body against our JSON schema: ${schemaId}...`)

    if (!validateJSON(data)) {
      const {errors} = validateJSON

      console.error(`Invalid JSON: ${JSON.stringify(errors)}, schemaID: ${schemaId}, url: ${url}`)
      if (onValidateJsonError) {
        if (errors) {
          onValidateJsonError(errors, response as AxiosResponse)
        }
      }

      throw new Error(`Invalid JSON for ${schemaId}`)
    }

    return response
  }
