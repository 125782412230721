import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Moment} from 'moment-timezone'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {getHcemQueryData, invalidateHcemQuery, setHcemQueryData} from '../../../api/queryHelpers'
import {PlannerQueryClient} from '../../../components/providers/QueryProvider'
import {useSelectedScheduleItemId} from '../../../components/providers/SelectedScheduleItemProvider'
import {useTrackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {
  ScheduleItem,
  Schedule,
  MutationError,
  UpdateScheduleItemResponse
} from '../../../interfaces/api'
import {doesOverlappingItemExist, getDefaultSelectedSilos} from '../../../schedule'
import {AssetWithOperationMode} from '../../../selectors/assets'
import {
  getPeakLoadWindows,
  getScheduleItemPowerConsumptionQuaterlyDict
} from '../../../selectors/electricity'
import {useUrlParam} from '../../useUrlParam'
import {useHistoryOperationModesByIdQuery} from '../assets'
import {usePeakLoadWindowsQuery} from '../dataScience'
import {useElectricityQuery} from '../kpi'
import {useMaterialsRecipesById} from '../materials'
import {useHistoryStoragesWithMaterialQuery} from '../materialStorage'

type ScheduleContext = {previousState: Schedule}

interface UpdateScheduleItemParams {
  startOfPlan: Moment
  endOfPlan: Moment
  assetDataByScheduleItemId: Record<string, AssetWithOperationMode> | undefined
}

export const useUpdateScheduleItem = ({
  startOfPlan,
  endOfPlan,
  assetDataByScheduleItemId
}: UpdateScheduleItemParams) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const plantCode = useUrlParam('plantCode')
  const trackPlannerEvent = useTrackPlannerEvent()

  const {data: peakLoadWindows} = usePeakLoadWindowsQuery([startOfPlan, endOfPlan], {
    useErrorBoundary: false
  })

  const {data: electricity} = useElectricityQuery({
    timeFrame: [startOfPlan, endOfPlan],
    useErrorBoundary: false
  })

  const {data: operationModesById} = useHistoryOperationModesByIdQuery({
    timeFrame: [startOfPlan, endOfPlan]
  })
  const {data: recipesById} = useMaterialsRecipesById({useErrorBoundary: false})
  const {data: storagesWithMaterial} = useHistoryStoragesWithMaterialQuery({
    timeFrame: [startOfPlan, endOfPlan],
    useErrorBoundary: false
  })

  const queryVariables = useMemo(
    () => ({plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}),
    [plantCode, startOfPlan, endOfPlan]
  )

  const {setScheduleItemId} = useSelectedScheduleItemId()

  const {mutate, isLoading: isUpdateScheduleItemLoading} = useMutation<
    UpdateScheduleItemResponse,
    AxiosError<MutationError<ScheduleItem>>,
    Parameters<typeof mutations.updateScheduleItem>[0],
    ScheduleContext
  >({
    mutationFn: mutations.updateScheduleItem,
    onMutate: async (variables) => {
      await PlannerQueryClient.cancelQueries({queryKey: ['schedule']})
      const previousState = getHcemQueryData('schedule', [queryVariables])
      const id = variables.scheduleItemId
      if (previousState) {
        const updatedSchedule: Schedule = {
          ...previousState,
          schedules: {
            ...previousState.schedules,
            [id]: {
              ...previousState.schedules[id],
              ...variables.scheduleItemDTO
            }
          }
        }
        PlannerQueryClient.setQueryData(['schedule', queryVariables], updatedSchedule)
      }
      return {previousState} as ScheduleContext
    },
    onError: (error, _, context) => {
      notify('error', error.response?.data.detail)
      if (context) {
        setHcemQueryData('schedule', [queryVariables], context.previousState)
      }
    },
    onSuccess: ({splitItemId}) => {
      invalidateHcemQuery('costAvoidanceForRange')
      if (splitItemId) {
        notify('success', t('success.scheduleItemSplit'))
        // when splitting we need to update selected schedule item id to replace active schedule item in edit dialog
        setScheduleItemId(splitItemId)
      }
    },
    onSettled: (data, _, variables) => {
      trackPlannerEvent({name: 'onEditScheduleItem'})
      invalidateHcemQuery('schedule')
      invalidateHcemQuery('electricity', {plantCode: variables.plantCode})
      invalidateHcemQuery('stockDevelopment')
    }
  })

  const updateScheduleItem = useMemo(
    () => (scheduleItem: ScheduleItem) => {
      const scheduleQueryKey = ['schedule', queryVariables]
      const schedule = PlannerQueryClient.getQueryData<Schedule>(scheduleQueryKey)
      if (
        !electricity ||
        !schedule ||
        !recipesById ||
        !storagesWithMaterial ||
        !assetDataByScheduleItemId ||
        !operationModesById ||
        !peakLoadWindows
      )
        return
      const prevScheduleItem = schedule.schedules[scheduleItem.id]

      // Since BE doesn't track operation mode change to selected silo relation we need to check it ourselves and reset selected silos on each operation mode change
      const activeOperationMode = operationModesById[scheduleItem.assetOperationModeId]
      if (prevScheduleItem.assetOperationModeId !== scheduleItem.assetOperationModeId) {
        scheduleItem.selectedSilos = getDefaultSelectedSilos(
          activeOperationMode,
          recipesById,
          storagesWithMaterial
        )
      }
      const prevTotalPowerConsumption = electricity.planned

      const powerConsumptionForPrevItemDict = getScheduleItemPowerConsumptionQuaterlyDict(
        prevScheduleItem,
        assetDataByScheduleItemId[prevScheduleItem.id].operationMode
      )

      const powerConsumptionForUpdatedItemDict = getScheduleItemPowerConsumptionQuaterlyDict(
        scheduleItem,
        activeOperationMode
      )
      const adjustedPowerConsumption = prevTotalPowerConsumption.map((energyConsumption) => {
        const prevItemPower = powerConsumptionForPrevItemDict[energyConsumption.dateTimeIso] || 0
        const updatedItemPower =
          powerConsumptionForUpdatedItemDict[energyConsumption.dateTimeIso] || 0
        const diff = updatedItemPower - prevItemPower
        return {...energyConsumption, power: energyConsumption.power + diff}
      })
      const adjustedPeakLoadWindows = getPeakLoadWindows({
        peakLoadWindows,
        powerConsumption: adjustedPowerConsumption
      })
      const hasPeakLoadWindowsViolated = adjustedPeakLoadWindows.some((plw) => plw.violated)

      const hasOverlap = doesOverlappingItemExist(schedule.schedules, scheduleItem, scheduleItem.id)
      if (hasOverlap) {
        notify('error', t('error.overlappingItemExists'))
        invalidateHcemQuery('schedule', queryVariables)
        return
      }
      if (hasPeakLoadWindowsViolated) {
        notify('error', t('error.planViolatesPeakLoadWindow'))
        invalidateHcemQuery('schedule', queryVariables)
        return
      }

      mutate({
        plantCode,
        scheduleId: schedule.scheduleId,
        scheduleItemDTO: scheduleItem,
        scheduleItemId: scheduleItem.id
      })
    },
    [
      electricity,
      plantCode,
      queryVariables,
      assetDataByScheduleItemId,
      operationModesById,
      recipesById,
      storagesWithMaterial,
      peakLoadWindows,
      mutate,
      notify,
      t
    ]
  )

  return {updateScheduleItem, isUpdateScheduleItemLoading}
}
