import {Status} from '@hconnect/common/types'
import moment, {Moment} from 'moment-timezone'

import {AssetType, OperationModeType} from '../../../shared/enums'
import type {AssetResponse, OperationModeResponse} from '../../../shared/interfaces/api'

const now = moment.utc('2023-01-16T17:47:33Z')

export const createOperationMode = ({
  id,
  power,
  type,
  throughput,
  recipeId,
  status = Status.Created
}: {
  id: number
  power: number
  type: OperationModeType
  throughput?: number
  recipeId?: number
  status?: Status.Created | Status.Edited | Status.Deleted
}): OperationModeResponse => {
  return {
    id,
    name: `operation mode with id ${id}`,
    type,
    powerConsumption: power,
    throughput: throughput ?? null,
    recipeId: recipeId ?? null,
    status: status,
    minimumRuntime: 0,
    isOptimized: false
  }
}
export const createAsset = (
  {
    id,
    name,
    type,
    isOptimized = false,
    status = Status.Created,
    createdOn = now,
    updatedOn
  }: {
    id: number
    name: string
    type: AssetType
    isOptimized?: boolean
    status?: Status.Created | Status.Edited | Status.Deleted
    createdOn?: Moment
    updatedOn?: Moment
  },
  operationModes: OperationModeResponse[]
): AssetResponse => {
  return {
    id,
    type,
    name,
    status,
    createdOn: createdOn.toISOString(),
    ...(updatedOn && {updatedOn: updatedOn.toISOString()}),
    createdBy: 'Test user',
    minStoptime: 0,
    isOptimized,
    operationModes
  }
}

const createdOn = moment.utc('2018-12-15T22:00:00.000Z')

const historyAssets: Record<string, AssetResponse[]> = {
  '1': [
    createAsset({id: 1, name: 'ZM6', type: AssetType.CementMill, isOptimized: true, createdOn}, [
      createOperationMode({
        id: 13,
        power: 2,
        type: OperationModeType.Production,
        throughput: 100,
        recipeId: 1
      }),
      createOperationMode({
        id: 17,
        power: 4,
        type: OperationModeType.Production,
        throughput: 50,
        recipeId: 6
      }),
      createOperationMode({
        id: 18,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 78,
        recipeId: 1
      }),
      createOperationMode({
        id: 19,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 52,
        recipeId: 7
      }),
      createOperationMode({
        id: 20,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 68,
        recipeId: 8
      }),
      createOperationMode({
        id: 21,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 107,
        recipeId: 3
      }),
      createOperationMode({
        id: 22,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 52,
        recipeId: 9
      }),
      createOperationMode({
        id: 23,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 77,
        recipeId: 10
      }),
      createOperationMode({
        id: 24,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 83,
        recipeId: 4
      }),
      createOperationMode({
        id: 25,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 78,
        recipeId: 5
      }),
      createOperationMode({
        id: 266,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 78,
        recipeId: 52345294
      }),
      createOperationMode({id: 76, power: 0, type: OperationModeType.Maintenance})
    ])
  ],
  '31': [
    createAsset({id: 31, name: 'Packerei', type: AssetType.Other, createdOn}, [
      createOperationMode({
        id: 55,
        power: 0.3,
        type: OperationModeType.Production
      }),
      createOperationMode({
        id: 65,
        power: 0,
        type: OperationModeType.Maintenance
      })
    ])
  ],
  '39': [
    createAsset({id: 39, name: 'ZM6-2', type: AssetType.CementMill, isOptimized: true, createdOn}, [
      createOperationMode({
        id: 170,
        power: 4,
        type: OperationModeType.Production,
        throughput: 50,
        recipeId: 6
      }),
      createOperationMode({
        id: 180,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 78,
        recipeId: 1
      }),
      createOperationMode({
        id: 190,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 52,
        recipeId: 7
      }),
      createOperationMode({
        id: 200,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 68,
        recipeId: 8
      }),
      createOperationMode({
        id: 210,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 107,
        recipeId: 3
      }),
      createOperationMode({
        id: 220,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 52,
        recipeId: 9
      }),
      createOperationMode({
        id: 230,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 77,
        recipeId: 10
      }),
      createOperationMode({
        id: 240,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 83,
        recipeId: 4
      }),
      createOperationMode({
        id: 250,
        power: 2.8,
        type: OperationModeType.Production,
        throughput: 78,
        recipeId: 5
      }),
      createOperationMode({id: 760, power: 0, type: OperationModeType.Maintenance})
    ])
  ],
  '30': [
    createAsset({id: 30, name: 'BPG-Mühle', type: AssetType.Other, createdOn}, [
      createOperationMode({id: 52, power: 1, type: OperationModeType.Maintenance}), // previously production
      createOperationMode({id: 64, power: 0, type: OperationModeType.Maintenance})
    ])
  ],
  '29': [
    createAsset({id: 29, name: 'Kohlenmühle', type: AssetType.Other, createdOn}, [
      createOperationMode({id: 51, power: 1.3, type: OperationModeType.Maintenance}), // previous production
      createOperationMode({id: 63, power: 0, type: OperationModeType.Maintenance})
    ])
  ],
  '42': [
    createAsset({id: 42, name: 'RM1', type: AssetType.RawMill, isOptimized: true, createdOn}, [
      createOperationMode({
        id: 36,
        power: 3.9,
        type: OperationModeType.Production,
        throughput: 214,
        recipeId: 13
      }),
      createOperationMode({
        id: 37,
        power: 3.9,
        type: OperationModeType.Production,
        throughput: 171,
        recipeId: 12
      }),
      createOperationMode({
        id: 68,
        power: 0,
        type: OperationModeType.Maintenance,
        throughput: 0,
        recipeId: 0
      })
    ])
  ],
  '26': [
    createAsset({id: 26, name: 'Crusher', type: AssetType.Other, createdOn}, [
      createOperationMode({
        id: 50,
        power: 1.7,
        type: OperationModeType.Maintenance
      }),
      createOperationMode({
        id: 60,
        power: 0,
        type: OperationModeType.Maintenance
      })
    ])
  ],
  '32': [
    createAsset({id: 32, name: 'Schottertr.', type: AssetType.Other, createdOn}, [
      createOperationMode({id: 54, power: 0.4, type: OperationModeType.Maintenance}),
      createOperationMode({id: 66, power: 0, type: OperationModeType.Maintenance})
    ])
  ],
  '40': [
    createAsset({id: 40, name: 'ZM7', type: AssetType.CementMill, isOptimized: true, createdOn}, [
      createOperationMode({
        id: 26,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 40,
        recipeId: 6
      }),
      createOperationMode({
        id: 27,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 90,
        recipeId: 1
      }),
      createOperationMode({
        id: 28,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 83,
        recipeId: 7
      }),
      createOperationMode({
        id: 29,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 87,
        recipeId: 8
      }),
      createOperationMode({
        id: 30,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 130,
        recipeId: 3
      }),
      createOperationMode({
        id: 31,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 50,
        recipeId: 9
      }),
      createOperationMode({
        id: 32,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 90,
        recipeId: 11
      }),
      createOperationMode({
        id: 33,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 106,
        recipeId: 10
      }),
      createOperationMode({
        id: 34,
        power: 1.9,
        type: OperationModeType.Production,
        throughput: 90,
        recipeId: 5
      }),
      createOperationMode({id: 77, power: 0, type: OperationModeType.Maintenance})
    ])
  ],
  '27': [
    createAsset({id: 27, name: 'WT01', type: AssetType.RotaryKiln, createdOn}, [
      createOperationMode({
        id: 53,
        power: 1,
        type: OperationModeType.Production,
        throughput: -100,
        recipeId: 13
      }),
      createOperationMode({
        id: 902,
        power: 5.2,
        type: OperationModeType.Production,
        throughput: -50,
        recipeId: 12
      }),
      createOperationMode({
        id: 903,
        power: 4,
        type: OperationModeType.Production,
        throughput: 40,
        recipeId: 14
      }),
      createOperationMode({id: 61, power: 0, type: OperationModeType.Maintenance})
    ])
  ],
  '43': [
    createAsset({id: 43, name: 'RM2', type: AssetType.RawMill, createdOn}, [
      createOperationMode({
        id: 38,
        power: 3.9,
        type: OperationModeType.Production,
        throughput: 209,
        recipeId: 13
      }),
      createOperationMode({
        id: 69,
        power: 0,
        type: OperationModeType.Maintenance
      })
    ])
  ],
  '38': [
    createAsset({id: 38, name: 'ZM5', type: AssetType.CementMill, createdOn}, [
      createOperationMode({
        id: 1300,
        power: 2,
        type: OperationModeType.Production,
        throughput: 100,
        recipeId: 1
      }),
      createOperationMode({
        id: 14,
        power: 2.5,
        type: OperationModeType.Production,
        throughput: 112,
        recipeId: 3
      }),
      createOperationMode({
        id: 15,
        power: 2.5,
        type: OperationModeType.Production,
        throughput: 87,
        recipeId: 4
      }),
      createOperationMode({
        id: 16,
        power: 2.5,
        type: OperationModeType.Production,
        throughput: 80,
        recipeId: 5
      }),
      createOperationMode({
        id: 75,
        power: 0,
        type: OperationModeType.Maintenance
      })
    ])
  ],
  '909': [
    createAsset({id: 909, name: 'BaseLoad', type: AssetType.BaseLoad, createdOn}, [
      createOperationMode({
        id: 1900,
        power: 2.5,
        type: OperationModeType.Production
      }),
      createOperationMode({
        id: 1901,
        power: 0,
        type: OperationModeType.Maintenance
      })
    ])
  ]
}

// eslint-disable-next-line import/no-default-export
export default historyAssets
