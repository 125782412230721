import {useMemo} from 'react'

import {usePlannerLocalStorageSettings} from '../components/providers/LocalStorageSettingsProvider'
import type {PlannerLocalStorageSettingsSchema} from '../components/providers/LocalStorageSettingsProvider'
import {PlannerLSSettingsKeys} from '../enums'

export const usePlannerUISettings = <K extends PlannerLSSettingsKeys>(
  key: K,
  initial: PlannerLocalStorageSettingsSchema[K]
) => {
  const {getSetting, setSetting} = usePlannerLocalStorageSettings()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedInitial = useMemo(() => initial, [])

  const getSettingsBySchemaKey = useMemo(() => getSetting(key), [key, getSetting])
  const setSettingsBySchemaKey = useMemo(() => setSetting(key), [key, setSetting])

  return useMemo<{
    settings: PlannerLocalStorageSettingsSchema[K]
    updateSettings: (settings: PlannerLocalStorageSettingsSchema[K]) => void
  }>(() => {
    const settingsInStorage = getSettingsBySchemaKey()
    if (!settingsInStorage) {
      setSettingsBySchemaKey(memoizedInitial)
    }
    const settings = settingsInStorage || memoizedInitial

    return {
      settings,
      updateSettings: setSettingsBySchemaKey
    }
  }, [getSettingsBySchemaKey, setSettingsBySchemaKey, memoizedInitial])
}
