import moment, {Moment} from 'moment-timezone'

import {PlannerFeature} from '../../../enums'
import {getPlannedIncomingMaterialHourly} from '../../../selectors/materialOrders'
import {usePlannerFeature} from '../../usePlannerFeature'
import {usePlantConfig} from '../../usePlantConfigData'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface MaterialOrdersQueryParams {
  materialId: number
  timeframe: [Moment, Moment] | undefined
}

export const usePlannedIncomingMaterialHourlyQuery = ({
  materialId,
  timeframe
}: MaterialOrdersQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const {created_at: planCreatedDate, timezone_id: timezoneId} = usePlantConfig()
  const startOfToday = moment.utc(planCreatedDate).tz(timezoneId).startOf('day')
  const [start, end]: (Moment | undefined)[] = timeframe ?? []

  const isRawMaterialsPlanningEnabled = usePlannerFeature(PlannerFeature.RawMaterialsPlanning)
  const isEnabled = Boolean(timeframe) && isRawMaterialsPlanningEnabled

  return usePlannerQuery(
    'materialOrders',
    [{plantCode, from: start?.toISOString(), to: end?.toISOString(), materialId}],
    {
      enabled: isEnabled,
      select: (orders) =>
        getPlannedIncomingMaterialHourly({
          startOfToday,
          orders
        })
    }
  )
}
