import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Box, Dialog, DialogContent, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedMaterialOrder} from '../../../../shared/components/providers/SelectedMaterialOrderProvider'
import {useSelectedTimeframe} from '../../../../shared/components/providers/SelectedTimeframeProvider'
import {
  useMaterialOrdersCommentsByOrderId,
  useMaterialOrderQuery,
  useScheduleAgreementsByVendorQuery,
  useMaterialsById
} from '../../../../shared/hooks/api'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'

import {CreateEditMaterialOrderDialogContent} from './CreateEditMaterialOrderDialogContent'

export const CreateEditMaterialOrderDialog: React.FC = () => {
  const {t} = useTranslation()
  const materialIdParam = useUrlParam('materialId')
  const materialId = Number(materialIdParam)
  const {data: materialsById} = useMaterialsById()
  const {data: scheduleAgreements} = useScheduleAgreementsByVendorQuery()
  const {selectedMaterialOrder} = useSelectedMaterialOrder()
  const {selectedTimeframe} = useSelectedTimeframe()

  const {data: currentMaterialOrder} = useMaterialOrderQuery({
    materialId,
    materialOrderId: selectedMaterialOrder?.id,
    timeframe: selectedTimeframe
  })

  const {data: materialOrdersCommentsByOrderId} = useMaterialOrdersCommentsByOrderId()

  if (!materialsById) {
    throw new Error('MaterialsById query should be loaded before rendering this component')
  }
  return (
    <Dialog
      open={Boolean(selectedMaterialOrder)}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return
        }
      }}
    >
      <DialogContent sx={{width: ({spacing}) => spacing(44)}}>
        {selectedMaterialOrder && scheduleAgreements && (
          <>
            <Box sx={{mb: 3}}>
              <CardTitle sx={{mb: 0}}>
                {t(
                  selectedMaterialOrder.id !== undefined
                    ? 'stock.materialOrders.editMaterialOrder'
                    : 'stock.materialOrders.newMaterialOrder'
                )}
              </CardTitle>
              <Typography variant="caption">{materialsById[materialId].name}</Typography>
            </Box>
            <CreateEditMaterialOrderDialogContent
              materialOrder={currentMaterialOrder}
              scheduleAgreements={scheduleAgreements}
              commentsByOrderId={materialOrdersCommentsByOrderId}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
