import {useCurrentStoragesWithMaterialQuery} from '../materialStorage'

export const useIsMaterialUnmeasured = (materialId: number | undefined) => {
  const {data: storagesWithMaterial} = useCurrentStoragesWithMaterialQuery()
  if (storagesWithMaterial === undefined || materialId === undefined) {
    return {isLoading: true}
  }
  const storagesWithThisMaterial = storagesWithMaterial.filter(
    (storage) => storage.materialId === materialId
  )
  if (storagesWithThisMaterial.length > 0) {
    const unmeasuredStorages = storagesWithThisMaterial.filter((storage) => !storage.isMeasured)
    if (unmeasuredStorages.length > 1) {
      throw new Error(`Multiple unmeasured storages found for materialId ${materialId}`)
    }
    return {isLoading: false, data: unmeasuredStorages.length === 1}
  }
  return {isLoading: false, data: false}
}
