import {MaterialWithRecipes, MaterialsHistory} from '@hconnect/common/types'

import materialsWithRecipesRaw from './materialsWithRecipes.json'

const materialsWithRecipes = materialsWithRecipesRaw as MaterialWithRecipes[]

// for testing purpose we need only 1 snapshot of a material which is available incurrent response
export const materialsHistory: MaterialsHistory = Object.fromEntries(
  materialsWithRecipes.map((material) => {
    return [material.id, [material]]
  })
)
