import {getAppStage} from '@hconnect/common/appStages'

import {LoginFlowState, PersistedToken} from '../types'

const defaultStorageKey = `HC-${process.env.REACT_APP_CLIENT_NAME}-${getAppStage()}`

function makeTokenReadable(accessToken?: string) {
  if (accessToken) {
    return `...${accessToken.slice(-10)}`
  }

  return 'null'
}

export class BrowserLoginStorage {
  constructor(storageKey: string = defaultStorageKey) {
    this.storageKey = storageKey
  }

  storageKey: string

  getToken() {
    const tokenInfo = localStorage.getItem(this.storageKey)
    return tokenInfo ? JSON.parse(tokenInfo) : {}
  }

  setToken(token: PersistedToken): void {
    if (!token.accessToken) {
      console.warn('Got invalid token info to store (missing field access_token)! Ignoring!')
      return
    }

    const oldToken = this.getToken()

    if (oldToken.accessToken === token.accessToken) {
      console.log('Identity: Got identical access token from identity server!')
    } else {
      console.log(
        `Identity: Got updated access token: ${makeTokenReadable(
          oldToken.accessToken as string
        )} => ${makeTokenReadable(token.accessToken)}`
      )
      localStorage.setItem(this.storageKey, JSON.stringify(token))
    }
  }

  resetToken(): void {
    localStorage.removeItem(this.storageKey)
  }

  setFlow(flowData: LoginFlowState): void {
    localStorage.setItem(`${this.storageKey}-loginFlow`, JSON.stringify(flowData))
  }

  getFlow(): LoginFlowState {
    const flowData = localStorage.getItem(`${this.storageKey}-loginFlow`)
    return flowData ? JSON.parse(flowData) : {}
  }

  resetFlow(): void {
    // Remove our own fields which are just use temporary during
    // exchange with the authentication system.
    localStorage.removeItem(`${this.storageKey}-loginFlow`)
  }

  clear(): void {
    // Remove our own fields which are just use temporary during
    // exchange with the authentication system.
    sessionStorage.clear()
  }
}
