import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'

import {handleConfigurationError} from '../common'
import {MutationError} from '../interfaces/api'

export const usePlannerErrorHandler = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return (error: AxiosError<MutationError<unknown>>) =>
    handleConfigurationError<unknown>(error, raiseError, notify)
}
