import {useEffect, useState} from 'react'

import {invalidateHcemQuery} from '../api/queryHelpers'
import {AutofillStatus} from '../enums'

import {useGetAutofillStatus} from './api/optimizer/useGetAutofillStatus'

export const usePeriodicAutofillCheck = () => {
  // useGetAutofillStatus has refresh period so there can be check
  const {data: autofillStatus} = useGetAutofillStatus()
  const [previousAutofillStatus, setPreviousAutofillStatus] =
    useState<typeof autofillStatus>(autofillStatus)
  // if autofill is finished then invalidate stockDevelopment query
  useEffect(() => {
    if (autofillStatus === previousAutofillStatus) {
      return
    }
    if (
      autofillStatus?.status === AutofillStatus.NotStarted &&
      previousAutofillStatus?.lastUpdatedAt !== autofillStatus.lastUpdatedAt
      // autofill finished
    ) {
      invalidateHcemQuery('stockDevelopment')
      invalidateHcemQuery('schedule')
    }
    if (autofillStatus !== previousAutofillStatus) {
      setPreviousAutofillStatus(autofillStatus)
    }
  }, [autofillStatus, previousAutofillStatus])
}
