import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import moment, {Moment} from 'moment'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'

import {mutations} from '../../../api/mutations'
import {getHcemQueryData, invalidateHcemQuery, setHcemQueryData} from '../../../api/queryHelpers'
import {notifyIfErrorMessage} from '../../../common'
import {useLoginDetails} from '../../../components/providers/LoginProvider'
import {PlannerQueryClient} from '../../../components/providers/QueryProvider'
import {AssetOperationTimeSource, OperationModeType} from '../../../enums'
import {useTrackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {ScheduleItem, Schedule, MutationError} from '../../../interfaces/api'
import {doesOverlappingItemExist, getDefaultSelectedSilos} from '../../../schedule'
import {
  getPeakLoadWindows,
  getScheduleItemPowerConsumptionQuaterlyDict
} from '../../../selectors/electricity'
import {getScheduleInTimeFrame} from '../../../selectors/schedule'
import {useUrlParam} from '../../useUrlParam'
import {useHistoryAssetsByIdQuery, useHistoryOperationModesByIdQuery} from '../assets'
import {usePeakLoadWindowsQuery} from '../dataScience'
import {useElectricityQuery} from '../kpi'
import {useMaterialsRecipesById} from '../materials'
import {useHistoryStoragesWithMaterialQuery} from '../materialStorage'

type ScheduleContext = {previousState: Schedule}

interface AddScheduleItemParams {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const useAddScheduleItem = ({startOfPlan, endOfPlan}: AddScheduleItemParams) => {
  const trackPlannerEvent = useTrackPlannerEvent()
  const {data: operationModesById} = useHistoryOperationModesByIdQuery({
    timeFrame: [startOfPlan, endOfPlan]
  })
  const {data: assetsById} = useHistoryAssetsByIdQuery({timeFrame: [startOfPlan, endOfPlan]})

  if (!(operationModesById && assetsById)) {
    throw new Error('BUG: Assets data should be loaded before using this hook')
  }
  const {loginDetails} = useLoginDetails()
  if (!loginDetails) {
    throw new Error('BUG: Login details should be loaded before using this hook')
  }
  const {data: recipesById} = useMaterialsRecipesById({useErrorBoundary: false})
  const {data: storagesWithMaterial} = useHistoryStoragesWithMaterialQuery({
    timeFrame: [startOfPlan, endOfPlan],
    useErrorBoundary: false
  })
  const {data: peakLoadWindows} = usePeakLoadWindowsQuery([startOfPlan, endOfPlan], {
    useErrorBoundary: false
  })
  const {data: electricity} = useElectricityQuery({
    timeFrame: [startOfPlan, endOfPlan],
    useErrorBoundary: false
  })
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const plantCode = useUrlParam('plantCode')

  const queryVariables = {plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}

  const {mutate} = useMutation<
    void,
    AxiosError<MutationError<ScheduleItem>>,
    Parameters<typeof mutations.addScheduleItem>[0] & {assetId: number}
  >({
    mutationFn: mutations.addScheduleItem,
    mutationKey: ['addScheduleItem'],
    onMutate: async (variables) => {
      await PlannerQueryClient.cancelQueries({queryKey: ['schedule']})

      const previousState = getHcemQueryData('schedule', [queryVariables])

      if (previousState) {
        const id = uuidv4()
        const updatedSchedule: Schedule = {
          ...previousState,
          schedules: {
            ...previousState.schedules,
            [id]: {
              ...variables.scheduleItemDTO,
              // temp id for the optimistic update
              id,
              isShutdownAvailable: true,
              source: AssetOperationTimeSource.Manual,
              selectedSilos: {
                isOrderSpecified: false,
                ids: []
              },
              assetId: variables.assetId,
              updatedOn: new Date().toISOString(),
              updatedBy: loginDetails.name ?? 'Optimistic update'
            }
          }
        }
        setHcemQueryData('schedule', [queryVariables], updatedSchedule)
      }
      return {previousState} as ScheduleContext
    },
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (_, {scheduleItemDTO}) => {
      trackPlannerEvent({name: 'onAddScheduleItem'})
      invalidateHcemQuery('costAvoidanceForRange')
      const isProductionModeItem =
        operationModesById[scheduleItemDTO.assetOperationModeId].type ===
        OperationModeType.Production
      // we need to invalidate stockDevelopment query only if we add production mode item
      if (isProductionModeItem) {
        invalidateHcemQuery('stockDevelopment')
      }
    },
    onSettled: () => {
      // invalidating all schedules for all start/end params
      invalidateHcemQuery('schedule')
      invalidateHcemQuery('electricity', {plantCode})
    }
  })

  return (
    newScheduleItem: Pick<
      ScheduleItem,
      'start' | 'end' | 'assetOperationModeId' | 'isTransitionTime' | 'assetId'
    >
  ) => {
    const scheduleQueryKey = ['schedule', queryVariables]

    const schedule = PlannerQueryClient.getQueryData<Schedule>(scheduleQueryKey)
    if (!schedule || !electricity || !peakLoadWindows || !storagesWithMaterial || !recipesById)
      return
    const activeSchedules = getScheduleInTimeFrame(schedule.schedules, moment.utc(), endOfPlan)

    const operationMode = operationModesById[newScheduleItem.assetOperationModeId]

    const scheduleItemDTO = {
      ...newScheduleItem,
      selectedSilos: getDefaultSelectedSilos(operationMode, recipesById, storagesWithMaterial),
      isFixed: false
    }

    const hasOverlap = doesOverlappingItemExist(activeSchedules, scheduleItemDTO)

    const powerConsumptionForUpdatedItemDict = getScheduleItemPowerConsumptionQuaterlyDict(
      scheduleItemDTO,
      assetsById[newScheduleItem.assetId].operationModes.find(
        (operationMode) => operationMode.id === newScheduleItem.assetOperationModeId
      )!
    )

    const prevTotalPowerConsumption = electricity.planned
    const adjustedPowerConsumption = prevTotalPowerConsumption.map((energyConsumption) => {
      const newItemPower = powerConsumptionForUpdatedItemDict[energyConsumption.dateTimeIso] || 0
      return {...energyConsumption, power: energyConsumption.power + newItemPower}
    })
    const updatedPeakLoadWindows = getPeakLoadWindows({
      powerConsumption: adjustedPowerConsumption,
      peakLoadWindows: peakLoadWindows
    })
    const hasPeakLoadWindowsViolated = updatedPeakLoadWindows.some((plw) => plw.violated)

    if (hasPeakLoadWindowsViolated) {
      notify('error', t('error.planViolatesPeakLoadWindow'))
      return
    }
    if (hasOverlap) {
      notify('error', t('error.overlappingItemExists'))
      return
    }
    mutate({
      plantCode,
      scheduleId: schedule.scheduleId,
      scheduleItemDTO,
      assetId: newScheduleItem.assetId
    })
  }
}
