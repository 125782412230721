import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {MaterialOrderStatus} from '../../shared/enums'
import type {
  CreateMaterialOrder,
  EditMaterialOrder,
  MaterialOrder
} from '../../shared/interfaces/api'
import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

export const enableMaterialOrdersEndpoints = (mock: MockAdapter) => {
  // GET material orders
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-orders$`)).reply((config) => {
    const {
      from,
      to,
      materialId: materialIdParam
    } = config.params as {from: string; to: string; materialId?: string}
    const materialId = materialIdParam !== undefined ? Number(materialIdParam) : undefined

    const {
      burglengenfeld: {materialOrders}
    } = mockStore.scenario()

    const allMaterialOrdersForTimeframe = materialOrders.filter((order) =>
      moment.utc(order.scheduledDate).isBetween(moment.utc(from), moment.utc(to), 'hour', '[]')
    )

    const materialOrdersResponseData =
      materialId === undefined
        ? allMaterialOrdersForTimeframe
        : allMaterialOrdersForTimeframe.filter((order) => order.materialId === materialId)

    return sleepResponse([200, materialOrdersResponseData])
  })

  // POST create new order
  mock.onPost(new RegExp(`^/plants/${numberRegEx}/material-orders$`)).reply((config) => {
    const dto = JSON.parse(config.data as string) as CreateMaterialOrder

    const scenario = mockStore.scenario()
    const newMaterialOrder: MaterialOrder = {
      id: Date.now(),
      ...dto,
      scheduleAgreement: dto.scheduleAgreement ?? {vendorName: 'NoVendor'},
      deliveries: [],
      createdOn: '2019-08-17T10:00:00.000Z',
      createdBy: 'Test user',
      status: MaterialOrderStatus.Created,
      attachments: []
    }
    scenario.burglengenfeld.materialOrders.push(newMaterialOrder)
    saveScenario(scenario)
    return sleepResponse([201, newMaterialOrder])
  })

  // PATCH edit order
  const editMaterialOrderPropertyRegEx = '(amount|schedule-agreement|scheduled-date)'
  mock
    .onPatch(
      new RegExp(
        `^/plants/${numberRegEx}/material-orders/${numberRegEx}/${editMaterialOrderPropertyRegEx}`
      )
    )
    .reply((config) => {
      const orderId = Number((config.url as string).split('/')[4])
      const dto = JSON.parse(config.data as string) as Partial<EditMaterialOrder>
      const scenario = mockStore.scenario()
      const orderToEdit = scenario.burglengenfeld.materialOrders.find(
        (order) => order.id === orderId
      )!

      const {scheduleAgreement, amount, scheduledDate} = dto

      const editedOrder = {
        ...orderToEdit,
        ...(amount !== undefined && {amount}),
        ...(scheduledDate !== undefined && {scheduledDate}),
        ...(scheduleAgreement !== undefined && {
          scheduleAgreement:
            scheduleAgreement === null
              ? {vendorName: 'NoVendor'}
              : {...orderToEdit.scheduleAgreement, ...dto.scheduleAgreement}
        }),
        status: MaterialOrderStatus.Edited
      }
      scenario.burglengenfeld.materialOrders = scenario.burglengenfeld.materialOrders.map(
        (order) => (order.id === orderId ? editedOrder : order)
      )
      saveScenario(scenario)
      return sleepResponse([200, editedOrder])
    })

  // PATCH cancel order
  mock
    .onPatch(new RegExp(`^/plants/${numberRegEx}/material-orders/${numberRegEx}/cancel$`))
    .reply((config) => {
      const orderId = Number((config.url as string).split('/')[4])
      const scenario = mockStore.scenario()
      scenario.burglengenfeld.materialOrders = scenario.burglengenfeld.materialOrders.map(
        (order) =>
          order.id === orderId ? {...order, status: MaterialOrderStatus.Cancelled} : order
      )
      saveScenario(scenario)
      return sleepResponse([204, {}])
    })

  // DELETE remove order
  mock
    .onDelete(new RegExp(`^/plants/${numberRegEx}/material-orders/${numberRegEx}$`))
    .reply((config) => {
      const orderId = Number((config.url as string).split('/')[4])
      const scenario = mockStore.scenario()
      scenario.burglengenfeld.materialOrders = scenario.burglengenfeld.materialOrders.filter(
        (order) => order.id !== orderId
      )
      saveScenario(scenario)
      return sleepResponse([204, {}])
    })

  // GET material unplanned deliveries
  mock
    .onGet(new RegExp(`^/plants/${numberRegEx}/material-orders/daily-unplanned-deliveries$`))
    .reply((config) => {
      const {
        from,
        to,
        materialId: materialIdParam
      } = config.params as {
        from: string
        to: string
        materialId?: string
      }
      const materialId = materialIdParam !== undefined ? Number(materialIdParam) : undefined

      const {
        burglengenfeld: {materialUnplannedDeliveries}
      } = mockStore.scenario()

      const unplannedDeliveriesForTimeframe = materialUnplannedDeliveries.filter(
        (unplannedDelivery) =>
          moment
            .utc(unplannedDelivery.deliveredOn)
            .isBetween(moment.utc(from), moment.utc(to), 'hour', '[]')
      )
      const unplannedDeliveriesResponse =
        materialId === undefined
          ? unplannedDeliveriesForTimeframe
          : unplannedDeliveriesForTimeframe.filter((order) => order.materialId === materialId)

      return sleepResponse([200, unplannedDeliveriesResponse])
    })

  return mock
}
