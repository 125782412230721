import {SvgIcon, SvgIconProps} from '@mui/material'
import React from 'react'

export const ClinkerIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="12px" height="12px" viewBox="0 0 12 12" {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g>
        <circle cx="2" cy="2" r="2" />
        <circle cx="10" cy="2" r="2" />
        <circle cx="10" cy="10" r="2" />
        <circle cx="6" cy="6" r="2" />
        <circle cx="2" cy="10" r="2" />
      </g>
    </g>
  </SvgIcon>
)
