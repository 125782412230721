// @flow
import * as AreaSchema from './AreaSchema.json'
import * as AreasSchema from './AreasSchema.json'
import * as BusinessLinesSchema from './BusinessLinesSchema.json'
import * as ContentSchema from './ContentSchema.json'
import * as CountriesSchema from './CountriesSchema.json'
import * as CountrySchema from './CountrySchema.json'
import * as CustomerSchema from './CustomerSchema.json'
import * as CustomersSchema from './CustomersSchema.json'
import * as DeliveriesSchema from './DeliveriesSchema.json'
import * as HaulierSchema from './HaulierSchema.json'
import * as HauliersSchema from './HauliersSchema.json'
import * as MaterialLocationsSchema from './MaterialLocationsSchema.json'
import * as OrderCountsSchema from './OrderCountsSchema.json'
import * as OrderSchema from './OrderSchema.json'
import * as OrdersSchema from './OrdersSchema.json'
import * as OrgUnitsSchema from './OrgUnitsSchema.json'
import * as ProjectsReportSchema from './ProjectsReportSchema.json'
import * as ProjectsSchema from './ProjectsSchema.json'
import * as RemoteVersionSchema from './RemoteVersionSchema.json'
import * as RoleRequestsSchema from './RoleRequests.json'
import * as RolesSchema from './Roles.json'
import * as UserProfileSchema from './UserProfileSchema.json'

export const SchemaIDs = {
  ProjectsSchemaID: 'ProjectsSchemaID',
  OrdersSchemaID: 'OrdersSchemaID',
  UserProfileSchemaID: 'UserProfileSchemaID',
  MaterialLocationsSchemaID: 'MaterialLocationsSchemaID',
  DeliveriesSchemaID: 'DeliveriesSchemaID',
  RemoteVersionSchemaID: 'RemoteVersionSchemaID',
  CustomerSchemaID: 'CustomerSchemaID',
  CustomersSchemaID: 'CustomersSchemaID',
  OrderCountsSchemaID: 'OrderCountsSchemaID',
  ProjectsReportSchemaID: 'ProjectsReportSchemaID',
  OrderSchemaID: 'OrderSchemaID',
  ContentSchemaID: 'ContentSchemaID',
  RolesSchemaID: 'RolesSchemaID',
  RoleRequestsSchemaID: 'RoleRequestsSchemaID',
  CountrySchemaID: 'CountrySchemaID',
  CountriesSchemaID: 'CountriesSchemaID',
  BusinessLinesSchemaID: 'BusinessLinesSchemaID',
  OrgUnitsSchemaID: 'OrgUnitsSchemaID',
  AreaSchemaID: 'AreaSchemaID',
  AreasSchemaID: 'AreasSchemaID',
  HaulierSchemaID: 'HaulierSchemaID',
  HauliersSchemaID: 'HauliersSchemaID'
}

const {
  ProjectsSchemaID,
  OrdersSchemaID,
  UserProfileSchemaID,
  MaterialLocationsSchemaID,
  DeliveriesSchemaID,
  RemoteVersionSchemaID,
  CustomerSchemaID,
  CustomersSchemaID,
  OrderCountsSchemaID,
  ProjectsReportSchemaID,
  OrderSchemaID,
  ContentSchemaID,
  RolesSchemaID,
  RoleRequestsSchemaID,
  CountrySchemaID,
  CountriesSchemaID,
  BusinessLinesSchemaID,
  OrgUnitsSchemaID,
  AreaSchemaID,
  AreasSchemaID,
  HaulierSchemaID,
  HauliersSchemaID
} = SchemaIDs

export const schemas = {
  [ProjectsSchemaID]: ProjectsSchema,
  [OrdersSchemaID]: OrdersSchema,
  [UserProfileSchemaID]: UserProfileSchema,
  [MaterialLocationsSchemaID]: MaterialLocationsSchema,
  [DeliveriesSchemaID]: DeliveriesSchema,
  [RemoteVersionSchemaID]: RemoteVersionSchema,
  [CustomerSchemaID]: CustomerSchema,
  [CustomersSchemaID]: CustomersSchema,
  [OrderCountsSchemaID]: OrderCountsSchema,
  [ProjectsReportSchemaID]: ProjectsReportSchema,
  [OrderSchemaID]: OrderSchema,
  [ContentSchemaID]: ContentSchema,
  [RolesSchemaID]: RolesSchema,
  [RoleRequestsSchemaID]: RoleRequestsSchema,
  [CountrySchemaID]: CountrySchema,
  [CountriesSchemaID]: CountriesSchema,
  [BusinessLinesSchemaID]: BusinessLinesSchema,
  [OrgUnitsSchemaID]: OrgUnitsSchema,
  [AreaSchemaID]: AreaSchema,
  [AreasSchemaID]: AreasSchema,
  [HaulierSchemaID]: HaulierSchema,
  [HauliersSchemaID]: HauliersSchema
}
