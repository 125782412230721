import {Typography} from '@mui/material'
import useSize from '@react-hook/size'
import React, {useRef} from 'react'

import {ActiveTrackLabelProps} from '../RangeSlider/Label'

interface GetDayRangeSliderActiveTrackLabelParams {
  formatStep: ([min, max]: [number, number], width: number) => string
}

export const getDayRangeSliderActiveTrackLabel = ({
  formatStep
}: GetDayRangeSliderActiveTrackLabelParams): React.FC<ActiveTrackLabelProps> =>
  React.memo(({min, max}) => {
    const ref = useRef<HTMLElement>(null)
    const [width] = useSize(ref)

    return (
      <Typography
        ref={ref}
        sx={{
          color: ({palette}) => palette.common.white,
          padding: '0 0.5rem',
          userSelect: 'none'
        }}
        noWrap
        align="center"
        variant="subtitle1"
      >
        {formatStep([min, max], width)}
      </Typography>
    )
  })
