import React, {useContext, useState, useMemo} from 'react'

interface SelectedScheduleItemState {
  scheduleItemId: string | undefined
  setScheduleItemId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const SelectedScheduleItemContext = React.createContext<SelectedScheduleItemState | undefined>(
  undefined
)

export const useSelectedScheduleItemId = () => {
  const context = useContext(SelectedScheduleItemContext)
  if (!context) throw new Error('Cannot use schedule context outside of a schedule provider')
  return context
}

export const SelectedScheduleItemProvider = ({children}: {children: React.ReactNode}) => {
  const [scheduleItemId, setScheduleItemId] = useState<string | undefined>(undefined)

  const memoizedState = useMemo(() => ({scheduleItemId, setScheduleItemId}), [scheduleItemId])

  return (
    <SelectedScheduleItemContext.Provider value={memoizedState}>
      {children}
    </SelectedScheduleItemContext.Provider>
  )
}
