export enum UnitOfMeasurement {
  Sheet = 'Sheet',
  Bag = 'Bag',
  Block = 'Block',
  Bottle = 'Bottle',
  Each = 'Each',
  Gram = 'Gram',
  UsGallon = 'US gallon',
  Kilogram = 'Kilogram',
  KilogramHour = 'Kilogramhour',
  Litre = 'Litre',
  UsPound = 'US pound',
  OngoingMeter = 'Ongoing meter',
  Metre = 'Metre',
  SquareMetre = 'Square metre',
  CubicMetre = 'Cubic metre',
  Millilitre = 'Millilitre',
  Pallet = 'Pallet',
  Roll = 'Roll',
  Sack = 'Sack',
  Set = 'Set',
  Items = 'Items',
  Board = 'Board',
  Thousands = 'Thousands',
  Tonnes = 'Tonnes',
  Other = 'Other'
}

export enum MeasurementType {
  Auto = 'Auto',
  Manual = 'Manual'
}

export enum Status {
  Created = 'Created',
  Edited = 'Edited',
  Deleted = 'Deleted',
  // only for FE
  New = 'New'
}

export enum MaterialType {
  Cement = 'Cement',
  Clinker = 'Clinker',
  RawMaterial = 'RawMaterial',
  RawMeal = 'RawMeal',
  ConventionalFuel = 'ConventionalFuel',
  AlternativeFuel = 'AlternativeFuel',
  OutOfSpec = 'OutOfSpec',
  SemiFinishedProduct = 'SemiFinishedProduct'
}

export enum NewMaterialType {
  Cement = 'Cement',
  Clinker = 'Clinker',
  IntermediateAndFinishedProducts = 'IntermediateAndFinishedProducts',
  RawMaterials = 'RawMaterials',
  Fuels = 'Fuels',
  EnhancersForCement = 'EnhancersForCement',
  AdditivesForClinker = 'AdditivesForClinker',
  NonReportedMaterials = 'NonReportedMaterials'
}

export enum MaterialSource {
  ProducedInPlant = 'ProducedInPlant',
  BoughtFromVendor = 'BoughtFromVendor'
}

export enum RecipeComponentType {
  Input = 'Input',
  Output = 'Output'
}

export enum ProductType {
  PH5_PRODH = 'BI_PRODH__BI_PRDH5A',
  PH5_MATSLS = 'BI_MATSLS__BI_PRDH5A',
  PH6_PRODH = 'BI_PRODH__BI_PRDH6A',
  PH6_MATSLS = 'BI_MATSLS__BI_PRDH6A',
  MARERIAL_SAP = 'BI_MATERL_MM_STOCK',
  MATERIAL_SD = 'BI_MATERL',
  MATERIAL_MM = 'BI_MATERL_MM'
}

export enum AssetLinkType {
  Asset = 'Asset',
  Conveyor = 'Conveyor',
  Silo = 'Silo'
}

export enum LinkDirection {
  To = 'to',
  From = 'from'
}

export enum OperationModeType {
  Production = 'Production',
  Maintenance = 'Maintenance'
}

export enum AssetType {
  CementMill = 'CementMill',
  RawMill = 'RawMill',
  RotaryKiln = 'RotaryKiln',
  Other = 'Other',
  BaseLoad = 'BaseLoad',
  Crusher = 'Crusher',
  CoalMill = 'CoalMill'
}
