import {dataTestId as spreadDataTestId} from '@hconnect/uikit'
import {DayRangeSlider} from '@hconnect/uikit/src/lib2/components/rangeSliders'
import {useTheme, useMediaQuery} from '@mui/material'
import {throttle} from 'lodash'
import React, {FC, useMemo, useCallback} from 'react'

import {MomentRange} from '../../shared/selectors/time'

const MIN_SELECTED_HOURS = 12
const DEFAULT_MAX_SELECTED_HOURS_DESKTOP = 7 * 24
const DEFAULT_MAX_SELECTED_HOURS_MOBILE = 3 * 24
const DEFAULT_MAX_SELECTED_HOURS_TABLET = 5 * 24
// Delay in ms to wait before calling onRangeChangeComplete after the user has stopped dragging the slider
const ON_RANGE_CHANGE_COMPLETE_DELAY = 300

interface PlannerDayRangeSliderProps {
  selectedRange: MomentRange
  setSelectedRange: (range: MomentRange) => void
  onRangeChangeComplete: (range: MomentRange) => void
  baseRange: MomentRange
  stepsPerHour: number
  ['data-test-id']?: string
}

export const PlannerDayRangeSlider: FC<PlannerDayRangeSliderProps> = React.memo(
  ({
    selectedRange,
    setSelectedRange,
    onRangeChangeComplete,
    baseRange,
    stepsPerHour,
    'data-test-id': dataTestId = 'production_planning_slider'
  }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

    const defaultMaxSelectedHours = isMobile
      ? DEFAULT_MAX_SELECTED_HOURS_MOBILE
      : isTablet
        ? DEFAULT_MAX_SELECTED_HOURS_TABLET
        : DEFAULT_MAX_SELECTED_HOURS_DESKTOP

    const throttledOnRangeChangeComplete = useMemo(
      () => throttle(onRangeChangeComplete, ON_RANGE_CHANGE_COMPLETE_DELAY),
      [onRangeChangeComplete]
    )

    const memoizedOptions = useMemo(
      () => ({
        hoursInStep: stepsPerHour,
        minSelectedHours: MIN_SELECTED_HOURS,
        maxSelectedHours: defaultMaxSelectedHours
      }),
      [stepsPerHour, defaultMaxSelectedHours]
    )

    const handleRangeChange = useCallback(
      (range: MomentRange) => {
        throttledOnRangeChangeComplete(range)
        setSelectedRange(range)
      },
      [throttledOnRangeChangeComplete, setSelectedRange]
    )

    return (
      <DayRangeSlider
        {...spreadDataTestId(dataTestId)}
        options={memoizedOptions}
        setSelectedRange={handleRangeChange}
        selectedRange={selectedRange}
        baseRange={baseRange}
        onChangeComplete={onRangeChangeComplete}
      />
    )
  }
)

PlannerDayRangeSlider.displayName = 'PlannerDayRangeSlider'
