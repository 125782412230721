import {compact} from 'lodash'

import {DeliveryStatus, IncomingMaterialStatus} from '../enums'

import {IncomingMaterial} from './materialOrders'

interface DeliveryDetailBase {
  amount: number
  vendorName: string
}
type Delivered = DeliveryDetailBase & {
  time: string
  status: DeliveryStatus.Delivered
}
type Overdue = DeliveryDetailBase & {
  status: DeliveryStatus.Overdue
}
type Unknown = DeliveryDetailBase & {
  status: DeliveryStatus.Unknown
  time: string
}
export type DeliveryDetail = Delivered | Overdue | Unknown

export const mapIncomingMaterialToDeliveryDetails = (
  incomingMaterial: IncomingMaterial
): DeliveryDetail[] => {
  switch (incomingMaterial.status) {
    case IncomingMaterialStatus.Delivered:
    case IncomingMaterialStatus.Overdue:
      return compact([
        ...incomingMaterial.order.deliveries.map(({deliveredOn, amountDelivered}) => ({
          vendorName: incomingMaterial.order.scheduleAgreement.vendorName,
          status: DeliveryStatus.Delivered,
          amount: amountDelivered,
          time: deliveredOn
        })),
        incomingMaterial.value < incomingMaterial.order.amount && {
          vendorName: incomingMaterial.order.scheduleAgreement.vendorName,
          status: DeliveryStatus.Overdue,
          amount: incomingMaterial.order.amount - incomingMaterial.value
        }
      ])
    case IncomingMaterialStatus.Unknown:
      return incomingMaterial.unplannedDelivery.deliveries.map(
        ({deliveredOn, amountDelivered}) => ({
          vendorName: incomingMaterial.unplannedDelivery.scheduleAgreement.vendorName,
          status: DeliveryStatus.Unknown,
          amount: amountDelivered,
          time: deliveredOn
        })
      )
    case IncomingMaterialStatus.Planned:
    case IncomingMaterialStatus.Cancelled:
      return []
    default:
      throw new Error('BUG: incorrect incoming material status')
  }
}
