import {useQueryErrorResetBoundary} from '@tanstack/react-query'
import React from 'react'
import {Outlet, useNavigate} from 'react-router-dom'

import {prefetchPlannerQuery} from '../../shared/api/queries'
import {DefaultShell} from '../../shared/components/DefaultShell'
import {PlannerErrorBoundary} from '../../shared/components/errorBoundary/PlannerErrorBoundary'
import {GuidedToursShell} from '../../shared/components/GuidedToursShell'
import {PlannerHeader} from '../../shared/components/PlannerHeader'
import {PlannerToursProvider} from '../../shared/components/providers/PlannerToursProvider'
import {PlannerPageSkeleton} from '../../shared/components/skeletons/PlannerPageSkeleton'
import {usePermissionsQuery, usePlantConfigQuery} from '../../shared/hooks/api'
import {useUrlParam} from '../../shared/hooks/useUrlParam'

export const PagePlant: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  const {data: plantConfig} = usePlantConfigQuery()
  const {data: permissions} = usePermissionsQuery()
  prefetchPlannerQuery('materials', [plantCode])
  prefetchPlannerQuery('materialsRecipes', [{plantCode}])

  const navigate = useNavigate()
  const refreshPage = () => navigate(0)
  const {reset} = useQueryErrorResetBoundary()

  return plantConfig && permissions ? (
    <PlannerToursProvider>
      <GuidedToursShell>
        <PlannerErrorBoundary
          onRetry={reset}
          fallbackSx={{width: 'auto', maxWidth: ({spacing}) => spacing(60)}}
          fallbackWrapperSx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
          fallbackTestId="page_plant_error_boundary_fallback"
          onReload={refreshPage}
        >
          <Outlet />
        </PlannerErrorBoundary>
      </GuidedToursShell>
    </PlannerToursProvider>
  ) : (
    <DefaultShell HeaderContent={<PlannerHeader />}>
      <PlannerPageSkeleton />
    </DefaultShell>
  )
}
