import {styled, TextField as MUITextField, TextFieldProps, alpha} from '@mui/material'

export type HPTextFieldProps = TextFieldProps & {backgroundMode?: 'light' | 'dark'}
/**
 * Styled wrapper around MUI TextField component which could be used on light and dark background
 * accepts all MUI TextField props plus backgroundMode prop
 */
export const HPTextField = styled(MUITextField, {
  shouldForwardProp: (prop) => prop !== 'backgroundMode'
})<HPTextFieldProps>(({theme: {palette}, backgroundMode = 'light'}) => ({
  // base style override
  '& .MuiSelect-select': {
    paddingTop: 14,
    paddingBottom: 14
  },
  ...(backgroundMode === 'dark'
    ? {
        // styles for dark background
        '& .MuiInputBase-root': {
          backgroundColor: 'unset',
          color: palette.common.white,
          '& .MuiSvgIcon-root': {
            color: palette.common.white
          },
          '& fieldset': {
            borderColor: alpha(palette.common.white, 0.4)
          },
          '&:hover fieldset': {
            borderColor: alpha(palette.common.white, 0.8)
          },
          '&.Mui-focused': {
            color: palette.common.white,
            '& fieldset': {
              borderColor: alpha(palette.common.white, 0.8)
            }
          }
        },
        '& .MuiInputLabel-root': {
          color: palette.common.white,
          '&.Mui-focused': {
            color: palette.common.white
          }
        }
      }
    : // styles for light background, already configured in HProduceTheme
      {})
}))
