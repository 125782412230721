export const ROUTES = {
  BASE: {
    path: '/',
    PLANT_SELECTOR: {
      path: '/plants',
      PLANT: {
        path: '/plants/:plantCode',
        STOCK: {
          path: '/plants/:plantCode/stock',
          SILOS: {path: '/plants/:plantCode/stock/silos'},
          MATERIALS: {
            path: '/plants/:plantCode/stock/materials',
            ORDERS: {
              path: '/plants/:plantCode/stock/materials/:materialId'
            }
          }
        },
        ELECTRICITY_FORECAST: {
          path: '/plants/:plantCode/electricity-price-forecast'
        },
        SALES_FORECAST: {
          path: '/plants/:plantCode/sales-forecast'
        },
        PRODUCTION_PLANNING: {
          path: '/plants/:plantCode/production-planning',
          DAILY: {
            path: '/plants/:plantCode/production-planning/daily'
          },
          MONTHLY: {
            path: '/plants/:plantCode/production-planning/monthly'
          },
          OPTIMIZER: {
            path: '/plants/:plantCode/production-planning/optimizer',
            SUMMARY: {
              path: '/plants/:plantCode/production-planning/optimizer/summary'
            },
            DETAILS: {
              path: '/plants/:plantCode/production-planning/optimizer/details',
              OPTIMIZED_PLAN: {
                path: '/plants/:plantCode/production-planning/optimizer/details/optimized-plan'
              },
              LAST_PLAN: {
                path: '/plants/:plantCode/production-planning/optimizer/details/last-plan'
              }
            }
          }
        },
        OPERATOR_VIEW: {
          path: '/plants/:plantCode/operator-view'
        }
      }
    },
    NOT_AUTHORIZED: {
      path: '/403'
    },
    NOT_FOUND: {
      path: '/404'
    }
  }
} as const

export const PLANT_ROUTES = ROUTES.BASE.PLANT_SELECTOR.PLANT

export enum RouteName {
  BASE = 'base',
  PLANT_SELECTOR = 'plantSelector',
  NOT_AUTHORIZED = 'notAuthorized',
  NOT_FOUND = 'notFound',
  PLANT = 'plant',
  STOCK = 'stock',
  STOCK_MATERIAL = 'stockMaterial',
  STOCK_SILOS = 'stockDevelopment',
  MATERIAL_ORDERS = 'materialOrders',
  ELECTRICITY_FORECAST = 'electricityForecast',
  SALES_FORECAST = 'salesForecast',
  PRODUCTION_PLANNING = 'productionPlanning',
  PRODUCTION_PLANNING_DAILY = 'productionPlanningDaily',
  PRODUCTION_PLANNING_MONTHLY = 'productionPlanningMonthly',
  OPTIMIZER = 'optimizer',
  OPTIMIZER_SUMMARY = 'optimizerSummary',
  OPTIMIZER_DETAILS = 'optimizerDetails',
  OPTIMIZED_PLAN_DETAILS = 'optimizedPlanDetails',
  LAST_PLAN_DETAILS = 'lastPlanDetails',
  OPERATOR_VIEW = 'operatorView'
}

/**
 * used for old permission implementation, remove it later
 */
export type MainRoutes = typeof mainRoutes
export const mainRoutes = {
  [RouteName.STOCK]: PLANT_ROUTES.STOCK.path,
  [RouteName.ELECTRICITY_FORECAST]: PLANT_ROUTES.ELECTRICITY_FORECAST.path,
  [RouteName.SALES_FORECAST]: PLANT_ROUTES.SALES_FORECAST.path,
  [RouteName.PRODUCTION_PLANNING]: PLANT_ROUTES.PRODUCTION_PLANNING.path,
  [RouteName.OPERATOR_VIEW]: PLANT_ROUTES.OPERATOR_VIEW.path
}
