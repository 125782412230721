import {useCallback} from 'react'

import {AssetCapacityResponse} from '../../../interfaces/api'
import {getProducedPerMaterial} from '../../../selectors/assetCapacity'
import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useProducedPerMaterial = ([from, to]: MomentRange) => {
  const plantCode = useUrlParam('plantCode')

  const selector = useCallback(
    (data: AssetCapacityResponse) => getProducedPerMaterial(data.assets),
    []
  )

  return usePlannerQuery(
    'assetCapacity',
    [plantCode, {from: from.toISOString(), to: to.toISOString()}],
    {select: selector}
  )
}
