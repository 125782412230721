import {Shell, ShellFooter} from '@hconnect/uikit/src/lib2'
import * as React from 'react'
import {useTranslation} from 'react-i18next'

import {useFullscreen} from './providers/FullscreenProvider'

export interface DefaultShellProps {
  HeaderContent?: React.ReactNode
  children: React.ReactNode
}

export const DefaultShell = ({children, HeaderContent}: DefaultShellProps) => {
  const {isFullScreen} = useFullscreen()
  const {t} = useTranslation()

  return (
    <Shell
      boxed={!isFullScreen}
      header={HeaderContent}
      footer={<ShellFooter label={t('footer.poweredBy')} />}
      isResponsive
      compact
    >
      {children}
    </Shell>
  )
}
