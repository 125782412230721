import {MaterialStorage} from '@hconnect/common/types'

import {
  calculateCapacityForStorages,
  calculateDeadStockLevelForStorages,
  calculateMinTargetLevelForStorages
} from '../../../selectors/storageLevels'
import {isStorageWithMaterialGuard} from '../../../selectors/storages'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

const selector = (storages: MaterialStorage[], materialId: number) => {
  const storagesWithMaterial = storages
    .filter(isStorageWithMaterialGuard)
    .filter((storage) => storage.materialId === materialId)

  const minTargetLevels = calculateMinTargetLevelForStorages(storagesWithMaterial)
  const deadStockLevel = calculateDeadStockLevelForStorages(storagesWithMaterial)
  const storageCapacity = calculateCapacityForStorages(storagesWithMaterial)
  return {...minTargetLevels, deadStockLevel, storageCapacity}
}

interface MaterialStorageDataQueryParams {
  materialId: number
}

export const useMaterialStorageDataQuery = ({materialId}: MaterialStorageDataQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('materialStorage', [plantCode], {
    select: (data) => selector(data, materialId)
  })
}
