import {useNotification} from '@hconnect/uikit/src/common/'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {invalidateHcemQuery} from '../../../api/queryHelpers'
import {notifyIfErrorMessage} from '../../../common'
import {OptimizerCalculationAction} from '../../../enums'
import {ErrorResponse} from '../../../interfaces/api/common'

export const useCalculateOptimizedPlanning = () => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const raiseError = useErrorHandler()
  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.calculateOptimizedPlanning>[0]
  >({
    mutationFn: mutations.calculateOptimizedPlanning,
    onError: (error) => {
      notify('error', t('error.apology'))
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (_, {status}) => {
      invalidateHcemQuery('recalculateOptimizerStatus')
      notify(
        'success',
        status === OptimizerCalculationAction.Start
          ? t('success.recalculateOptimizedPlanning')
          : t('success.cancelRecalculateOptimizedPlanning')
      )
    }
  })
}
