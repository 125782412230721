import {retry, retryMutation} from '@hconnect/common/query/queryMutationRetry'
import {useNotification} from '@hconnect/uikit'
import {DefaultOptions, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {AxiosError} from 'axios'
import React from 'react'

const defaults: DefaultOptions = {
  queries: {
    retry,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  },
  mutations: {
    retry: retryMutation
  }
}
export const PlannerQueryClient = new QueryClient({defaultOptions: defaults})

export const QueryProvider = ({children}: {children: React.ReactNode}) => {
  const {notify} = useNotification()

  // TODO use onError in QueryCache instead to handle errors only after a certain number of retries
  // right now it's called on every failed query/mutation
  const onError = (err: unknown) => {
    const axiosError = err as AxiosError<Record<string, string>>
    const response = axiosError.response
    const responseErrorData = response?.data
    if (!responseErrorData) {
      notify('error', axiosError.message)
    } else {
      const responseErrorMessage =
        typeof responseErrorData === 'string' ? responseErrorData : responseErrorData?.detail
      notify('error', responseErrorMessage ?? axiosError.message)
    }
  }

  PlannerQueryClient.setDefaultOptions({
    queries: {...defaults.queries, onError},
    mutations: {onError}
  })

  return (
    <QueryClientProvider client={PlannerQueryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
