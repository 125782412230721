import {styled} from '@mui/material'
import * as d3 from 'd3'
import React, {useLayoutEffect, useRef} from 'react'

import {ScaleFn} from '../../helpers/scale'

const StyledAxis = styled('g')(({theme}) => ({...theme.typography.subtitle1}))

const axisFunction = <Domain extends number>(
  orientation: 'x' | 'y'
): ((scale: ScaleFn<Domain>) => d3.Axis<Domain>) => {
  return {x: d3.axisBottom, y: d3.axisLeft}[orientation] as (
    scale: ScaleFn<Domain>
  ) => d3.Axis<Domain>
}

export interface ChartAxisProps {
  orientation: 'x' | 'y'
  scale: ScaleFn
  ticks?: number
  tickFormat?: (value: number, index: number) => string
  children?: React.ReactNode
  // x and y are referring to the position of the x-axis
  positionX?: number
  positionY?: number
  hideLine?: boolean
}

export const Axis: React.FC<ChartAxisProps> = ({
  scale,
  orientation,
  ticks,
  tickFormat,
  children,
  positionX = 0,
  positionY = 0,
  hideLine = false
}) => {
  const ref = useRef<SVGGElement>(null)
  const noTicks = (): string => ''
  const format = children ? noTicks : tickFormat ?? null

  useLayoutEffect(() => {
    if (!ref.current) return
    const axis = axisFunction(orientation)(scale)

    d3.select(ref.current)
      .attr('transform', `translate(${positionX}, ${positionY})`)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .call(axis.ticks(ticks).tickFormat(format!))
  }, [hideLine, children, orientation, scale, ticks, format, positionX, positionY])

  return (
    <StyledAxis
      ref={ref}
      sx={[
        hideLine && {
          '& .domain': {
            opacity: 0
          }
        }
      ]}
    >
      {children}
    </StyledAxis>
  )
}
