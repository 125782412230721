import {Box, BoxProps} from '@mui/material'
import React, {FC, useMemo} from 'react'

interface RowContainerProps extends BoxProps {
  rowHeight: string
  rows: number
  columns: number
  columnWidth: string
}

export const FixedRepeatingGrid: FC<RowContainerProps> = ({
  rows,
  rowHeight,
  columns,
  columnWidth,
  children,
  ...props
}) => {
  const gridStyle: React.CSSProperties = useMemo(
    () => ({
      gridTemplateColumns: `repeat(${columns}, ${columnWidth})`,
      gridTemplateRows: `repeat(${rows}, ${rowHeight})`
    }),
    [columns, rows, columnWidth, rowHeight]
  )
  return (
    <Box display="grid" style={gridStyle} position="relative" {...props}>
      {children}
    </Box>
  )
}
