import {KeyboardArrowUp} from '@mui/icons-material'
import {Box, IconButton, Divider} from '@mui/material'
import {get} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {animateScroll} from 'react-scroll/modules'

import {ShellProvider, useYScroll} from '../../common'

import {Content} from './Content'
import {Footer} from './footer'
import {HeaderContainer} from './HeaderContainer'

// moved from uikit v1, should be reworked to styled components
const palette = {
  scrollToTopButton: {
    mainText: '#29AAFF',
    mainBorder: '#29AAFFB2',
    hoverText: '#29AAFF',
    hoverBorder: '#29AAFF',
    activeText: '#F9AAFF',
    activeBorder: '#29AAFF7F'
  }
}

interface ShellProps {
  compact?: boolean
  boxed?: boolean
  onDark?: boolean
  showScrollToTop?: boolean
  isResponsive?: boolean
  header?: React.ReactNode
  headerClassName?: string
  footer?: React.ReactNode
  stickyFooter?: boolean
  zIndex?: number | string
  children?: React.ReactNode
}

const handleScrollToTop = () => {
  animateScroll.scrollToTop()
}

const SCROLL_TOP_HEIGHT = 35

export const Shell: React.FC<ShellProps> = ({
  children,
  header,
  footer,
  compact = false,
  boxed = true,
  onDark = true,
  showScrollToTop = true,
  isResponsive = false,
  stickyFooter = false,
  headerClassName,
  zIndex
}) => {
  const footerRef = useRef<HTMLDivElement>(null)
  const yScroll = useYScroll()
  const scrolled = yScroll > 1

  const [isScrollTopVisible, setScrollTopVisible] = useState(false)
  const [scrollTopAtFooter, setScrollTopAtFooter] = useState<number>(0)

  useEffect(() => {
    if (showScrollToTop) {
      setScrollTopVisible(scrolled)
      if (scrolled) {
        const footerHeight = get(footerRef, 'current.clientHeight', 0)
        const bottomY = yScroll + window.innerHeight
        const limitHeight = document.documentElement.scrollHeight - footerHeight + SCROLL_TOP_HEIGHT
        const diff = bottomY - limitHeight
        setScrollTopAtFooter(Math.max(0, diff))
      }
    }
  }, [scrolled, yScroll, footerRef, showScrollToTop])

  return (
    <ShellProvider defaultBoxed={boxed} defaultCompact={compact} scrolled={scrolled}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          ...(isResponsive
            ? {}
            : {
                overflowX: 'initial',
                minWidth: '1280px'
              })
        }}
      >
        <HeaderContainer scrolled={scrolled} className={headerClassName}>
          {header}
        </HeaderContainer>
        <Content>{children}</Content>
        <Divider variant="middle" light />
        <Footer ref={footerRef} onDark={onDark} sticky={stickyFooter} zIndex={zIndex}>
          {footer}
        </Footer>
        {showScrollToTop && (
          <IconButton
            onClick={handleScrollToTop}
            color="inherit"
            aria-label="scroll to top"
            sx={(theme) => ({
              '@media print': {
                display: 'none'
              },
              color: palette.scrollToTopButton.mainText,
              borderColor: palette.scrollToTopButton.mainBorder,
              borderStyle: 'solid',
              borderWidth: 3,
              boxShadow: theme.shadows[4],
              padding: theme.spacing(0.6),
              position: 'fixed',
              bottom: scrollTopAtFooter,
              right: theme.spacing(1),
              opacity: isScrollTopVisible ? 1 : 0,
              transition: 'visibility .3s, opacity .3s',
              '&:active': {
                color: palette.scrollToTopButton.activeText,
                backgroundColor: 'transparent',
                borderColor: palette.scrollToTopButton.activeBorder,
                boxShadow: theme.shadows[4]
              },
              '&:hover': {
                color: palette.scrollToTopButton.hoverText,
                backgroundColor: 'transparent',
                borderColor: palette.scrollToTopButton.hoverBorder,
                boxShadow: theme.shadows[4]
              },
              mb: 2,
              visibility: isScrollTopVisible ? 'visible' : 'hidden'
            })}
          >
            <KeyboardArrowUp />
          </IconButton>
        )}
      </Box>
    </ShellProvider>
  )
}
