import {
  AttachementUploadConfig,
  AttachmentUpload,
  FileUploadEvent,
  FileUploadEventType
} from '@hconnect/common/components/attachments'
import {AttachmentFile} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {Skeleton} from '@mui/material'
import React, {useMemo, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../hooks/permissions/usePermission'

const MAX_ALLOWED_ATTACHMENTS = 10
const FILE_MAX_SIZE_IN_MB = 10

interface PlannerAttachmentsUploadProps {
  attachments: AttachmentFile[]
  setAttachments: (attachments: AttachmentFile[]) => void
  isDisabled?: boolean
  isLoading?: boolean
}

export const PlannerAttachmentsUpload: React.FC<PlannerAttachmentsUploadProps> = ({
  attachments,
  setAttachments,
  isDisabled = false,
  isLoading = false
}) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const canAddDocuments = usePermission('ADD_DOCUMENTS')
  const canDeleteDocuments = usePermission('DELETE_DOCUMENTS')
  const attachmentUploadConfig = useMemo(
    (): AttachementUploadConfig => ({
      allowedFormats: [
        '.pdf',
        '.jpeg',
        '.jpg',
        '.png',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx'
      ],
      maxAttachements: MAX_ALLOWED_ATTACHMENTS,
      maxFileSizeMB: FILE_MAX_SIZE_IN_MB,
      isAddingEnabled: canAddDocuments && !isDisabled,
      isDeletingEnabled: canDeleteDocuments && !isDisabled
    }),
    [canAddDocuments, canDeleteDocuments, isDisabled]
  )

  const handleUploadEvent = useCallback(
    (event: FileUploadEvent) => {
      switch (event.type) {
        case FileUploadEventType.FILE_DUPLICATED:
          return notify('info', t('common.attachments.duplicateFiles', {name: event.fileName}))
        case FileUploadEventType.FILE_SIZE_TOO_LARGE:
          return notify('info', t('common.attachments.fileIsTooLarge', {name: event.fileName}))
        case FileUploadEventType.FILE_TYPE_INVALID:
          return notify('info', t('common.attachments.fileTypeIsInvalid', {name: event.fileName}))
        case FileUploadEventType.FILE_LIMIT_REACHED:
          return notify('info', t('common.attachments.fileLimitReached', {name: event.fileName}))
        case FileUploadEventType.FILE_REMOVED:
          return notify(
            'warning',
            <>
              {t('common.attachments.fileIsRemoved', {name: event.fileName})} &nbsp;
              {t('common.attachments.undoRemove')}
            </>,
            {onClickClose: event.undoRemove}
          )
      }
    },
    [notify, t]
  )

  if (isLoading) {
    return <Skeleton variant="rectangular" sx={{width: 1, height: ({spacing}) => spacing(20)}} />
  }

  return (
    <AttachmentUpload
      attachments={attachments}
      setAttachments={setAttachments}
      attachmentsTitle={
        <CardSectionTitle variant="h4">
          {t('common.attachments.label', {
            amount: attachments.length,
            maxAmount: MAX_ALLOWED_ATTACHMENTS
          })}
        </CardSectionTitle>
      }
      uploadEventsCallback={handleUploadEvent}
      config={attachmentUploadConfig}
    />
  )
}
