import {CostAvoidanceAssetValuesForRange} from '../../../interfaces/api'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useCostAvoidanceForRangeQuery = ({
  scheduleId,
  from,
  to
}: {
  scheduleId: number
  from: string
  to: string
}) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('costAvoidanceForRange', [{plantCode, scheduleId, from, to}], {
    select: (data) => {
      const totalInit: CostAvoidanceAssetValuesForRange = {
        electricityConsumed: 0,
        electricityCost: 0,
        costAvoidance: 0,
        producedTonnes: 0
      }
      if (!data.assets) {
        return undefined
      }
      const total = Object.values(data.assets).reduce(
        (prev, next) => ({
          electricityConsumed: prev.electricityConsumed + next.electricityConsumed,
          electricityCost: prev.electricityCost + next.electricityCost,
          costAvoidance: prev.costAvoidance + next.costAvoidance,
          producedTonnes: prev.producedTonnes + next.producedTonnes
        }),
        totalInit
      )

      return {
        ...data,
        total
      }
    }
  })
}
