import {Box} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {KPI} from '../../shared/components/KPI'

import {HighlightedKPI} from './HighlightedKPI'

const kpiFontStyles = {
  fontWeight: 600,
  lineHeight: '1rem',
  marginBottom: '4px'
}

interface HighlightedKPIsProps {
  producedTons: number
  totalPrice: number
  pricePerTon: number
  pricePerMWh: number
  producedTonsDifference: number
  totalPriceDifference: number
  pricePerTonDifference: number
  pricePerMWhDifference: number
  testId?: string
}

export const HighlightedKPIs: FC<HighlightedKPIsProps> = ({
  testId,
  producedTons,
  totalPrice,
  pricePerMWh,
  pricePerTon,
  producedTonsDifference,
  totalPriceDifference,
  pricePerTonDifference,
  pricePerMWhDifference
}) => {
  const {t} = useTranslation()

  return (
    <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="8px">
      <HighlightedKPI label={t('common.producedTons')} difference={producedTonsDifference}>
        <KPI
          amount={producedTons}
          unit="tons"
          sx={kpiFontStyles}
          testId={`${testId}_produced_tons`}
        />
      </HighlightedKPI>
      <HighlightedKPI label={t('common.expectedCosts')} difference={totalPriceDifference}>
        <KPI
          amount={totalPrice}
          unit="currency"
          sx={kpiFontStyles}
          testId={`${testId}_total_price`}
        />
      </HighlightedKPI>
      <HighlightedKPI label={t('common.costsPerTon')} difference={pricePerTonDifference}>
        <KPI
          amount={pricePerTon}
          unit="currency"
          sx={kpiFontStyles}
          digits={2}
          testId={`${testId}_costs_per_ton`}
        />
      </HighlightedKPI>
      <HighlightedKPI label={t('common.costsPerMWh')} difference={pricePerMWhDifference}>
        <KPI
          amount={pricePerMWh}
          unit="currency"
          sx={kpiFontStyles}
          digits={2}
          testId={`${testId}_costs_per_mwh`}
        />
      </HighlightedKPI>
    </Box>
  )
}
