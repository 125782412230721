import {Box, Skeleton} from '@mui/material'
import React from 'react'

interface StockDevelopmentChartSkeletonProps {
  height?: string
}

export const StockDevelopmentChartSkeleton: React.FC<StockDevelopmentChartSkeletonProps> = ({
  height
}) => {
  return (
    <Box width="100%" sx={{height: ({spacing}) => height ?? spacing(35)}}>
      <Skeleton variant="rectangular" height="100%" />
    </Box>
  )
}
