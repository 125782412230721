import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'

import {PlannerFeature} from '../shared/enums'
import {usePlantConfig} from '../shared/hooks/usePlantConfigData'

import {getUrl} from './helpers'
import {ROUTES} from './router'

interface FeatureGuardedRouteProps {
  feature: PlannerFeature
}

export const FeatureGuardedRoute: React.FC<FeatureGuardedRouteProps> = ({feature}) => {
  const plantConfig = usePlantConfig()
  const isFeatureAvailable = plantConfig.available_features.includes(feature)

  if (!isFeatureAvailable) {
    return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
  }

  return <Outlet />
}
