import {dataTestId, formatCurrency} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as Illustration} from '../../../../assets/images/calculating.svg'
import type {ScheduleCostAvoidance} from '../../../../shared/interfaces/api'

import {CostAvoidanceKPI} from './CostAvoidanceKPI'

interface NegativeCostAvoidanceInfoProps {
  scheduleCostAvoidance: ScheduleCostAvoidance
}

export const NegativeCostAvoidanceInfo: React.FC<NegativeCostAvoidanceInfoProps> = ({
  scheduleCostAvoidance
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {totalCost, currency, electricityConsumed, electricityAverageMarketPrice, costAvoidance} =
    scheduleCostAvoidance
  return (
    <Stack spacing={4} alignItems="center" {...dataTestId('negative_cost_avoidance_info')}>
      <CardTitle sx={{mb: 0}} variant="h3">
        {t('planning.electricityCostOverview')}
      </CardTitle>
      <Illustration />
      <Stack spacing={2} direction="row" {...dataTestId('cost_avoidance_kpis')}>
        <CostAvoidanceKPI
          amount={formatCurrency(totalCost, 0, language)}
          currency={currency}
          label={t('electricity.cost')}
        />
        <CostAvoidanceKPI
          amount={formatCurrency(electricityConsumed !== 0 ? totalCost / electricityConsumed : 0)}
          currency={currency}
          label={t('planning.effectiveCosts')}
        />
        <CostAvoidanceKPI
          amount={formatCurrency(electricityAverageMarketPrice, 2, language)}
          currency={currency}
          label={t('electricity.marketCostsPerMWh')}
        />
        <CostAvoidanceKPI
          amount={formatCurrency(costAvoidance, 0, language)}
          currency={currency}
          label={t('planning.costAvoidance')}
          titleSx={{color: ({palette}) => palette.error.light}}
        />
      </Stack>
    </Stack>
  )
}
