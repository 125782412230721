import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useCallback, useMemo} from 'react'

import {useLoginDetails} from '../components/providers/LoginProvider'
import {PlannerTrackedFeatures} from '../enums'
import {usePlantConfig} from '../hooks/usePlantConfigData'
import {useUrlParam} from '../hooks/useUrlParam'

const optimizerEvents = [
  'onViewOptimizedPlan',
  'onAcceptOptimizedPlan',
  'onRejectOptimizedPlan',
  'onViewDetailsOptimizedPlan',
  'onViewDetailsLastSavedPlan',
  'onSwitchToViewLastSavedPlan',
  'onSwitchToViewOptimizedPlan',
  'onAcceptLastSavedPlanFromDetailView',
  'onAcceptOptimizedPlanFromDetailView'
] as const

const productionPlanningEvents = [
  'onSubmitPlanClick',
  'onEditScheduleItem',
  'onDeleteScheduleItem',
  'onAddScheduleItem',
  'onCommentAddToScheduleItem',
  'onCommentEditToScheduleItem',
  'onCommentDeleteToScheduleItem',
  'onCommentAddToAsset',
  'onCommentDeleteToAsset',
  'onCommentEditToAsset',
  'onExportProductionPlan'
] as const

const rawMaterialsPlanningEvents = ['userEntersMaterialPlan'] as const

// TODO guided tours events in the scope of HCP-65350

type PlannerEventName = (
  | typeof optimizerEvents
  | typeof productionPlanningEvents
  | typeof rawMaterialsPlanningEvents
)[number]

type PlannerEvent = {
  name: PlannerEventName
}

const mapEventsToFeatures = (
  events: readonly PlannerEventName[],
  feature: PlannerTrackedFeatures
) =>
  events.reduce(
    (acc, event) => {
      acc[event] = feature
      return acc
    },
    {} as Record<PlannerEventName, PlannerTrackedFeatures>
  )

const eventToFeatureMap: Record<PlannerEvent['name'], PlannerTrackedFeatures | undefined> = {
  ...mapEventsToFeatures(optimizerEvents, PlannerTrackedFeatures.Optimizer),
  ...mapEventsToFeatures(productionPlanningEvents, PlannerTrackedFeatures.ProductionPlanning),
  ...mapEventsToFeatures(rawMaterialsPlanningEvents, PlannerTrackedFeatures.RawMaterialsPlanning)
}

interface CommonEventData {
  plantId: string
  plantName: string
  country: string
  userId: string
}

export const useTrackPlannerEvent = () => {
  const plantId = useUrlParam('plantCode')
  const {country, name: plantName} = usePlantConfig()
  const {loginDetails} = useLoginDetails()
  if (!loginDetails) {
    throw new Error('Login details are missing')
  }
  const {userId} = loginDetails

  const commonData: CommonEventData = useMemo(
    () => ({plantId, plantName, country, userId}),
    [plantId, plantName, country, userId]
  )

  return useCallback(
    ({name}: PlannerEvent) =>
      trackEvent(name, {
        product: 'planner',
        feature: eventToFeatureMap[name],
        ...commonData
      }),
    [commonData]
  )
}
