import {useTheme} from '@mui/system'
import React, {useContext, useEffect, useRef, useState} from 'react'

interface FullscreenState {
  screenWidth: number
  isFullScreen: boolean
  setFullscreen: (boolean) => void
}

const FullscreenContext = React.createContext<FullscreenState | undefined>(undefined)

export const useFullscreen = () => {
  const context = useContext(FullscreenContext)
  if (!context) throw new Error('Cannot use fullscreen context outside of a FullscreenProvider')
  return context
}

export const FullScreenProvider = ({children}: {children: React.ReactNode}) => {
  const {spacing} = useTheme()
  const fullscreenWidthRef = useRef<HTMLHeadingElement>(null)
  const [fullscreen, setFullscreen] = useState<boolean>(true)
  const [screenWidth, setScreenWidth] = useState<number>(1280)
  useEffect(() => {
    const updateScreenWidth = () => {
      if (fullscreen && fullscreenWidthRef && fullscreenWidthRef.current)
        setScreenWidth(fullscreenWidthRef.current.offsetWidth - parseInt(spacing(6), 10) * 2)
      else setScreenWidth(1280)
    }
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)
    return () => window.removeEventListener('resize', updateScreenWidth)
  }, [fullscreen, spacing])

  return (
    <FullscreenContext.Provider value={{screenWidth, isFullScreen: fullscreen, setFullscreen}}>
      <div ref={fullscreenWidthRef}>{children}</div>
    </FullscreenContext.Provider>
  )
}
