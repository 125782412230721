import type {Env} from '@hconnect/common/hproduce'
import {settings} from '@hconnect/common/hproduce/config/settings'
import {PLANT_ID} from '@hconnect/common/hproduce/consts'
import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {Alert, HPButton} from '@hconnect/uikit/src/lib2'
import {Stack, Typography} from '@mui/material'
import moment from 'moment-timezone'
import {useTranslation} from 'react-i18next'

import {useHistoryAssetsByIdQuery} from '../../../../../../shared/hooks/api'
import {useCurrentTime} from '../../../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../../../../shared/hooks/useUrlParam'
import {ScheduleItem} from '../../../../../../shared/interfaces/api/schedule'
import {usePlanningChartStartEnd} from '../../../PlanningChartStartEndProvider'

const env = process.env.REACT_APP_STAGE as Env
const settingsBaseUrl = settings.url[env]

export const ScheduleItemStandardOperationTimeInfo = ({
  scheduleItem
}: {
  scheduleItem: ScheduleItem
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()

  const {timezone_id: timezoneId} = usePlantConfig()
  const now = useCurrentTime({timezoneId})

  const shouldShowOpenSettingsButton = moment
    .utc(scheduleItem.end)
    .isAfter(roundTo15MinIntervalStart(now))

  const {data: assetsById} = useHistoryAssetsByIdQuery({timeFrame: [startOfPlan, endOfPlan]})

  if (!assetsById) {
    throw new Error('BUG: assetsById should be loaded to render this component')
  }

  const assetType = assetsById[scheduleItem.assetId].type
  const assetId = scheduleItem.assetId

  const handleOpenSettingsClick = () => {
    window.location.replace(
      `${settingsBaseUrl.replace(PLANT_ID, plantCode)}/assets/${assetType}/id/${assetId}`
    )
  }

  return (
    <Alert
      {...dataTestId('schedule_item_standard_operation_time_info')}
      content={
        <Stack
          direction="row"
          spacing={2}
          sx={{justifyContent: 'space-between', alignItems: 'center'}}
        >
          <Typography variant="body1">{t('planning.recurringItemInfo')}</Typography>
          {shouldShowOpenSettingsButton && (
            <HPButton
              variant="text"
              color="primary"
              onClick={handleOpenSettingsClick}
              {...dataTestId('schedule_item_asset_link_button')}
            >
              {t('common.openSettings')}
            </HPButton>
          )}
        </Stack>
      }
    />
  )
}
