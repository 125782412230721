import {ArrowLeft} from '@mui/icons-material'
import {Button, ButtonProps} from '@mui/material'
import React from 'react'

interface PlannerBackButtonProps extends ButtonProps {
  text: string
  onGoBack: () => void
}

export const PlannerBackButton: React.FC<PlannerBackButtonProps> = ({
  onGoBack,
  text,
  ...otherProps
}) => {
  return (
    <Button
      variant="text"
      startIcon={<ArrowLeft />}
      sx={{color: 'primary.contrastText', opacity: 0.6, pl: 1}}
      onClick={onGoBack}
      {...otherProps}
    >
      {text}
    </Button>
  )
}
