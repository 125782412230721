import {useCallback} from 'react'

import {CommentsCategory} from '../../../enums'
import {Comment} from '../../../interfaces/api'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface UseScheduleCommentsQueryOptions {
  scheduleItemIds: string[]
  selectedScheduleItemIds: string[]
  isDisabled?: boolean
}

export const useScheduleCommentsQuery = ({
  scheduleItemIds,
  selectedScheduleItemIds,
  isDisabled = false
}: UseScheduleCommentsQueryOptions) => {
  const plantCode = useUrlParam('plantCode')

  const selectComments = useCallback(
    (comments: Comment[]) =>
      comments.filter((comment) =>
        selectedScheduleItemIds.includes(comment.commentsCategoryInstanceId)
      ),
    [selectedScheduleItemIds]
  )

  return usePlannerQuery(
    'comments',
    [
      {
        plantCode,
        commentsCategory: CommentsCategory.AssetOperationTimes,
        commentsCategoryInstanceIds: scheduleItemIds
      }
    ],
    {
      enabled: scheduleItemIds.length > 0 && !isDisabled,
      select: selectComments
    }
  )
}
