// import {StockLevelOverwrite} from '@hconnect/common/types'
import {Edit} from '@mui/icons-material'
import {BoxProps} from '@mui/material'
import React from 'react'

import {ChartPoint} from '../../d3chart/ChartPoint'

export const INDICATOR_SIZE = 2

interface StockOverwriteIndicatorProps extends BoxProps {
  x: number
  y: number
}

export const StockOverwriteIndicator: React.FC<StockOverwriteIndicatorProps> = ({
  x,
  y,
  ...props
}) => {
  return (
    <ChartPoint
      {...props}
      x={x}
      y={y}
      circleSx={{
        r: ({spacing}) => spacing(INDICATOR_SIZE),
        stroke: 'none',
        overflow: 'hidden',
        filter: 'drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.12))'
      }}
    >
      <Edit component="g" transform={'translate(-12,-12)'} />
    </ChartPoint>
  )
}
