import cockpitContactPersonImageSrc from '../assets/ContactPersonImage/cockpit.jpeg'
import {PLANT_ID} from '../consts'
import {AppConfig} from '../types'

export const COCKPIT_HOST = {
  qa: 'https://cockpit-qa.hproduce.digital',
  uat: 'https://cockpit-uat.hproduce.digital',
  prod: 'https://cockpit.hproduce.digital'
}

export const cockpit: AppConfig = {
  contact: {
    name: 'Gesa Hartmann',
    mail: 'gesa.hartmann@heidelbergmaterials.com',
    profileImage: cockpitContactPersonImageSrc
  },
  name: 'Cockpit',
  url: {
    qa: `${COCKPIT_HOST.qa}/${PLANT_ID}`,
    uat: `${COCKPIT_HOST.uat}/${PLANT_ID}`,
    prod: `${COCKPIT_HOST.prod}/${PLANT_ID}`
  },

  // 'VIEW_SHIFT_REPORT' and 'VIEW_PLANT_EVENT' is unfortunately also used by RELIABILITY_CONTRIBUTOR
  whiteListedPermissionTypes: ['VIEW_PLANT_TASK']
}

// route to cockpit plant events page
export const PLANT_EVENTS = '/:plantId/events'
