import * as Common from '@microsoft/applicationinsights-common'
import {ITelemetryItem, ICustomProperties} from '@microsoft/applicationinsights-core-js'
import {Tags} from '@microsoft/applicationinsights-core-js/src/JavaScriptSDK.Interfaces/ITelemetryItem'
import {ApplicationInsights, DistributedTracingModes} from '@microsoft/applicationinsights-web'
import jwtDecode from 'jwt-decode'
import {get} from 'lodash'
import moment from 'moment'

import {getAppStage} from '../../../appStages'
import {loginStorage} from '../../../authentication/loginClasses'
import {isDev} from '../../lib'

// This is implemented so obtusely because webpack's environment / define plugin does a search and replace for `process.env...` to replace the code with a specific string.
export const getConnectionStringFromEnvVariable = (): string | undefined => {
  const stage = isDev() ? 'DEV' : getAppStage()
  if (typeof stage !== 'string') {
    console.error('Environment variable REACT_APP_STAGE was not present')
    return
  }
  const vars = {
    QA: process.env.REACT_APP_QA_AZURE_CONNECTION_STRING,
    UAT: process.env.REACT_APP_UAT_AZURE_CONNECTION_STRING,
    UATR: process.env.REACT_APP_UATR_AZURE_CONNECTION_STRING,
    REGRESSION: process.env.REACT_APP_REGRESSION_AZURE_CONNECTION_STRING,
    PROD: process.env.REACT_APP_PROD_AZURE_CONNECTION_STRING
  }
  const variable = isDev()
    ? process.env.REACT_APP_DEV_AZURE_CONNECTION_STRING
    : vars[stage.toUpperCase()]
  if (!variable && !isDev())
    console.error(
      `Environment variable REACT_APP_${stage.toUpperCase()}_AZURE_CONNECTION_STRING was not present`
    )
  else return variable
}
const getAppId = (): string | undefined => {
  const appId = process.env.REACT_APP_ANALYTICS_APP_ID
  if (!appId && !isDev()) {
    console.error(
      'Could not identify app id from environment variable REACT_APP_ANALYTICS_APP_ID or REACT_APP_STAGE'
    )
  } else return appId
}

type TelemetryInitializer = (item: ITelemetryItem) => boolean | void

/**
 * Singleton that gives access to an azure monitor app insights instance and sets it up.  If the instance is not needed then one can simply run `AzureMonitorInitializer.initialize()` at the start of your app and and monitoring is automatically enabled.
 */
export class AppInsightsAdapter {
  private static AppInsights: ApplicationInsights
  private static canTrack = false
  private static UNTRACKED_USER_ID = 'untrackedUser'
  private static NULL_USER_ID = 'unknownUser'

  static allowTracking() {
    AppInsightsAdapter.canTrack = true
    if (AppInsightsAdapter.AppInsights) {
      AppInsightsAdapter.AppInsights.config.disableTelemetry = false
      AppInsightsAdapter.AppInsights.config.isCookieUseDisabled = false
      AppInsightsAdapter.AppInsights.config.isStorageUseDisabled = false
      AppInsightsAdapter.AppInsights.config.disableCookiesUsage = false
    }
  }

  static stopTracking() {
    AppInsightsAdapter.canTrack = false
    if (AppInsightsAdapter.AppInsights) {
      AppInsightsAdapter.AppInsights.config.disableTelemetry = true
      AppInsightsAdapter.AppInsights.config.isCookieUseDisabled = true
      AppInsightsAdapter.AppInsights.config.isStorageUseDisabled = true
      AppInsightsAdapter.AppInsights.config.disableCookiesUsage = true
    }
  }

  public static init(): ApplicationInsights | undefined {
    if (AppInsightsAdapter.AppInsights) return AppInsightsAdapter.AppInsights
    const connectionString = getConnectionStringFromEnvVariable()
    const appId = getAppId()

    const getUserId = (): string => {
      const {accessToken} = loginStorage.getToken()
      if (!accessToken) return AppInsightsAdapter.NULL_USER_ID
      const decoded = jwtDecode(accessToken)
      const userId = get(decoded, 'user_id', null)
      if (!userId) return AppInsightsAdapter.NULL_USER_ID
      return AppInsightsAdapter.canTrack ? userId : AppInsightsAdapter.UNTRACKED_USER_ID
    }

    if (isDev() && !connectionString) {
      console.log(
        'If you wish to enable app insights locally then you must uncomment the REACT_APP_DEV_AZURE_CONNECTION_STRING env variable'
      )
      return
    }
    if (!connectionString) {
      console.error('🚨 Could not initialize app insights with empty connection string')
    } else {
      AppInsightsAdapter.AppInsights = new ApplicationInsights({
        config: {
          correlationHeaderExcludedDomains: [
            'lehigh-qrl-web.delego-cloud.com',
            'lehigh-qrk-web.delego-cloud.com',
            'lehigh-prs-web.delego-cloud.com',
            'hanson-acq-rp.delego-cloud.com',
            'hanson-acp-rp.delego-cloud.com',
            'maps.googleapis.com'
          ],
          connectionString: connectionString,
          enableAutoRouteTracking: true,
          disableFetchTracking: false,
          enableCorsCorrelation: true,
          disableAjaxTracking: false,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          isCookieUseDisabled: true,
          isStorageUseDisabled: true,
          enableSessionStorageBuffer: true,
          disableTelemetry: false,
          distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
          appId
        }
      })
      AppInsightsAdapter.AppInsights.loadAppInsights()

      const addCloudRole: TelemetryInitializer = (envelope) => {
        envelope.tags = [
          {
            ...(envelope.tags ?? {}),
            ...{
              'ai.cloud.role': appId,
              'ai.cloud.roleInstance': getAppStage()
            }
          }
        ] as Tags[]
      }

      /**
       * Removes JWT completely.
       * We have decided not to only strip JWT signature due to the fact,
       * that token could still be decoded and email + other personal data
       * would be visible.
       */
      const removeJWT: TelemetryInitializer = (envelope) => {
        if (envelope?.baseData?.properties?.requestHeaders?.Authorization) {
          envelope.baseData.properties.requestHeaders.Authorization = 'restricted'
        }
      }

      const requireUserConsent: TelemetryInitializer = () => {
        if (!AppInsightsAdapter.canTrack) {
          return false
        }
      }

      const checkCypress: TelemetryInitializer = () => {
        if ((window as any).Cypress) {
          return false
        }
      }

      const injectUserId: TelemetryInitializer = (envelope: ITelemetryItem) => {
        const userId: string = getUserId()
        if (envelope.baseData) {
          envelope.baseData.properties.user_uuid = userId
        }
        AppInsightsAdapter.AppInsights.context.user.id = userId
      }

      const injectTimestamp: TelemetryInitializer = (envelope: ITelemetryItem) => {
        envelope.data = envelope.data || {}
        const timestamp = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]')
        envelope.data.timestamp = timestamp
      }

      AppInsightsAdapter.AppInsights.addTelemetryInitializer(requireUserConsent)
      AppInsightsAdapter.AppInsights.addTelemetryInitializer(addCloudRole)
      AppInsightsAdapter.AppInsights.addTelemetryInitializer(removeJWT)
      AppInsightsAdapter.AppInsights.addTelemetryInitializer(checkCypress)
      AppInsightsAdapter.AppInsights.addTelemetryInitializer(injectUserId)
      AppInsightsAdapter.AppInsights.addTelemetryInitializer(injectTimestamp)
      return AppInsightsAdapter.AppInsights
    }
  }

  public static trackException(exception: Common.IExceptionTelemetry) {
    AppInsightsAdapter.AppInsights?.trackException(exception)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static trackEvent(eventName: string, customProperties?: {[key: string]: any}) {
    AppInsightsAdapter.AppInsights?.trackEvent({name: eventName}, customProperties)
  }

  public static trackTrace(trace: Common.ITraceTelemetry, customProperties?: ICustomProperties) {
    AppInsightsAdapter.AppInsights?.trackTrace(trace, customProperties)
  }
}
