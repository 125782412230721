import {useParams} from 'react-router-dom'

import {UrlParams} from '../../routing'

/**
 * Typed wrapper around react router's `useParams` hook that gets a set of known parameters from the current URL
 */
export const useTryGetUrlParam = (param: keyof UrlParams) => {
  const obtainedParams = useParams<Partial<UrlParams>>()
  const parameter = obtainedParams[param]
  return parameter
}
