import useSize from '@react-hook/size'
import {useRef} from 'react'

/**
 * wrapper around useSize hook to get width of the element
 * @returns
 */
export const useElementWidth = <T extends HTMLElement = HTMLDivElement>(): [
  React.RefObject<T>,
  number
] => {
  const ref = useRef<T>(null)
  const [width] = useSize(ref)
  return [ref, width]
}
