import {AxiosRequestConfig} from 'axios'
import {nanoid} from 'nanoid'

export const requestIdHeaderInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const headers = {
    ...config.headers,
    'Request-ID': nanoid()
  }

  return {...config, headers}
}
