import AU from './assets/flags/AU.png'
import BA from './assets/flags/BA.png'
import BE from './assets/flags/BE.png'
import CN from './assets/flags/CN.png'
import CZ from './assets/flags/CZ.png'
import DE from './assets/flags/DE.png'
import DK from './assets/flags/DK.png'
import ES from './assets/flags/ES.png'
import FR from './assets/flags/FR.png'
import GB from './assets/flags/GB.png'
import GE from './assets/flags/GE.png'
import HK from './assets/flags/HK.png'
import HR from './assets/flags/HR.png'
import KZ from './assets/flags/KZ.png'
import NL from './assets/flags/NL.png'
import NO from './assets/flags/NO.png'
import PL from './assets/flags/PL.png'
import RS from './assets/flags/RS.png'
import RU from './assets/flags/RU.png'
import SE from './assets/flags/SE.png'
import HU from './assets/flags/svg/HU.svg'
import IT from './assets/flags/svg/IT.svg'
import RO from './assets/flags/svg/RO.svg'
import TH from './assets/flags/TH.png'
import US from './assets/flags/US.png'

const flags: {[key: string]: string | React.FC<React.SVGProps<SVGSVGElement>>} = {
  en: GB,
  de: DE,
  ru: RU,
  es: ES,
  se: SE,
  nl: NL,
  fr: FR,
  it: IT,
  pl: PL,
  ro: RO,
  no: NO,
  hu: HU,
  cs: CZ,
  'bs-BA': BA,
  'da-DK': DK,
  'de-DE': DE,
  'de-BE': DE,
  'en-AU': AU,
  'en-CA': GB,
  'en-DK': US,
  'en-GB': GB,
  'en-HK': GB,
  'en-SE': US,
  'en-US': US,
  'en-NO': US,
  'es-ES': ES,
  'es-US': ES,
  'fr-CA': FR,
  'fr-BE': FR,
  'fr-FR': FR,
  'hr-BS': HR,
  'hu-HU': HU,
  'ka-GE': GE,
  'kk-KZ': KZ,
  'nl-NL': NL,
  'nl-BE': BE,
  'nn-NO': NO,
  'no-NO': NO,
  'pl-PL': PL,
  'ro-RO': RO,
  'rs-BS': RS,
  'ru-RU': RU,
  'ru-KK': RU,
  'sv-SE': SE,
  'th-TH': TH,
  'zh-CN': CN,
  'zh-HK': HK,
  'it-IT': IT
}

export {flags}
