import {Box} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {GanttChartLabel} from '../../../../shared/components/ganttChart/rowLabels/GanttChartLabel'
import {dataTestId} from '@hconnect/uikit'
import {toPixel} from '../../../../shared/helpers/utils'
import {useAssetCommentsQuery} from '../../../../shared/hooks/api'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {AssetResponse} from '../../../../shared/interfaces/api'

import {AssetLabel} from './AssetLabel'
export interface PlanningChartLabelsProps {
  sortedAssets: AssetResponse[]
  hourlyPriceHeight: number
  cellHeight: number
  width: number
  isReadOnly: boolean
  shouldShowPriceInfo: boolean
  shouldShowAssetComments?: boolean
}

export const PlanningChartLabels: FC<PlanningChartLabelsProps> = React.memo(
  ({
    sortedAssets,
    width,
    cellHeight,
    hourlyPriceHeight,
    isReadOnly,
    shouldShowPriceInfo,
    shouldShowAssetComments = false
  }) => {
    const {currency} = usePlantConfig()
    const assetIds = sortedAssets.map((asset) => asset.id)
    const numberOfAssets = sortedAssets.length

    const {data: comments} = useAssetCommentsQuery(assetIds, !shouldShowAssetComments)
    const {t} = useTranslation()

    const getAssetComment = (assetId: number) =>
      comments?.find(
        ({commentsCategoryInstanceId}) => commentsCategoryInstanceId === assetId.toString()
      )

    const labelWidth = width
    const gridTemplateRows = ((): string => {
      const priceRowTemplate = shouldShowPriceInfo ? toPixel(hourlyPriceHeight) : ''
      return `${priceRowTemplate} repeat(${numberOfAssets}, ${toPixel(cellHeight)})`
    })()
    const gridTemplateColumns = toPixel(labelWidth)

    return (
      <Box
        sx={{
          display: 'grid',
          width,
          gridTemplateRows,
          gridTemplateColumns
        }}
        {...dataTestId('planning_chart_label_container')}
      >
        {shouldShowPriceInfo && (
          <GanttChartLabel
            title={t('planning.hourlyPriceRowLabel', {currency})}
            sx={{width: labelWidth, pr: 3}}
          />
        )}
        {sortedAssets?.map((asset) => (
          <AssetLabel
            key={asset.id}
            asset={asset}
            comment={getAssetComment(asset.id)}
            width={labelWidth}
            isReadOnly={isReadOnly}
            shouldShowComment={shouldShowAssetComments}
          />
        ))}
      </Box>
    )
  }
)
PlanningChartLabels.displayName = 'PlanningChartLabels'
