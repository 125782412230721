import {useMemo} from 'react'

import {MomentRange} from '../selectors/time'

import {useStartOfToday} from './useStartOfToday'

interface UsePlanRangeParams {
  days?: number
  createdAt: string
  timezoneId: string
}

export const usePlanRange = ({
  createdAt,
  timezoneId,
  days = 8
}: UsePlanRangeParams): MomentRange => {
  const startOfPlan = useStartOfToday({createdAt, timezoneId})

  return useMemo(() => {
    const endOfPlan = startOfPlan.clone().add(days, 'day')
    return [startOfPlan, endOfPlan]
  }, [startOfPlan, days])
}
