import {ButtonProps} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {getUrl, PLANT_ROUTES} from '../../routing'
import {PlannerBackButton} from '../../shared/components/buttons'
import {useUrlParam} from '../../shared/hooks/useUrlParam'

interface OptimizerBackButtonProps extends ButtonProps {
  to: 'summary' | 'planning'
}

export const OptimizerBackButton: FC<OptimizerBackButtonProps> = ({to, ...otherProps}) => {
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()
  const {t} = useTranslation()
  const planningRoute = PLANT_ROUTES.PRODUCTION_PLANNING
  const url = getUrl(to === 'summary' ? planningRoute.OPTIMIZER.SUMMARY.path : planningRoute.path, {
    plantCode
  })
  const buttonText =
    to === 'summary' ? t('navItems.backToSummary') : t('navItems.backToMillPlanning')
  return <PlannerBackButton text={buttonText} onGoBack={() => navigate(url)} {...otherProps} />
}
