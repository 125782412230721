import {useCallback, useEffect} from 'react'

import {useHorizontalDrag} from './useHorizontalDrag'
import {useWheelScroll} from './useWheelScroll'

interface ScrollDragParams {
  ref: React.RefObject<HTMLDivElement>
  stepWidth: number
  callback: (delta: number) => void
  multiplier?: number
  isDragEnabled?: boolean
}

export const useScrollDrag = ({
  ref,
  stepWidth,
  callback,
  multiplier = 1,
  isDragEnabled = false
}: ScrollDragParams) => {
  const {deltaX} = useHorizontalDrag(ref)
  const {delta: deltaScroll, setDelta: setDeltaScroll} = useWheelScroll(ref, true)

  const handleChartScrollWheel = useCallback(
    (deltaY: number) => {
      const stepsPerScroll = multiplier
      const deltaSteps = stepsPerScroll * Math.sign(deltaY)
      callback(deltaSteps)
      setDeltaScroll(0)
    },
    [multiplier, callback, setDeltaScroll]
  )

  const handleChartDrag = useCallback(
    (deltaX: number) => {
      const deltaSteps = Math.floor(deltaX / stepWidth) * multiplier
      callback(deltaSteps)
    },
    [stepWidth, callback, multiplier]
  )

  useEffect(() => {
    if (!deltaX || !isDragEnabled) return
    handleChartDrag(deltaX)
  }, [deltaX, handleChartDrag, isDragEnabled])

  useEffect(() => {
    if (!deltaScroll) return
    handleChartScrollWheel(deltaScroll)
  }, [deltaScroll, handleChartScrollWheel])
}
