import {Status} from '@hconnect/common/types'
import moment, {Moment} from 'moment-timezone'

import {AssetType, OperationModeType} from '../enums'
import {toRecord} from '../helpers/utils'
import {
  AssetResponse,
  AssetsHistory,
  OperationModeResponse,
  OperationModeResponseProduction,
  OperationModeResponseProducing,
  ScheduleItemDict
} from '../interfaces/api'

import {getRelevantHistoryEntry} from './history'

export const groupAndSortAssets = (assets: AssetResponse[]): Record<AssetType, AssetResponse[]> => {
  const assetsByType: Record<AssetType, AssetResponse[]> = {
    [AssetType.CementMill]: [],
    [AssetType.RawMill]: [],
    [AssetType.RotaryKiln]: [],
    [AssetType.CoalMill]: [],
    [AssetType.Crusher]: [],
    [AssetType.Other]: [],
    [AssetType.BaseLoad]: []
  }
  assets.forEach((asset) => {
    asset.operationModes = sortOperationModes(asset.operationModes)
    assetsByType[asset.type].push(asset)
  })
  Object.entries(assetsByType).forEach(([type, assets]) => {
    assetsByType[type] = assets.sort((a, b) => a.name.localeCompare(b.name))
  })
  return assetsByType
}

export const sortAssets = (assets: AssetResponse[]): AssetResponse[] =>
  Object.values(groupAndSortAssets(assets)).flatMap((assets) => assets)

export const sortOperationModes = (
  operationModes: OperationModeResponse[]
): OperationModeResponse[] => {
  const modesByType: Record<OperationModeType, OperationModeResponse[]> = {
    [OperationModeType.Production]: [],
    [OperationModeType.Maintenance]: []
  }
  operationModes.forEach((mode) => modesByType[mode.type].push(mode))

  Object.entries(modesByType).forEach(
    ([type, modes]) => (modesByType[type] = modes.sort((a, b) => a.name.localeCompare(b.name)))
  )
  return Object.values(modesByType).flatMap((modes) => modes)
}

export const getOperationModeIdToAssetIdMap = (assets: AssetResponse[]) =>
  assets.reduce<Record<string, number>>((operationModeToAssetsMap, item) => {
    const operationModesToAsset = item.operationModes.reduce<Record<string, number>>(
      (acc, operationMode) => ({...acc, [operationMode.id]: item.id}),
      {}
    )
    return {
      ...operationModeToAssetsMap,
      ...operationModesToAsset
    }
  }, {})

export const guardProducingMode = (
  operationMode: OperationModeResponse
): operationMode is OperationModeResponseProducing =>
  !!operationMode.recipeId && !!operationMode.throughput
export const guardProductionMode = (
  operationMode: OperationModeResponse
): operationMode is OperationModeResponseProduction =>
  operationMode.type === OperationModeType.Production

export const getOperationModesById = (assets: AssetResponse[]) => {
  const operationModes = assets.flatMap((asset) => asset.operationModes)
  return toRecord(operationModes, 'id')
}

export const getAssetsById = (assets: AssetResponse[]) => toRecord(assets, 'id')

export const getBaseLoadPowerConsumptionFromAssets = (assets: AssetResponse[]) => {
  const baseLoadAssets = assets.filter((asset) => asset.type === AssetType.BaseLoad)
  const baseloadOperationModes = baseLoadAssets[0].operationModes.filter(guardProductionMode)
  return baseloadOperationModes[0]?.powerConsumption ?? 0
}

const basicBaseLoadError = 'Please check the base load assets in the Assets configuration.'

/**
 * function to validate if base load asset is added correctly from BE
 */

export const validateBaseLoadInAssets = (assets: AssetResponse[]) => {
  const baseLoadAssets = assets.filter((asset) => asset.type === AssetType.BaseLoad)
  if (baseLoadAssets.length === 0)
    throw new Error(`Plant has no base load specified. ${basicBaseLoadError}`)
  if (baseLoadAssets.length > 1)
    throw new Error(`Plant has more than 1 base load asset specified. ${basicBaseLoadError}`)

  const baseloadOperationModes = baseLoadAssets[0].operationModes.filter(
    (operationMode) =>
      operationMode.type === OperationModeType.Production && operationMode.status !== Status.Deleted
  )
  if (baseloadOperationModes.length === 0)
    throw new Error(
      `Base load asset has no valid production operation modes with a base load value. ${basicBaseLoadError}`
    )
  if (baseloadOperationModes.length > 1)
    throw new Error(
      `Base load asset has more than one production operation mode. ${basicBaseLoadError}`
    )
}

export type AssetWithOperationMode = {
  asset: AssetResponse
  operationMode: OperationModeResponse
}

// function to map scheduleItemId to the corresponding asset and operation mode in history
export const mapScheduleItemIdToAssetData = (
  scheduleItemDict: ScheduleItemDict,
  assetsHistory: AssetsHistory
): Record<string, AssetWithOperationMode> => {
  return Object.values(scheduleItemDict).reduce<Record<string, AssetWithOperationMode>>(
    (acc, scheduleItem) => {
      const assetHistory: AssetResponse[] | undefined = assetsHistory[scheduleItem.assetId]
      if (!assetHistory) {
        throw new Error(
          `BUG:No asset history found for asset ${scheduleItem.assetId} for the given scheduleItem ${scheduleItem.id}`
        )
      }
      // we need to check only end date to simplify things
      const asset = getRelevantHistoryEntry(assetHistory, moment.utc(scheduleItem.end))
      const operationMode = asset.operationModes.find(
        (operationMode) => operationMode.id === scheduleItem.assetOperationModeId
      )
      if (!operationMode) {
        throw new Error(
          `BUG:No operation mode found for the given scheduleItem ${scheduleItem.id} on asset ${scheduleItem.assetId}`
        )
      }
      acc[scheduleItem.id] = {
        asset,
        operationMode
      }
      return acc
    },
    {}
  )
}

/**
 * function to get the assets from the history by date
 * we are checking on to date, because it's possible that the first history entry is outside the range
 * in that case, we should take newest entry available before the range
 * if asset was deleted in this range, the last history entry would have status Deleted
 */
export const getAssetsFromHistoryByDate = (
  assetsHistory: AssetsHistory,
  to: Moment
): AssetResponse[] => {
  return Object.values(assetsHistory).map((assetHistory) =>
    getRelevantHistoryEntry(assetHistory, to)
  )
}

// function to get the base load power consumption from the history assets
export const getBaseLoadPowerConsumptionFromHistoryAssets = (
  assetsHistory: AssetsHistory,
  date: Moment
) => {
  const assets = getAssetsFromHistoryByDate(assetsHistory, date)
  return getBaseLoadPowerConsumptionFromAssets(assets)
}

/**
 * function to get assets by id dictionary from assets history for a specific date
 */
export const getHistoryAssetsById = (assetsHistory: AssetsHistory, date: Moment) => {
  const assets = getAssetsFromHistoryByDate(assetsHistory, date)
  return toRecord(assets, 'id')
}
