import {AttachFile} from '@mui/icons-material'
import {Typography, Stack, Link, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IconWithPopover} from '../../../../shared/components/IconWithPopover'
import {useAttachmentFilesQuery} from '../../../../shared/hooks/api'
import {MaterialOrderAttachment} from '../../../../shared/interfaces/api'

interface MaterialOrderAttachmentMenuProps {
  attachments: MaterialOrderAttachment[]
}
export const MaterialOrderAttachmentDownloadMenu: React.FC<MaterialOrderAttachmentMenuProps> = ({
  attachments
}) => {
  const {t} = useTranslation()
  const {data: files} = useAttachmentFilesQuery({attachments, isPreview: false})

  if (attachments.length === 0) {
    return null
  }
  return (
    <Box>
      <IconWithPopover
        closeOnClick
        iconSize="small"
        icon={<AttachFile color="primary" />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        popoverPaperSx={{
          p: 2,
          backgroundColor: 'common.white'
        }}
      >
        <Stack spacing={2}>
          <Typography variant="body1">{t('stock.materialOrders.attachedFilesInfo')}</Typography>
          {files?.map((file) => (
            <Link key={file.id} href={file.preview} download={file.name}>
              {file.name}
            </Link>
          ))}
        </Stack>
      </IconWithPopover>
    </Box>
  )
}
