import {MaterialStoragesHistory, MaterialType, Status} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {
  getStoragesFromHistory,
  isHistoryStorageWithMaterialGuard
} from '../../../selectors/storages'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

interface SelectorParams {
  storageHistory: MaterialStoragesHistory
  date: Moment
  hideDeleted: boolean
}

const selector = ({storageHistory, date, hideDeleted}: SelectorParams) => {
  const storagesFromHistory = getStoragesFromHistory(storageHistory, date)
  const storagesTypesWithMaterial = storagesFromHistory
    .filter(isHistoryStorageWithMaterialGuard)
    .filter((storage) => (hideDeleted ? storage.status !== Status.Deleted : true))
    .map((storage) => storage.materialType)

  return [...new Set(storagesTypesWithMaterial)]
}

interface StoredMaterialIdsQueryParams {
  materialType?: MaterialType
  timeFrame: MomentRange
  date?: Moment
  hideDeleted?: boolean
}

export const useStoredMaterialTypesFromHistoryQuery = ({
  timeFrame: [from, to],
  date,
  hideDeleted = true
}: StoredMaterialIdsQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery(
    'materialStorageHistory',
    [plantCode, from.toISOString(), to.toISOString()],
    {
      select: (data) => selector({storageHistory: data, date: date ?? to, hideDeleted})
    }
  )
}
