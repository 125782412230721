import {useTheme} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'

import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {DatetimeValue} from '../../interfaces/common'

interface ChartPoints {
  datetimeValues: DatetimeValue<string>[]
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  testId?: string
}

export const ChartPoints: React.FC<ChartPoints> = ({datetimeValues, xScale, yScale}) => {
  const {palette} = useTheme()
  return (
    <>
      {datetimeValues.map(({value, datetime}, index) => (
        <circle
          key={index}
          cx={xScale(moment.utc(datetime))}
          cy={yScale(value)}
          r={6}
          fill={palette.common.white}
          style={{
            zIndex: 1,
            strokeWidth: 2,
            stroke: palette.primary.light
          }}
        />
      ))}
    </>
  )
}
