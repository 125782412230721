import {Moment} from 'moment-timezone'

import {DatetimeValue} from '../../interfaces/common'

export const calculateDeltaValue = (
  currentValue: number,
  currentDay: Moment,
  startDay: Moment,
  endDay: Moment
): number => {
  const hoursRange = startDay.diff(endDay, 'hours')
  const currentHours = startDay.diff(currentDay, 'hours')
  return (currentValue / hoursRange) * currentHours
}

export const getTargetDatetimeValues = (
  listOfDays: Moment[],
  weekValue: number,
  weekendValue: number,
  timezoneId: string
): DatetimeValue[] => {
  return listOfDays.flatMap<DatetimeValue>((day) => {
    const utcOffset = day.clone().tz(timezoneId).utcOffset()
    const adjustedDay = day.clone().add(utcOffset, 'minutes')
    const isSunday = adjustedDay.day() === 0
    const isMonday = adjustedDay.day() === 1
    const datetimeIso = day.toISOString()

    if (weekendValue > weekValue) {
      const weekendWeekDiff = weekendValue - weekValue
      if (isSunday) {
        const value = calculateDeltaValue(
          weekendWeekDiff,
          day,
          day.clone().subtract(1, 'day'),
          day.clone().add(1, 'day').add(6, 'hours')
        )
        return [{datetime: datetimeIso, value: weekValue + value}]
      }
      if (isMonday) {
        const startDate = day.clone().subtract(2, 'day')
        const endDate = day.clone().add(6, 'hours')
        const value = calculateDeltaValue(weekendWeekDiff, day, startDate, endDate)
        return [
          {datetime: datetimeIso, value: value + weekValue},
          {datetime: endDate.toISOString(), value: weekendValue},
          {datetime: endDate.toISOString(), value: weekValue}
        ]
      }
    }

    return [{datetime: datetimeIso, value: weekValue}]
  })
}
