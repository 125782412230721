import {scaleTime, scaleUtc, scaleLinear, scaleBand, ScaleTime, ScaleLinear, ScaleBand} from 'd3'
import {useMemo} from 'react'

import type {MomentRange, NumberRange} from '../selectors/time'

export type BaseScaleFnType = number

export type TimeScaleFn<
  Range extends BaseScaleFnType = BaseScaleFnType,
  Output = Range,
  Unknown = never
> = ScaleTime<Range, Output, Unknown>

export type LinearScaleFn<
  Range extends BaseScaleFnType = BaseScaleFnType,
  Output = Range,
  Unknown = never
> = ScaleLinear<Range, Output, Unknown>

export type BandScaleFn<Domain extends BaseScaleFnType = BaseScaleFnType> = ScaleBand<Domain>

export type ScaleFn<
  Range extends BaseScaleFnType = BaseScaleFnType,
  Output = Range,
  Unknown = never
> = TimeScaleFn<Range, Output, Unknown> | LinearScaleFn<Range, Output, Unknown> | BandScaleFn<Range>
interface GetDateScaleParams {
  domain: MomentRange
  range: NumberRange
  isUTC?: boolean
}

export const getDateScale = ({domain, range, isUTC = false}: GetDateScaleParams) =>
  (isUTC ? scaleUtc : scaleTime)().domain(domain).range(range)

export const useDateScale = ({
  range: [rangeStart, rangeEnd],
  domain: [domainStart, domainEnd],
  isUTC = false
}: GetDateScaleParams) =>
  useMemo(
    () => getDateScale({range: [rangeStart, rangeEnd], domain: [domainStart, domainEnd], isUTC}),
    [rangeStart, rangeEnd, domainStart, domainEnd, isUTC]
  )

interface GetLinearScaleParams {
  domain: NumberRange
  range: NumberRange
  isRangeRounded?: boolean
}
export const getLinearScale = ({domain, range, isRangeRounded}: Required<GetLinearScaleParams>) => {
  const scale = scaleLinear().domain(domain)
  return isRangeRounded ? scale.rangeRound(range) : scale.range(range)
}
export const useLinearScale = ({
  domain: [domainStart, domainEnd],
  range: [rangeStart, rangeEnd],
  isRangeRounded = false
}: GetLinearScaleParams) =>
  useMemo(
    () =>
      getLinearScale({
        range: [rangeStart, rangeEnd],
        domain: [domainStart, domainEnd],
        isRangeRounded
      }),
    [rangeStart, rangeEnd, domainStart, domainEnd, isRangeRounded]
  )

interface GetBandScaleParams {
  domain: number[]
  range: NumberRange
  padding?: number
}

export const getBandScale = ({domain, range, padding}: Required<GetBandScaleParams>) =>
  scaleBand<number>().domain(domain).rangeRound(range).padding(padding)

export const useBandScale = ({domain, range, padding = 0.2}: GetBandScaleParams) =>
  useMemo(() => getBandScale({domain, range, padding}), [domain, range, padding])
