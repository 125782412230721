import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {getHistoryAssetsById} from '../../../selectors/assets'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

interface HistoryAssetsByIdQueryParams {
  timeFrame: MomentRange
  // date which indicates point in time for which assets should be selected from history
  // Example: for planning we usually want date to be end of the loaded portion of the plan
  date?: Moment
  useErrorBoundary?: boolean
}
/**
 * hook to retrieve assets by id dictionary from assets history
 */
export const useHistoryAssetsByIdQuery = ({
  timeFrame: [startOfPlan, endOfPlan],
  date,
  useErrorBoundary = true
}: HistoryAssetsByIdQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery(
    'assetsHistory',
    [plantCode, startOfPlan.toISOString(), endOfPlan.toISOString()],
    {
      select: (assetsHistory) => {
        // if date not provided, uses endOfPlan value
        return getHistoryAssetsById(assetsHistory, date ?? endOfPlan)
      },
      useErrorBoundary
    }
  )
}
