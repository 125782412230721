import React from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'

import {PageNotFound} from '../dynamicImports'
import {getRelPath, ROUTES} from '../routing'
import {DefaultPageRedirect, EntryPointRedirect} from '../routing/redirects'

import {GuardedRoutes} from './GuardedRoutes'
import {PageForbidden} from './page-not-authorized/PageForbidden'
import {PagePlant} from './page-plant/PagePlant'

export const App: React.FC = () => (
  <Routes>
    <Route path={getRelPath(ROUTES.BASE.path)}>
      <Route path={getRelPath(ROUTES.BASE.PLANT_SELECTOR.path)}>
        {/* to select a plant we are doing redirect to HProduce Entry Point app */}
        <Route index element={<EntryPointRedirect />} />
        <Route path={getRelPath(ROUTES.BASE.PLANT_SELECTOR.PLANT.path)} element={<PagePlant />}>
          {/* routes which require role and feature check */}
          <Route index element={<DefaultPageRedirect />} />
          <Route path="*" element={<GuardedRoutes />} />
        </Route>
      </Route>
      <Route
        path=""
        element={<Navigate to={getRelPath(ROUTES.BASE.PLANT_SELECTOR.path)} replace />}
      />
      {/* Error routes */}
      <Route path={getRelPath(ROUTES.BASE.NOT_FOUND.path)} element={<PageNotFound />} />
      <Route path={getRelPath(ROUTES.BASE.NOT_AUTHORIZED.path)} element={<PageForbidden />} />
      {/* No match route */}
      <Route path="*" element={<Navigate to={getRelPath(ROUTES.BASE.NOT_FOUND.path)} replace />} />
    </Route>
  </Routes>
)
