import {useCallback, useMemo} from 'react'
import {useLocation, useSearchParams as useRouterSearchParams} from 'react-router-dom'

import {SearchParamKey} from '../../routing'

/**
 * Typed wrapper around react router's `useSearchParams` hook that gets a set of known search parameters
 */

export const useSearchParams = <P extends SearchParamKey, V extends Record<P, string | null>>(
  ...paramNames: P[]
): [V, (newValues: V) => void] => {
  const {pathname, search} = useLocation()
  const [obtainedSearchParams, setRouterSearchParams] = useRouterSearchParams()

  const obtainedValues = useMemo(
    () =>
      paramNames.reduce(
        (acc, paramName) => {
          const value = obtainedSearchParams.get(paramName)
          acc[paramName] = value
          return acc
        },
        {} as Record<P, string | null>
      ),
    [paramNames, obtainedSearchParams]
  )

  // we are using callback here because this fn could be debounced and a stable reference is needed to debounce work correctly
  const setSearchParams = useCallback(
    (params: Record<P, string | null>) => {
      setRouterSearchParams((URLSearchParams) => {
        Object.entries(params).map(([name, value]) => {
          if (value === null) URLSearchParams.delete(name)
          else URLSearchParams.set(name, value as string)
        })
        return URLSearchParams
      })
    },
    // we need to redefine this fn only when pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, search]
  )

  return [obtainedValues as V, setSearchParams]
}
