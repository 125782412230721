import {dataTestId, formatCurrency} from '@hconnect/uikit/src/common'
import {Typography} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {toPixel} from '../../../helpers/utils'
import {PricePerHour} from '../../../interfaces/common'
import {FixedRepeatingGrid} from '../../FixedRepeatingGrid'

export interface ElectricityPriceTopBarNumbersProps {
  hoursList: Moment[]
  hourlyPriceHeight: number
  cellWidth: number
  pricePerHour: PricePerHour | undefined
  isTextVisible: boolean
  visibleHours: Moment[]
}

export const _ElectricityPriceTopBarNumbers: React.FC<ElectricityPriceTopBarNumbersProps> = ({
  hoursList,
  hourlyPriceHeight,
  cellWidth,
  pricePerHour,
  isTextVisible,
  visibleHours
}) => {
  const {
    i18n: {language}
  } = useTranslation()
  const getHourlyPrice = (date: Moment): string => {
    const dateKey = date.toISOString()
    return pricePerHour?.[dateKey] !== undefined
      ? formatCurrency(pricePerHour[dateKey].price, 0, language)
      : ''
  }

  return (
    <FixedRepeatingGrid
      position="absolute"
      rowHeight={`${hourlyPriceHeight}px`}
      rows={1}
      columns={hoursList.length}
      columnWidth={`${cellWidth}px`}
      {...dataTestId('electricity-price-top-bar-numbers')}
    >
      {visibleHours.map((hour) => (
        <Typography
          sx={{
            textAlign: 'center',
            whiteSpace: 'nowrap',
            fontSize: '12px',
            width: toPixel(cellWidth),
            height: toPixel(hourlyPriceHeight),
            lineHeight: toPixel(hourlyPriceHeight),
            color: ({palette}) => palette.common.white,
            verticalAlign: 'middle',
            fontWeight: 600,
            ...(!isTextVisible && {visibility: 'hidden'})
          }}
          key={hour.toISOString()}
        >
          {getHourlyPrice(hour)}
        </Typography>
      ))}
    </FixedRepeatingGrid>
  )
}

export const ElectricityPriceTopBarNumbers = React.memo(_ElectricityPriceTopBarNumbers)
