import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useState, useCallback, useMemo, useEffect} from 'react'

import {dataTestId as spreadDataTestId} from '../../../../common/utils/testingUtils'
import {RangeSlider, type RangeSliderProps} from '../RangeSlider/RangeSlider'
import {NumberRange} from '../RangeSlider/utils'

import {
  convertDateHourRangeToNumberRange,
  convertNumberRangeToDateHourRange,
  calculateRangeWithinBoundary
} from './dayRangeSlider.utils'
import {getDayRangeSliderActiveTrackLabel} from './DayRangeSliderActiveTrackLabel'
import {getDayRangeSliderLabel} from './DayRangeSliderLabel'

const DEFAULT_HOURS_IN_STEP = 1
const HOURS_IN_DAY = 24
const DEFAULT_MIN_SELECTED_HOURS = HOURS_IN_DAY
const DEFAULT_MAX_SELECTED_HOURS = 7 * 24
const SLIDER_MIN_STEP_WIDTH = 24
// breakpoints for showing the text on the active track
const HIDE_LABEL_TEXT_WIDTH = 40
const SHORT_LABEL_TEXT_WIDTH = 100

const getRangeLengthInHours = (range: [Moment, Moment]) => {
  return range[1].diff(range[0], 'hours')
}

interface DayRangeSliderProps {
  options?: {
    minSelectedHours?: number
    maxSelectedHours?: number
    hoursInStep?: number
  }
  setSelectedRange: (value: [Moment, Moment]) => void
  selectedRange: [Moment, Moment]
  baseRange: [Moment, Moment]
  // use this function to fire an action just before the user starts dragging the slider
  onChangeStart?: (value: [Moment, Moment]) => void
  // use this function to fire an action just after the user stops dragging the slider
  onChangeComplete?: (value: [Moment, Moment]) => void
  'data-test-id'?: string
}

export const DayRangeSlider = ({
  options,
  setSelectedRange,
  selectedRange,
  baseRange,
  onChangeComplete,
  onChangeStart,
  'data-test-id': dataTestId
}: DayRangeSliderProps) => {
  const {
    minSelectedHours = DEFAULT_MIN_SELECTED_HOURS,
    maxSelectedHours = DEFAULT_MAX_SELECTED_HOURS,
    hoursInStep = DEFAULT_HOURS_IN_STEP
  } = options ?? {}

  const [startOfBaseRange] = baseRange

  const formatFn = useCallback(
    (value: number, format?: string) => {
      return startOfBaseRange
        .clone()
        .add(value, 'hours')
        .format(format ?? 'D')
    },
    [startOfBaseRange]
  )

  const [range, setRange] = useState<NumberRange>(
    convertDateHourRangeToNumberRange(selectedRange, startOfBaseRange)
  )

  useEffect(() => {
    // adjust the selected range if it is outside of the base range
    const adjustedRange = calculateRangeWithinBoundary(
      convertDateHourRangeToNumberRange(selectedRange, startOfBaseRange),
      convertDateHourRangeToNumberRange(baseRange, startOfBaseRange)
    )
    setRange(adjustedRange)
    // we need to update internal state when base range is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRange])

  const handleRangeChange = useCallback(
    (range: NumberRange) => {
      // we need to exclude this state update from the React render phase
      setTimeout(() => {
        setSelectedRange(convertNumberRangeToDateHourRange(range, startOfBaseRange))
      }, 0)
    },
    [setSelectedRange, startOfBaseRange]
  )

  const handleChangeStart = useCallback(
    (range: NumberRange) => {
      onChangeStart?.(convertNumberRangeToDateHourRange(range, startOfBaseRange))
    },
    [onChangeStart, startOfBaseRange]
  )

  const handleChangeComplete = useCallback(
    (range: NumberRange) => {
      setTimeout(() => {
        onChangeComplete?.(convertNumberRangeToDateHourRange(range, startOfBaseRange))
      }, 0)
    },
    [onChangeComplete, startOfBaseRange]
  )

  const ActiveTrackLabel = useMemo(
    () =>
      getDayRangeSliderActiveTrackLabel({
        formatStep: ([min, max], width) => {
          if (width < HIDE_LABEL_TEXT_WIDTH) {
            return ''
          }
          const format = width < SHORT_LABEL_TEXT_WIDTH ? 'D' : 'D MMM'
          // if the range is 1 day, show only the day
          return max - min <= HOURS_IN_DAY
            ? `${formatFn(min, format)}`
            : `${formatFn(min, format)} - ${formatFn(max - 1, format)}`
        }
      }),
    [formatFn]
  )

  const LabelComponent = useMemo(() => {
    return getDayRangeSliderLabel({
      checkIsWeekend: (value) => {
        const day = startOfBaseRange.clone().add(value, 'hours')
        return day.day() === 0 || day.day() === 6
      }
    })
  }, [startOfBaseRange])

  const sliderSkipElements = useCallback(
    (index: number, {sliderWidth, totalSteps}: {sliderWidth: number; totalSteps: number}) => {
      // when width is too small we do want to skip every Nth element
      const fitsMaxSteps = sliderWidth / SLIDER_MIN_STEP_WIDTH
      const showEveryNth = Math.ceil(totalSteps / fitsMaxSteps)

      return index % showEveryNth !== 0
    },
    []
  )

  const label: RangeSliderProps['label'] = useMemo(
    () => ({
      step: HOURS_IN_DAY,
      formatFn: (value: number) => formatFn(value),
      skip: sliderSkipElements,
      LabelComponent
    }),
    [sliderSkipElements, LabelComponent, formatFn]
  )

  const minMax: NumberRange = useMemo(() => [0, getRangeLengthInHours(baseRange)], [baseRange])

  const stepLimits = useMemo(
    () => ({min: minSelectedHours, max: maxSelectedHours}),
    [minSelectedHours, maxSelectedHours]
  )

  const roundClicksOptions = useMemo(() => ({roundTo: HOURS_IN_DAY}), [])

  return (
    <Box sx={{width: '100%'}} {...spreadDataTestId(dataTestId ?? 'day_range_slider')}>
      <RangeSlider
        isDraggable
        step={hoursInStep}
        minMax={minMax}
        values={range}
        setValues={setRange}
        onChange={handleRangeChange}
        onChangeComplete={handleChangeComplete}
        onChangeStart={handleChangeStart}
        stepLimits={stepLimits}
        label={label}
        ActiveTrackLabel={ActiveTrackLabel}
        roundClicks={roundClicksOptions}
      />
    </Box>
  )
}
