import {MaterialStoragesHistory} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {
  getStoragesFromHistory,
  isHistoryStorageWithMaterialGuard
} from '../../../selectors/storages'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

interface HistoryStoragesWithMaterialQueryParams {
  timeFrame: MomentRange
  date?: Moment
  useErrorBoundary?: boolean
}

export const useHistoryStoragesWithMaterialQuery = ({
  timeFrame: [from, to],
  date,
  useErrorBoundary = true
}: HistoryStoragesWithMaterialQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery(
    'materialStorageHistory',
    [plantCode, from.toISOString(), to.toISOString()],
    {
      select: (storagesHistory: MaterialStoragesHistory) => {
        const storages = getStoragesFromHistory(storagesHistory, date ?? to)
        return storages.filter(isHistoryStorageWithMaterialGuard)
      },
      useErrorBoundary
    }
  )
}
