import {Skeleton, Box, useTheme, Stack} from '@mui/material'
import React from 'react'

export const SILO_HEIGHT = 8.5 // in theme spacing multiplies

export const SiloSkeleton: React.FC = () => {
  const theme = useTheme()
  const backgroundColor = theme.palette.grey['A400']

  return (
    <Box sx={{width: '100%', minHeight: (theme) => theme.spacing(SILO_HEIGHT)}}>
      <Stack direction="row" sx={{width: '100%'}} justifyContent="space-between">
        <Skeleton variant="text" sx={{backgroundColor, width: (theme) => theme.spacing(20)}} />
        <Skeleton variant="text" sx={{backgroundColor, width: (theme) => theme.spacing(12)}} />
      </Stack>
      <Skeleton variant="rectangular" width={'100%'} sx={{backgroundColor}} />
      <Stack direction="row" sx={{width: '100%'}} justifyContent="space-between">
        <Skeleton variant="text" sx={{backgroundColor, width: (theme) => theme.spacing(12)}} />
        <Skeleton variant="text" sx={{backgroundColor, width: (theme) => theme.spacing(12)}} />
      </Stack>
    </Box>
  )
}
