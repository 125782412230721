import {hpTheme} from '@hconnect/uikit/src/lib2'
import {
  AccessTime,
  Check,
  Close,
  HelpOutline,
  PendingOutlined,
  SvgIconComponent
} from '@mui/icons-material'
import React from 'react'

import {IncomingMaterialStatus, DeliveryStatus} from '../../../shared/enums'

const {palette} = hpTheme

export const IncomingMaterialStatusToIconMap: Record<IncomingMaterialStatus, SvgIconComponent> = {
  [IncomingMaterialStatus.Cancelled]: Close,
  [IncomingMaterialStatus.Delivered]: Check,
  [IncomingMaterialStatus.Overdue]: AccessTime,
  [IncomingMaterialStatus.Unknown]: HelpOutline,
  [IncomingMaterialStatus.Planned]: PendingOutlined
}

interface DeliveryStatusIconProps {
  status: DeliveryStatus
}

export const deliveryStatusToIconMap: Record<DeliveryStatus, SvgIconComponent> = {
  [DeliveryStatus.Delivered]: Check,
  [DeliveryStatus.Overdue]: AccessTime,
  [DeliveryStatus.Unknown]: HelpOutline
}

const deliveryStatusToStyleMap: Record<DeliveryStatus, {color: string}> = {
  [DeliveryStatus.Delivered]: {color: palette.common.white},
  [DeliveryStatus.Overdue]: {color: palette.common.white},
  [DeliveryStatus.Unknown]: {color: palette.text.primarySoft}
}

export const DeliveryStatusBarIcon: React.FC<DeliveryStatusIconProps> = ({status}) => {
  const Icon = deliveryStatusToIconMap[status]
  return <Icon sx={{color: deliveryStatusToStyleMap[status]}} />
}
