import moment from 'moment-timezone'

import {StockDevelopmentEntry, StockDevelopmentResponse} from '../interfaces/api'
import {DatetimeValue} from '../interfaces/common'

import {MomentRange} from './time'

export interface StockDevelopmentData {
  stockLevel: StockDevelopmentEntry & {combined: DatetimeValue[]}
  stockLevelHourly: Record<string, number>
}

export const getStockDevelopmentData = (
  stockDevelopmentEntry: StockDevelopmentEntry
): StockDevelopmentData => {
  const {actual, forecast} = stockDevelopmentEntry
  // assuming here that BE will send actual and forecast in order, without any gaps
  const combined = actual.concat(forecast)
  const levelHourly = Object.fromEntries(combined.map((entry) => [entry.datetime, entry.value]))
  return {stockLevel: {...stockDevelopmentEntry, combined}, stockLevelHourly: levelHourly}
}

export const getStockDevelopmentDataPerMaterial = (
  stockDevelopment: StockDevelopmentResponse
): Record<string, StockDevelopmentData> => {
  return Object.fromEntries(
    Object.entries(stockDevelopment).map(([materialId, stockDevelopmentEntry]) => {
      return [materialId, getStockDevelopmentData(stockDevelopmentEntry)]
    })
  )
}

export const getStockDevelopmentForRange = (
  stockDevelopment: StockDevelopmentResponse,
  range: MomentRange
): StockDevelopmentResponse => {
  return Object.fromEntries(
    Object.entries(stockDevelopment).map(([materialId, entry]) => {
      const filteredActual = entry.actual.filter(({datetime}) =>
        moment.utc(datetime).isBetween(range[0], range[1], 'minute', '[)')
      )
      const filteredForecast = entry.forecast.filter(({datetime}) =>
        moment.utc(datetime).isBetween(range[0], range[1], 'minute', '[)')
      )
      const adjustedStockDevelopment: StockDevelopmentEntry = {
        actual: filteredActual,
        forecast: filteredForecast
      }
      return [materialId, adjustedStockDevelopment]
    })
  )
}
