import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

export const enableMaterialStorageEndpoints = (mock: MockAdapter) => {
  // GET material storage (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-storage$`)).reply(() => {
    const {
      burglengenfeld: {currentMaterialStorages}
    } = mockStore.scenario()

    return sleepResponse([200, currentMaterialStorages])
  })

  // GET material storage history (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-storage/history$`)).reply((config) => {
    const {from, to} = config.params as {from: string; to: string}
    const {
      burglengenfeld: {materialStorageHistory, backendErrors}
    } = mockStore.scenario()

    const selectedMaterialStorageHistory = Object.fromEntries(
      Object.entries(materialStorageHistory)
        .map(
          ([storageId, storageSnapshots]) =>
            [
              storageId,
              storageSnapshots.filter((storage) =>
                moment.utc(storage.updatedOn ?? storage.createdOn).isBefore(to)
              )
            ] as [string, typeof storageSnapshots]
        )
        .map(([storageId, storageSnapshots]) => {
          const filterByFromDate = storageSnapshots.filter((storage) =>
            moment.utc(storage.updatedOn ?? storage.createdOn).isAfter(from)
          )
          return [
            storageId,
            filterByFromDate.length
              ? filterByFromDate
              : [storageSnapshots[storageSnapshots.length - 1]]
          ] as [string, typeof filterByFromDate]
        })
    )
    if (backendErrors.materialStorageError) {
      return sleepResponse(backendErrors.materialStorageError)
    }

    return sleepResponse([200, selectedMaterialStorageHistory])
  })

  // GET material storage overwrites (C#)
  mock
    .onGet(
      new RegExp(`^/plants/${numberRegEx}/material-storage/stock-level/material/${numberRegEx}$`)
    )
    .reply((config) => {
      const materialId = Number((config.url as string).split('/')[6])
      const {from, to} = config.params as {from: string; to: string}
      const {
        burglengenfeld: {stockOverwrites}
      } = mockStore.scenario()

      const overwrites = (stockOverwrites[materialId] ?? []).filter((overwrite) =>
        moment(overwrite.measuredAt).isBetween(moment(from), moment(to))
      )

      return sleepResponse([200, overwrites])
    })

  // POST material storage overwrites (C#)
  mock
    .onPost(
      new RegExp(`^/plants/${numberRegEx}/material-storage/stock-level/material/${numberRegEx}$`)
    )
    .reply((config) => {
      const materialId = Number((config.url as string).split('/')[6])
      const {date, level} = JSON.parse((config.data || '{}') + '') as {
        date: string
        level: number
      }
      const scenario = mockStore.scenario()

      if (!scenario.burglengenfeld.stockOverwrites[materialId]) {
        scenario.burglengenfeld.stockOverwrites[materialId] = []
      }

      scenario.burglengenfeld.stockOverwrites[materialId].push({
        measuredAt: date,
        level: level,
        updatedBy: 'test',
        storageId: 1,
        createdAt: moment.utc().toISOString()
      })

      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  return mock
}
