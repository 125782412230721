import {formatMW} from '@hconnect/uikit'
import {hpTheme} from '@hconnect/uikit/src/lib2'
import {TooltipOptions, TooltipCallbacks, ScaleOptions, Plugin} from 'chart.js/auto'
import {TFunction} from 'i18next'
import moment, {Moment} from 'moment-timezone'

import {
  coreTimeScaleOptions,
  coreLinearScaleOptions,
  coreBlackTooltipConfig
} from '../../../../shared/chartJScofigs'
import {isStartOfDay} from '../../../../shared/selectors/time'

const gridColor = hpTheme.palette.grey[200]
const gridStartOfDayColor = hpTheme.palette.grey[400]

export const getTooltipOptions = (t: TFunction, language: string) => ({
  ...coreBlackTooltipConfig,
  xAlign: 'left' as const,
  filter: function (item) {
    return Boolean(item.parsed.y !== null)
  } as TooltipOptions['filter'],
  callbacks: {
    title: (context) => {
      return context[0].label ?? ''
    },
    label({datasetIndex, parsed}) {
      if (datasetIndex === 0) {
        return `${t('planning.planned')} ${formatMW(parsed.y, language)}`
      }
      if (datasetIndex === 1) {
        return `${t('planning.purchasedBlocked')} ${formatMW(parsed.y, language)}`
      }
      if (datasetIndex === 2) {
        return `${t('planning.purchased')} ${formatMW(parsed.y, language)}`
      }
      return ''
    }
  } as Partial<TooltipCallbacks<'bar'>>
})

interface XScaleOptions {
  minXValue: Moment
  maxXValue: Moment
  timezoneId: string
  isMinimized: boolean
}

export const getXScaleOptions = ({
  minXValue,
  maxXValue,
  timezoneId,
  isMinimized
}: XScaleOptions): ScaleOptions<'time'> => ({
  ...coreTimeScaleOptions,
  stacked: true,
  position: 'top',
  min: minXValue.valueOf(),
  max: maxXValue.valueOf(),
  ticks: {
    ...coreTimeScaleOptions.ticks,
    display: isMinimized ? false : true,
    callback: function (value, index, ticks) {
      const epochTime = Number(value)
      const date = moment.utc(epochTime)
      if (date.minute() === 0) {
        // hide first and last index
        if (index === 0 || index === ticks.length - 1) {
          return null
        }
        return date.tz(timezoneId).format('HH')
      }
      return null
    }
  },
  grid: {
    ...coreTimeScaleOptions.grid,
    drawTicks: false,
    color: (context) => {
      const date = moment.utc(context.tick.value).tz(timezoneId)
      return isStartOfDay(date) ? gridStartOfDayColor : gridColor
    },
    offset: false
  },
  border: {
    display: false
  }
})

export const getYScaleOptions = ({
  t,
  language,
  isMinimized,
  maxYValue,
  minYValue,
  yValueStep,
  yAxisWidth,
  gridColor
}: {
  t: TFunction
  language: string
  isMinimized: boolean
  maxYValue: number
  minYValue: number
  yValueStep: number
  yAxisWidth: number
  gridColor: string
}): ScaleOptions<'linear'> => ({
  ...coreLinearScaleOptions,
  max: isMinimized ? 0 : maxYValue,
  min: minYValue,
  ticks: {
    padding: 24,
    display: true,
    stepSize: yValueStep, // amount of MW per step
    align: isMinimized ? 'end' : 'center',
    callback: (value, index) => {
      if (index === 0) {
        return isMinimized ? t('planning.delta', {unit: t('common.unit.MW')}) : null
      }
      return formatMW(Number(value), language)
    }
  },
  grid: {
    drawTicks: false,
    offset: isMinimized ? true : false,
    color: gridColor
  },
  border: {
    display: true
  },
  afterFit: (scaleInstance) => {
    // setting scale width manually
    scaleInstance.width = yAxisWidth
    scaleInstance.paddingBottom = 0
    scaleInstance.paddingTop = 0
  }
})

export const getShiftBarPositionPlugin = (amountOfHours: number): Plugin<'bar'> => ({
  id: 'shiftBarPosition',
  beforeDatasetDraw(chart, {index}) {
    const {
      data,
      chartArea: {width},
      scales: {x}
    } = chart
    const currentDataSet = data.datasets[index]
    // allow only bar type
    if (currentDataSet.type !== 'bar') {
      return
    }

    const barWidthPx = width / (amountOfHours * 4) // 4 bars per hour
    const fullDataSet = currentDataSet.data as unknown as Record<string, number>
    const firstDateIso = Object.keys(fullDataSet)[0]
    const firstDataPointTimeUnix = moment.utc(firstDateIso).valueOf()

    const firstBarXPosition = x.getPixelForValue(firstDataPointTimeUnix)

    chart.getDatasetMeta(index).data.forEach((dataPoint, index) => {
      dataPoint.x = firstBarXPosition + barWidthPx * index + barWidthPx / 2
    })
  }
})
