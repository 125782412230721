import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {Box, Typography, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePlanningChartStartEnd} from '../../../app/page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {NOW_INDICATOR_REFRESH_INTERVAL_MS} from '../../constants'
import {useDateScale} from '../../helpers/scale'
import {toPixel} from '../../helpers/utils'
import {useCurrentTime} from '../../hooks/useCurrentTime'
import {usePlantConfig} from '../../hooks/usePlantConfigData'

import {CurrentTimeIndicator} from './CurrentTimeIndicator'

type ChartNowIndicatorProps = {
  hoursDisplayed: number
  cellWidth: number
  shouldRoundCurrentTime: boolean
}

export const ChartNowIndicator: React.FC<ChartNowIndicatorProps> = ({
  hoursDisplayed,
  cellWidth,
  shouldRoundCurrentTime
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()

  const currentTime = useCurrentTime({
    timezoneId,
    intervalMs: NOW_INDICATOR_REFRESH_INTERVAL_MS
  })
  const currentTimeAfterRounding = shouldRoundCurrentTime
    ? roundTo15MinIntervalStart(currentTime)
    : currentTime

  const {startOfChart, endOfChart} = usePlanningChartStartEnd()
  const width = hoursDisplayed * cellWidth

  const scale = useDateScale({
    domain: [startOfChart, endOfChart],
    range: [0, width]
  })

  if (
    currentTimeAfterRounding.isBefore(startOfChart) ||
    currentTimeAfterRounding.isAfter(endOfChart)
  ) {
    return null
  }

  const xOffset = scale(currentTimeAfterRounding)

  const isLabelToRight = xOffset + 100 < width
  const borderRadiusRight = theme.spacing(isLabelToRight ? 0.5 : 0)
  const borderRadiusLeft = theme.spacing(isLabelToRight ? 0 : 0.5)

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.text.primary,
          color: (theme) => theme.palette.background.paper,
          position: 'absolute',
          top: 0,
          left: isLabelToRight ? toPixel(xOffset) : undefined,
          right: isLabelToRight ? undefined : toPixel(width - xOffset),
          paddingRight: (theme) => theme.spacing(1),
          paddingLeft: (theme) => theme.spacing(1),
          zIndex: 1,
          borderRadius: `${borderRadiusLeft} ${borderRadiusRight} ${borderRadiusRight} ${borderRadiusLeft}`
        }}
      >
        <Typography variant="body2">
          {t('common.nowWithTime', {time: currentTimeAfterRounding.format('HH:mm')})}
        </Typography>
      </Box>
      <CurrentTimeIndicator xOffset={xOffset} />
    </>
  )
}
