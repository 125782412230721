import {Avatar, AvatarProps} from '@mui/material'
import React from 'react'

import {getStringAvatar} from './getStringAvatar'

interface HPAvatarProps extends Omit<AvatarProps, 'children'> {
  userName: string
}

export const HPAvatar: React.FC<HPAvatarProps> = ({userName}) => {
  return <Avatar {...getStringAvatar(userName)} alt={userName} />
}
