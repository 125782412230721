import {dataTestId} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Typography, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {UnplannedIncomingMaterial} from '../../../../shared/selectors/materialOrders'

import {useDeliveriesCardHeaderOrderInfo} from './materialOrderDeliveriesHeader.helpers'

interface MaterialUnknownDeliveriesCardHeaderProps {
  incomingMaterial: UnplannedIncomingMaterial
}

export const MaterialUnknownDeliveriesCardHeader: React.FC<
  MaterialUnknownDeliveriesCardHeaderProps
> = ({incomingMaterial}) => {
  const {t} = useTranslation()

  const {
    amountDelivered,
    deliveredOn,
    scheduleAgreement: {vendorName, vendorId, documentNumber}
  } = incomingMaterial.unplannedDelivery

  const orderInfo = useDeliveriesCardHeaderOrderInfo({
    amount: amountDelivered,
    scheduledDate: deliveredOn,
    documentNumber
  })

  return (
    <Stack direction="row" spacing={2}>
      <Stack flex={1}>
        <CardTitle sx={{mb: 0}} {...dataTestId('vendor_name')}>
          {vendorId ? vendorName : t('stock.materialOrders.unknownVendor')}
        </CardTitle>
        <Typography variant="caption" {...dataTestId('ordered_delivered_amount_and_date')}>
          {orderInfo}
        </Typography>
      </Stack>
    </Stack>
  )
}
