import {AxiosRequestConfig} from 'axios'

const IS_IE11 =
  typeof window !== 'undefined' &&
  ((Object.getOwnPropertyDescriptor && Object.getOwnPropertyDescriptor(window, 'ActiveXObject')) ||
    'ActiveXObject' in window)
const BROWSER_CACHES_API_REQUESTS = IS_IE11

export const createNoCacheInterceptor =
  () =>
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (BROWSER_CACHES_API_REQUESTS) {
      // Reduced precision to seconds which is enough - also keeps size smaller
      return {
        ...config,
        params: {
          ...(config.params || {}),
          refresh: Math.round(Date.now() / 1000)
        }
      }
    }
    return config
  }
