import {formatTimeZoneDate} from '@hconnect/uikit/src/common'
import {MaintenancePage} from '@hconnect/uikit/src/lib2'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DefaultShell} from './DefaultShell'
import {PlannerPageLayout} from './PlannerPageLayout'
import {FullScreenProvider} from './providers/FullscreenProvider'

export const MaintenanceSwitch = ({children}: {children: React.ReactNode}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  // cypress sets window.PLANNER_MAINTENANCE_UNTIL explicitly to date in the past, to allow cypress tests work
  // so we are checking for the maintenance date in the global window object first, then process.env

  const maintenanceCutoff =
    (window as any).PLANNER_MAINTENANCE_UNTIL ?? process.env.REACT_APP_PLANNER_MAINTENANCE_UNTIL
  const isCutoffInPast: boolean = maintenanceCutoff
    ? moment.utc(maintenanceCutoff).isBefore(moment.utc())
    : true

  // showing children if REACT_APP_PLANNER_MAINTENANCE_UNTIL env variable equals undefined or contains a past date
  if (isCutoffInPast || !maintenanceCutoff) return <>{children}</>

  const formattedDateTime: string = formatTimeZoneDate(
    maintenanceCutoff,
    moment.tz.guess(),
    'DD.MM.YYYY LT',
    language
  )

  return (
    <FullScreenProvider>
      <DefaultShell>
        <PlannerPageLayout
          isTourBannerEnabled={false}
          pageTitle={t('plannerMaintenance.caption')}
          sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
        >
          <MaintenancePage
            caption={t('plannerMaintenance.caption')}
            description={t('plannerMaintenance.description', {datetime: formattedDateTime})}
          />
        </PlannerPageLayout>
      </DefaultShell>
    </FullScreenProvider>
  )
}
