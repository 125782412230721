// this file contains functions to select and modify input data into a ready-to-use format
import {Recipe, Status} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'

import {AssetType, RecipeComponentType} from './enums'
import {AssetResponse, OperationModeResponseProducing} from './interfaces/api'
import type {DatetimeValue} from './interfaces/common'
import {getOperationModeIdToAssetIdMap, guardProducingMode} from './selectors/assets'
import {getRecipeComponentsWithMaterial} from './selectors/materials'

export const getLeftAlignedHourListFromRange = (startDate: Moment, endDate: Moment): Moment[] => {
  const hoursList: Moment[] = []
  let now = startDate.clone()

  while (now.isBefore(endDate)) {
    hoursList.push(now)
    now = now.clone().add(1, 'hours')
  }
  return hoursList
}

interface GetMillProductionTimeForMaterial {
  assetsById: Record<string, AssetResponse>
  assets: AssetResponse[]
  recipesById: Record<string, Recipe>
  materialId: number
  tonsOfMaterial: number
}

export const getMillProductionTimeForMaterial = ({
  assetsById,
  assets,
  recipesById,
  materialId,
  tonsOfMaterial
}: GetMillProductionTimeForMaterial) => {
  const cementOrRawMillAssets = assets.filter((asset) =>
    [AssetType.RawMill, AssetType.CementMill].includes(asset.type)
  )
  const operationIdToAssetIdMap = getOperationModeIdToAssetIdMap(cementOrRawMillAssets)
  const allAssetOperationModesInAssets = cementOrRawMillAssets.flatMap((asset) =>
    asset.operationModes
      .filter(guardProducingMode)
      // filtering out deleted operation modes without current recipes
      .filter(
        (operationMode) =>
          operationMode.status !== Status.Deleted && !!recipesById[operationMode.recipeId]
      )
  )
  const operationModesUsingMaterial = allAssetOperationModesInAssets.filter(
    (operationMode) =>
      getRecipeComponentsWithMaterial(materialId, recipesById[operationMode.recipeId], [
        RecipeComponentType.Output
      ]).length > 0
  )

  const aomWithHighestThroughput = filterOperationModesByMaxThroughputPerAsset(
    operationModesUsingMaterial,
    operationIdToAssetIdMap
  )

  return aomWithHighestThroughput.map((aom) => ({
    asset_name: assetsById[operationIdToAssetIdMap[aom.id]].name,
    time: tonsOfMaterial / aom.throughput
  }))
}

export const filterOperationModesByMaxThroughputPerAsset = (
  operationModes: OperationModeResponseProducing[],
  operationIdToAssetIdMap: Record<string, number>
): OperationModeResponseProducing[] => {
  const assetToMaxThroughputAom: Record<string, OperationModeResponseProducing> = {}
  for (const operationMode of operationModes) {
    const assetId = operationIdToAssetIdMap[operationMode.id]
    const maxThroughput = assetToMaxThroughputAom[assetId]?.throughput
    if (maxThroughput === undefined || maxThroughput < operationMode.throughput) {
      assetToMaxThroughputAom[assetId] = operationMode
    }
  }
  return Object.values(assetToMaxThroughputAom)
}

interface MergeActualAndForecastInput {
  actual: DatetimeValue<Moment>[]
  forecast: DatetimeValue<Moment>[]
  startDatetime: Moment
  endDatetime: Moment
  timezoneId: string
}
/**
 * merges two datetime arrays of potentially overlapping times as follows
 * actual         aaa
 * forecast         bbb
 * result         aaabb
 * @param input as MergeActualAndForecastInput
 * @returns DatetimeValue<Moment>[]
 */
export const mergeActualAndForecast = ({
  actual,
  forecast,
  startDatetime,
  endDatetime,
  timezoneId
}: MergeActualAndForecastInput): DatetimeValue<Moment>[] => {
  const retval: DatetimeValue<Moment>[] = []

  let currentDate: Moment | null = null

  for (const entry of actual) {
    const datetimeInTimezone = entry.datetime.clone().tz(timezoneId)
    if (datetimeInTimezone < startDatetime) {
      continue
    }
    if (datetimeInTimezone > endDatetime) {
      break
    }
    retval.push({...entry})
    currentDate = datetimeInTimezone
  }

  for (const entry of forecast) {
    const datetimeInTimezone = entry.datetime.clone().tz(timezoneId)
    if (datetimeInTimezone < startDatetime) {
      continue
    }
    if (datetimeInTimezone > endDatetime) {
      break
    }
    if (currentDate && datetimeInTimezone <= currentDate) {
      continue
    } // avoid duplication due to overlap between actual and forecast
    retval.push({...entry})
  }

  return retval
}

export interface PeakLoadWindow {
  start: Moment
  end: Moment
  maxPower: number
  violated: boolean
}

export const filterPeakLoadWindows = (
  data: PeakLoadWindow[] | undefined,
  startDatetime: Moment,
  endDatetime: Moment
): PeakLoadWindow[] => {
  if (!data) return []
  return data
    .filter((plw) => plw.start < endDatetime && plw.end > startDatetime)
    .map(({start, end, ...rest}) => {
      return {
        start: start > startDatetime ? start : startDatetime,
        end: end < endDatetime ? end : endDatetime,
        ...rest
      }
    })
}

