import {dataTestId} from '@hconnect/uikit'
import {hpTheme} from '@hconnect/uikit/src/lib2'
import {mapValues} from 'lodash'
import React, {useMemo} from 'react'

interface TimeLabelProps {
  variant: 'start' | 'end'
  color?: string
  formattedTime: string
  textColor?: string
}
export const TimeLabel: React.FC<TimeLabelProps> = ({
  variant,
  color = hpTheme.palette.primary.main,
  formattedTime,
  textColor = hpTheme.palette.common.white
}) => {
  const width = 40
  const height = 15
  const textPadding = 5
  const triangleSide = 10
  const strokeWidth = 2
  const isStart = variant === 'start'

  const corners = useMemo(
    () =>
      mapValues(
        {
          leftTop: [0, 0],
          rightTop: [width, 0],
          rightBottom: [width, height],
          leftBottom: [0, height]
        },
        (value) => value.join(',')
      ),
    [width, height]
  )
  const triangleCoordinates = useMemo(
    () =>
      mapValues(
        {
          leftBottom: [
            [10, height],
            [0, height + 10]
          ],
          rightTop: [
            [width - 10, 0],
            [width, -10]
          ]
        },
        (value) => value.map((coords) => coords.join(','))
      ),
    [width, height]
  )
  const coordinates = useMemo(
    () =>
      [
        corners.leftTop,
        ...(isStart ? [corners.rightTop] : triangleCoordinates.rightTop),
        corners.rightBottom,
        ...(isStart ? [triangleCoordinates.leftBottom] : [corners.leftBottom])
      ].join(' '),
    [corners, triangleCoordinates, isStart]
  )

  const viewBoxCoordinates = Object.values({
    x: 0 - strokeWidth,
    y: -(triangleSide + strokeWidth),
    width: width + strokeWidth * 2,
    height: height + triangleSide * 2 + strokeWidth * 2
  }).join(' ')
  return (
    <svg viewBox={viewBoxCoordinates} width={width} {...dataTestId(`time_label_${variant}`)}>
      <polygon
        points={coordinates}
        fill={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        stroke={color}
      />

      <text
        x={textPadding}
        y={height / 2 + strokeWidth / 2}
        dominantBaseline="middle"
        fill={textColor}
        fontSize={hpTheme.typography.caption.fontSize}
      >
        {formattedTime}
      </text>
    </svg>
  )
}
