import useDebounce from '@react-hook/debounce'
import {useEffect, useMemo} from 'react'

interface UseDebouncedValueConfig<Type> {
  value: Type
  initialValue?: Type
  wait: number
  leading?: boolean
}
/**
 * takes config object as input, returns debounced value
 * on value change after wait,
 * can be used for debouncing props or states
 * @param config
 * @returns object with debounced value
 */
export const useDebouncedValue = <T>({
  value,
  initialValue,
  wait,
  leading = false
}: UseDebouncedValueConfig<T>) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFirstValue = useMemo(() => value, [])
  const [debouncedValue, setDebouncedValue] = useDebounce<T>(
    initialValue ?? memoizedFirstValue,
    wait,
    leading
  )
  useEffect(() => {
    setDebouncedValue(value)
  }, [value, setDebouncedValue])

  return {debouncedValue}
}
