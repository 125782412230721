import {Material} from '@hconnect/common/types'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useMaterialsById = () => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('materials', [plantCode], {
    select: (data) =>
      data.reduce(
        (materialsById, material) => ({...materialsById, [String(material.id)]: material}),
        {} as Record<string, Material>
      )
  })
}
