import type {PermissionType} from '@hconnect/apiclient'
import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'

import {usePermissionsQuery} from '../shared/hooks/api'
import {usePlantConfig} from '../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../shared/hooks/useUrlParam'
import {
  checkIfMultiplePermissionsGranted,
  checkPermissionAccessByCountryOrPlantId
} from '../shared/permissions'

import {getUrl} from './helpers'
import {ROUTES} from './router'

interface PermissionGuardedRouteProps {
  requiredPermissions: [type: PermissionType, ...types: PermissionType[]]
  operator?: 'AND' | 'OR'
}

export const PermissionGuardedRoute: React.FC<PermissionGuardedRouteProps> = ({
  requiredPermissions,
  operator = 'AND'
}) => {
  const {data: permissions} = usePermissionsQuery(requiredPermissions)
  const plantCode = useUrlParam('plantCode')
  const {country: countryId} = usePlantConfig()

  const isAccessGranted = permissions
    ? checkIfMultiplePermissionsGranted({
        requiredPermissionTypes: requiredPermissions,
        permissions,
        testFn: (permission) =>
          checkPermissionAccessByCountryOrPlantId(countryId, plantCode, permission.parsedDataScope),
        operator
      })
    : false

  if (!isAccessGranted) {
    return <Navigate to={getUrl(ROUTES.BASE.NOT_AUTHORIZED.path, {})} replace />
  }
  return <Outlet />
}
