import {Box, BoxProps, Typography} from '@mui/material'
import React, {FC} from 'react'

import {convertIndexToLetter} from './convertIndexToLetter'

interface NumberLetterSequenceIndicatorProps extends BoxProps {
  numberIndex?: number
  letterIndex?: number
}
/**
 * Component displays order like 1, 2A, 2B, 3A, 3B
 */
export const NumberLetterSequenceIndicator: FC<NumberLetterSequenceIndicatorProps> = ({
  numberIndex,
  letterIndex,
  ...boxProps
}) => {
  const number = numberIndex !== undefined ? numberIndex + 1 : ''
  const letter = letterIndex !== undefined ? convertIndexToLetter(letterIndex) : ''
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        width: theme.spacing(2),
        minWidth: theme.spacing(2),
        height: theme.spacing(2),
        minHeight: theme.spacing(2),
        backgroundColor: theme.palette.grey[300]
      })}
      {...boxProps}
    >
      <Typography
        variant="caption"
        sx={{letterSpacing: '0.001em', fontSize: '10px', color: 'secondary.dark', fontWeight: 600}}
      >
        {number}
        {letter}
      </Typography>
    </Box>
  )
}
