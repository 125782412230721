import {dataTestId} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RouteName} from '../../routing'
import {PlannerPageLayout} from '../../shared/components/PlannerPageLayout'
import {pageDataTestId} from '../../shared/formatutils'
import {usePlanRange} from '../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'

import {LayoutOptimizerSummary} from './LayoutOptimizerSummary'
import {OptimizerBackButton} from './OptimizerBackToSummary'

export const PageOptimizerSummary: React.FC = () => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const [startOfPlan, endOfPlan] = usePlanRange({timezoneId, createdAt})

  return (
    <PlannerPageLayout
      isTourBannerEnabled={true}
      title={t('navItems.optimizerSummary')}
      pageTitle={t('navItems.optimizerSummary')}
      aboveTitle={
        <OptimizerBackButton {...dataTestId('back_to_mill_planning_button')} to="planning" />
      }
      {...pageDataTestId(RouteName.OPTIMIZER_SUMMARY)}
    >
      <LayoutOptimizerSummary startOfPlan={startOfPlan} endOfPlan={endOfPlan} />
    </PlannerPageLayout>
  )
}
