export const mapHostToBackend = (host: string): string => {
  // e.g. localhost:3000
  if (/^localhost:/.exec(host)) {
    return 'qa'
  }

  // e.g. hub-qa.heidelbergcement.com or hconnecthub-prod.azureedge.net => "qa" or "prod"
  const matchTestCDNDomain = /[a-z]+-(qa|uat|regression|prod|uatr|dptest)\W/.exec(host)
  if (matchTestCDNDomain) {
    // Once CDN is migrated to Azure Front Door - we can replace this with 'origin'
    return matchTestCDNDomain[1]
  }

  // e.g qa.hproduce.digital, uat.hproduce.digital, www.hproduce.digital
  const matchHProduce = /^(qa|uat|regression|prod|uatr|dptest|www)\./.exec(host)
  if (matchHProduce) {
    return matchHProduce[1] === 'www' ? 'prod' : matchHProduce[1]
  }

  // e.g. hconnecttmonqa.z6.web.core.windows.net/ => "qa"
  const matchStaticHost = /(qa|uat|uatr|regression|prod)\.z\d+\.web\.core\.windows\.net/.exec(host)
  if (matchStaticHost) {
    return matchStaticHost[1]
  }
  return 'qa'
}
