import {SvgIcon, SvgIconProps} from '@mui/material'
import React from 'react'

export const CementIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="12px" height="12px" viewBox="0 0 12 12" {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g>
        <circle cx="1.25" cy="1.25" r="1.25" />
        <circle cx="6" cy="1.25" r="1.25" />
        <circle cx="10.75" cy="1.25" r="1.25" />
        <circle cx="1.25" cy="6" r="1.25" />
        <circle cx="6" cy="6" r="1.25" />
        <circle cx="10.75" cy="6" r="1.25" />
        <circle cx="1.25" cy="10.75" r="1.25" />
        <circle cx="6" cy="10.75" r="1.25" />
        <circle cx="10.75" cy="10.75" r="1.25" />
        <circle cx="3.625" cy="3.625" r="1.25" />
        <circle cx="8.5" cy="3.625" r="1.25" />
        <circle cx="3.625" cy="8.25" r="1.25" />
        <circle cx="8.5" cy="8.25" r="1.25" />
      </g>
    </g>
  </SvgIcon>
)
