import {Error403Page} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'

import {RouteName} from '../../routing'
import {useLoginDetails} from '../../shared/components/providers/LoginProvider'

export const PageForbidden: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const loginDetails = useLoginDetails()

  return (
    <Error403Page
      caption={t('error.accessDenied')}
      description={t('error.accessDeniedDescription')}
      onLogout={() => void loginDetails.logout()}
      onGoBack={() => navigate(-1)}
      goBackCaption={t('navItems.backToLastPage')}
      dataTestId={`page_${RouteName.NOT_AUTHORIZED}`}
    />
  )
}
