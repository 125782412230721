import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Dialog, DialogContent} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedScheduleItemId} from '../../../../../../shared/components/providers/SelectedScheduleItemProvider'
import {useHistoryStoragesByIdQuery} from '../../../../../../shared/hooks/api'
import {useScheduleItem} from '../../../../../../shared/hooks/api/schedules/useScheduleItem'
import {useScheduleQuery} from '../../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useAssetHistoryDataByScheduleItemId} from '../../../../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {Comment} from '../../../../../../shared/interfaces/api'

import {EditScheduleItemForm} from './EditScheduleItemForm'

export interface EditScheduleItemProps {
  startOfPlan: Moment
  endOfPlan: Moment
  commentsByItemId?: Record<string, Comment>
}

export const EditScheduleItemDialog: React.FC<EditScheduleItemProps> = ({
  startOfPlan,
  endOfPlan,
  commentsByItemId
}) => {
  const {t} = useTranslation()
  const {scheduleItemId, setScheduleItemId} = useSelectedScheduleItemId()
  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], isOptimized: false})
  const historyAssetsByItemId = useAssetHistoryDataByScheduleItemId({
    schedule,
    timeFrame: [startOfPlan, endOfPlan]
  })

  // TODO HCP-81604 filter selectedSilos by scheduleItemEnd, then select snapshot by date
  const {data: storagesById} = useHistoryStoragesByIdQuery({
    timeFrame: [startOfPlan, endOfPlan],
    useErrorBoundary: false
  })
  const {data: scheduleItem} = useScheduleItem({id: scheduleItemId, startOfPlan, endOfPlan})
  if (!scheduleItem || !historyAssetsByItemId || !storagesById) return null
  const comment = commentsByItemId?.[scheduleItem.id]
  const assetName = historyAssetsByItemId[scheduleItem.id].asset.name

  return (
    <Dialog
      open={!!scheduleItem}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return
        }
        setScheduleItemId(undefined)
      }}
      fullWidth
    >
      <DialogContent>
        <CardTitle>{t('planning.planAsset', {asset: assetName})}</CardTitle>
        <EditScheduleItemForm
          scheduleItem={scheduleItem}
          startOfPlan={startOfPlan}
          endOfPlan={endOfPlan}
          comment={comment}
          assetData={historyAssetsByItemId[scheduleItem.id]}
          storagesById={storagesById}
        />
      </DialogContent>
    </Dialog>
  )
}
