import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {invalidateHcemQuery} from '../../../api/queryHelpers'
import {notifyIfErrorMessage} from '../../../common'
import {ErrorResponse, MaterialOrder} from '../../../interfaces/api'

export const useEditMaterialOrder = () => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  return useMutation<
    MaterialOrder,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editMaterialOrder>[0]
  >(mutations.editMaterialOrder, {
    mutationKey: ['editMaterialOrder'],
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.editMaterialOrder'))
    },
    onSettled: () => {
      invalidateHcemQuery('materialOrders')
      invalidateHcemQuery('stockDevelopment')
    }
  })
}
