import {Moment} from 'moment-timezone'

/**
 * function to round datetime to 15 min interval start
 * starting from the beginning of an hour
 * Eg. "2023-08-15T02:05:00Z" to "2023-08-15T02:00:00Z"
 * Eg. "2023-08-15T02:16:00Z" to "2023-08-15T02:15:00Z"
 */
export const roundTo15MinIntervalStart = (datetime: Moment) => {
  const minutes = datetime.minutes()
  const ratio = 60 / (minutes ?? 1)
  if (ratio === 60 || ratio > 60 / 15) {
    return datetime.clone().startOf('hour')
  }
  if (ratio > 60 / 30) {
    return datetime.clone().startOf('hour').add(15, 'minutes')
  }
  if (ratio > 60 / 45) {
    return datetime.clone().startOf('hour').add(30, 'minutes')
  }
  return datetime.clone().startOf('hour').add(45, 'minutes')
}
