import {toRecord} from '../../../helpers/utils'
import {getMaterialsFromHistoryByDate} from '../../../selectors/materials'
import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface HistoryMaterialsByIdQueryParams {
  timeFrame: MomentRange
}

export const useHistoryMaterialsById = ({
  timeFrame: [start, end]
}: HistoryMaterialsByIdQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery('materialsHistory', [plantCode, start.toISOString(), end.toISOString()], {
    select: (materialsHistory) =>
      toRecord(getMaterialsFromHistoryByDate(materialsHistory, end), 'id')
  })
}
