import {dataTestId} from '@hconnect/uikit'
import {BasicGaugeBar} from '@hconnect/uikit/src/lib2'
import {KeyboardArrowDown, KeyboardArrowRight} from '@mui/icons-material'
import {Box, Typography, useTheme} from '@mui/material'
import React from 'react'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {useHistoryAssetsByIdQuery} from '../../../shared/hooks/api'
import {AssetCapacity} from '../../../shared/interfaces/api/kpi'

import {getPercentageInDecimal} from './assetCapacity.helpers'

interface AssetCapacityHeaderProps {
  assetCapacity: AssetCapacity
  assetId: number
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
}

export const AssetCapacityHeader: React.FC<AssetCapacityHeaderProps> = ({
  assetCapacity: {maintenance, runtimeInHours: totalRuntimeHours},
  assetId,
  isCollapsed,
  setIsCollapsed
}) => {
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }
  const {palette} = useTheme()

  const {data: assetsById} = useHistoryAssetsByIdQuery({timeFrame: selectedTimeframe})

  const assetName = assetsById?.[assetId]?.name

  const hoursInTimeframe = selectedTimeframe[1].diff(selectedTimeframe[0], 'hours')
  const maintenanceRuntimeHours = maintenance.runtimeInHours
  const productionRuntimeHours = totalRuntimeHours - maintenanceRuntimeHours

  const utilizationPercentage = getPercentageInDecimal(totalRuntimeHours, hoursInTimeframe)
  return (
    <Box
      sx={{
        width: 1,
        display: 'grid',
        gridTemplateColumns: '20px 2fr 9fr 1fr',
        px: 1.5,
        gap: 2,
        alignItems: 'center',
        cursor: 'pointer'
      }}
      onClick={() => setIsCollapsed(!isCollapsed)}
      {...dataTestId('asset_capacity_header')}
    >
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        {isCollapsed ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
      </Box>
      <Typography variant="h5" noWrap={true}>
        {assetName}
      </Typography>
      <Box>
        <BasicGaugeBar
          minValue={0}
          maxValue={hoursInTimeframe}
          bufferValue={maintenanceRuntimeHours + productionRuntimeHours}
          value={productionRuntimeHours}
          color={palette.background.default}
          bufferColor={palette.text.secondary}
          barHeight={24}
        />
      </Box>
      <Typography variant="h5">{utilizationPercentage}</Typography>
    </Box>
  )
}
