import {Dialog, DialogContent, DialogTitle} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedAssetId} from '../../../../shared/components/providers/SelectedAssetCommentProvider'
import {CommentsCategory, PlannerFeature} from '../../../../shared/enums'
import {useHistoryAssetsByIdQuery, useHistoryAssetsQuery} from '../../../../shared/hooks/api'
import {useAssetCommentsQuery} from '../../../../shared/hooks/api/comments/useAssetsCommentsQuery'
import {useCommentsPermissions} from '../../../../shared/hooks/permissions/useCommentsPermissions'
import {usePlannerFeature} from '../../../../shared/hooks/usePlannerFeature'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {AssetCommentForm} from '../AssetCommentForm'

interface AddEditAssetCommentDialogProps {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const AddEditAssetCommentDialog: FC<AddEditAssetCommentDialogProps> = ({
  startOfPlan,
  endOfPlan
}) => {
  const {t} = useTranslation()
  const plantConfig = usePlantConfig()
  const areCommentsEnabled = usePlannerFeature(PlannerFeature.PlanningComments)
  const {canViewComments, canEditComments, canAddComments} = useCommentsPermissions(
    CommentsCategory.Assets
  )
  const {assetId, setAssetId} = useSelectedAssetId()
  const {data: assets} = useHistoryAssetsQuery({
    timeFrame: [startOfPlan, endOfPlan],
    sorted: true
  })
  const {data: assetsById} = useHistoryAssetsByIdQuery({timeFrame: [startOfPlan, endOfPlan]})
  const isAssetCommentsQueryDisabled = !areCommentsEnabled || !canViewComments
  const {data: comments} = useAssetCommentsQuery(
    assets?.map((asset) => asset.id),
    isAssetCommentsQueryDisabled
  )

  if (assetId === undefined || !areCommentsEnabled || !(canAddComments && canEditComments)) {
    return null
  }

  const assetComment = comments?.find(
    ({commentsCategoryInstanceId: entityInstanceId}) => entityInstanceId === assetId.toString()
  )

  return (
    <Dialog open={true} onClose={() => setAssetId(undefined)} fullWidth>
      <DialogTitle>
        {t('planning.assetComment', {assetName: assetsById?.[assetId].name ?? t('common.loading')})}
      </DialogTitle>
      <DialogContent>
        <AssetCommentForm
          timezoneId={plantConfig.timezone_id}
          comment={assetComment}
          assetId={assetId}
        />
      </DialogContent>
    </Dialog>
  )
}
