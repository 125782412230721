import {dataTestId} from '@hconnect/uikit'
import {HPTextField, HPTextFieldProps} from '@hconnect/uikit/src/lib2'
import {MenuItem} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

/**
 * dayOptions should be an array of integers
 * days should be integer
 * if isHumanized is true, the value of days will be displayed in humanized format,
 * Eg. 7 days will be displayed as 'a week'
 */

type PlannerDaysSelectProps = Pick<HPTextFieldProps, 'backgroundMode'> & {
  dayOptions: number[]
  days: number
  onChange: (days: number) => void
  label: string
  isHumanized?: boolean
  testId?: string
}

export const PlannerDaysSelect: React.FC<PlannerDaysSelectProps> = ({
  days,
  dayOptions,
  onChange,
  label,
  isHumanized = false,
  testId = 'amount_of_days_select',
  ...otherProps
}) => {
  if (days < 0) {
    throw new Error('BUG: value of days should be natural number or zero')
  }
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const formatAsHumanized = (value: number) =>
    moment.duration(value, 'days').locale(language).humanize({d: 7, w: 4})
  const formatAsDays = (value: number) => `${t('common.amountOfDays', {count: value})}`
  const formatOption = isHumanized ? formatAsHumanized : formatAsDays

  return (
    <HPTextField
      sx={{width: ({spacing}) => spacing(28)}}
      fullWidth
      select
      label={label}
      value={String(days)}
      onChange={({target: {value}}) => onChange(parseInt(value))}
      {...otherProps}
      {...dataTestId(testId)}
    >
      {dayOptions.map((value, index) => (
        <MenuItem key={index} value={String(value)} {...dataTestId(`${testId}_item_${value}_days`)}>
          {formatOption(value)}
        </MenuItem>
      ))}
    </HPTextField>
  )
}
