import React, {useMemo, useLayoutEffect} from 'react'

import {SelectedTimeframeProvider} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {PlannerSkeleton} from '../../../shared/components/skeletons/PlannerSkeleton'
import {
  convertToURLDatetime,
  parseStartEndDatetimeFromURLParams
} from '../../../shared/helpers/urlParams.utils'
import {calculateDatetimeRangeWithinBoundary} from '../../../shared/helpers/utils'
import {useLongTermPlanBoundary} from '../../../shared/hooks/useLongTermPlanBoundary'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useSearchParams} from '../../../shared/hooks/useSearchParams'
import {useStartOfToday} from '../../../shared/hooks/useStartOfToday'
import {MomentRange} from '../../../shared/selectors/time'
import {LayoutMonthlyPlanning} from '../LayoutMonthlyPlanning'

export const PageMonthlyProductionPlanning: React.FC = () => {
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  // boundary to set limits of scroll back and forward
  const boundary = useLongTermPlanBoundary()

  // we need to infer full timeframe from a selected range from URL
  // TODO abstract it to hook or fn getTimeFrameFromUrlParams
  const [obtainedParams, setSearchParams] = useSearchParams('start', 'end')

  const parsedSelectedRange = useMemo(() => {
    return parseStartEndDatetimeFromURLParams({...obtainedParams, timezoneId})
  }, [obtainedParams, timezoneId])

  const adjustedRange = useMemo(() => {
    if (!parsedSelectedRange) {
      return undefined
    }
    const rangeAdjusteByBoundary = calculateDatetimeRangeWithinBoundary(
      parsedSelectedRange,
      boundary
    )
    const startOfMonthRange = rangeAdjusteByBoundary[0].clone().tz(timezoneId).startOf('month')
    const monthRange: MomentRange = [startOfMonthRange, startOfMonthRange.clone().add(1, 'month')]
    return monthRange
  }, [parsedSelectedRange, boundary, timezoneId])

  const startOfToday = useStartOfToday({timezoneId, createdAt})

  const defaultTimeframe: MomentRange = [
    startOfToday.clone().startOf('month'),
    startOfToday.clone().startOf('month').add(1, 'month')
  ]

  // get timeframe from url at this point, if not exist, use default timeframe
  const timeframe = adjustedRange ?? defaultTimeframe

  const isParsedRangeCorrect =
    parsedSelectedRange &&
    adjustedRange &&
    parsedSelectedRange[0].isSame(adjustedRange[0]) &&
    parsedSelectedRange[1].isSame(adjustedRange[1])

  useLayoutEffect(() => {
    // if selectedRange is not set, set it to default selected range
    if (!parsedSelectedRange) {
      return setSearchParams({
        start: convertToURLDatetime(timeframe[0].clone().startOf('month'), timezoneId),
        end: convertToURLDatetime(timeframe[0].clone().startOf('month').add(1, 'month'), timezoneId)
      })
    }
  }, [parsedSelectedRange, setSearchParams, timeframe, timezoneId])

  useLayoutEffect(() => {
    // if selectedRange is not correct, set it to adjustedRange
    if (!isParsedRangeCorrect && adjustedRange) {
      return setSearchParams({
        start: convertToURLDatetime(adjustedRange[0], timezoneId),
        end: convertToURLDatetime(adjustedRange[1], timezoneId)
      })
    }
  }, [adjustedRange, isParsedRangeCorrect, setSearchParams, timezoneId])

  if (!isParsedRangeCorrect) {
    return <PlannerSkeleton height="40vh" />
  }

  return (
    <SelectedTimeframeProvider defaultTimeframe={timeframe}>
      <LayoutMonthlyPlanning />
    </SelectedTimeframeProvider>
  )
}
