import {Attachment} from '@hconnect/common/types'

import {usePlannerQuery} from '../usePlannerQuery'

interface AttachmentFilesQueryParams {
  attachments: Attachment[]
  // use preview url if exist
  isPreview: boolean
}

export const useAttachmentFilesQuery = ({attachments, isPreview}: AttachmentFilesQueryParams) => {
  return usePlannerQuery('attachmentFiles', [{attachments, isPreview}], {
    enabled: Boolean(attachments.length)
  })
}
