import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'

import {PlannerPageSkeleton} from '../../shared/components/skeletons/PlannerPageSkeleton'
import {useHistoryStoragesWithMaterialQuery} from '../../shared/hooks/api'
import {useScheduleQuery} from '../../shared/hooks/api/schedules/useScheduleQuery'
import {usePlanRange} from '../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'

export const PageOptimizer: React.FC = () => {
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const planTimeFrame = usePlanRange({timezoneId, createdAt})
  const {data: schedule} = useScheduleQuery({range: planTimeFrame, isOptimized: false})
  const {data: storages} = useHistoryStoragesWithMaterialQuery({timeFrame: planTimeFrame})

  if (!schedule || !storages) {
    return <PlannerPageSkeleton />
  }
  if (!schedule.isOptimizedScheduleAvailable) {
    return <Navigate to=".." replace />
  }
  return <Outlet />
}
