import {hpTheme} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import * as d3 from 'd3'
import {debounce} from 'lodash'
import React, {ReactNode, useEffect, useRef, useState, useMemo} from 'react'

import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {DatetimeValue} from '../../interfaces/common'

const {palette} = hpTheme
type ChartTooltipVariant = 'light' | 'dark'

const tooltipStyleMap: Record<ChartTooltipVariant, {color: string; background: string}> = {
  dark: {background: palette.grey[900], color: palette.common.white},
  light: {background: palette.common.white, color: palette.text.secondary}
}
interface DatetimeValueChartTooltipProps {
  chartRef: React.RefObject<HTMLDivElement | SVGSVGElement>
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  datetimeValues: DatetimeValue<string>[]
  formatTooltip: (datetime: DatetimeValue, index: number) => ReactNode
  variant?: 'light' | 'dark'
}

const _DatetimeValueChartTooltip: React.FC<DatetimeValueChartTooltipProps> = ({
  chartRef,
  xScale,
  yScale,
  datetimeValues,
  formatTooltip,
  variant = 'dark'
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null)
  const [tooltipContent, setTooltipContentState] = useState<ReactNode | undefined>()

  const debouncedSetTooltipContent = useMemo(() => debounce(setTooltipContentState, 0), [])

  useEffect(() => {
    if (!chartRef.current || !tooltipRef.current) {
      return
    }
    const hoverEffectD3 = d3.select(chartRef.current)
    const tooltipD3 = d3.select(tooltipRef.current)

    hoverEffectD3
      .on('mouseout', () => {
        tooltipD3.style('opacity', '0')
      })
      .on('mouseover', () => {
        tooltipD3.style('opacity', '1')
      })
      .on('mousemove', (event: MouseEvent) => {
        const [mouseX]: [number, number] = d3.pointer(event)
        const hoveredDate = xScale.invert(mouseX)
        const positionX = d3.bisect(
          datetimeValues.map((entry) => new Date(entry.datetime)),
          hoveredDate
        )

        const currentDatetimeValue = datetimeValues[positionX]
        if (currentDatetimeValue) {
          const positionY = yScale(currentDatetimeValue.value)
          tooltipD3.style('left', `${xScale(new Date(currentDatetimeValue.datetime))}px`)
          tooltipD3.style('top', `${positionY}px`)
          tooltipD3.style('opacity', '1')
          const tooltipContent = formatTooltip(currentDatetimeValue, positionX)
          debouncedSetTooltipContent(tooltipContent)
        } else {
          tooltipD3.style('left', `${mouseX}px`)
          tooltipD3.style('opacity', '0')
        }
      })
  }, [chartRef, xScale, yScale, datetimeValues, formatTooltip, debouncedSetTooltipContent])

  const {background, color} = tooltipStyleMap[variant]

  return (
    <Box
      ref={tooltipRef}
      sx={{
        zIndex: 1,
        opacity: 0,
        position: 'absolute',
        width: 'auto',
        pointerEvents: 'none',
        transform: 'translate(-50%,-100%)'
      }}
    >
      {tooltipContent && (
        <Box
          sx={{
            p: 2,
            mb: 2,
            background,
            color,
            boxShadow: ({shadows}) => shadows[8],
            border: 0,
            borderRadius: 1,
            position: 'relative',
            '&:after': {
              position: 'absolute',
              content: "''",
              width: 0,
              height: 0,
              borderLeft: '5px solid transparent',
              borderRight: '5px solid transparent',
              borderTop: `6px solid ${background}`,
              top: '100%',
              left: '50%',
              transform: 'translate(-50%)'
            }
          }}
        >
          {tooltipContent}
        </Box>
      )}
    </Box>
  )
}

export const DatetimeValueChartTooltip = React.memo(_DatetimeValueChartTooltip)
