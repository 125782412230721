import {Moment} from 'moment-timezone'

import {Range} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface UnplannedDeliveriesQueryParams {
  materialId: number
  timeframe: Range<Moment> | undefined
}

export const useUnplannedDeliveriesQuery = ({
  materialId,
  timeframe
}: UnplannedDeliveriesQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const [start, end]: (Moment | undefined)[] = timeframe ?? []

  const isEnabled = Boolean(timeframe)

  return usePlannerQuery(
    'materialUnplannedDeliveries',
    [{plantCode, from: start?.toISOString(), to: end?.toISOString(), materialId}],
    {enabled: isEnabled}
  )
}
