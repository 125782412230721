import {dataTestId} from '@hconnect/uikit'
import {Button} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {fetchHcemQuery} from '../../../../../shared/api/queries'
import {useScheduleCostAvoidanceDialog} from '../../../../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {useScheduleQuery} from '../../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {usePlanningChartStartEnd} from '../../PlanningChartStartEndProvider'

export const CalculateCostAvoidanceButton = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const {data: schedule} = useScheduleQuery({range: [startOfPlan, endOfPlan], isOptimized: false})
  const scheduleId = schedule?.scheduleId
  const {setIsDialogOpen} = useScheduleCostAvoidanceDialog()

  const handleCalculateCostAvoidance =
    scheduleId !== undefined
      ? () => {
          void fetchHcemQuery('scheduleCostAvoidance', {plantCode, scheduleId})
          setIsDialogOpen(true)
        }
      : undefined

  return (
    <Button
      variant="contained"
      id="calculate-cost-avoidance"
      onClick={handleCalculateCostAvoidance}
      {...dataTestId('calculate_cost_avoidance_button')}
    >
      {t('planning.calculateCostAvoidance')}
    </Button>
  )
}
