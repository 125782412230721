import {HistoryStorageWithMaterial} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Button, SvgIcon} from '@mui/material'
import React, {FC} from 'react'

import {ReactComponent as SiloIcon} from '../../../../../../assets/icons/SilosIcon.svg'

interface SelectStorageButtonProps {
  storage: Pick<HistoryStorageWithMaterial, 'id' | 'name'>
  isSelected: boolean
  isDisabled: boolean
  handleSelectStorage?: (id: number) => void
}

export const SelectStorageButton: FC<SelectStorageButtonProps> = ({
  storage,
  isSelected,
  isDisabled,
  handleSelectStorage
}) => {
  return (
    <Button
      startIcon={
        <SvgIcon
          component={SiloIcon}
          inheritViewBox
          sx={
            isSelected
              ? {
                  '& path': {
                    fill: ({palette}) => palette.common.white
                  }
                }
              : undefined
          }
        />
      }
      color="secondary"
      variant={isSelected ? 'contained' : 'outlined'}
      onClick={(e) => {
        e.preventDefault()
        handleSelectStorage?.(storage.id)
      }}
      disabled={isDisabled}
      {...dataTestId('select_storage_button')}
    >
      {storage.name}
    </Button>
  )
}
