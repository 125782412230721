import type {Material, StockLevelOverwrite} from '@hconnect/common/types'
import {dataTestId, dateFormatter, useTranslation} from '@hconnect/uikit'
import {CardTitle, DateTimePicker} from '@hconnect/uikit/src/lib2'
import {Check, Close} from '@mui/icons-material'
import {Box, Dialog, DialogContent, Stack, Typography, Button} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import {useMemo} from 'react'
import {Controller, useForm} from 'react-hook-form'

import {minValidator, requiredValidator} from '../../../helpers/validators'
import {useCurrentTime} from '../../../hooks/useCurrentTime'
import {usePlantConfig} from '../../../hooks/usePlantConfigData'
import {DatetimeValue} from '../../../interfaces/common'
import {getPlantUtcOffset} from '../../../selectors/time'
import {PlannerNumberInput} from '../../formComponents/PlannerNumberInput'

export type StockOverwriteDialogProps = {
  stock: DatetimeValue<string, number> | undefined
  material: Material
  disabledDateEditing: boolean
  onOverwrite: (stock: DatetimeValue<string, number>) => void
  overwrite: StockLevelOverwrite | undefined
  isSaving: boolean
  onClose: () => void
}

export const StockOverwriteDialog = ({
  stock,
  onClose,
  onOverwrite,
  overwrite,
  isSaving,
  material,
  disabledDateEditing
}: StockOverwriteDialogProps) => {
  const {timezone_id: timezoneId} = usePlantConfig()
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const currentTime = useCurrentTime({timezoneId})

  const defaultValues = useMemo(
    () => ({
      datetime: moment(stock?.datetime)
        .tz(timezoneId)
        .startOf('hour'),
      stockLevel: stock?.value ?? 0
    }),
    [stock?.datetime, stock?.value, timezoneId]
  )

  const {
    handleSubmit,
    control,
    formState: {isValid}
  } = useForm<{
    datetime: Moment
    stockLevel: number
  }>({
    mode: 'all',
    shouldFocusError: false,
    defaultValues
  })

  const submit = handleSubmit((data) => {
    if (!isValid) return

    onOverwrite({
      datetime: data.datetime.toISOString(),
      value: data.stockLevel
    })
  })

  return (
    <Dialog open={Boolean(stock)} onClose={onClose}>
      <DialogContent
        sx={{width: ({spacing}) => spacing(50)}}
        {...dataTestId('stock_overwrite_dialog_content')}
      >
        <>
          <Box sx={{mb: 3}}>
            <CardTitle sx={{mb: 0}}>{t('stock.materials.correctStockLevel')}</CardTitle>
            <Typography variant="caption">{material.name}</Typography>
          </Box>
          <Stack spacing={3}>
            <Controller
              name="datetime"
              control={control}
              rules={{
                ...requiredValidator(t),
                validate: (value) => {
                  const valueAsDate = moment(value)
                  if (valueAsDate.isAfter(currentTime)) {
                    return t('common.dateMustBeInThePast')
                  }
                  return undefined
                }
              }}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <Box pb={1} {...dataTestId('stock_overwrite_datetime')}>
                  <DateTimePicker
                    ref={ref}
                    onChange={onChange}
                    disabled={disabledDateEditing}
                    dateLabel={t('datePicker.date')}
                    timeLabel={t('datePicker.time')}
                    value={value}
                    maxDate={currentTime}
                    error={error}
                    timezoneId={timezoneId}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name="stockLevel"
              rules={{...requiredValidator(t), ...minValidator(t, 0)}}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <PlannerNumberInput
                  inputRef={ref}
                  min={0}
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  label={t('planning.stockLevel')}
                  dataTestId="stock_overwrite_amount_input"
                />
              )}
            />
            {overwrite && (
              <Stack>
                <Typography variant="caption">
                  {dateFormatter(
                    moment(overwrite.createdAt).tz(timezoneId),
                    language,
                    getPlantUtcOffset(timezoneId)
                  )}
                </Typography>
                <Typography variant="caption">
                  {t('stock.materials.lastCorrectionBy', {name: overwrite.updatedBy})}
                </Typography>
              </Stack>
            )}
            <Box sx={{display: 'flex', mt: 2, justifyContent: 'flex-end'}}>
              <Button
                color="primary"
                variant="text"
                {...dataTestId('stock_overwrite_dialog_cancel_button')}
                sx={{
                  minWidth: (theme) => theme.spacing(15)
                }}
                onClick={onClose}
                startIcon={<Close />}
              >
                {t('common.cancel')}
              </Button>

              <Button
                variant="contained"
                color="primary"
                {...dataTestId('stock_overwrite_dialog_save_button')}
                sx={{
                  minWidth: (theme) => theme.spacing(15)
                }}
                onClick={submit}
                disabled={isSaving}
                startIcon={<Check />}
              >
                {t('common.save')}
              </Button>
            </Box>
          </Stack>
        </>
      </DialogContent>
    </Dialog>
  )
}
