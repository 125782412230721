import {dataTestId, useTranslation} from '@hconnect/uikit'
import {AutoAwesomeOutlined} from '@mui/icons-material'
import {Button, CircularProgress} from '@mui/material'
import {useCallback, useState} from 'react'

import {AutofillStatus} from '../../../../shared/enums'
import {useGetAutofillStatus} from '../../../../shared/hooks/api/optimizer/useGetAutofillStatus'
import {usePeriodicAutofillCheck} from '../../../../shared/hooks/usePeriodicAutofillCheck'

import {AutoFillDialog} from './AutoFillDialog'

export const AutoFillButton = () => {
  const {t} = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const {data: autofillStatus} = useGetAutofillStatus()

  // to handle reload schedule on autofill finished
  usePeriodicAutofillCheck()

  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])
  const openAutofillDialog = useCallback(() => {
    setDialogOpen(true)
  }, [setDialogOpen])

  // if autofill is not available, do not show the button
  if (autofillStatus?.status === AutofillStatus.NotAvailable) {
    return null
  }

  return (
    <>
      <Button
        size="large"
        variant="text"
        color="primary"
        disabled={autofillStatus?.status === AutofillStatus.InProgress}
        startIcon={
          autofillStatus?.status === AutofillStatus.InProgress ? (
            <CircularProgress color="primary" size={14} />
          ) : (
            <AutoAwesomeOutlined fontSize="small" />
          )
        }
        onClick={openAutofillDialog}
        {...dataTestId('autofill_month_button')}
      >
        {t('planning.autofill.autofillMonth')}
      </Button>
      <AutoFillDialog dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />
    </>
  )
}
