import React from 'react'

import {BaseErrorPage} from './BaseErrorPage'
import {ReactComponent as Illustration} from './illustrationMaintenance.svg'

interface MaintenancePageProps {
  caption: string
  description: string
}

export const MaintenancePage: React.FC<MaintenancePageProps> = ({caption, description}) => {
  return (
    <BaseErrorPage caption={caption} description={description} illustration={<Illustration />} />
  )
}
