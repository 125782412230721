import {dataTestId} from '@hconnect/uikit'
import {Stack, Box} from '@mui/material'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {convertToURLDatetime} from '../../../shared/helpers/urlParams.utils'
import {useLongTermPlanBoundary} from '../../../shared/hooks/useLongTermPlanBoundary'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useSearchParams} from '../../../shared/hooks/useSearchParams'
import {MomentRange} from '../../../shared/selectors/time'
import {MonthRangeSelect} from '../shared/MonthRangeSelect'
import {PlanningScopeSelect} from '../shared/PlanningScopeSelect'

export const MonthlyProductionPlanningActions = () => {
  const {selectedTimeframe, setSelectedTimeframe} = useSelectedTimeframe()
  const {timezone_id: timezoneId} = usePlantConfig()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }
  const boundary = useLongTermPlanBoundary()

  const [, setSearchParams] = useSearchParams('start', 'end')

  const handleTimeframeChange = (timeframe: MomentRange) => {
    // TODO validate if it's a monthly timeframe
    setSelectedTimeframe(timeframe)
    setSearchParams({
      start: convertToURLDatetime(timeframe[0], timezoneId),
      end: convertToURLDatetime(timeframe[1], timezoneId)
    })
  }

  return (
    <Box>
      <Stack direction="row" spacing={1.5} {...dataTestId('monthly_production_planning_actions')}>
        <MonthRangeSelect
          selectedRange={selectedTimeframe}
          setSelectedRange={handleTimeframeChange}
          validDateRange={boundary}
        />
        <PlanningScopeSelect />
      </Stack>
    </Box>
  )
}
