import type {BackendModule, Services, InitOptions, ReadCallback} from 'i18next'

interface BackendResolverOptions {
  loader: (filename: string) => Promise<Record<string, unknown> | undefined>
  forceUnderscore: boolean
  loadFile: string
}

const defaultOptions: BackendResolverOptions = {
  loader: () => Promise.reject(new Error()),
  // will replace - with _ in filenames (default: true)
  forceUnderscore: true,
  loadFile: '{{lng}}'
}

class BackendResolver implements BackendModule<Partial<BackendResolverOptions>> {
  type: 'backend' = 'backend' as const

  private services: Services
  private options: BackendResolverOptions
  private i18nextOptions: InitOptions

  static type: 'backend' = 'backend' as const

  constructor(
    services: Services,
    backendOptions: Partial<BackendResolverOptions>,
    i18nextOptions: InitOptions
  ) {
    this.services = services
    this.options = this.options = {...defaultOptions, ...backendOptions}
    this.i18nextOptions = i18nextOptions
  }

  init(
    services: Services,
    backendOptions: Partial<BackendResolverOptions>,
    i18nextOptions: InitOptions
  ): void {
    this.services = services
    this.options = this.options = {...defaultOptions, ...backendOptions}
    this.i18nextOptions = i18nextOptions
  }

  create(
    languages: readonly string[],
    namespace: string,
    key: string,
    fallbackValue: string
  ): void {
    // handle missing translations
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `Translation for "${key}" is missing in [${languages.join(
          ', '
        )}]:[${namespace}], fallback to "${fallbackValue}"`
      )
    }
  }

  read(language: string, namespace: string, callback: ReadCallback): void {
    const {forceUnderscore, loadFile, loader} = this.options
    const lng = forceUnderscore ? language.replace(/-/, '_') : language
    const filename = this.services.interpolator.interpolate(loadFile, {lng, ns: namespace}, lng, {})

    loader(filename)
      .then((resource) => {
        if (resource) {
          callback(undefined, resource)
        } else {
          callback(new Error(`could not load translation [${language}]:[${namespace}]!`), undefined)
        }
      })
      .catch((e: unknown) => {
        callback(
          new Error(
            `Loading translation [${language}]:[${namespace}] caused error ${(
              e as Error | undefined
            )?.message}`
          ),
          undefined
        )
      })
  }
}

export {BackendResolver as backendResolver}
