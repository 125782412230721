import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  createHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends
} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'

export type AsyncFn<P extends unknown[], R> = (...args: P) => Promise<R>
export const dsApiBasePath = '/hcem/ui'

const checkIsLocal = (host: string) => {
  return /^localhost:/.exec(host)
}

export class ApiClient {
  static #axiosInstance: AxiosInstance | null = null
  static #loginStorage: BrowserLoginStorage | null = null
  static #loginFlow: BrowserLoginFlow | null = null
  private static initialize() {
    const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
    const selectedBackend = backendSelector.getSelectedBackend()

    ApiClient.#loginStorage = new BrowserLoginStorage(
      `HC-${process.env.REACT_APP_CLIENT_NAME}-${selectedBackend.NAME}`
    )

    const dfAuthRequestProvider = new EnvAuthRequestProvider(backendSelector)
    ApiClient.#loginFlow = new BrowserLoginFlow(
      ApiClient.#loginStorage,
      dfAuthRequestProvider,
      backendSelector
    )

    // API for all service requests going through api gateway
    ApiClient.#axiosInstance = createHttpClient(
      {
        backendSelector: backendSelector,
        authRequestProvider: dfAuthRequestProvider,
        loginStorage: ApiClient.#loginStorage,
        loginFlow: ApiClient.#loginFlow
      },
      // overwriting x-correlation-id for local development
      checkIsLocal(window.location.host)
        ? {
            headers: {
              'x-correlation-id':
                'localplaceholder|6973fe3b46aa49cf985cb240c0383d4e.localplaceholder'
            }
          }
        : undefined
    )
  }

  public static get axiosInstance(): AxiosInstance {
    if (ApiClient.#axiosInstance === null) ApiClient.initialize()
    return ApiClient.#axiosInstance as AxiosInstance
  }
  public static get loginFlow(): BrowserLoginFlow {
    if (ApiClient.#loginFlow === null) ApiClient.initialize()
    return ApiClient.#loginFlow as BrowserLoginFlow
  }
}
