import type {Plugin, TimeScaleOptions} from 'chart.js/auto'
import moment from 'moment'

export const weekendIndicationOnAxisPlugin: Plugin<
  'bar' | 'line',
  {backgroundColor: string; axisName: string; isShifted?: boolean}
> = {
  id: 'weekendIndicationOnAxisPlugin',
  beforeDraw: function (chart, _, {axisName, backgroundColor, isShifted = false}) {
    const ctx = chart.ctx
    const timeAxis = chart.scales[axisName]
    const canvasHeight = chart.height

    const isOffseted = (timeAxis.options as TimeScaleOptions).grid.offset
    ctx.save()
    ctx.fillStyle = backgroundColor

    // Get min and max values of the x-axisx
    const minDate = moment(timeAxis.min)
    const maxDate = moment(timeAxis.max).add(1, 'day')

    const dayWidth =
      timeAxis.getPixelForValue(minDate.clone().add(1, 'day').valueOf()) -
      timeAxis.getPixelForValue(minDate.valueOf())
    // since bar chart puts the ticks between grid lines, we need to shift it by half of the day width
    // for line chartwe don't need to shift it
    const offsetAwareShift = isOffseted ? dayWidth : dayWidth / 2
    const shiftAmount = isShifted ? offsetAwareShift : 0

    for (let date = minDate.clone(); date.isSameOrBefore(maxDate); date.add(1, 'day')) {
      const dayOfWeek = date.day()
      // Check if the day is Saturday or Sunday
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        const xStart = timeAxis.getPixelForValue(date.valueOf()) - shiftAmount
        const xEnd = timeAxis.getPixelForValue(date.clone().add(1, 'day').valueOf()) - shiftAmount

        // Draw a rectangle from xStart to xEnd
        ctx.fillRect(xStart, canvasHeight - timeAxis.height, xEnd - xStart, canvasHeight)
      }
    }
    ctx.restore()
  }
}
