import moment, {Moment} from 'moment-timezone'

import {IncomingMaterialStatus, MaterialOrderStatus} from '../enums'
import type {GroupedUnplannedDelivery, MaterialOrder} from '../interfaces/api'
import {DatetimeValue} from '../interfaces/common'

export const splitOrdersToPastAndFuture = (orders: MaterialOrder[], startOfToday: Moment) =>
  orders.reduce<{
    past: MaterialOrder[]
    future: MaterialOrder[]
  }>(
    (acc, order) => {
      if (moment.utc(order.scheduledDate).isSameOrAfter(startOfToday)) {
        acc['future'].push(order)
      } else {
        acc['past'].push(order)
      }
      return acc
    },
    {past: [], future: []}
  )

/**
 * function which combines all deliveries for one order to one datapoint per order
 * if deliveries array is not empty
 */
export const combineDeliveredPerOrder = (orders: MaterialOrder[]): DatetimeValue[] =>
  orders
    .filter(({deliveries}) => Boolean(deliveries.length))
    .map(({deliveries}) => ({
      // BE should return deliveries sorted by deliveredOn date
      datetime: deliveries[deliveries.length - 1].deliveredOn,
      value: deliveries.reduce((sum, {amountDelivered}) => (sum += amountDelivered), 0)
    }))

/**
 * helper function to calculate incoming amount hourly for a specific material
 * hours are rounded to the begining of an hour
 * deliveries for one order are combined, and put to the latest delivery datetime
 * deliveries displayed only from the past up to the beginning of today
 * ordered amount is displayed from the beginning of today to the future
 */
interface GetPlannedIncomingMaterialHourlyParams {
  startOfToday: Moment
  orders: MaterialOrder[]
}

export const getPlannedIncomingMaterialHourly = ({
  startOfToday,
  orders
}: GetPlannedIncomingMaterialHourlyParams) => {
  // Canceled orders should be added to the stock development
  const validOrders = orders.filter(({status}) => status !== MaterialOrderStatus.Cancelled)
  const {past: pastOrders, future: futureOrders} = splitOrdersToPastAndFuture(
    validOrders,
    startOfToday
  )

  const delivered = combineDeliveredPerOrder(pastOrders)
  const ordered: DatetimeValue[] = futureOrders.map(({amount, scheduledDate}) => ({
    value: amount,
    datetime: scheduledDate
  }))

  const incomingMaterial = [...delivered, ...ordered]

  const hourlyIncomingMaterialDictionary = incomingMaterial.reduce<Record<string, number>>(
    (acc, {datetime, value}) => {
      const hourDelivered = moment.utc(datetime).startOf('hour').toISOString()
      acc[hourDelivered] = (acc[hourDelivered] ?? 0) + value
      return acc
    },
    {}
  )

  return hourlyIncomingMaterialDictionary
}

const DELIVERED_AMOUNT_FRACTION_THRESHOLD = 0.1

export const getDeliveredIncomingMaterialStatus = (ordered: number, delivered: number) => {
  return ordered / (delivered ?? ordered) - 1 < DELIVERED_AMOUNT_FRACTION_THRESHOLD
    ? IncomingMaterialStatus.Delivered
    : IncomingMaterialStatus.Overdue
}

export type CanceledIncomingMaterial = {
  status: IncomingMaterialStatus.Cancelled
  order: MaterialOrder
} & DatetimeValue<Moment>

export type PlannedIncomingMaterial = {
  status: IncomingMaterialStatus.Planned
  order: MaterialOrder
} & DatetimeValue<Moment>

export type DeliveredIncomingMaterial = {
  status: IncomingMaterialStatus.Delivered | IncomingMaterialStatus.Overdue
  order: MaterialOrder
} & DatetimeValue<Moment>

export type UnplannedIncomingMaterial = {
  status: IncomingMaterialStatus.Unknown
  unplannedDelivery: GroupedUnplannedDelivery
} & DatetimeValue<Moment>

export type IncomingMaterial =
  | PlannedIncomingMaterial
  | DeliveredIncomingMaterial
  | CanceledIncomingMaterial
  | UnplannedIncomingMaterial

export const mapFutureOrdersToPlannedMaterial = (
  materialOrders: MaterialOrder[]
): PlannedIncomingMaterial[] =>
  materialOrders.map((order) => ({
    status: IncomingMaterialStatus.Planned,
    datetime: moment.utc(order.scheduledDate).startOf('hour'),
    value: order.amount,
    order
  }))

export const mapPastOrdersToDeliveredMaterial = (
  materialOrders: MaterialOrder[]
): DeliveredIncomingMaterial[] =>
  materialOrders.map((order) => {
    const {deliveries, amount, scheduledDate} = order
    const delivered = deliveries.reduce((sum, {amountDelivered}) => sum + amountDelivered, 0)
    // for datetime assuming that BE will return deliveries chronologically
    const datetime = delivered !== 0 ? deliveries[deliveries.length - 1].deliveredOn : scheduledDate
    return {
      status: getDeliveredIncomingMaterialStatus(amount, delivered),
      datetime: moment.utc(datetime).startOf('hour'),
      value: delivered,
      order
    }
  })

export const mapCanceledOrdersToCancelledMaterial = (
  materialOrders: MaterialOrder[]
): CanceledIncomingMaterial[] =>
  materialOrders.map((order) => ({
    status: IncomingMaterialStatus.Cancelled,
    datetime: moment.utc(order.scheduledDate).startOf('hour'),
    value: 0,
    order
  }))

export const mapUnplannedDeliveriesToUnplannedMaterial = (
  unplannedDeliveries: GroupedUnplannedDelivery[]
): UnplannedIncomingMaterial[] =>
  unplannedDeliveries.map((unplannedDelivery) => ({
    status: IncomingMaterialStatus.Unknown,
    datetime: moment.utc(unplannedDelivery.deliveredOn).startOf('hour'),
    value: unplannedDelivery.amountDelivered,
    unplannedDelivery: unplannedDelivery
  }))

/**
 * function to map all incoming material like unplanned deliveries, orders and deliveries
 * to hourly dictionary for use in stock development chart
 */

export const mapMaterialOrdersToIncomingMaterial = (
  startOfToday: Moment,
  materialOrders: MaterialOrder[],
  unplannedDeliveries: GroupedUnplannedDelivery[]
) => {
  const validMaterialOrders = materialOrders.filter(
    ({status}) => status !== MaterialOrderStatus.Cancelled
  )
  const canceledMaterialOrders = materialOrders.filter(
    ({status}) => status === MaterialOrderStatus.Cancelled
  )

  const {future: futureOrders, past: pastOrders} = splitOrdersToPastAndFuture(
    validMaterialOrders,
    startOfToday
  )

  const plannedIcomingMaterial = mapFutureOrdersToPlannedMaterial(futureOrders)
  const deliveredIncomingMaterial = mapPastOrdersToDeliveredMaterial(pastOrders)
  const canceledIncomingMaterial = mapCanceledOrdersToCancelledMaterial(canceledMaterialOrders)
  const unplannedIncomingMaterial = mapUnplannedDeliveriesToUnplannedMaterial(unplannedDeliveries)

  return {
    planned: plannedIcomingMaterial,
    canceled: canceledIncomingMaterial,
    delivered: deliveredIncomingMaterial,
    unplanned: unplannedIncomingMaterial
  }
}

export const getIncomingMaterialHourly = (
  incomingMaterial: ReturnType<typeof mapMaterialOrdersToIncomingMaterial>
): Record<string, IncomingMaterial[]> => {
  const {planned, canceled, delivered, unplanned} = incomingMaterial

  const incomingMaterialHourly = [...planned, ...canceled, ...delivered, ...unplanned].reduce<
    Record<string, IncomingMaterial[]>
  >((acc, incomingMaterial) => {
    const key = incomingMaterial.datetime.toISOString()
    acc[key] ? acc[key].push(incomingMaterial) : (acc[key] = [incomingMaterial])
    return acc
  }, {})

  return incomingMaterialHourly
}
