import moment from 'moment-timezone'

import {usePlantDatetime} from '../../usePlantTime'

import {
  START_OF_COST_AVOIDANCE_DATE,
  useCostAvoidanceMonthlyQuery
} from './useCostAvoidanceMonthlyQuery'

export const useCostAvoidanceAvailableYears = (): string[] => {
  const {localDatetimeUtc} = usePlantDatetime()

  const {data: costAvoidanceMonthly} = useCostAvoidanceMonthlyQuery([
    START_OF_COST_AVOIDANCE_DATE,
    localDatetimeUtc.clone().startOf('year').add(1, 'year').toISOString()
  ])

  const availableYears = costAvoidanceMonthly
    ? [
        ...new Set(
          costAvoidanceMonthly.map(({date}) => moment.utc(date).startOf('year').toISOString())
        )
      ]
    : []
  return availableYears
}
