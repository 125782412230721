import {dataTestId} from '@hconnect/uikit'
import {SxProps, styled, useTheme} from '@mui/material'
import React from 'react'

import {getAmpelBackgroundColorStyle} from '../../../../../shared/ampel.styles'
import {PriceLevel} from '../../../../../shared/enums'
import {toPixel} from '../../../../../shared/helpers/utils'

const PRICE_IDICATOR_HEIGHT_PX = 4

const ScheduleItemBackgroundDiv = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isPriceIndicatorVisible'
})<{isPriceIndicatorVisible: boolean}>(({isPriceIndicatorVisible}) => ({
  position: 'absolute',
  top: 0,
  height: isPriceIndicatorVisible ? `calc(100% - ${toPixel(PRICE_IDICATOR_HEIGHT_PX)})` : '100%',
  left: 0,
  right: 0,
  zIndex: -1
}))

const PriceIndicatorDiv = styled('div')({
  borderRadius: 2,
  width: '100%',
  height: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -2
})

export interface ScheduleItemBackgroundProps {
  isMaintenance: boolean
  shouldDisplayColorCodedPrice: boolean
  priceLevel: PriceLevel
}

export const ScheduleItemBackground: React.FC<ScheduleItemBackgroundProps> = ({
  isMaintenance,
  shouldDisplayColorCodedPrice,
  priceLevel
}) => {
  const theme = useTheme()

  const backgroundColor = isMaintenance ? theme.palette.grey[600] : theme.palette.common.white
  const priceIndicatorBackgroundColor = getAmpelBackgroundColorStyle(priceLevel) as SxProps

  return (
    <>
      <ScheduleItemBackgroundDiv
        sx={{backgroundColor}}
        isPriceIndicatorVisible={shouldDisplayColorCodedPrice}
      />
      {shouldDisplayColorCodedPrice && (
        <PriceIndicatorDiv {...dataTestId('price_indicator')} sx={priceIndicatorBackgroundColor} />
      )}
    </>
  )
}
