import React, {useContext, useState, useMemo} from 'react'

import type {MaterialOrder} from '../../interfaces/api'

type SelectedMaterialOrder = {id: MaterialOrder['id'] | undefined}
interface SelectedMaterialOrderState {
  selectedMaterialOrder: SelectedMaterialOrder | undefined
  setSelectedMaterialOrder: (materialOrder: undefined | SelectedMaterialOrder) => void
}

const SelectedMaterialOrderContext = React.createContext<SelectedMaterialOrderState | undefined>(
  undefined
)

export const useSelectedMaterialOrder = () => {
  const context = useContext(SelectedMaterialOrderContext)
  if (!context)
    throw new Error(
      'Cannot use SelectedMaterialOrderContext outside of a SelectedMaterialOrderProvider provider'
    )
  return context
}

type SelectedMaterialOrderProviderProps = {
  children: React.ReactNode
}

export const SelectedMaterialOrderProvider: React.FC<SelectedMaterialOrderProviderProps> = ({
  children
}) => {
  const [selectedMaterialOrder, setSelectedMaterialOrder] = useState<SelectedMaterialOrder>()

  const memoizedState = useMemo(
    () => ({
      selectedMaterialOrder,
      setSelectedMaterialOrder
    }),
    [selectedMaterialOrder]
  )

  return (
    <SelectedMaterialOrderContext.Provider value={memoizedState}>
      {children}
    </SelectedMaterialOrderContext.Provider>
  )
}
