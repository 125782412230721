import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {notifyIfErrorMessage} from '../../../common'
import {PlannerQueryClient} from '../../../components/providers/QueryProvider'
import {CommentsCategory} from '../../../enums'
import {useTrackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {ErrorResponse, Comment} from '../../../interfaces/api'

export const useAddAssetComment = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()
  const trackPlannerEvent = useTrackPlannerEvent()
  const {mutateAsync} = useMutation<
    Comment,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.addComment>[0]
  >({
    mutationFn: mutations.addComment,
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.addedAssetComment'))
      trackPlannerEvent({name: 'onCommentAddToAsset'})
    },
    onSettled: () => {
      void PlannerQueryClient.invalidateQueries({
        queryKey: ['comments', {commentsCategory: CommentsCategory.Assets}]
      })
    }
  })

  return (params: Omit<Parameters<typeof mutations.addComment>[0], 'commentsCategory'>) =>
    mutateAsync({...params, commentsCategory: CommentsCategory.Assets})
}
