import {PageTitle} from '@hconnect/uikit/src/lib2'
import {Stack, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {OptimizerNotification} from './shared/OptimizerNotification'

interface PageProductionPlanningHeaderProps {
  actions: React.ReactNode
}

export const PageProductionPlanningHeader: React.FC<PageProductionPlanningHeaderProps> = React.memo(
  ({actions}) => {
    const {t} = useTranslation()
    return (
      <Stack direction="row" spacing={2} justifyContent="space-between" sx={{pr: 3}}>
        <Box component="span" sx={{position: 'relative'}}>
          <PageTitle>{t('navItems.productionPlanning')}</PageTitle>
          <OptimizerNotification />
        </Box>
        {actions}
      </Stack>
    )
  }
)

PageProductionPlanningHeader.displayName = 'PageProductionPlanningHeader'
