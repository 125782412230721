import {AxiosRequestConfig} from 'axios'

import {ClientConfig} from '../../types'

export const createOnRequestInterceptor =
  (clientConfig: Partial<ClientConfig>) => (request: AxiosRequestConfig) => {
    const {onRequest} = clientConfig
    if (onRequest) {
      onRequest(request)
    }

    return request
  }
