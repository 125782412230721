import {dataTestId} from '@hconnect/uikit'
import {Stack, TextField, Typography} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import moment, {Moment} from 'moment-timezone'
import {useCallback, useEffect} from 'react'
import {Controller, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {requiredValidator} from '../../../../shared/helpers/validators'
import {useCurrentTime} from '../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'

export type AutoFillDialogParams = {
  startTime: Moment
  endTime: Moment
}

export type AutoFillDialogContentProps = Pick<
  UseFormReturn<AutoFillDialogParams>,
  'control' | 'watch' | 'trigger'
>

export const AutoFillDialogContent = ({control, watch, trigger}: AutoFillDialogContentProps) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()

  const now = useCurrentTime({timezoneId})

  const startTime = watch('startTime')
  const endTime = watch('endTime')

  useEffect(() => {
    void trigger()
  }, [startTime, endTime, trigger])

  const validateStartEnd = useCallback(
    ({startTime, endTime}: {endTime: Moment; startTime: Moment}) => {
      return endTime.isAfter(startTime) ? undefined : t('common.validation.endAfterStart')
    },
    [t]
  )

  const minRangeDate = now.clone().startOf('day').add(1, 'day')

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
        <Controller
          control={control}
          name="startTime"
          rules={{
            ...requiredValidator(t)
          }}
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <DatePicker
              value={value}
              onChange={onChange}
              minDate={minRangeDate}
              maxDate={moment().tz(timezoneId).add(1, 'years').endOf('year')}
              renderInput={(props) => (
                <TextField
                  {...props}
                  error={!!error}
                  inputProps={{
                    ...props.inputProps,
                    ...dataTestId('autofill_dialog_start_date_select_input'),
                    readOnly: true
                  }}
                />
              )}
              label={t('common.startDate')}
              {...dataTestId('autofill_dialog_start_date_select')}
            />
          )}
        />
        <Typography variant="subtitle1">&mdash;</Typography>
        <Controller
          control={control}
          name="endTime"
          rules={{
            ...requiredValidator(t),
            validate: (value, otherFields) =>
              validateStartEnd({endTime: value, startTime: otherFields.startTime})
          }}
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <DatePicker
              value={value}
              onChange={onChange}
              minDate={minRangeDate}
              maxDate={moment().tz(timezoneId).add(1, 'years').endOf('year')}
              renderInput={(props) => (
                <TextField
                  {...props}
                  error={!!error}
                  inputProps={{
                    ...props.inputProps,
                    ...dataTestId('autofill_dialog_end_date_select_input'),
                    readOnly: true
                  }}
                />
              )}
              label={t('common.endDate')}
              {...dataTestId('autofill_dialog_end_date_select')}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  )
}
