import {dataTestId} from '@hconnect/uikit'
import {Skeleton} from '@mui/material'

import {PlannerPageLayout} from '../PlannerPageLayout'

import {PlannerSkeleton} from './PlannerSkeleton'

export const PlannerPageSkeleton = () => {
  return (
    <PlannerPageLayout
      {...dataTestId('planner_page_skeleton')}
      isTourBannerEnabled={false}
      title={
        <Skeleton
          variant="text"
          sx={{width: (theme) => theme.spacing(50), bgcolor: 'background.paper'}}
        />
      }
    >
      <PlannerSkeleton height="65vh" />
    </PlannerPageLayout>
  )
}
