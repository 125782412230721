import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx} from './utils'

export const enableKpiEndpoints = (mock: MockAdapter) => {
  // GET history assets
  mock
    .onGet(new RegExp(`^/plants/${numberRegEx}/schedules/kpi/asset-capacity$`))
    .reply((config) => {
      const {from} = config.params as {from: string; to: string}
      const {
        burglengenfeld: {
          assetsCapacityByMonth,
          plantConfig: {timezone_id: timezoneId}
        }
      } = mockStore.scenario()

      const month = moment.utc(from).tz(timezoneId).format('MM')

      return sleepResponse([200, assetsCapacityByMonth[month]])
    })

  return mock
}
