import moment, {Moment} from 'moment-timezone'

import {AssetType} from './enums'
import {ScheduleItemDict} from './interfaces/api'
import {DatetimeValue} from './interfaces/common'
import {AssetWithOperationMode, guardProductionMode} from './selectors/assets'
import {getScheduleInTimeFrame} from './selectors/schedule'

const MINUTES_PER_HOUR = 60

interface GetCostsMWhAndTonsParams {
  prices: DatetimeValue<Moment>[]
  powerConsumption: number
  throughput: number | null
}

/**
 * function to calculate cumulated costs, consumed MWh and produced tons
 * for a given datetime - value range
 * @param prices should be an inclusive array
 */

export const getCostsMWhAndTons = ({
  prices,
  powerConsumption,
  throughput
}: GetCostsMWhAndTonsParams) => {
  let cumulatedCosts = 0
  let cumulatedMWh = 0
  let cumulatedTons = 0

  prices.forEach(({datetime, value: priceValue}, index) => {
    const nextPrice: DatetimeValue<Moment> | undefined = prices[index + 1]
    // duration in hours
    const duration =
      nextPrice !== undefined
        ? nextPrice.datetime.diff(datetime, 'minutes', true) / MINUTES_PER_HOUR
        : 0

    cumulatedCosts += priceValue * powerConsumption * duration
    cumulatedMWh += powerConsumption * duration
    cumulatedTons += (throughput ?? 0) * duration
  })

  return {cumulatedCosts, cumulatedMWh, cumulatedTons}
}

interface GetKPIParams {
  prices: DatetimeValue<Moment>[]
  schedules: ScheduleItemDict | undefined
  startDatetime: Moment
  endDatetime: Moment
  assetDataByScheduleItemId: Record<string, AssetWithOperationMode>
  baseLoad?: number
}

type KPICategory = 'cement' | 'clinker' | 'rawMeal' | 'other' | 'total'
export interface KPIValues {
  costs: number
  consumedMWh: number
  producedTons: number
}

const addNewValuesToKPIs = (prevKPIsValues: KPIValues, newValues: KPIValues): KPIValues => {
  return {
    costs: prevKPIsValues.costs + newValues.costs,
    consumedMWh: prevKPIsValues.consumedMWh + newValues.consumedMWh,
    producedTons: prevKPIsValues.producedTons + newValues.producedTons
  }
}

/**
 * function to caculate costs and MWh if baseLoad provided
 * @param baseLoad
 * @param prices
 * @returns
 */
const getBaseLoadCostsAndMWh = (baseLoad: number | undefined, prices: DatetimeValue<Moment>[]) => {
  const result = {
    baseLoadCosts: 0,
    baseLoadMWh: 0
  }
  if (baseLoad === undefined) {
    return result
  }
  const {cumulatedCosts: baseLoadCosts, cumulatedMWh: baseLoadMWh} = getCostsMWhAndTons({
    prices: prices,
    powerConsumption: baseLoad,
    throughput: 0
  })
  result.baseLoadCosts = baseLoadCosts
  result.baseLoadMWh = baseLoadMWh
  return result
}

export const getKPIs = ({
  prices,
  schedules,
  startDatetime,
  endDatetime,
  baseLoad,
  assetDataByScheduleItemId
}: GetKPIParams) => {
  const scheduleItemDictInTimeFrame =
    schedules && getScheduleInTimeFrame(schedules, startDatetime, endDatetime)

  const pricesInTimeFrame = prices.filter(
    (entry) =>
      entry.datetime.isSameOrAfter(startDatetime) && entry.datetime.isSameOrBefore(endDatetime)
  )

  const pricesLeftAligned = prices.filter(
    (entry) => entry.datetime.isSameOrAfter(startDatetime) && entry.datetime.isBefore(endDatetime)
  )

  const averageMarketPrice =
    pricesLeftAligned.map((entry) => entry.value).reduce((acc, current) => acc + current, 0) /
    pricesLeftAligned.length

  const {baseLoadCosts, baseLoadMWh} = getBaseLoadCostsAndMWh(baseLoad, pricesInTimeFrame)

  const initialKPIsPerAssetType: Record<KPICategory, KPIValues> = {
    cement: {costs: 0, consumedMWh: 0, producedTons: 0},
    clinker: {costs: 0, consumedMWh: 0, producedTons: 0},
    rawMeal: {costs: 0, consumedMWh: 0, producedTons: 0},
    other: {costs: 0, consumedMWh: 0, producedTons: 0},
    total: {costs: baseLoadCosts, consumedMWh: baseLoadMWh, producedTons: 0}
  }
  const KPIsPerAssetType = Object.values(scheduleItemDictInTimeFrame ?? {}).reduce<
    Record<KPICategory, KPIValues>
  >((acc, scheduleItem) => {
    const scheduleStart = moment.utc(scheduleItem.start)
    const scheduleEnd = moment.utc(scheduleItem.end)
    const assetType = assetDataByScheduleItemId[scheduleItem.id].asset.type
    const {operationMode} = assetDataByScheduleItemId[scheduleItem.id]
    if (!guardProductionMode(operationMode)) {
      return acc
    }

    const {powerConsumption, throughput} = operationMode

    const pricesForScheduleItem = pricesInTimeFrame.filter(
      (entry) =>
        entry.datetime.isSameOrAfter(scheduleStart) && entry.datetime.isSameOrBefore(scheduleEnd)
    )
    const {cumulatedCosts, cumulatedMWh, cumulatedTons} = getCostsMWhAndTons({
      prices: pricesForScheduleItem,
      powerConsumption,
      // handling the case of transition items, production from these items should equal 0
      throughput: scheduleItem.isTransitionTime ? 0 : throughput
    })

    const newKPIsValues: KPIValues = {
      costs: cumulatedCosts,
      consumedMWh: cumulatedMWh,
      producedTons: cumulatedTons
    }

    acc['total'] = addNewValuesToKPIs(acc['total'], newKPIsValues)

    if (assetType === AssetType.CementMill) {
      acc['cement'] = addNewValuesToKPIs(acc['cement'], newKPIsValues)
      return acc
    }
    if (assetType === AssetType.RawMill) {
      acc['rawMeal'] = addNewValuesToKPIs(acc['rawMeal'], newKPIsValues)
      return acc
    }
    if (assetType === AssetType.RotaryKiln) {
      acc['clinker'] = addNewValuesToKPIs(acc['clinker'], newKPIsValues)
      return acc
    }
    if ([AssetType.Other, AssetType.CoalMill, AssetType.Crusher].includes(assetType)) {
      acc['other'] = addNewValuesToKPIs(acc['other'], newKPIsValues)
      return acc
    }
    return acc
  }, initialKPIsPerAssetType)

  return {
    assetsKPIs: KPIsPerAssetType,
    averageMarketPrice
  }
}

interface KPIsDiffParams {
  type: 'optimized' | 'manual'
  values: {
    tonsManual: number
    tonsOptimized: number
    costsManual: number
    costsOptimized: number
    costsPerTonManual: number
    costsPerTonOptimized: number
    costsPerMWhManual: number
    costsPerMWhOptimized: number
  }
}

export const getScheduleKPIsDiff = ({type, values}: KPIsDiffParams) => {
  const {
    tonsManual,
    tonsOptimized,
    costsManual,
    costsOptimized,
    costsPerTonManual,
    costsPerTonOptimized,
    costsPerMWhManual,
    costsPerMWhOptimized
  } = values

  const multiplier = type === 'optimized' ? 1 : -1

  return {
    tonsDiff: multiplier * (tonsOptimized - tonsManual),
    costsDiff: multiplier * (costsManual - costsOptimized),
    costsPerTonDiff: multiplier * (costsPerTonManual - costsPerTonOptimized),
    costsPerMWhDiff: multiplier * (costsPerMWhManual - costsPerMWhOptimized)
  }
}
