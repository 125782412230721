import {dataTestId, datetimeFormatter, formatTons} from '@hconnect/uikit'
import {hpTheme} from '@hconnect/uikit/src/lib2'
import {Stack, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DeliveryStatus} from '../../../../shared/enums'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {DeliveryDetail} from '../../../../shared/selectors/materialDeliveryDetails'
import {deliveryStatusToIconMap} from '../materialOrdersIcons'

const {palette} = hpTheme

const deliveryStatusToStyleMap: Record<DeliveryStatus, {color: string}> = {
  [DeliveryStatus.Delivered]: {color: palette.success.light},
  [DeliveryStatus.Overdue]: {color: palette.warning.light},
  [DeliveryStatus.Unknown]: {color: palette.text.primary}
}

interface MaterialDeliveryTooltipContentProps {
  delivery: DeliveryDetail
}

export const MaterialDeliveryTooltipContent: React.FC<MaterialDeliveryTooltipContentProps> = ({
  delivery
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const Icon = deliveryStatusToIconMap[delivery.status]

  const deliveryTime =
    delivery.status === DeliveryStatus.Delivered || delivery.status === DeliveryStatus.Unknown
      ? moment.utc(delivery.time).tz(timezoneId)
      : undefined
  return (
    <Stack {...dataTestId('delivery_detail_tooltip_content')}>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{alignContent: 'center', ...deliveryStatusToStyleMap[delivery.status]}}
      >
        <Icon fontSize="inherit" />
        <Typography variant="subtitle2" sx={{dispaly: 'flex'}} {...dataTestId('delivery_status')}>
          {t(`stock.materialOrders.${delivery.status}`)}
        </Typography>
      </Stack>
      <Typography variant="h5" {...dataTestId('delivery_amount')}>
        {formatTons(delivery.amount, language)}
      </Typography>
      <Typography variant="subtitle1" {...dataTestId('delivery_date')}>
        {deliveryTime ? datetimeFormatter(deliveryTime, language, deliveryTime.utcOffset()) : '-'}
      </Typography>
      {/* TODO Hauler name from HANA */}
    </Stack>
  )
}
