import {Box, Typography, BoxProps, Fade} from '@mui/material'
import React, {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

interface SavingIndicatorProps extends BoxProps {
  isSaving: boolean
}
export const SavingIndicator: FC<SavingIndicatorProps> = ({isSaving, ...boxProps}) => {
  const {t} = useTranslation()
  const [, setIsSaving] = useState(false)
  const [shouldShowSaved, setShouldShowSaved] = useState(false)

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    setIsSaving((prevState) => {
      if (prevState && !isSaving) {
        setShouldShowSaved(true)
        timeoutId = setTimeout(() => {
          setShouldShowSaved(false)
        }, 2000)
      }
      return isSaving
    })
    return () => {
      if (timeoutId) {
        setShouldShowSaved(false)
        clearTimeout(timeoutId)
      }
    }
  }, [isSaving])

  return (
    <Box {...boxProps}>
      {isSaving ? <Typography variant="body1">{`... ${t('common.saving')}`}</Typography> : null}
      {shouldShowSaved ? (
        <Fade in timeout={{exit: 1000}}>
          <Typography variant="body1">{t('common.saved')}</Typography>
        </Fade>
      ) : null}
    </Box>
  )
}
