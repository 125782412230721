import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Tooltip, Typography, Theme} from '@mui/material'
import {Variant} from '@mui/material/styles/createTypography'
import React from 'react'

export interface GanttChartLabelProps {
  title: string
  titleVariant?: 'inherit' | Variant
  isTooltipEnabled?: boolean
  sx?: SxProps<Theme>
}

export const GanttChartLabel: React.FC<GanttChartLabelProps> = ({
  title,
  titleVariant = 'caption',
  isTooltipEnabled = false,
  sx
}) => {
  const baseStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
  return (
    <Box sx={baseStyles}>
      <Tooltip title={isTooltipEnabled && title} placement="top">
        <Typography
          noWrap
          variant={titleVariant}
          sx={mergeSx(
            {
              whiteSpace: 'nowrap',
              textTransform: 'initial',
              textAlign: 'right'
            },
            sx
          )}
        >
          {title}
        </Typography>
      </Tooltip>
    </Box>
  )
}
