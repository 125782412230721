import {useMemo} from 'react'

import {useLoginDetails} from '../components/providers/LoginProvider'
import {usePlantConfigQuery} from '../hooks/api/dataScience'
import {usePermissionsQuery} from '../hooks/api/permissions/usePermissionsQuery'
import {getPageAvailability} from '../permissions'

export const usePageAvailability = (plantCode?: string) => {
  const {data: plantConfig} = usePlantConfigQuery(plantCode)
  const {loginDetails} = useLoginDetails()
  const {data: permissions} = usePermissionsQuery()

  return useMemo(() => {
    if (!plantConfig || !loginDetails || !plantCode || !permissions) {
      return null
    }
    const availableFeatures = plantConfig.available_features
    return getPageAvailability(availableFeatures, permissions, plantConfig.country, plantCode)
  }, [plantConfig, permissions, loginDetails, plantCode])
}
