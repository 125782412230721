import {dataTestId} from '@hconnect/uikit'
import {ArrowForward} from '@mui/icons-material'
import {Button, Stack} from '@mui/material'
import {useIsMutating} from '@tanstack/react-query'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedMaterialOrder} from '../../../../shared/components/providers/SelectedMaterialOrderProvider'
import {SavingIndicator} from '../../../../shared/components/SavingIndicator'
import {CommentsCategory} from '../../../../shared/enums'
import {usePermission} from '../../../../shared/hooks/permissions/usePermission'
import type {
  Comment,
  MaterialOrder,
  ScheduleAgreementsByVendor
} from '../../../../shared/interfaces/api'
import {checkPermissionCommentCategory} from '../../../../shared/permissions'

import {MaterialOrderAttachmentSection} from './MaterialOrderAttachmentSection'
import {MaterialOrderCommentForm} from './MaterialOrderCommentForm'
import {MaterialOrderForm} from './MaterialOrderForm'

interface CreateEditMaterialOrderDialogContentProps {
  materialOrder?: MaterialOrder
  scheduleAgreements: ScheduleAgreementsByVendor[]
  commentsByOrderId: Record<string, Comment> | undefined
}

export const CreateEditMaterialOrderDialogContent: React.FC<
  CreateEditMaterialOrderDialogContentProps
> = ({materialOrder, scheduleAgreements, commentsByOrderId = {}}) => {
  const {t} = useTranslation()
  const {setSelectedMaterialOrder} = useSelectedMaterialOrder()

  const addOrderMutations = useIsMutating({
    mutationKey: ['addMaterialOrder']
  })
  const editOrderMutations = useIsMutating({
    mutationKey: ['editMaterialOrder']
  })
  const addCommentMutations = useIsMutating({
    mutationKey: ['addMaterialOrderComment']
  })
  const editCommentMutations = useIsMutating({
    mutationKey: ['editMaterialOrderComment']
  })
  const addAttachmentMutations = useIsMutating({
    mutationKey: ['addMaterialOrderAttachments']
  })
  const deleteAttachmentMutations = useIsMutating({
    mutationKey: ['deleteMaterialOrderAttachment']
  })
  const isOrderAdding = Boolean(addOrderMutations)
  const isOrderEditing = Boolean(editOrderMutations)
  const isCommentAdding = Boolean(addCommentMutations)
  const isCommentEditing = Boolean(editCommentMutations)
  const isAttachmentAdding = Boolean(addAttachmentMutations)
  const isAttachmentDeleting = Boolean(deleteAttachmentMutations)

  const canEditComments = usePermission(
    'EDIT_COMMENTS',
    checkPermissionCommentCategory(CommentsCategory.MaterialOrders)
  )
  const canViewComments = usePermission(
    'VIEW_COMMENTS',
    checkPermissionCommentCategory(CommentsCategory.MaterialOrders)
  )
  const canViewAttachments = usePermission('VIEW_DOCUMENTS')

  const isSaving =
    isOrderAdding ||
    isOrderEditing ||
    isCommentAdding ||
    isCommentEditing ||
    isAttachmentAdding ||
    isAttachmentDeleting

  const shouldDisableCommentInput = Boolean(!materialOrder) || !canEditComments
  const shouldDisableAttachments = Boolean(!materialOrder)

  return (
    <Stack spacing={2} {...dataTestId('create_edit_material_order_dialog_content')}>
      <MaterialOrderForm scheduleAgreements={scheduleAgreements} materialOrder={materialOrder} />
      {canViewComments && (
        <MaterialOrderCommentForm
          isDisabled={shouldDisableCommentInput}
          materialOrderId={materialOrder?.id}
          comment={materialOrder ? commentsByOrderId[materialOrder.id] : undefined}
        />
      )}
      {canViewAttachments && (
        <MaterialOrderAttachmentSection
          materialOrder={materialOrder}
          isDisabled={shouldDisableAttachments}
        />
      )}
      <Stack direction="row" spacing={1.5} sx={{justifyContent: 'flex-end', alignItems: 'center'}}>
        <SavingIndicator {...dataTestId('saving_indicator')} isSaving={isSaving} />
        <Button
          variant="contained"
          startIcon={<ArrowForward />}
          onClick={() => setSelectedMaterialOrder(undefined)}
          {...dataTestId('done_button')}
        >
          {t('common.done')}
        </Button>
      </Stack>
    </Stack>
  )
}
