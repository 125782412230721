import {StockLevelOverwrite} from '@hconnect/common/types'
import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {datetimeFormatter, formatTons} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Typography} from '@mui/material'
import {Stack} from '@mui/system'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useCurrentTimeRounded} from '../../hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../hooks/usePlantConfigData'
import {DatetimeValue} from '../../interfaces/common'

interface StockDevelopmentTooltipContentProps {
  datetimeValue: DatetimeValue
  canCreateStockOverwrite: boolean
  stockOverwrites: StockLevelOverwrite[]
}

export const StockDevelopmentTooltipContent: React.FC<StockDevelopmentTooltipContentProps> = ({
  canCreateStockOverwrite,
  datetimeValue: {value, datetime},
  stockOverwrites
}) => {
  const {timezone_id: timezoneId} = usePlantConfig()

  const {
    t,
    i18n: {language}
  } = useTranslation()

  const currentDateTime = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})
  const hoveredDatetime = moment.utc(datetime).tz(timezoneId)

  const dateTimeMoment = moment.utc(datetime).tz(timezoneId)

  const overwrite = stockOverwrites.find((o) =>
    moment.utc(o.measuredAt).tz(timezoneId).isSame(dateTimeMoment)
  )

  const showStockOverwriteTooltip =
    canCreateStockOverwrite && hoveredDatetime.isSameOrBefore(currentDateTime)

  return (
    <>
      <CardTitle>
        {datetimeFormatter(hoveredDatetime, language, hoveredDatetime.utcOffset())}
      </CardTitle>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1">{t('common.stock')}</Typography>
          <Typography variant="subtitle1">{formatTons(value, language)}</Typography>
        </Stack>
        {overwrite && (
          <Typography variant="subtitle1">
            {t('stock.materials.lastCorrectionBy', {name: overwrite.updatedBy})}
          </Typography>
        )}
        {showStockOverwriteTooltip && !overwrite && (
          <Typography variant="subtitle1">
            <InfoOutlined
              sx={{
                mr: 0.5,
                verticalAlign: 'middle',
                fontSize: 'inherit'
              }}
            />
            {t('stock.materials.clickToCorrectStockLevel')}
          </Typography>
        )}
      </Stack>
    </>
  )
}
