import React, {useContext, useState, useMemo} from 'react'

interface SelectedAssetIdState {
  assetId: number | undefined
  setAssetId: React.Dispatch<React.SetStateAction<number | undefined>>
}

const SelectedAssetIdContext = React.createContext<SelectedAssetIdState | undefined>(undefined)

export const useSelectedAssetId = () => {
  const context = useContext(SelectedAssetIdContext)
  if (!context)
    throw new Error('Cannot use selected asset id context outside of a asset id provider')
  return context
}

export const SelectedAssetCommentProvider = ({children}: {children: React.ReactNode}) => {
  const [assetId, setAssetId] = useState<number | undefined>(undefined)

  const memoizedAssetContext = useMemo(() => ({assetId, setAssetId}), [assetId])

  return (
    <SelectedAssetIdContext.Provider value={memoizedAssetContext}>
      {children}
    </SelectedAssetIdContext.Provider>
  )
}
