import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {AutofillStatus} from '../../shared/enums'
import {mockStore} from '../mockStore'

import {saveScenario, sleepResponse} from './utils'
export const enableDSEndpoints = (mock: MockAdapter) => {
  // GET Latest (Py)
  mock.onGet('/hcem/ui/v2/latest/0014').reply(() => {
    const {burglengenfeld} = mockStore.scenario()
    return sleepResponse([200, burglengenfeld.latest])
  })

  // GET Electricity prices (Py)
  mock.onGet('/hcem/ui/electricity/0014').reply((config) => {
    const {time_from: from, time_to: to} = config.params as {time_from: string; time_to: string}
    const {
      burglengenfeld: {electricityPrice}
    } = mockStore.scenario()

    const filteredActuals = electricityPrice.actual.filter((entry) =>
      moment.utc(entry.datetime).isBetween(from, to, 'second', '[)')
    )
    const filteredForecast = electricityPrice.forecast.filter((entry) =>
      moment.utc(entry.datetime).isBetween(from, to, 'second', '[)')
    )

    return sleepResponse([
      200,
      {...electricityPrice, actual: filteredActuals, forecast: filteredForecast}
    ])
  })

  // GET optimiziation calculation status (Py)
  mock.onGet('/hcem/ui/v2/optimizer/0014/status').reply(() => {
    const {burglengenfeld} = mockStore.scenario()
    return sleepResponse([200, burglengenfeld.optimizer_calc_status])
  })

  mock.onGet('/hcem/ui/v2/config/0014').reply(() => {
    const {
      burglengenfeld: {plantConfig}
    } = mockStore.scenario()
    return sleepResponse([200, plantConfig])
  })

  mock.onGet('/hcem/ui/peak_load_windows/0014').reply((config) => {
    const {time_from: from, time_to: to} = config.params as {time_from: string; time_to: string}
    const {
      burglengenfeld: {peakLoadWindows, backendErrors}
    } = mockStore.scenario()
    const filteredPeakLoadWindows = peakLoadWindows.filter(
      (entry) => moment.utc(entry.start).isBefore(to) && moment.utc(entry.end).isAfter(from)
    )
    if (backendErrors.peakLoadWindowsError) {
      return sleepResponse(backendErrors.peakLoadWindowsError)
    }
    return sleepResponse([200, filteredPeakLoadWindows])
  })

  function checkAutofillExpiring() {
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {autofillStatus}
    } = scenario

    if (autofillStatus.data.status === AutofillStatus.InProgress) {
      if (!autofillStatus.inProgressChange) {
        throw new Error('In progress change must be defined')
      }

      // in progress autofill expires after 1minute
      if (moment(autofillStatus.inProgressChange).isBefore(moment().subtract(1, 'minute'))) {
        scenario.burglengenfeld.autofillStatus.inProgressChange = moment().toISOString()
        scenario.burglengenfeld.autofillStatus.data.status = AutofillStatus.NotStarted
        scenario.burglengenfeld.autofillStatus.data.last_updated_at = moment().toISOString()
        saveScenario(scenario)
      }
    }
  }

  mock.onGet('/hcem/ui/v2/autofill/0014/status').reply(() => {
    checkAutofillExpiring()
    const {
      burglengenfeld: {
        autofillStatus: {data}
      }
    } = mockStore.scenario()
    return sleepResponse([200, data])
  })

  mock.onPost('/hcem/ui/v2/autofill/0014').reply(() => {
    checkAutofillExpiring()
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {autofillStatus}
    } = scenario

    if (autofillStatus.data.status === AutofillStatus.InProgress) {
      if (!autofillStatus.inProgressChange) {
        // TODO: check correct status with DS BE
        return sleepResponse([409, {message: 'Autofill already in progress'}])
      }
    }

    scenario.burglengenfeld.autofillStatus.inProgressChange = moment().toISOString()
    scenario.burglengenfeld.autofillStatus.data.status = AutofillStatus.InProgress
    scenario.burglengenfeld.autofillStatus.data.last_updated_at = moment().toISOString()
    saveScenario(scenario)

    return sleepResponse([202, autofillStatus])
  })

  return mock
}
