import {dataTestId} from '@hconnect/uikit'
import AutoAwesome from '@mui/icons-material/AutoAwesome'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import {Button} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useInterval} from 'react-use'

import {invalidateHcemQuery} from '../../../../../shared/api/queryHelpers'
import {OptimizerCalculationAction, OptimizerCalculationStatus} from '../../../../../shared/enums'
import {
  useRecalculateOptimizerStatusQuery,
  useCalculateOptimizedPlanning
} from '../../../../../shared/hooks/api'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'

const OPTIMIZER_STATUS_REFETCH_INTERVAL = 1000 * 60 * 5

export const StartCancelRecalculation = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {
    data: recalculateStatus,
    isLoading: isStatusLoading,
    refetch: refetchOptimizerStatus
  } = useRecalculateOptimizerStatusQuery()
  const status = recalculateStatus?.status
  const {mutate: calculate, isLoading: isCalcLoading} = useCalculateOptimizedPlanning()

  useInterval(() => {
    if (recalculateStatus?.status === OptimizerCalculationStatus.InProgress) {
      void refetchOptimizerStatus().then(({data}) => {
        // if status changed, invalidates optimized schedule
        if (data?.status === OptimizerCalculationStatus.Finished) {
          invalidateHcemQuery('optimizedSchedule')
          invalidateHcemQuery('schedule')

          invalidateHcemQuery('stockDevelopment')
          invalidateHcemQuery('costAvoidanceForRange')
          invalidateHcemQuery('costAvoidanceDaily')
          invalidateHcemQuery('costAvoidanceMonthly')
        }
      })
    }
  }, OPTIMIZER_STATUS_REFETCH_INTERVAL)

  if (status === OptimizerCalculationStatus.NotAvailable) return <></>
  if (status === OptimizerCalculationStatus.InProgress)
    return (
      <Button
        startIcon={
          <RotateRightIcon
            sx={{
              animation: 'spin 2s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)'
                },
                '100%': {
                  transform: 'rotate(360deg)'
                }
              }
            }}
          />
        }
        variant="contained"
        color="primary"
        disabled={isStatusLoading || isCalcLoading}
        onClick={() => calculate({plantCode, status: OptimizerCalculationAction.Cancel})}
        {...dataTestId('cancel_recalculate_optimized_planning_button')}
      >
        {t('planning.recalculatingOptimizedPlanning')}
      </Button>
    )
  return (
    <Button
      startIcon={<AutoAwesome />}
      variant="contained"
      color="primary"
      disabled={isStatusLoading || isCalcLoading}
      onClick={() => calculate({plantCode, status: OptimizerCalculationAction.Start})}
      {...dataTestId('recalculate_optimized_planning_button')}
    >
      {t('planning.recalculateOptimizedPlanning')}
    </Button>
  )
}
