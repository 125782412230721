import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface useScheduleItemParams {
  id?: string
  startOfPlan: Moment
  endOfPlan: Moment
}

export const useScheduleItem = ({id, startOfPlan, endOfPlan}: useScheduleItemParams) => {
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery(
    'schedule',
    [{plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}],
    {
      select: (schedule) => (id ? schedule.schedules[id] : undefined),
      enabled: id !== undefined
    }
  )
}
