import {dataTestId} from '@hconnect/uikit'
import {HPTextField} from '@hconnect/uikit/src/lib2'
import React, {useMemo} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useAddMaterialOrderComment, useEditMaterialOrderComment} from '../../../../shared/hooks/api'
import {useAutoSaveValue} from '../../../../shared/hooks/useAutoSaveValue'
import type {Comment} from '../../../../shared/interfaces/api'

interface MaterialOrderCommentFormProps {
  isDisabled: boolean
  materialOrderId?: number
  comment?: Comment
}

export const MaterialOrderCommentForm: React.FC<MaterialOrderCommentFormProps> = ({
  materialOrderId,
  isDisabled,
  comment
}) => {
  const {t} = useTranslation()
  const {addMaterialOrderComment} = useAddMaterialOrderComment()
  const {editMaterialOrderComment} = useEditMaterialOrderComment()

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: {isDirty}
  } = useForm({
    defaultValues: {commentValue: comment?.value ?? ''}
  })

  const commentId = comment?.commentId

  const onSubmit = useMemo(
    () =>
      handleSubmit(({commentValue}) => {
        if (!materialOrderId) {
          return
        }
        if (commentId) {
          return editMaterialOrderComment(
            {
              comment: commentValue,
              commentId,
              orderId: materialOrderId
            },
            {onSuccess: () => reset({commentValue}), onError: () => reset()}
          )
        }
        return addMaterialOrderComment(
          {comment: commentValue, orderId: materialOrderId},
          {onSuccess: () => reset({commentValue}), onError: () => reset()}
        )
      }),
    [
      editMaterialOrderComment,
      addMaterialOrderComment,
      commentId,
      reset,
      handleSubmit,
      materialOrderId
    ]
  )
  const currentCommentValue = watch('commentValue')

  useAutoSaveValue({
    currentValue: currentCommentValue,
    shouldSave: isDirty && currentCommentValue !== '',
    saveFn: onSubmit
  })

  return (
    <Controller
      name="commentValue"
      control={control}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <HPTextField
          multiline
          minRows={3}
          label={t('common.comment')}
          value={value}
          onChange={onChange}
          error={Boolean(error?.message)}
          helperText={error?.message}
          disabled={isDisabled}
          {...dataTestId('material_order_comment_input')}
        />
      )}
    />
  )
}
