import {StackProps} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {usePlantConfig} from '../../../../../shared/hooks/usePlantConfigData'
import {getLocalizedDayMonthTimeFormat} from '../../../../../shared/selectors/time'

interface StockLevelsOverviewSubtitleProps extends StackProps {
  lastUpdated: Moment
}

export const StockLevelsOverviewSubtitle: FC<StockLevelsOverviewSubtitleProps> = React.memo(
  ({lastUpdated}) => {
    const {timezone_id: timezoneId} = usePlantConfig()
    const {
      t,
      i18n: {language}
    } = useTranslation()

    return (
      <>
        {lastUpdated &&
          t('common.lastUpdate', {
            date: getLocalizedDayMonthTimeFormat(lastUpdated, timezoneId, language)
          })}
      </>
    )
  }
)

StockLevelsOverviewSubtitle.displayName = 'StockLevelsOverviewTitle'
