import {Moment} from 'moment-timezone'
import {useMemo} from 'react'

import {getTargetDatetimeValues} from './getTargetDatetimeValues'

interface UseTargetLevelValuesParams {
  minTargetLevels: {week: number; weekend: number} | undefined
  listOfDays: Moment[]
  timezoneId: string
}

/*
 * Hook to get target levels for each day
 * for stock development chart use
 * listOfDays should have start of the day
 */
export const useTargetLevelsDaily = ({
  minTargetLevels,
  listOfDays,
  timezoneId
}: UseTargetLevelValuesParams) => {
  return useMemo(() => {
    if (!minTargetLevels) {
      return undefined
    }
    const {week, weekend} = minTargetLevels
    return getTargetDatetimeValues(listOfDays, week, weekend, timezoneId)
  }, [listOfDays, minTargetLevels, timezoneId])
}
