import {Bolt} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {dataTestId} from '@hconnect/uikit'

export interface SubmissionButtonProps {
  buttonLabel: string
  onClick: () => void
  disabled?: boolean
  buttonTestId?: string
  id?: string
}

export const SubmissionButton: React.FC<SubmissionButtonProps> = ({
  buttonLabel,
  onClick,
  id,
  disabled = false,
  buttonTestId
}) => {
  const {t} = useTranslation()

  return (
    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
      <Button
        startIcon={<Bolt />}
        variant="text"
        sx={{color: 'primary.light'}}
        id={id}
        {...dataTestId(buttonTestId ?? '')}
        disabled={disabled}
        onClick={onClick}
      >
        {t(buttonLabel)}
      </Button>
    </Box>
  )
}
