import {dataTestId} from '@hconnect/uikit'
import {Card, CardProps} from '@hconnect/uikit/src/lib2'
import {KeyboardArrowDown, KeyboardArrowRight} from '@mui/icons-material'
import {Typography, Stack, Box} from '@mui/material'
import React from 'react'

interface CollapsibleCardProps
  extends Pick<CardProps, 'headerContent' | 'footerContent' | 'children' | 'data-test-id'> {
  isCollapsed: boolean
  onToggleCollapsed: () => void
  title: string
  subtitle?: string | React.ReactNode
}

export const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  isCollapsed,
  onToggleCollapsed,
  title,
  subtitle,
  children: cardContent,
  headerContent,
  footerContent,
  'data-test-id': testId
}) => {
  return (
    <Card
      headerContent={
        <Stack direction="row" sx={{justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <Stack
            direction="row"
            spacing={0.5}
            sx={(theme) => ({
              alignItems: 'start',
              pr: 1,
              color: 'currentColor',
              cursor: 'pointer',
              width: 1,
              ...theme.typography.h2
            })}
            onClick={onToggleCollapsed}
          >
            {isCollapsed ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
            <Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography
                  variant="h3"
                  sx={{textTransform: 'initial'}}
                  {...dataTestId('card_title')}
                >
                  {title}
                </Typography>
              </Stack>
              {subtitle && (
                <Typography
                  variant="body1"
                  sx={{color: 'text.secondary'}}
                  {...dataTestId('card_subtitle')}
                >
                  {subtitle}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Box sx={{width: 1}}>{headerContent}</Box>
        </Stack>
      }
      footerContent={footerContent}
      {...dataTestId(testId ?? 'collapsible_card')}
    >
      {cardContent}
    </Card>
  )
}
