import {PermissionType} from '@hconnect/apiclient'

import {useLoginDetails} from '../../../components/providers/LoginProvider'
import {PermissionParsed} from '../../../interfaces/api/permissions'
import {usePlannerQuery} from '../usePlannerQuery'

const selector = (permissionTypes: PermissionType[]) => (permissions: PermissionParsed[]) =>
  permissions.filter((entry) => permissionTypes.includes(entry.permissionType))

export const usePermissionsQuery = (permissionTypes?: PermissionType[]) => {
  const {loginDetails} = useLoginDetails()
  if (!loginDetails) throw new Error('Login details data is missing!')
  const {userId} = loginDetails
  return usePlannerQuery('permissions', [userId], {
    select: permissionTypes ? selector(permissionTypes) : undefined
  })
}
