import {dataTestId} from '@hconnect/uikit'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme} from '@mui/material'
import {AxiosError} from 'axios'
import React from 'react'
import {ErrorBoundary, ErrorBoundaryPropsWithRender} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

import {Log} from '../../Log'

import {ErrorFallback} from './ErrorFallback'

const isAxiosError = (error: Error): error is AxiosError<{detail: string}> =>
  (error as AxiosError).name === 'AxiosError'

export type PlannerErrorBoundaryProps = {
  title?: string
  contextName?: string
  children?: React.ReactNode
  fallbackWrapperSx?: SxProps<Theme>
  fallbackSx?: SxProps<Theme>
  fallbackTestId?: string
  onRetry?: () => void
  onReload?: () => void
}

export const PlannerErrorBoundary = ({
  title,
  children,
  fallbackSx,
  fallbackWrapperSx,
  fallbackTestId,
  contextName = 'Error Boundary',
  onRetry,
  onReload
}: PlannerErrorBoundaryProps) => {
  const {t} = useTranslation()
  const {pathname} = useLocation()

  const onError = (error: Error, info: {componentStack: string}) => {
    console.error(info.componentStack)
    Log.context('PlannerPageErrorBoundary', contextName).fatal(error)
  }
  const onReset = () => {
    Log.context('PlannerPageErrorBoundary', contextName).info('Error Boundary Reset')
  }

  const fallbackRender: ErrorBoundaryPropsWithRender['fallbackRender'] = ({
    error,
    resetErrorBoundary
  }) => {
    return (
      <Box sx={mergeSx({display: 'flex'}, fallbackWrapperSx)}>
        <ErrorFallback
          {...dataTestId(fallbackTestId ?? 'planner_error_fallback')}
          title={title ?? t('error.apology')}
          message={`${error.name}: ${error.message}`}
          detail={isAxiosError(error) ? error.response?.data?.detail : undefined}
          actionText={t('common.tryAgain')}
          onAction={() => {
            onRetry?.()
            resetErrorBoundary()
          }}
          onSecondaryAction={onReload}
          secondaryActionText={t('common.refreshPage')}
          sx={fallbackSx}
        />
      </Box>
    )
  }
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      // using resetKeys to reset the error boundary when pathname changes to enable selecting different pages
      resetKeys={[pathname]}
      onError={onError}
      onReset={onReset}
    >
      {children}
    </ErrorBoundary>
  )
}
