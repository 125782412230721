import moment from 'moment-timezone'

import {mockStore} from '../../../../__mock__/mockStore'
import {usePlantDatetime} from '../../usePlantTime'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export interface CostAvoidanceMonthlyQueryParams {
  // Should be an iso date, only year and month are taken into account
  // E.g.: 2023-01-01T00:00:00
  fromYearMonth: string
  toYearMonth: string
}

// for mocking purpose start date is set to match a mock datasets
export const START_OF_COST_AVOIDANCE_DATE = mockStore.isEnabled()
  ? '2018-01-01T00:00:00.000Z'
  : '2020-01-01T00:00:00.000Z'

export const useCostAvoidanceMonthlyQuery = (
  range?: [fromYearMonth: string, toYearMonth: string]
) => {
  const {localDatetimeUtc} = usePlantDatetime()

  const endOfCurrentYearLocal = localDatetimeUtc.startOf('year').add(1, 'year').toISOString()
  const plantCode = useUrlParam('plantCode')
  return usePlannerQuery(
    'costAvoidanceMonthly',
    [
      {
        plantCode,
        fromYearMonth: START_OF_COST_AVOIDANCE_DATE,
        toYearMonth: endOfCurrentYearLocal
      }
    ],
    {
      select: (costAvoidanceMonthly) =>
        costAvoidanceMonthly.filter((entry) =>
          range
            ? moment(entry.date).isBetween(moment(range[0]), moment(range[1]), 'day', '[)')
            : undefined
        ),
      enabled: Boolean(range)
    }
  )
}
