/* eslint-disable @typescript-eslint/no-non-null-assertion */
import qs from 'query-string'
import queryString from 'query-string'

import {BackendSelector, LoginStorage, Product, SignInRequest, SignOutRequest} from '../types'

import {deriveChallenge, generateRandomString} from './crypto.utils'

export class EnvAuthRequestProvider {
  constructor(backendSelector: BackendSelector) {
    this.backendSelector = backendSelector
  }

  private backendSelector: BackendSelector

  /**
   * Returns the API scope the application should ask for.
   */
  private getScope() {
    return process.env.REACT_APP_API_SCOPE!
  }

  /**
   * Returns base url of the authenticator based on backendSelector
   */
  private getAuthenticatorBaseUrl() {
    return this.backendSelector.getSelectedBackend().AUTH_URL
  }

  /**
   * Returns client ID of the current product
   */
  getClientId() {
    return process.env.REACT_APP_CLIENT_ID!
  }

  /**
   * Upon successful login Identity server will redirect to this url
   */
  getAuthSuccessRedirectUrl() {
    return `${window.location.origin}/auth`
  }

  /**
   * Returns the URL of the Authenticator as well as the flow that must be stored in loginStorage
   */
  async createSignInRequest(
    authenticatorUrl: string,
    extraAuthenticatorQueryParams: {[key: string]: string} = {},
    clientId?: string
  ): Promise<SignInRequest> {
    const idsReturnUrl = queryString.parse(window.location.search)?.ids_return_url
    const clientIdParam =
      typeof idsReturnUrl === 'string' && queryString.parse(new URL(idsReturnUrl).search)?.client_id

    const redirectUri =
      typeof idsReturnUrl === 'string' &&
      queryString.parse(new URL(idsReturnUrl).search)?.redirect_uri

    const selectedClientId = clientId || clientIdParam || this.getClientId() || Product.CRM
    const authenticatorUrlParams = new URL(authenticatorUrl)
    const state = generateRandomString(21)

    const codeVerifier = generateRandomString(96)
    const codeChallenge = await deriveChallenge(codeVerifier).catch((error) => {
      console.log('Browser does not support crypto, not using PKCE', error)
      return undefined
    })

    const queryParams = {
      redirect_uri:
        extraAuthenticatorQueryParams?.registrationType === 'request-access' && redirectUri
          ? redirectUri
          : selectedClientId === Product.CRM
          ? window.location.origin
          : `${window.location.origin}/auth`,
      client_id: selectedClientId,
      response_type: 'code',
      scope:
        selectedClientId === Product.CRM
          ? 'global openid offline_access'
          : this.getScope() || 'global openid offline_access',
      state,
      response_mode: 'query',
      ...extraAuthenticatorQueryParams,
      ...(codeChallenge
        ? {
            code_challenge: codeChallenge,
            code_challenge_method: 'S256'
          }
        : {})
    }

    const stringifiedQueryParams =
      qs.stringify(queryParams) + authenticatorUrlParams.search.replace('?', '&')

    const isSignInOrSignOutUrl = window.location.href.indexOf(`${window.origin}/auth`) === 0
    const href = !isSignInOrSignOutUrl ? window.location.href : window.origin

    return {
      url: `${authenticatorUrl}?${stringifiedQueryParams}`,
      flow: {
        state,
        href,
        codeVerifier
      }
    }
  }

  /**
   * Returns the URL of the Authenticator as well as the flow that must be stored in loginStorage
   */
  createRegistrationRequest(
    authenticatorUrl: string,
    registrationType: 'create-account' | 'request-access',
    countryId = '',
    clientId?: string
  ): Promise<SignInRequest> {
    const extraAuthenticatorQueryParams = {
      registrationType,
      countryId
    }
    return this.createSignInRequest(authenticatorUrl, extraAuthenticatorQueryParams, clientId)
  }

  createSignOutRequest(endSessionUrl: string, storage: LoginStorage): SignOutRequest {
    const token = storage.getToken()
    const endSessionUrlParams = new URL(endSessionUrl)
    const queryParams = {
      id_token_hint: token.idToken,
      post_logout_redirect_uri: `${window.location.origin}/auth/callback/logout`
    }

    const stringifiedQueryParams =
      qs.stringify(queryParams) + endSessionUrlParams.search.replace('?', '&')

    return {
      url: `${endSessionUrl}?${stringifiedQueryParams}`
    }
  }
}
