import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Theme, Box, SxProps} from '@mui/material'
import React from 'react'

mergeSx
interface ChartPoint {
  x: number
  y: number
  circleSx?: SxProps<Theme>
  'data-test-id'?: string
  children?: React.ReactNode
}

export const ChartPoint: React.FC<ChartPoint> = React.forwardRef(
  ({x, y, circleSx, children, ...props}, ref) => {
    return (
      <Box component="g" transform={`translate(${x} ${y})`} ref={ref} {...props}>
        <Box
          component="circle"
          cx={0}
          cy={0}
          sx={{
            zIndex: 1,
            r: 6,
            fill: ({palette}) => palette.common.white,
            stroke: ({palette}) => palette.primary.light,
            strokeWidth: 2,
            ...(circleSx ? circleSx : {})
          }}
        />
        {children}
      </Box>
    )
  }
)
