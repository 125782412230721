import {getPermissionsByUserId} from '@hconnect/apiclient'
import {
  Material,
  Recipe,
  AttachmentFile,
  Status,
  MaterialStoragesHistory,
  MaterialStorage,
  MaterialsHistory,
  StockLevelOverwrite
} from '@hconnect/common/types'
import {FetchQueryOptions} from '@tanstack/react-query'
import type {AxiosRequestHeaders} from 'axios'
import chunk from 'lodash/chunk'

import {PlannerQueryClient} from '../components/providers/QueryProvider'
import {CommentsCategory, ScheduleStatus} from '../enums'
import type {
  Latest,
  Schedule,
  Comment,
  ElectricityResponse,
  CostAvoidanceMonthly,
  ScheduleCostAvoidance,
  CostAvoidanceForRange,
  MaterialOrder,
  ScheduleAgreementsByVendor,
  StockDevelopmentResponse,
  AssetsHistory,
  LatestPeakLoadWindow,
  ElectricityPrice,
  GroupedUnplannedDelivery
} from '../interfaces/api'
import {PlantConfigData} from '../interfaces/api'
import {AutofillStatusDataAPIResponse} from '../interfaces/api/autofill'
import {AssetCapacityResponse} from '../interfaces/api/kpi'
import {MaterialDemandByDay} from '../interfaces/api/materialDemand'
import {OptimizerCalculationInfo} from '../interfaces/api/optimizer'
import {PermissionParsed} from '../interfaces/api/permissions'
import {validateBaseLoadInAssets} from '../selectors/assets'

import {ApiClient, dsApiBasePath} from './apiClient'
import {Queries, QueryHelperAsync, QueryReturn} from './queryType'

export const fetchHcemQuery: QueryHelperAsync = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: Parameters<T[K]>
) =>
  PlannerQueryClient.fetchQuery<QueryReturn<K>>({
    queryKey: [queryKey, ...params],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return queries[queryKey](...params) as Promise<QueryReturn<K>>
    }
  })

/**
 * we should use it for prefetchin because itoesn't throw to the error boundaries
 */
export const prefetchPlannerQuery = <K extends keyof Queries, TReturn = QueryReturn<K>>(
  queryKey: K,
  params: Parameters<Queries[K]>,
  options?: Omit<FetchQueryOptions<QueryReturn<K>, unknown, TReturn>, 'queryKey' | 'queryFn'>
) => {
  void PlannerQueryClient.prefetchQuery({
    queryKey: [queryKey, ...params],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return queries[queryKey](...params) as Promise<QueryReturn<K>>
    },
    ...options
  })
}

export const queries: Queries = {
  permissions: async (userId) => {
    const permissions = await getPermissionsByUserId(ApiClient.axiosInstance)(userId)
    const permissionsParsed: PermissionParsed[] = permissions.map((permission) => ({
      ...permission,
      parsedDataScope: JSON.parse(permission.dataScope) as PermissionParsed['parsedDataScope']
    }))
    return permissionsParsed
  },
  plantConfig: async (plantCode) => {
    const path = `${dsApiBasePath}/v2/config/${plantCode}`
    const response = await ApiClient.axiosInstance.get<PlantConfigData>(path)
    return response.data
  },
  latest: async (plantCode) => {
    if (plantCode === undefined) return undefined
    const path = `${dsApiBasePath}/v2/latest/${plantCode}`
    const response = await ApiClient.axiosInstance.get<Latest>(path)
    return response.data
  },
  autofillStatus: async (plantCode) => {
    const path = `${dsApiBasePath}/v2/autofill/${plantCode}/status`
    const response = await ApiClient.axiosInstance.get<AutofillStatusDataAPIResponse>(path)
    return {
      status: response.data.status,
      lastUpdatedAt: response.data.last_updated_at
    }
  },
  peakLoadWindows: async ({plantCode, from, to}) => {
    const path = `${dsApiBasePath}/peak_load_windows/${plantCode}`
    const params = {time_from: from, time_to: to}
    const response = await ApiClient.axiosInstance.get<LatestPeakLoadWindow[]>(path, {params})
    return response.data
  },
  electricityPrice: async ({plantCode, from, to}) => {
    const path = `${dsApiBasePath}/electricity/${plantCode}`
    const params = {time_from: from, time_to: to}
    const response = await ApiClient.axiosInstance.get<ElectricityPrice>(path, {params})
    return response.data
  },
  // C# Endpoints
  downloadElectricityDataExcelFile: async (plantCode, createdByOptimiser) => {
    const path = '/electricity'
    const params: Record<string, unknown> = {
      plantCode,
      ...(createdByOptimiser && {createdByOptimiser})
    }
    const {data, headers} = await ApiClient.axiosInstance.get<Blob>(path, {
      params,
      responseType: 'blob',
      transformRequest: (data, headers) => {
        ;(headers as AxiosRequestHeaders)['Accept'] =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        return data
      }
    })

    // getting encoded filename with utf characters from content disposition header
    const encodedFileName = headers['content-disposition'].split("filename*=UTF-8''")[1]
    // decoding filename
    const fileName = decodeURIComponent(encodedFileName)

    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  },
  schedule: async ({plantCode, start, end}) => {
    const path = '/schedules/current'
    const response = await ApiClient.axiosInstance.get<Schedule>(path, {
      params: {plantCode, start, end}
    })
    return response.data
  },
  optimizedSchedule: async ({plantCode, start, end}) => {
    const path = '/schedules/latest'
    const params: Record<string, string> = {
      start,
      end,
      plantCode: plantCode,
      status: ScheduleStatus.PENDING
    }
    const response = await ApiClient.axiosInstance.get<Schedule>(path, {params})

    return response.data
  },
  electricity: async ({plantCode, start, end}) => {
    const path = '/schedules/current/load-curves'
    const params: Record<string, unknown> = {
      plantCode,
      start,
      end
    }
    const {data} = await ApiClient.axiosInstance.get<ElectricityResponse>(path, {params})
    return data
  },
  comments: async ({plantCode, commentsCategory, commentsCategoryInstanceIds}) => {
    const path = `/comments/${plantCode}/${commentsCategory}`
    if (!commentsCategoryInstanceIds) {
      return Promise.reject(
        `comment category instance ids for category ${commentsCategory} were not provided`
      )
    }
    // HOTFIX: BE has a limit on url length, so we need to chunk the uuids for schedule items
    const AssetOperationModeIdsLimit = 50
    if (
      commentsCategory === CommentsCategory.AssetOperationTimes &&
      commentsCategoryInstanceIds.length > AssetOperationModeIdsLimit
    ) {
      const chunkedIds = chunk(commentsCategoryInstanceIds, AssetOperationModeIdsLimit)
      const comments = await Promise.all(
        chunkedIds.map((ids) =>
          ApiClient.axiosInstance.get<Comment[]>(path, {
            params: {commentCategoryInstanceIds: ids.join(',')}
          })
        )
      )
      return comments.flatMap((response) => response.data)
    }
    const params: Record<string, unknown> = {
      commentCategoryInstanceIds: commentsCategoryInstanceIds.join(',')
    }
    const response = await ApiClient.axiosInstance.get<Comment[]>(path, {params})
    return response.data
  },
  materials: async (plantCode) => {
    const path = `/plants/${plantCode}/materials`
    const response = await ApiClient.axiosInstance.get<Material[]>(path)
    return response.data
  },
  materialsHistory: async (plantCode, from, to) => {
    const path = `/plants/${plantCode}/materials/history`
    const params = {from, to}
    const response = await ApiClient.axiosInstance.get<MaterialsHistory>(path, {params})
    return response.data
  },
  materialsRecipes: async ({plantCode, assetType}) => {
    const path = `/plants/${plantCode}/materials/recipes`
    const params = assetType ? {assetType} : undefined
    const response = await ApiClient.axiosInstance.get<Recipe[]>(path, {params})
    return response.data
  },
  assetsHistory: async (plantCode, from, to) => {
    const path = `/plants/${plantCode}/assets/history`
    const params = {from, to}
    const {data: assetsHistory} = await ApiClient.axiosInstance.get<AssetsHistory>(path, {params})
    const latestAssets = Object.values(assetsHistory)
      .map((assets) => assets[assets.length - 1])
      .filter((asset) => asset.status !== Status.Deleted)
    // validating that base load is present in latest assets
    validateBaseLoadInAssets(latestAssets)
    return assetsHistory
  },
  materialStorageHistory: async (plantCode, from, to) => {
    const path = `/plants/${plantCode}/material-storage/history`
    const params = {from, to}
    const response = await ApiClient.axiosInstance.get<MaterialStoragesHistory>(path, {params})
    return response.data
  },
  materialStorage: async (plantCode: string): Promise<MaterialStorage[]> => {
    const path = `/plants/${plantCode}/material-storage`
    const response = await ApiClient.axiosInstance.get<MaterialStorage[]>(path)
    return response.data
  },
  recalculateOptimizerStatus: async (plantCode) => {
    const path = `${dsApiBasePath}/v2/optimizer/${plantCode}/status`
    const response = await ApiClient.axiosInstance.get<OptimizerCalculationInfo>(path)
    return response.data
  },
  costAvoidanceMonthly: async ({plantCode, fromYearMonth, toYearMonth}) => {
    const path = `/plants/${plantCode}/schedules/kpi/cost-avoidance/monthly`
    // params should be iso date, but only year and month will be taken into account by BE
    const params = {from: fromYearMonth, to: toYearMonth}
    const response = await ApiClient.axiosInstance.get<CostAvoidanceMonthly[]>(path, {params})
    return response.data
  },
  costAvoidanceDaily: async ({plantCode, fromDay, toDay}) => {
    const path = `/plants/${plantCode}/schedules/kpi/cost-avoidance`
    // params should be iso date, but only year and month will be taken into account by BE
    const params = {from: fromDay, to: toDay}
    const response = await ApiClient.axiosInstance.get<ScheduleCostAvoidance[]>(path, {params})
    return response.data
  },
  scheduleCostAvoidance: async ({plantCode, scheduleId}) => {
    const path = `/plants/${plantCode}/schedules/${scheduleId}/kpi/cost-avoidance`
    const response = await ApiClient.axiosInstance.get<ScheduleCostAvoidance>(path)
    return response.data
  },
  costAvoidanceForRange: async ({plantCode, scheduleId, from, to}) => {
    const path = `/plants/${plantCode}/schedules/${scheduleId}/kpi/cost-avoidance-for-range`
    // params should be iso date
    const params = {from, to}
    const response = await ApiClient.axiosInstance.get<CostAvoidanceForRange>(path, {params})
    return response.data
  },
  stockOverwrites: async ({plantCode, materialId, from, to}) => {
    const path = `/plants/${plantCode}/material-storage/stock-level/material/${materialId}`
    // params should be iso date
    const params = {from, to}
    const response = await ApiClient.axiosInstance.get<StockLevelOverwrite[]>(path, {params})
    return response.data
  },
  materialOrders: async ({plantCode, from, to, materialId}) => {
    const path = `/plants/${plantCode}/material-orders`
    const params = {from, to, materialId}
    const response = await ApiClient.axiosInstance.get<MaterialOrder[]>(path, {params})
    return response.data
  },
  materialUnplannedDeliveries: async ({plantCode, from, to, materialId}) => {
    const path = `/plants/${plantCode}/material-orders/daily-unplanned-deliveries`
    const params = {from, to, materialId}
    const response = await ApiClient.axiosInstance.get<GroupedUnplannedDelivery[]>(path, {params})
    return response.data
  },
  scheduleAgreementsByVendor: async ({plantCode, materialId}) => {
    const path = `/plants/${plantCode}/materials/${materialId}/schedule-agreements`
    const response = await ApiClient.axiosInstance.get<ScheduleAgreementsByVendor[]>(path)
    return response.data
  },
  attachmentFile: async ({attachment: {id, fileName, previewUrl, url, mediaType}, isPreview}) => {
    const fileUrl = isPreview && previewUrl ? previewUrl : url
    const path = `/documents/${fileUrl}`
    const response = await ApiClient.axiosInstance.get<ArrayBuffer>(path, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': mediaType,
        Accept: mediaType
      }
    })
    const blobUrl = URL.createObjectURL(new Blob([response.data]))
    return {
      id,
      name: fileName,
      contentType: mediaType,
      preview: blobUrl,
      isImage: mediaType.includes('image')
    }
  },
  attachmentFiles: async ({attachments, isPreview}) => {
    const attachmentQueries = attachments.map((attachment) =>
      fetchHcemQuery('attachmentFile', {attachment, isPreview})
    )
    const data: AttachmentFile[] | undefined = await Promise.all(attachmentQueries)
    return data ?? []
  },
  stockDevelopment: async ({plantCode, materialIds, from, to, useOptimizedSchedule}) => {
    if (!from || !to) {
      return undefined
    }
    const path = `plants/${plantCode}/schedules/kpi/stock-development`
    const params = {
      from,
      to,
      materialIds: materialIds.join(','),
      ...(useOptimizedSchedule && {useOptimizedSchedule: true})
    }
    const response = await ApiClient.axiosInstance.get<StockDevelopmentResponse>(path, {params})
    return response.data
  },
  assetCapacity: async (plantCode, {from, to}) => {
    const path = `/plants/${plantCode}/schedules/kpi/asset-capacity`
    const params = {from, to}
    const response = await ApiClient.axiosInstance.get<AssetCapacityResponse>(path, {params})
    return response.data
  },
  materialDemand: async (plantCode, {fromDate, toDate}) => {
    const path = `/plants/${plantCode}/material-demand/daily`
    const params = {from: fromDate, to: toDate}
    const response = await ApiClient.axiosInstance.get<MaterialDemandByDay>(path, {params})
    return response.data
  }
}
