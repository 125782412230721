import React, {useContext} from 'react'

import {StockDevelopmentChartConfig} from './utils/useStockDevelopmentChartConfigData'

const StockDevelopmentChartContext = React.createContext<StockDevelopmentChartConfig | undefined>(
  undefined
)

export const useStockDevelopmentChartConfig = () => {
  const context = useContext(StockDevelopmentChartContext)
  if (!context)
    throw new Error(
      'Cannot use SelectedMaterialOrderContext outside of a SelectedMaterialOrderProvider provider'
    )
  return context
}

type StockDevelopmentChartConfigProviderProps = StockDevelopmentChartConfig & {
  children: React.ReactNode
}

export const StockDevelopmentChartConfigProvider: React.FC<
  StockDevelopmentChartConfigProviderProps
> = ({children, ...params}) => {
  return (
    <StockDevelopmentChartContext.Provider value={params}>
      {children}
    </StockDevelopmentChartContext.Provider>
  )
}
