import {useTheme} from '@mui/material'
import * as d3 from 'd3'
import moment from 'moment-timezone'
import React, {SVGAttributes} from 'react'

import {dataTestId} from '@hconnect/uikit'
import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {DatetimeValue} from '../../interfaces/common'

import {ChartFill} from './ChartFill'

interface ChartPathProps {
  datetimeValues: DatetimeValue[]
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  overridePathAttributes?: SVGAttributes<SVGPathElement>
  fill?: boolean
  testId?: string
}

export const _ChartPath: React.FC<ChartPathProps> = ({
  datetimeValues,
  xScale,
  yScale,
  overridePathAttributes,
  fill,
  testId = 'chart-path'
}) => {
  const {palette} = useTheme()
  const {stroke, ...otherPathAttributes} = overridePathAttributes || {}

  const chartPathFn = d3
    .line<DatetimeValue>()
    .x((d: DatetimeValue) => xScale(moment.utc(d.datetime)))
    .y((d: DatetimeValue) => yScale(d.value))

  return (
    <>
      <path
        style={{transform: `translate(${0}px,${0}px)`}}
        stroke={stroke ?? palette.primary.light}
        strokeWidth="2px"
        pointerEvents="none"
        fill="none"
        d={chartPathFn(datetimeValues) ?? undefined}
        {...otherPathAttributes}
        {...dataTestId(testId)}
      />
      {fill && (
        <ChartFill
          datetimeValues={datetimeValues}
          gradient="top"
          xScale={xScale}
          yScale={yScale}
          testId={testId}
          alpha={0.2}
          overridePathAttributes={{fill: stroke ?? palette.primary.light}}
        />
      )}
    </>
  )
}

export const ChartPath = React.memo(_ChartPath)
