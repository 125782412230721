import {dataTestId} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {DeleteOutline, Edit, Close} from '@mui/icons-material'
import {Typography, Stack} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {KebabMenu, MenuAction} from '../../../../shared/components/KebabMenu'
import {useSelectedMaterialOrder} from '../../../../shared/components/providers/SelectedMaterialOrderProvider'
import {IncomingMaterialStatus} from '../../../../shared/enums'
import {
  useCancelMaterialOrder,
  useDeleteMaterialOrder
} from '../../../../shared/hooks/api/materialOrders'
import {usePermission} from '../../../../shared/hooks/permissions/usePermission'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import type {
  CanceledIncomingMaterial,
  DeliveredIncomingMaterial,
  PlannedIncomingMaterial
} from '../../../../shared/selectors/materialOrders'

import {MaterialOrderAttachmentDownloadMenu} from './MaterialOrderAttachmentDownloadMenu'
import {useDeliveriesCardHeaderOrderInfo} from './materialOrderDeliveriesHeader.helpers'

interface MaterialOrderedDeliveriesCardHeaderProps {
  incomingMaterial: PlannedIncomingMaterial | DeliveredIncomingMaterial | CanceledIncomingMaterial
}

export const MaterialOrderedDeliveriesCardHeader: React.FC<
  MaterialOrderedDeliveriesCardHeaderProps
> = ({incomingMaterial}) => {
  const plantCode = useUrlParam('plantCode')
  const {t} = useTranslation()
  const {setSelectedMaterialOrder} = useSelectedMaterialOrder()

  const {
    amount,
    scheduledDate,
    attachments,
    scheduleAgreement: {vendorId, vendorName, documentNumber, positionNumber}
  } = incomingMaterial.order

  const canChangeMaterialOrder = usePermission('CHANGE_MATERIAL_ORDERS')

  const {mutate: cancelMaterialOrder, isLoading: isCanceling} = useCancelMaterialOrder()
  const {mutate: deleteMaterialOrder, isLoading: isDeleting} = useDeleteMaterialOrder()

  const menuActions = useMemo((): MenuAction[] => {
    if (!canChangeMaterialOrder) {
      return []
    }
    const isDisabled = isCanceling || isDeleting
    if (IncomingMaterialStatus.Planned === incomingMaterial.status) {
      return [
        {
          icon: <Edit />,
          title: t('common.edit'),
          testId: 'material_order_edit_action',
          isDisabled,
          onClick: () => setSelectedMaterialOrder(incomingMaterial.order)
        },
        {
          icon: <Close />,
          title: t('common.cancel'),
          testId: 'material_order_cancel_action',
          isDisabled,
          onClick: () =>
            cancelMaterialOrder({plantCode, materialOrderId: incomingMaterial.order.id})
        }
      ]
    }
    if (IncomingMaterialStatus.Cancelled === incomingMaterial.status) {
      return [
        {
          icon: <DeleteOutline />,
          title: t('common.delete'),
          testId: 'material_order_delete_action',
          isDisabled,
          onClick: () =>
            deleteMaterialOrder({plantCode, materialOrderId: incomingMaterial.order.id})
        }
      ]
    }
    return []
  }, [
    t,
    canChangeMaterialOrder,
    setSelectedMaterialOrder,
    deleteMaterialOrder,
    cancelMaterialOrder,
    incomingMaterial,
    isCanceling,
    isDeleting,
    plantCode
  ])
  const isFutureOrder = [IncomingMaterialStatus.Planned, IncomingMaterialStatus.Cancelled].includes(
    incomingMaterial.status
  )

  const orderInfo = useDeliveriesCardHeaderOrderInfo({
    amount,
    scheduledDate,
    documentNumber,
    positionNumber
  })

  return (
    <Stack direction="row" spacing={2}>
      <Stack flex={1}>
        <CardTitle sx={{mb: 0}} {...dataTestId('vendor_name')}>
          {vendorId
            ? vendorName
            : t(
                isFutureOrder
                  ? 'stock.materialOrders.vendorNotSelected'
                  : 'stock.materialOrders.unknownVendor'
              )}
        </CardTitle>
        <Typography variant="caption" {...dataTestId('ordered_delivered_amount_and_date')}>
          {orderInfo}
        </Typography>
      </Stack>
      {attachments && <MaterialOrderAttachmentDownloadMenu attachments={attachments} />}
      <KebabMenu actions={menuActions} />
    </Stack>
  )
}
