import {dataTestId} from '@hconnect/uikit/src/common'
import {useTheme} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useCallback} from 'react'

import {getAmpelBackgroundColorStyle} from '../../../ampel.styles'
import {PricePerHour} from '../../../interfaces/common'
import {Canvas2D} from '../../canvas/Canvas2D'

export interface ElectricityPriceTopBarColoredBackgroundProps {
  hoursList: Moment[]
  hourlyPriceHeight: number
  cellWidth: number
  pricePerHour: PricePerHour | undefined
}

export const _ElectricityPriceTopBarColoredBackground: React.FC<
  ElectricityPriceTopBarColoredBackgroundProps
> = ({hoursList, hourlyPriceHeight, cellWidth, pricePerHour}) => {
  const {palette} = useTheme()
  const canvasHeight = hourlyPriceHeight

  const canvasWidth = cellWidth * hoursList.length
  const adjustedCellWidth = (canvasWidth - 2) / hoursList.length

  const renderCanvas = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight)
      ctx.fillStyle = palette.grey[200]
      ctx.fillRect(0, 0, canvasWidth, canvasHeight)

      for (let i = 0; i < hoursList.length; i++) {
        const level = pricePerHour?.[hoursList[i].toISOString()]?.level
        const bg = getAmpelBackgroundColorStyle(level).backgroundColor
        ctx.strokeStyle = bg
        ctx.fillStyle = bg

        const startX = i * adjustedCellWidth
        const endX = i + 1 < hoursList.length ? (i + 1) * adjustedCellWidth : canvasWidth
        ctx.fillRect(startX, 0, endX - startX, hourlyPriceHeight)
      }
    },
    [
      canvasWidth,
      canvasHeight,
      adjustedCellWidth,
      hoursList,
      pricePerHour,
      palette.grey,
      hourlyPriceHeight
    ]
  )

  return (
    <Canvas2D
      renderCanvas={renderCanvas}
      width={canvasWidth}
      height={canvasHeight}
      style={{backgroundColor: palette.grey[200]}}
      {...dataTestId('electricity-price-top-bar-colored-background')}
    />
  )
}

export const ElectricityPriceTopBarColoredBackground = React.memo(
  _ElectricityPriceTopBarColoredBackground
)
