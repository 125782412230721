import moment, {Moment} from 'moment-timezone'
import {useMemo} from 'react'

import {useCurrentTime} from './useCurrentTime'

const INTERVAL_MS = 1000 * 30

interface CurrentTimeRoundedParams {
  timezoneId: string
  roundingFn: (datetime: Moment) => Moment
}

/**
 * hook to return a rounded current time only whenthe rounded time changes
 */
export const useCurrentTimeRounded = ({roundingFn, timezoneId}: CurrentTimeRoundedParams) => {
  const now = useCurrentTime({timezoneId, intervalMs: INTERVAL_MS})
  const nowISO = roundingFn(now).toISOString()

  return useMemo(() => moment.utc(nowISO).tz(timezoneId), [nowISO, timezoneId])
}
