import moment from 'moment-timezone'

import {computePriceLevelsByQuantile, getPricePerHour} from '../../../ampel'
import {mergeActualAndForecast} from '../../../selectors'
import type {MomentRange} from '../../../selectors/time'
import {usePlantConfig} from '../../usePlantConfigData'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

/**
 * hook to provide electricity prices data used in planning charts
 */

export const useElectricityPricesDataQuery = (range: MomentRange) => {
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId} = usePlantConfig()
  const [start, end] = range

  return usePlannerQuery(
    'electricityPrice',
    [{plantCode, from: start.toISOString(), to: end.toISOString()}],
    {
      enabled: Boolean(plantCode),
      select: (electricityPrices) => {
        const {currency} = electricityPrices
        const actual = electricityPrices.actual.map((entry) => ({
          datetime: moment.utc(entry.datetime),
          value: entry.value
        }))
        const forecast = electricityPrices.forecast.map((entry) => ({
          datetime: moment.utc(entry.datetime),
          value: entry.value
        }))
        const mergedPrices = mergeActualAndForecast({
          actual,
          forecast,
          startDatetime: start,
          endDatetime: end,
          timezoneId
        })
        const priceLevels = computePriceLevelsByQuantile(mergedPrices)
        const pricePerHour = getPricePerHour(mergedPrices, priceLevels)
        return {
          currency,
          actual,
          forecast,
          mergedPrices,
          priceLevels,
          pricePerHour
        }
      }
    }
  )
}
