export const getAppStage = () => {
  if (process.env.REACT_APP_STAGE !== 'uat') {
    return process.env.REACT_APP_STAGE
  }

  const host = window.location.host

  // e.g. hub-qa.heidelbergcement.com or hconnecthub-prod.azureedge.net => "qa" or "prod"
  const matchTestCDNDomain = /[a-z]+-(uat|uatr)\W/.exec(host)
  if (matchTestCDNDomain) {
    return matchTestCDNDomain[1]
  }

  // e.g. hconnecttmonqa.z6.web.core.windows.net/ => "qa"
  const matchStaticHost = /(uat|uatr)\.z\d+\.web\.core\.windows\.net/.exec(host)
  if (matchStaticHost) {
    return matchStaticHost[1]
  }

  return 'uat'
}
