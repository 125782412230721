import {Material} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import {Skeleton} from '@mui/material'
import React from 'react'

import {
  useMaterialStorageDataFromHistoryQuery,
  useStoredMaterialIdsFromHistoryQuery
} from '../../shared/hooks/api'
import {useStockDevelopmentQuery} from '../../shared/hooks/api/kpi/useStockDevelopmentQuery'
import {useTargetLevelsDaily} from '../../shared/hooks/minTargetLevelsDaily'
import {usePlanRange} from '../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'
import {getListOfDays} from '../../shared/selectors/time'

import {SiloLevelsCardHeader} from './SiloLevelsCardHeader'
import {SiloLevelsChart} from './SiloLevelsChart'

interface SiloLevelsCardProps {
  material: Material
}

export const SiloLevelsCard: React.FC<SiloLevelsCardProps> = ({material}) => {
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const planTimeFrame = usePlanRange({timezoneId, createdAt})

  const {data: allMaterialIds} = useStoredMaterialIdsFromHistoryQuery({timeFrame: planTimeFrame})

  const {data: manualStockDevelopment} = useStockDevelopmentQuery({
    materialIds: allMaterialIds,
    timeframe: planTimeFrame
  })
  const {data: optimizedStockDevelopment} = useStockDevelopmentQuery({
    materialIds: allMaterialIds,
    timeframe: planTimeFrame,
    useOptimizedSchedule: true
  })
  const {data: storageData} = useMaterialStorageDataFromHistoryQuery({
    materialId: material.id,
    timeFrame: planTimeFrame
  })
  if (!storageData) {
    throw new Error('Storages should be loaded before rendering this component')
  }

  const {storageCapacity, minTargetLevel, minTargetLevelWeekend} = storageData

  const listOfDays = getListOfDays({
    start: planTimeFrame[0],
    end: planTimeFrame[1],
    additionalDays: 1
  })

  const minTargetLevelDatetimeValues = useTargetLevelsDaily({
    minTargetLevels: {week: minTargetLevel, weekend: minTargetLevelWeekend},
    listOfDays,
    timezoneId
  })

  return (
    <Card
      {...dataTestId('silo_levels_card')}
      sx={{height: '100%'}}
      headerContent={<SiloLevelsCardHeader material={material} />}
    >
      {manualStockDevelopment && optimizedStockDevelopment ? (
        <SiloLevelsChart
          listOfDays={listOfDays}
          manualStock={manualStockDevelopment[material.id].stockLevel}
          optimizedStock={optimizedStockDevelopment[material.id].stockLevel}
          storageCapacity={storageCapacity}
          targetLevel={minTargetLevelDatetimeValues}
        />
      ) : (
        <Skeleton variant="rectangular" height={280} />
      )}
    </Card>
  )
}
