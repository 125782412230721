import {useTheme, alpha as alphaValue} from '@mui/material'
import * as d3 from 'd3'
import moment from 'moment-timezone'
import React, {SVGAttributes, useMemo} from 'react'
import {v4 as uuidV4} from 'uuid'

import {dataTestId} from '@hconnect/uikit'
import {LinearScaleFn, TimeScaleFn} from '../../helpers/scale'
import {DatetimeValue} from '../../interfaces/common'

interface ChartFillProps {
  datetimeValues: DatetimeValue[]
  xScale: TimeScaleFn
  yScale: LinearScaleFn
  overridePathAttributes?: SVGAttributes<SVGPathElement>
  gradient?: 'top' | 'bottom'
  testId?: string
  alpha?: number
  startFillYValue?: number
}

export const _ChartFill: React.FC<ChartFillProps> = ({
  datetimeValues,
  xScale,
  yScale,
  overridePathAttributes,
  gradient,
  testId = 'chart-fill',
  alpha = 1,
  startFillYValue = 0
}) => {
  const {palette} = useTheme()
  const {fill, ...otherPathAttributes} = overridePathAttributes || {}
  const getAdjustedDateValues = (datetimeValues: DatetimeValue[]): DatetimeValue[] => {
    if (datetimeValues.length <= 0) return datetimeValues
    const arrayStartAddition = {datetime: datetimeValues[0].datetime, value: startFillYValue}
    const arrayEndAddition = {
      datetime: datetimeValues[datetimeValues.length - 1].datetime,
      value: startFillYValue
    }
    return [arrayStartAddition, ...datetimeValues, arrayEndAddition]
  }

  const chartPathFn = d3
    .line<DatetimeValue>()
    .x((d: DatetimeValue) => xScale(moment.utc(d.datetime)))
    .y((d: DatetimeValue) => yScale(d.value))

  const gradientColor = fill ?? palette.primary.light
  const {gradientTop, gradientBottom, gradientId} = useMemo(() => {
    const darkerColor = alphaValue(`${gradientColor}`, alpha)
    const lighterColor = alphaValue(`${gradientColor}`, 0)
    return {
      gradientTop: gradient === 'top' ? darkerColor : lighterColor,
      gradientBottom: gradient === 'top' ? lighterColor : darkerColor,
      gradientId: uuidV4()
    }
  }, [gradientColor, gradient, alpha])

  return (
    <>
      <path
        style={{transform: `translate(${0}px,${0}px)`}}
        stroke="none"
        strokeWidth="none"
        pointerEvents="none"
        fill={
          gradient ? `url(#${gradientId})` : alphaValue(`${fill ?? palette.primary.light}`, alpha)
        }
        d={chartPathFn(getAdjustedDateValues(datetimeValues)) ?? undefined}
        {...otherPathAttributes}
        {...dataTestId(testId)}
      />
      {gradient && (
        <>
          <defs>
            <linearGradient id={gradientId} gradientTransform="rotate(90)">
              <stop offset="0%" stopColor={gradientTop} />
              <stop offset="100%" stopColor={gradientBottom} />
            </linearGradient>
          </defs>
        </>
      )}
    </>
  )
}

export const ChartFill = React.memo(_ChartFill)
