import {dataTestId} from '@hconnect/uikit'
import {SectionTitle} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/system'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {PlannerSkeleton} from '../../../shared/components/skeletons/PlannerSkeleton'
import {useIncomingMaterialsQuery} from '../../../shared/hooks/api/materialOrders/useIncomingMaterialsQuery'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import type {IncomingMaterial} from '../../../shared/selectors/materialOrders'

import {MaterialDeliveriesCard} from './deliveries/MaterialDeliveriesCard'

const sortByDateAsc = (input: IncomingMaterial[]) =>
  input.sort((a, b) => a.datetime.valueOf() - b.datetime.valueOf())

export const ManageOrdersSection = () => {
  const {t} = useTranslation()
  const materialIdParam = useUrlParam('materialId')
  const materialId = Number(materialIdParam)
  const {selectedTimeframe} = useSelectedTimeframe()

  const {data: incomingMaterial} = useIncomingMaterialsQuery({
    materialId,
    timeframe: selectedTimeframe
  })

  const deliveredAmount = incomingMaterial?.delivered.length ?? 0
  const unplannedAmount = incomingMaterial?.unplanned.length ?? 0
  const plannedAmount = incomingMaterial?.planned.length ?? 0
  const canceledAmount = incomingMaterial?.canceled.length ?? 0

  const pastEntries =
    incomingMaterial &&
    sortByDateAsc([...incomingMaterial.delivered, ...incomingMaterial.unplanned]).reverse()
  const futureEntries =
    incomingMaterial && sortByDateAsc([...incomingMaterial.planned, ...incomingMaterial.canceled])
  return (
    <Stack
      direction={{xs: 'column', md: 'row'}}
      spacing={2}
      {...dataTestId('manage_orders_section')}
    >
      <Stack spacing={2} sx={{flex: 1}} {...dataTestId('active_orders')}>
        <SectionTitle {...dataTestId('past_orders_title')} sx={{mt: 1, mb: 1}}>
          {t('stock.materialOrders.activeAmount', {amount: deliveredAmount + unplannedAmount})}
        </SectionTitle>
        {pastEntries ? (
          pastEntries.map((entry, index) => (
            <MaterialDeliveriesCard key={index} incomingMaterial={entry} />
          ))
        ) : (
          <PlannerSkeleton height="15vh" />
        )}
      </Stack>
      <Stack spacing={2} sx={{flex: 1}} {...dataTestId('future_orders')}>
        <SectionTitle {...dataTestId('future_orders_title')} sx={{mt: 1, mb: 1}}>
          {t('stock.materialOrders.futureAmount', {amount: plannedAmount + canceledAmount})}
        </SectionTitle>
        {futureEntries ? (
          futureEntries.map((entry, index) => (
            <MaterialDeliveriesCard key={index} incomingMaterial={entry} />
          ))
        ) : (
          <>
            <PlannerSkeleton height="10vh" />
            <PlannerSkeleton height="8vh" />
          </>
        )}
      </Stack>
    </Stack>
  )
}
