import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Typography, Link} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HighlightedKPIs} from './HighlightedKPIs'

interface KPIBoxProps {
  title: string
  producedTons: number
  producedTonsDifference?: number
  totalPrice: number
  totalPriceDifference?: number
  pricePerTon: number
  pricePerTonDifference?: number
  pricePerMWh: number
  pricePerMWhDifference?: number
  onViewDetails: () => void
  testId?: string
  children?: React.ReactNode
}

export const KPIBox = ({
  title,
  producedTons,
  producedTonsDifference = 0,
  totalPrice,
  totalPriceDifference = 0,
  pricePerTon,
  pricePerTonDifference = 0,
  pricePerMWh,
  pricePerMWhDifference = 0,
  onViewDetails,
  testId = 'kpi_box',
  children
}: KPIBoxProps) => {
  const {t} = useTranslation()
  return (
    <CardBox sx={{height: '100%'}}>
      <Box display="flex" justifyContent="space-between">
        <Box mb={3}>
          <Typography variant="h3">{title}</Typography>
        </Box>
        <Link
          sx={{
            height: 'min-content'
          }}
          color="primary"
          variant="body2"
          onClick={onViewDetails}
          {...dataTestId(`${testId}_view_details`)}
        >
          {t('optimizer.viewDetails')}
        </Link>
      </Box>
      <Box sx={{mb: 3}}>
        <HighlightedKPIs
          testId={testId}
          producedTons={producedTons}
          totalPrice={totalPrice}
          pricePerTon={pricePerTon}
          pricePerMWh={pricePerMWh}
          producedTonsDifference={producedTonsDifference}
          totalPriceDifference={totalPriceDifference}
          pricePerTonDifference={pricePerTonDifference}
          pricePerMWhDifference={pricePerMWhDifference}
        />
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>{children}</Box>
    </CardBox>
  )
}
