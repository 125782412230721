import {useUrlParam} from '../../../hooks/useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useScheduleAgreementsByVendorQuery = () => {
  const plantCode = useUrlParam('plantCode')
  const obtainedMaterialId = useUrlParam('materialId')
  const materialId = Number(obtainedMaterialId)

  return usePlannerQuery('scheduleAgreementsByVendor', [{plantCode, materialId}])
}
